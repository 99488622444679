@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.globalMediaVideo {
  height: 100%;
  width: 100%;
  min-height: 62px;
  min-width: 62px;
  position: relative;
  background: var(--grey-sur);
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }

  .video {
    display: none;
  }

  .play {
    height: 70px;
    width: 70px;
    border-radius: 100%;
    cursor: pointer;
    background: #000000b5;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;

    .icon {
      height: 24px;
      width: 24px;
      filter: var(--icon-white);

      @include icons.ico("black-play");
    }
  }
}