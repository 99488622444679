@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/outline";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.globalRecorder {
  height: 100%;
  width: 100%;

  &.recording {
    .recordButton {
      background: var(--red) !important;
    }

    .time {
      background: var(--red) !important;
    }
  }

  .recordButton {
    background: var(--black);
    border-radius: 100%;
    height: 35px;
    left: 10px;
    top: 10px;
    width: 35px;
    position: absolute;

    @include positioning.flex-globally;
    @include outline.blue-hover(4);

    &:hover {
      transform: scale(1.1);
    }

    .icon {
      height: 15px;
      width: 15px;
      z-index: 5;
      position: absolute;
      filter: var(--icon-white);

      &.record {
        @include icons.ico("black-recorder");
      }

      &.stop {
        @include icons.ico("black-stop");
      }
    }
  }

  .time {
    background: var(--black);
    color: var(--white);
    top: 17px;
    border-radius: 11px;
    right: 12px;
    font-size: 10px;
    width: 45px;
    height: 23px;
    position: absolute;

    @include positioning.flex-globally;
  }

  .canvas {
    left: 52px;
    top: 1px;
    height: 100%;
    width: calc(100% - 116px);
    position: absolute;

  }

  .loadingContainer {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: 999999;
    border-radius: 15px;
    position: absolute;
    background: var(--white);

    @include positioning.flex-globally;
  }
}
