import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./mails-emails-recipients.scss";
import {MailsEmailsRecipientComponent} from "./recipient/MailsEmailsRecipientComponent";
import {MailsEmailsRecipientsInputComponent} from "./input/MailsEmailsRecipientsInputComponent";
import {IEntityModel} from "../../../../../../../models/entity/IEntityModel";
import {IMailsEmailsRecipient} from "./types/IMailsEmailsRecipient";
import {IMailModel} from "../../../../../../../models/mail/IMailModel";
import {EntityType} from "../../../../../../../models/entity/types/EntityType";
import {Models} from "../../../../../../../models/Models";
import {IMailsEmailsRecipientSettings} from "./types/IMailsEmailsRecipientSettings";
import {
    objectEquals
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/ObjectEquals";

export class MailsEmailsRecipientsComponent extends Component {

    public inputComponent: MailsEmailsRecipientsInputComponent;
    public recipients: IMailsEmailsRecipient[];
    public recipientComponents: MailsEmailsRecipientComponent[];
    public selectorContainer: Component;

    public settings: IMailsEmailsRecipientSettings;

    constructor(settings: IMailsEmailsRecipientSettings) {
        super();
        this.inputComponent = new MailsEmailsRecipientsInputComponent(settings);
        this.settings = settings;
        this.recipients = [];
        this.recipientComponents = [];

        //language=HTML
        this.template = `
            <div class="${s.componentMailsEmailsRecipients}">
                ${this.draw(this.inputComponent)}
                <div class="${s.selector}"></div>
            </div>
        `;
    }

    commit() {
        this.selectorContainer = this.el(s.selector);

        this.inputComponent.removeLast = () => {
            if (this.recipients.length > 0) {
                this.removeRecipient(this.recipients[this.recipients.length - 1]);
                this.onContentChange();
            }
        };

        this.inputComponent.onPick = (mail, object) => {
            if (!this.hasRecipient(mail, object)) {
                this.renderRecipient(mail, object);
                this.onContentChange();
            }
        };
        this.selectorContainer.onClick(() => this.inputComponent.focus());
        super.commit();
    }


    renderRecipient(mail: string, object?: IEntityModel | IMailModel, animate?: { color: string, viewerName: string }) {
        let entry = this.toRecipient(mail, object);
        if (!this.recipients.find(v => objectEquals(entry, v))) {
            this.recipients.push(entry);

            let recipientComponent = new MailsEmailsRecipientComponent(this.settings, mail, object);
            recipientComponent.onRemoveClick = () => {
                this.removeRecipient(entry);
                this.onContentChange();
            };
            recipientComponent.onUpdate = (oldValue, newValue) => {
                this.recipients.splice(this.recipients.indexOf(entry), 1);

                entry = this.toRecipient(newValue.mail, newValue.object);
                this.recipients.push(entry);

                this.onContentChange();
            }

            this.render(recipientComponent, false, this.inputComponent);
            if (animate) {
                recipientComponent.scaleShow(100, true);
                recipientComponent.showTooltip(animate.viewerName, 1500)
                recipientComponent.setViewerColor(animate.color);
            }

            this.recipientComponents.push(recipientComponent);
        }
    }

    renderRecipients(values: { mail: string, object: IEntityModel | IMailModel }[]) {
        values.forEach(value => this.renderRecipient(value.mail, value.object));
    }

    toRecipient(mail: string, object: IEntityModel | IMailModel): IMailsEmailsRecipient {
        let entry: IMailsEmailsRecipient = {
            mail: mail
        };

        if (object != undefined) {
            if (Models.isEntityModel(object)) {
                object = object as IEntityModel;
                entry.name = object.name;
                entry.entityId = object.id;
                entry.entityType = object.type;
            } else {
                object = object as IMailModel;
                if (object.assigned) {
                    entry.name = object.assigned.name;
                    entry.entityId = object.assigned.id;
                    entry.entityType = EntityType.ACCOUNT;
                }
            }
        }

        return entry;
    }

    hasRecipient(mail: string, object: IEntityModel | IMailModel) {
        let entry = this.toRecipient(mail, object);
        return this.recipients.filter(v => {
            if (v.entityId == undefined) {
                return v.mail == entry.mail;
            } else {
                return objectEquals(v, entry);
            }
        })[0] != undefined;
    }

    removeRecipient(recipient: IMailsEmailsRecipient, animate?: { color: string, viewerName: string }) {
        let recipientComponent = this.recipientComponents.filter(comp => objectEquals(this.toRecipient(comp.mail, comp.object), recipient))[0];
        if (recipientComponent != undefined) {
            this.recipientComponents.splice(this.recipientComponents.indexOf(recipientComponent), 1);
            this.recipients.splice(this.recipients.indexOf(recipient), 1);

            if (animate) {
                recipientComponent.showTooltip(animate.viewerName, 1000)
                recipientComponent.setViewerColor(animate.color);
                this.timeOut(async () => {
                    await recipientComponent.scaleHide(100);
                    recipientComponent.remove();
                }, 1500);
            } else {
                recipientComponent.remove();
            }
        }
    }

    onContentChange() {

    }

    removeLast() {

    }

}