import {Component} from "../../sedestral-interface-component/interface/component/Component";
import Quill, {BoundsStatic, RangeStatic, Sources, StringMap} from "quill";
import Delta from 'quill-delta';
import MagicUrl from "quill-magic-url";
import QuillCursors from 'quill-cursors';
import {IQuillContent} from "./types/IQuillContent";
import {IQuillDelta} from "./types/IQuillDelta";
import {QuillIntegrationBlot} from "./blots/integration/QuillIntegrationBlot";
import {QuillAttachmentsComponent} from "./attachments/QuillAttachmentsComponent";
import * as s from "./quill.scss";
import {SedestralMachine} from "../../sedestral-interface-component/machine/SedestralMachine";
import {QuillImageBlot} from "./blots/image/QuillImageBlot";
import {QuillQuoteBlot} from "./blots/blocks/quote/QuillQuoteBlot";
import {QuillLogic} from "./logic/QuillLogic";
import {QuillLogicPasteable} from "./logic/QuillLogicPasteable";
import {QuillLogicFormattingLine} from "./logic/QuillLogicFormattingLine";
import {QuillLogicSpellChecker} from "./logic/QuillLogicSpellChecker";
import {QuillLogicMagicUrl} from "./logic/QuillLogicMagicUrl";
import {QuillLogicLimit} from "./logic/QuillLogicLimit";
import {QuillLogicLineBreak} from "./logic/QuillLogicLineBreak";
import {QuillIntegrationsLogic} from "./logic/QuillIntegrationsLogic";
import {QuillAttachmentComponent} from "./attachments/QuillAttachmentComponent";
import {QuillLogicShiftEnter} from "./logic/QuillLogicShiftEnter";
import {QuillParagraphLineClassAttributor} from "./attributors/QuillParagraphLineClassAttributor";
import {QuillHtmlBlot} from "./blots/html/QuillHtmlBlot";
import {QuillHtmlReplyBlot} from "./blots/html/reply/QuillHtmlReplyBlot";
import {LoaderLightComponent} from "../loader/light/LoaderLightComponent";
import {QuillParagraphBlot} from "./blots/blocks/paragraph/QuillParagraphBlot";
import {Resources} from "../../../resources/Resources";
import {QuillCollaborationLogic} from "./logic/QuillCollaborationLogic";
import {QuillSectionBlot} from "./blots/section/QuillSectionBlot";
import {IQuill} from "./types/settings/IQuill";
import {elementChildrens} from "../../sedestral-interface-component/utilities/ElementChildrens";
import {fileToBase64} from "../../sedestral-interface-component/utilities/FileToBase64";
import {fileResizeImage, getImageSize} from "../../sedestral-interface-component/utilities/FileResizeImage";
import {randomString} from "../../sedestral-interface-component/utilities/RandomString";

export class QuillComponent extends Component {
    public static _ready: boolean = false;
    public static _fonts = [
        {name: "Montserrat", font: "Montserrat Medium"},
        {name: "Sans Serif", font: "arial"},
        {name: "Serif", font: "times new roman"},
        {name: "Fixed Width", font: "monospace"},
        {name: "Wide", font: 'arial black'},
        {name: "Narrow", font: "arial narrow"},
        {name: "Comic Sans MS", font: "comic sans ms"},
        {name: "Garamond", font: "garamond"},
        {name: "Georgia", font: "georgia"},
        {name: "Tahoma", font: "tahoma"},
        {name: "Trebuchet MS", font: "trebuchet ms"},
        {name: "Verdana", font: "verdana"},
    ];
    public static _quills: { id: string, quill: QuillComponent }[] = [];


    public quill: Quill;
    public quillId: string;
    public myQuill: { id: string, quill: QuillComponent };
    public quillComponent: Component;

    public attachmentsContainer: Component;
    public attachmentsComponent: QuillAttachmentsComponent;
    public loaderComponent: Component;

    public changesFunc: ((delta?: Delta, oldContents?: Delta, source?: Sources, attachmentsChange?: boolean) => any)[];
    public attachmentsChangesFunc: ((component: QuillAttachmentComponent, removed?: boolean) => any)[];
    public attachmentsUploadedFunc: ((component: QuillAttachmentComponent) => any)[];
    public entersFunc: (() => any)[];

    public lastSelection: RangeStatic;
    public lastEnterKey: number = performance.now();

    public logicIntegrations: QuillIntegrationsLogic;
    public logicCollaboration: QuillCollaborationLogic;
    public logicLineBreak: QuillLogicLineBreak;
    public logicSpellChecker: QuillLogicSpellChecker;

    public settings: IQuill;

    constructor(settings?: IQuill) {
        super();
        this.quillId = randomString(50, true);
        this.settings = settings ? settings : {};
        this.settings.lineBreak = this.settings.lineBreak != undefined ? this.settings.lineBreak : true;
        this.settings.canCrop = this.settings.canCrop != undefined ? this.settings.canCrop : true;
        this.settings.pasteable = true;

        this.lastSelection = {length: 1, index: 0};
        this.changesFunc = [];
        this.attachmentsChangesFunc = [];
        this.attachmentsUploadedFunc = [];
        this.entersFunc = [];

        this.myQuill = {quill: this, id: this.quillId};
        QuillComponent._quills.push(this.myQuill);

        //language=HTML
        this.template = `
            <div gQl class="${s.globalQuill}">
                <div qEditor class="${s.quillEditor}"></div>
                <div qAttachments class="${s.attachmentsContainer}"></div>
            </div>`;
    }

    commit() {
        super.commit();
        this.quillComponent = this.el(s.quillEditor);

        this.registerAll();
        this.registerAttributors();
        this.registerQuill();
        this.registerAttachments();
        this.registerLogics();
        this.registerBinds();

    }

    /**
     * registers
     */

    registerAll() {
        if (!QuillComponent._ready) {
            QuillComponent._ready = true;

            let ColorClass = Quill.import('attributors/style/color');
            Quill.register(ColorClass, true);

            let AlignClass = Quill.import('attributors/style/align');
            Quill.register(AlignClass, true);

            let Size = Quill.import('attributors/style/size');
            Size.whitelist = ['2em', '1.5em', '1.17em', '14px'];
            Quill.register(Size, true);

            let Font = Quill.import('attributors/style/font');
            Font.whitelist = ['Montserrat Medium', 'montserrat', 'arial', 'times new roman', 'monospace', 'arial black', 'arial narrow', 'comic sans ms', 'garamond', 'georgia', 'tahoma', 'trebuchet ms', 'verdana'];
            Quill.register(Font, true);

            Quill.register('modules/magicUrl', MagicUrl);
            Quill.register('modules/cursors', QuillCursors, true);
            Quill.register(QuillImageBlot, true);
            Quill.register(QuillHtmlReplyBlot, true);
            Quill.register(QuillHtmlBlot, true);
            Quill.register('formats/block', QuillParagraphBlot, true);
            Quill.register(QuillIntegrationBlot, true);
            Quill.register(QuillQuoteBlot, true);
            Quill.register(QuillSectionBlot, true);
        }
    }

    registerAttributors() {
        Quill.register('formats/linebreak', QuillParagraphLineClassAttributor, true);
    }


    registerQuill() {
        let modules: any = {
            clipboard: {
                matchVisual: false,
            }
        };

        if (this.settings.magicUrl) {
            modules.magicUrl = true;
        }


        if (this.settings.collaboration) {
            modules.cursors = {
                transformOnTextChange: false
            }
        }

        this.quill = new Quill(this.quillComponent.getHTMLElement(), {
            placeholder: this.settings.placeholder ? this.settings.placeholder : Resources.t("words.messagePlaceholder"),
            modules: modules
        });

        this.quill['quillComponent'] = this;
        this.quillComponent.getHTMLElement()['quill'] = this.quill;
        this.quill.root.setAttribute("qleditor", "");

        this.scrollable();
    }

    registerLogic(logic: QuillLogic) {
        logic.init();
    }

    registerLogics() {
        if (this.settings.pasteable) {
            this.registerLogic(new QuillLogicPasteable(this));
        }
        if (this.settings.formattingLine) {
            this.registerLogic(new QuillLogicFormattingLine(this));
        }

        this.logicSpellChecker = new QuillLogicSpellChecker(this, this.settings.spellChecker);
        this.registerLogic(this.logicSpellChecker);

        if (this.settings.magicUrl) {
            this.registerLogic(new QuillLogicMagicUrl(this));
        }

        if (this.settings.collaboration?.editorId) {
            this.registerLogicCollaboration();
        }

        if (this.settings.shiftEnterLineBreak) {
            this.registerLogic(new QuillLogicShiftEnter(this));
        }

        this.registerLogic(new QuillLogicLimit(this));

        this.logicLineBreak = new QuillLogicLineBreak(this);
        this.registerLogic(this.logicLineBreak);

        this.logicIntegrations = new QuillIntegrationsLogic(this);
        this.registerLogic(this.logicIntegrations);
    }

    registerLogicCollaboration() {
        this.logicCollaboration = new QuillCollaborationLogic(this, this.settings.collaboration?.productType);
        this.registerLogic(this.logicCollaboration);
    }

    registerAttachments() {
        this.attachmentsContainer = this.el(s.attachmentsContainer);
        if (this.settings.attachment) {
            this.attachmentsComponent = new QuillAttachmentsComponent(this);
            this.attachmentsContainer.render(this.attachmentsComponent);
            this.attachmentsComponent.onAttachmentUploaded = component => this.executeAttachmentsUploaded(component);

            if (this.settings.emptyText) {
                this.attachmentsContainer.pendTop();
            }
        }
    }

    registerBinds() {
        this.quill.on('selection-change', (range) => {
            if (range != undefined) {
                this.lastSelection = range;
            }
        });

        this.quill.on('text-change', (delta, oldContents, source) => {
            SedestralMachine.requestFrame()(() => {
                let range = this.quill.getSelection();
                if (range != undefined) {
                    this.lastSelection = range;
                }
            })
            this.executeContentChange(delta, oldContents, source);
        });
    }

    /**
     * settings
     */

    setSpellChecker(value: boolean): void {
        this.settings.spellChecker = value;
        if (this.logicSpellChecker) {
            this.logicSpellChecker.destroy();
        }

        this.logicSpellChecker = new QuillLogicSpellChecker(this, this.settings.spellChecker);
        this.registerLogic(this.logicSpellChecker);
    }

    setLineBreak(value: string): void {
        this.settings.lineMode = value;
        if (this.logicLineBreak) {
            this.logicLineBreak.destroy();
        }

        this.logicLineBreak = new QuillLogicLineBreak(this);
        this.registerLogic(this.logicLineBreak);
    }

    /**
     * html manipulation
     */

    setContent(html: string) {
        if (html != undefined) {
            this.quill.clipboard.dangerouslyPasteHTML(html);
        }
    }

    public streamContent(text: string, delay: number): void {
        let quillIndex = this.lastSelection.index;
        let index = 0;
        this.setContent("");
        let interval = this.interval(() => {
            if (index < text.length) {
                this.quill.insertText(quillIndex, text[index], "silent");
                quillIndex++;
                index++;
            } else {
                clearInterval(interval);
            }
        }, delay / text.length);
    }

    reset() {
        this.quill.setText('');
        this.lastSelection = {length: 1, index: 0};

        if (this.attachmentsComponent != undefined) {
            this.attachmentsComponent.reset();
        }

        this.executeContentChange(undefined, undefined, "silent");
    }

    limit(): number {
        let lessLimit = 9999999999;
        for (let limit of this.settings.limits) {
            if (lessLimit > limit.limit) {
                lessLimit = limit.limit;
            }
        }

        return lessLimit;
    }

    /**
     * result
     */

    contents(selection?: RangeStatic): IQuillContent {
        if (selection == undefined) {

            let buffer = this.cleanHtml(this.quill.root);
            let contents = {
                html: buffer.innerHTML,
                text: this.quill.getText()
            };

            if (this.settings.integrations) {
                return this.logicIntegrations.parseHtml(contents);
            } else {
                return contents;
            }
        } else {
            return {
                text: this.readOps(this.quill.getContents(selection.index, selection.length)).insert,
                html: undefined
            };
        }
    }

    links(): string[] {
        let result = [];
        let list = this.el("ql-editor").getHTMLElement().getElementsByTagName("a");

        for (let i = 0; i < list.length; i++)
            result.push(list[i].href);

        return result;
    }

    length(): number {
        return this.quill.getLength();
    }

    lengthWithoutSpaces(): number {
        return this.contents().text.replace(/\s/g, "").length;
    }

    currentFormats(): StringMap {
        return this.quill.getFormat(this.lastSelection);
    }

    deltaContents(): string {
        let data = this.quill.getContents();
        return JSON.stringify(this.cleanDeltas(data));
    }

    /**
     * cleaners
     */
    cleanDeltas(deltas): any {
        deltas = {...deltas};

        for (let key in deltas) {
            if (deltas.hasOwnProperty(key)) {
                if (deltas[key] !== null && typeof deltas[key] === 'object') {
                    if (Array.isArray(deltas[key])) {
                        for (let i = 0; i < deltas[key].length; i++)
                            deltas[key][i] = this.cleanDeltas(deltas[key][i]);
                    } else {
                        deltas[key] = this.cleanDeltas(deltas[key]);
                    }
                } else {
                    if (key == "quillId" || key.startsWith("sedestral")) {
                        delete deltas[key];
                    }
                }
            }
        }


        return deltas;
    }

    cleanHtml(node: HTMLElement): HTMLElement {
        let buffer = document.createElement("div");
        buffer.innerHTML = node.outerHTML.trim()
            .replaceAll("<p><br></p>", "<br>");

        elementChildrens(buffer).forEach(element => {
            element.removeAttribute("contenteditable");
            element.removeAttribute("sdt-id");
            element.getAttributeNames().forEach(attrName => {
                let attr = element.getAttribute(attrName);
                if (!attr || attr == "undefined") {
                    element.removeAttribute(attrName);
                }
            })

            if (element.className.includes("sedestral-Reply")) {
                element.className = "sedestral-Reply";
            }

            if (element.tagName == "P" && element.innerHTML.trim().length == 0) {
                element.parentElement.removeChild(element);
            }

            if (element.tagName == "SPAN" && element.className.includes("ql-ui") && element.innerHTML.trim().length == 0) {
                element.parentElement.removeChild(element);
            }
        });

        return buffer.firstElementChild as HTMLElement;
    }

    /**
     * inserts
     */

    async insertImage(file: File | string, user: boolean) {

        this.focusScroll();

        if (file instanceof File) {
            fileToBase64(file, (data) => {
                let img = new Image();
                img.src = data;
                img.onload = () => {
                    let selection = this.quill.getSelection(true);
                    let index = selection.index;

                    let resize = fileResizeImage(img.width, img.height, 300, 300);

                    this.quill.insertEmbed(index, "image", {
                        upload: true,
                        height: resize.height,
                        width: resize.width
                    }, "user");

                    let blot = this.quill.getLeaf(index + 2)[0];
                    if (blot == undefined || blot instanceof QuillHtmlReplyBlot) {
                        this.insertParagraph(index + 1);
                    }

                    SedestralMachine.requestFrame()(() => {
                        let blot = this.getBlotAtIndex(index);
                        this.onInsertedImageFile(blot, file, data, img.height, img.width);

                        this.quill.setSelection(index, 1, "user");
                    });
                }
            });
        } else {

            let sizeImage = await getImageSize(file);
            if (sizeImage) {

                let resize = fileResizeImage(sizeImage.width, sizeImage.height, 300, 300);

                let selection = this.quill.getSelection(true);
                let index = selection.index;
                this.quill.insertEmbed(index, "image", {
                    src: file,
                    height: resize.height,
                    width: resize.width
                }, user ? "user" : "api");

                let blot = this.quill.getLeaf(index + 2)[0];
                if (blot == undefined || blot instanceof QuillHtmlReplyBlot) {
                    this.insertParagraph(index + 1);
                }

                SedestralMachine.requestFrame()(() => {
                    this.quill.setSelection(index, 1, "user");
                });
            }
        }
    }

    insertLink(text: string, url: string, user: boolean) {
        let scrollTop = this.getHTMLElement().scrollTop;
        const r = this.quill.getSelection(true)
        this.quill.updateContents(new Delta().retain(r.index).delete(r.length).insert(text, {link: url}), user ? "user" : "api");

        this.focusScroll();
        this.quill.setSelection(r.index, text.length);
        SedestralMachine.requestFrame()(() => {
            let currentSelection = this.quill.getSelection();
            this.quill.setSelection(currentSelection.index + currentSelection.length, 0);
            this.setScrollTop(scrollTop);
        });
    }

    insertParagraph(index: number) {
        const delta = new Delta()
            .retain(index)
            .insert('\n');
        this.quill.updateContents(delta, "user");
    }

    insertText(text: string, index?: number) {
        index = index ? index : this.lastSelection.index;
        this.quill.insertText(index, text, "user");
        this.focusScroll();
        this.quill.setSelection(index + text.length, 0);
    }

    /**
     * general blots manipulation
     */
    getBlotIndex(node: Node): number {
        let blot = Quill.find(node);
        return blot.offset(this.quill.scroll);
    }

    getBlotAtIndex(index: number): any {
        let [blot] = this.quill.getLeaf(index + 1);
        return blot;
    }

    getActiveBlot(): any {
        return this.getBlotAtIndex(this.lastSelection.index);
    }

    /**
     * matching
     */
    onMatch(matcher: string, callback: (status: boolean, matchedText: string, index: number) => void) {
        let calc = (index: number) => {
            let text = this.quill.getText(0, index + 1);
            let slashIndex = text.lastIndexOf("/");
            if (slashIndex >= 0) {
                if (slashIndex == 0 || text[slashIndex - 1] == " " || text[slashIndex - 1] == undefined || text[slashIndex - 1].trim().length == 0) {
                    let matchedText = text.substring(slashIndex + 1, index + 1);
                    let regex = /^[a-zA-Z0-9]*$/;
                    if (regex.test(matchedText.replace(/\n$/, ""))) {
                        callback(true, matchedText, index);
                    } else {
                        callback(false, undefined, index);
                    }
                } else {
                    callback(false, undefined, index);
                }
            } else {
                callback(false, undefined, index);
            }
        };

        this.quill.on('selection-change', (range, oldContents, source) => {
            if (range != undefined && source == "user") {
                calc(range.index - 1);
            }
        });
        this.quill.on('text-change', (delta, oldContents, source) => {
            if (source == "user") {
                let calcIndex = this.lastSelection.index;
                calc(calcIndex);
                SedestralMachine.requestFrame()(() => {
                    if (this.lastSelection.index != calcIndex) {
                        calc(this.lastSelection.index);
                    }
                });
            }
        });
    }

    /**
     * deltas
     */

    setDeltas(deltas: any) {
        this.quill.setContents(deltas);
    }

    readOps(deltas: any): IQuillDelta {
        let insert = "";
        let retain = 0;
        for (let ops of deltas.ops) {
            if (ops.hasOwnProperty("insert")) {
                insert += ops.insert;
            }
            if (ops.hasOwnProperty("retain")) {
                retain = ops.retain;
            }
        }

        return {insert: insert, retain: retain};
    }

    readOpsInserts(operations: any[]): string {
        let insert = "";
        for (let ops of operations) {
            if (ops.insert != undefined) {
                insert += ops.insert;
            }
        }
        return insert;
    }

    getOps(name: string, deltas?: any): any[] {
        let opses = [];
        for (let ops of (deltas ? deltas : this.quill.getContents()).ops) {
            if (ops.insert.hasOwnProperty(name)) {
                opses.push(ops.insert[name]);
            }
        }

        return opses;
    }

    /**
     * others logic
     */
    getBounds(index: number, length: number): BoundsStatic {
        return this.quill.getBounds(index, length);
    }

    focus() {
        this.quill.focus();
    }

    focusScroll() {
        this.quill.root.focus({preventScroll: true});
        this.quill.setSelection(this.lastSelection)
    }

    focusAtEnd() {
        this.quill.focus();
        this.quill.setSelection(this.quill.getLength(), 0);
    }

    isHtmlBlot(): boolean {
        let activeBlot = this.getBlotAtIndex(this.lastSelection.index - 1);
        return activeBlot instanceof QuillHtmlBlot;
    }

    contentId(): string {
        return this.settings.collaboration?.editorId ? this.settings.collaboration?.editorId :
            this.quillId;
    }

    /**
     * mode
     */

    isReadOnly(): boolean {
        return this.quill.root.getAttribute("contenteditable") == "false";
    }

    setReadOnly(): void {
        this.quill.root.setAttribute("contenteditable", "false");
    }

    remReadyOnly() {
        this.quill.root.setAttribute("contenteditable", "true");
    }

    /**
     * attachments
     */

    async beforeAddAttachment(file: File): Promise<boolean> {
        return true;
    }

    addAttachment(file: File | string) {
        this.attachmentsComponent.addAttachment(file);
    }

    /**
     * loader
     */

    renderLoader() {
        if (!this.loaderComponent) {
            //language=HTML
            this.loaderComponent = this.append(`
                <div qLoader class="${s.loader}">
                    ${this.draw(new LoaderLightComponent())}
                </div>`);
        }
    }

    removeLoader() {
        this.loaderComponent.remove();
    }

    /**
     * execute events
     */
    executeContentChange(delta: Delta, oldContents: Delta, source: Sources, attachmentsChange?: boolean) {
        this.changesFunc.forEach(value => {
            value(delta, oldContents, source, attachmentsChange);
        });
    }

    executeAttachmentsChange(component: QuillAttachmentComponent, removed?: boolean) {
        this.attachmentsChangesFunc.forEach(value => {
            value(component, removed);
        });
    }

    executeAttachmentsUploaded(component: QuillAttachmentComponent) {
        this.attachmentsUploadedFunc.forEach(value => {
            value(component);
        });
    }

    executeEnterKey() {
        if (this.lastEnterKey < (performance.now() - this.settings.antiSpam)) {
            this.lastEnterKey = performance.now();
            this.entersFunc.forEach(value => {
                value();
            });
        }
    }

    /**
     * events
     */

    onContentChange(component: Component, func: (delta?: Delta, oldContents?: Delta, source?: Sources, attachmentsChange?: boolean) => any) {
        this.changesFunc.push(func);
        component.onRemove(() => this.changesFunc.splice(this.changesFunc.indexOf(func), 1));
    }

    onAttachmentsChange(component: Component, func: (component: QuillAttachmentComponent, removed?: boolean) => any) {
        this.attachmentsChangesFunc.push(func);
        component.onRemove(() => this.attachmentsChangesFunc.splice(this.attachmentsChangesFunc.indexOf(func), 1));
    }

    onAttachmentsUploader(component: Component, func: (component: QuillAttachmentComponent) => any) {
        this.attachmentsUploadedFunc.push(func);
        component.onRemove(() => this.attachmentsUploadedFunc.splice(this.attachmentsUploadedFunc.indexOf(func), 1));
    }

    onEnterKey(func: () => any) {
        this.entersFunc.push(func);
    }

    onInsertedImageFile(blot: any, file: File, data: string, height: number, width: number) {

    }

    /**
     * end
     */
    destroy() {
        //this.quill.blur();
        this.quill.disable();
        QuillComponent._quills.splice(QuillComponent._quills.indexOf(this.myQuill), 1);
        if (this.logicCollaboration) {
            this.logicCollaboration.dispose();
        }

        super.destroy();
    }
}