import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./media-image-cropper-ratio.scss";
import {
    ChannelIconComponent
} from "../../../../../../products/panel/components/components/channel/icon/ChannelIconComponent";
import {channelTypeName} from "../../../../../../models/channel/ChannelType";
import {IMediaImageCropperRatio} from "./types/IMediaImageCropperRatio";
import {Resources} from "../../../../../../resources/Resources";

export class MediaImageCropperRatioComponent extends Component {

    public ratios: IMediaImageCropperRatio[];
    public selectedEntry: Component;

    constructor(ratios: IMediaImageCropperRatio[]) {
        super();
        this.ratios = ratios;
        //language=HTML
        this.template = `
            <div class="${s.componentMediaImageCropperRatio}"></div>
        `;
    }

    commit() {
        this.ratios.forEach(ratio => {
            //language=HTML
            let container = this.append(`
                <div class="${s.ratio}">
                    <div class="${s.names}">
                        <div class="${s.icon}">
                            ${this.draw(new ChannelIconComponent(ratio.channelType))}
                        </div>
                        <div class="${s.name}">${Resources.t(`words.channels.${channelTypeName(ratio.channelType)}.name`)}
                        </div>
                    </div>
                    <div class="${s.entries}"></div>
                </div>
            `);

            let entriesContainer = container.el(s.entries);
            ratio.ratios.forEach(value => {
                //language=HTML
                let entry = entriesContainer.append(`
                    <div class="${s.entry}">
                        <div style="width: calc(50px * ${value.first / value.second});" class="${s.shape}">
                            ${value.first} : ${value.second}
                        </div>
                        <div class="${s.name}">
                            ${value.name}
                        </div>
                    </div>
                `);

                entry.onClick(() => {
                    if (this.selectedEntry) {
                        this.selectedEntry.removeClass(s.selected);
                    }

                    this.selectedEntry = entry;
                    entry.addClass(s.selected);
                    this.onSelect(value.first / value.second);
                });
            });

        });

        super.scrollable();
        super.commit();
    }

    onSelect(ratio: number) {

    }
}