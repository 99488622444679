import {
    VisualClickTooltipWhiteComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {AssignmentPickerComponent} from "../AssignmentPickerComponent";
import {IAssignmentSettings} from "../../types/IAssignmentSettings";

export class AssignmentPickerTooltipComponent extends VisualClickTooltipWhiteComponent {
    public picker: AssignmentPickerComponent;

    constructor(component: Component, settings: IAssignmentSettings) {
        super(component, 0, 290);
        this.picker = new AssignmentPickerComponent(settings);
        this.template = this.draw(this.picker);
        this.mobileStyle(true);
        this.autoMaxHeight(true);
    }

    commit() {
        this.setStyle(`padding: 6px 0px 0px 0px;border-radius: 14px;`);
        this.swapable(() => this.dispose());
        super.commit();
    }

    calcMiddle(): number {
        return super.calcLeft();
    }

}