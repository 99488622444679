import {MediaFileComponent} from "../file/MediaFileComponent";
import {MediaAbstractComponent} from "../MediaAbstractComponent";
import {MediaGifComponent} from "../gif/MediaGifComponent";
import {MediaImageComponent} from "../image/MediaImageComponent";
import {MediaVideoComponent} from "../video/MediaVideoComponent";
import {MediaAudioComponent} from "../audio/MediaAudioComponent";
import {MediaLinkComponent} from "../link/MediaLinkComponent";
import {Models} from "../../../../models/Models";
import {MediaErrorComponent} from "../error/MediaErrorComponent";
import {
    IInboxConversationMessageAttachmentModel
} from "../../../../models/inbox/conversation/message/attachment/IInboxConversationMessageAttachmentModel";
import {IPreviewModel} from "../../../../models/preview/IPreviewModel";
import {PreviewService} from "../../../../services/preview/PreviewService";
import {IFileModel} from "../../../../models/file/IFileModel";
import {PreviewType} from "../../../../models/preview/PreviewType";
import {AttachmentType} from "../../../../models/enums/AttachmentType";
import {fileUrlExtension} from "../../../sedestral-interface-component/utilities/FileUrlExtension";
import {fileIsImage} from "../../../sedestral-interface-component/utilities/FileIsImage";
import {fileIsAudio} from "../../../sedestral-interface-component/utilities/FileIsAudio";
import {fileIsGif} from "../../../sedestral-interface-component/utilities/FileIsGif";
import {fileIsVideo} from "../../../sedestral-interface-component/utilities/FileIsVideo";

export class MediaFactory {
    static generate(object: any, uploaded?: any): MediaAbstractComponent {
        if (uploaded == undefined) {
            return new MediaErrorComponent();
        }

        let type = MediaFactory.type(object, uploaded);
        let uploadedPreviewModel = Models.isPreviewModel(uploaded);

        if (type != undefined) {
            if (fileIsGif(type)) {
                return new MediaGifComponent(object);
            } else if (fileIsImage(type)) {
                return new MediaImageComponent(object);
            } else if (fileIsVideo(type)) {
                return new MediaVideoComponent(object, false);
            } else if (fileIsAudio(type)) {
                return new MediaAudioComponent(object);
            }
        }

        let objectMediaModel = Models.isMediaModel(object);

        if (object instanceof File || objectMediaModel) {
            return new MediaFileComponent(object);
        }

        if (uploadedPreviewModel) {
            return new MediaLinkComponent(uploaded);
        }

        return undefined;
    }

    static type(object: any, uploaded?: any): string {
        let objectMediaModel = Models.isMediaModel(object);
        let uploadedPreviewModel = Models.isPreviewModel(uploaded);

        if (uploadedPreviewModel) {
            return uploaded.typeStr;
        } else if (objectMediaModel) {
            return object.extension;
        } else if (object instanceof File) {
            return object.type;
        } else {
            return fileUrlExtension(object);
        }
    }

    static attachmentToComponent(attachment: IInboxConversationMessageAttachmentModel): MediaAbstractComponent {
        if (attachment.type == AttachmentType.PREVIEW) {
            return this.previewToComponent(attachment.content as IPreviewModel);
        }

        let content: IFileModel = attachment.content as IFileModel;
        return MediaFactory.generate(content, content);
    }

    static previewToComponent(preview: IPreviewModel): MediaAbstractComponent {
        if (preview.ping && ((preview.title || preview.description || preview.fileUrl) || preview.type != PreviewType.WEBSITE)) {
            return MediaFactory.generate(PreviewService.proxyUrl(preview), preview);
        }
    }
}