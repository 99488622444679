@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/effects";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";


.componentAccountNavigation {
  height: 100%;
  width: 100%;

  .navigationContent {
    width: 100%;
    height: calc(100% - 41px);
    position: absolute;
  }

  .navigationButtons {
    height: 40px;
    border-top: var(--grey-sur) 1px solid;
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;
    background: var(--white);

    .button {
      width: calc(50% - 1px);
      height: 100%;
      font-size: 13px;
      transition: 0.1s;
      position: relative;
      float: left;
      cursor: pointer;

      @include effects.hover-alpha;
      @include texts.font("bold");
      @include positioning.flex-globally;

      &.selected {
        visibility: visible;
      }

      &.board {
        visibility: visible;
      }

      &.notifications {
        visibility: visible;
      }

      &:first-child {
        border-right: var(--grey-sur) 1px solid;
      }

      .notifs {
        height: 18px;
        width: 18px;
        border-radius: 100%;
        margin-left: 3px;
        color: var(--white);
        font-size: 9px;
        background: var(--red);
        float: left;

        @include texts.font("bold");
        @include positioning.flex-globally;
      }
    }
  }
}