import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {ShortcutsListComponent} from "./list/ShortcutsListComponent";
import * as s from "./shortcuts.scss";
import {IInboxShortcutModel} from "../../../../../models/inbox/shortcut/IInboxShortcutModel";
import {
    EmptyBasicComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/empty/EmptyBasicComponent";
import {Resources} from "../../../../../resources/Resources";

export class ShortcutsComponent extends Component {

    public buttons: boolean;
    public head: boolean;
    public pocket: boolean;
    public useScrollbar: boolean;

    public listContainer: Component;
    public shortcuts: IInboxShortcutModel[];

    constructor(head: boolean, useScrollbar: boolean, pocket?: boolean) {
        super();
        this.head = head;
        this.pocket = pocket;
        this.useScrollbar = useScrollbar;

        this.template = `
            <div shortcuts class="${s.componentShortcuts}">
                <div class="${s.listContainer}"></div>
            </div>
        `;
    }

    commit(): void {
        super.commit();
        this.listContainer = this.el(s.listContainer);

        if (this.head) {
            this.renderHead();
        }

        if (this.useScrollbar) {
            this.scrollable();
        }
    }

    renderHead() {
        this.prepend(`
            <div class="${s.shortcutsHead}">
                <div shortcutsSearch class="${s.searchBloc}">
                    <div shortcutsSearchIcn class="${s.icon}"></div>
                    <input placeholder="${Resources.t("words.search")}" class="${s.input}"/>
                </div>
                <div shortcutsAdd class="${s.addButton}">
                    <div>
                        <div class="${s.icon}"></div>
                        <div class="${s.text}">${Resources.t("words.new")}</div>
                    </div>
                </div>
            </div>
        `);
        this.el(s.addButton).onClick(() => this.onAddClick());
        let input = this.el(s.input);
        input.onKey(() => this.renderShortcuts(this.shortcuts, this.buttons, input.getValue()), 0);
    }


    renderShortcuts(shortcuts: IInboxShortcutModel[], buttons?: boolean, filter?: string) {
        if (filter == undefined) {
            this.shortcuts = [...shortcuts];
        }

        shortcuts = [...shortcuts];
        this.buttons = buttons;

        this.removeClass(s.shine);
        this.listContainer.clearAll();

        if (filter != undefined && filter.length > 0) {
            shortcuts = this.filter(shortcuts, filter);
        }

        let list = new ShortcutsListComponent(shortcuts);
        list.onValidateSelect = (component) => this.onValidateSelect(component);
        this.listContainer.render(list);

        if (buttons) {
            list.renderButtons();
        }


        if (shortcuts.length == 0) {
            this.listContainer.clearAll();
            this.renderGhost(false, this.pocket ? 8 : 10);
            let basic = new EmptyBasicComponent(
                `<div class="${s.icon}"></div>`,
                `${Resources.t("words.shortcutsExplanation")}`,
                `${Resources.t("words.createFirstShortcut")}`
            );
            basic.onButtonClick = () => this.onAddClick();
            //language=HTML
            this.listContainer.append(`
                <div class="${s.emptyContainer} ${!this.pocket ? `` : s.pocket}">
                    ${this.listContainer.draw(basic)}
                </div>`);

        } else if (buttons && shortcuts.length < 5) {
            this.renderGhost(false, 10);
        }

    }

    renderGhost(shine?: boolean, length?: number) {
        if (shine) {
            this.listContainer.clearAll();
            this.addClass(s.shine);
        }


        let list = new ShortcutsListComponent([]);
        this.listContainer.render(list);
        list.renderGhost(length);
    }

    remGhost() {
        this.removeClass(s.shine);
        this.listContainer.clearAll();
    }

    filter(shortcuts: IInboxShortcutModel[], filter: string): IInboxShortcutModel[] {
        let result = [];
        for (let shortcut of shortcuts) {
            if (shortcut.name.toLowerCase().trim().includes(filter.toLowerCase().trim())) {
                result.push(shortcut);
            }
        }

        return result;
    }

    onValidateSelect(component: Component) {

    }

    onAddClick(shortcut?: IInboxShortcutModel) {

    }
}