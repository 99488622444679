import {Network} from "../../network/Network";
import {AccountService} from "../account/AccountService";
import {RoleService} from "../roles/RoleService";
import {IFindAssignment} from "./types/IFindAssignment";
import {ContactService} from "../contact/ContactService";
import {CancelTokenSource} from "axios";
import {IResultAssignment} from "./types/IResultAssignment";
import {MailService} from "../mail/MailService";
import {ProductType} from "../../models/product/ProductType";

export class AssignmentService {

    public static dispose(): void {
    }

    public static init(): void {

    }

    /**
     * http
     */

    public static find(s: IFindAssignment): {
        cancelable: CancelTokenSource,
        promise: Promise<{ data: IResultAssignment }>
    } {
        if (s.assignmentType == undefined) {
            s.assignmentType = 0;
        }

        let cancelable: any;
        let request = Network.post(ProductType.PANEL, "/assignments", {
            siteId: s.siteId,
            type: s.assignmentType,
            contentId: s.contentId,
            additionalAccounts: s.additionalAccounts,
            accounts: s.accounts,
            roles: s.roles,
            mails: s.mails,
            limitAccounts: s.limitAccounts,
            limitContacts: s.limitContacts,
            limitMails: s.limitMails,
            limitRoles: s.limitRoles,
            contacts: s.contacts,
            nameFilter: s.nameFilter,
            mailFilter: s.mailFilter
        }, s.component, {
            cancelableCallback: source => {
                cancelable = source;
            }
        });

        return {cancelable: cancelable, promise: request};
    }

    public static store(s: IFindAssignment, data: IResultAssignment): IResultAssignment {
        return {
            accounts: s.accounts ? AccountService.storeAll(data.accounts) : undefined,
            roles: s.roles ? RoleService.storeAll(data.roles) : undefined,
            contacts: s.contacts ? ContactService.storeAll(data.contacts) : undefined,
            mails: s.mails ? MailService.storeAll(data.mails) : undefined
        }
    }
}