import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./quill-limits.scss";
import {IQuillLimit} from "./types/IQuillLimit";

export class QuillLimitComponent extends Component {
    constructor(limit: IQuillLimit, currentLength: number) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.globalQuillLimit}">
                <div class="${s.limitHead}">
                    <div class="${s.channelIconContainer}">
                        ${limit.iconComponent ? this.draw(limit.iconComponent()) : ``}
                    </div>
                    <div class="${s.text}">${currentLength - 1}/${limit.limit}</div>
                </div>
                <div class="${s.progression}">
                    <div style="width: ${(currentLength * 100) / limit.limit}%;" class="${s.progress}"></div>
                </div>
            </div>
        `;
    }
}