import {QuillFormattingBaseButtonComponent} from "../QuillFormattingBaseButtonComponent";
import * as s from "./../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../QuillFormattingComponent";

export class QuillFormattingButtonQuoteComponent extends QuillFormattingBaseButtonComponent {
    constructor(formatter: QuillFormattingComponent) {
        super("blockquote", formatter);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton}">
                <div class="${s.icon} ${s.quote}"></div>
            </button>
        `;
    }
}