import {EditorButton} from "./EditorButton";
import {EditorComponent} from "../EditorComponent";
import * as s from "../editor.scss";
import {
    QuillEditorSendMenuTooltipComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/editor/send/menu/tooltip/QuillEditorSendMenuTooltipComponent";
import {
    LoaderLightComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {Resources} from "../../../../../../resources/Resources";

export class EditorButtonSend extends EditorButton {

    public simpleSend: boolean;

    constructor(editor: EditorComponent, simpleSend?: boolean) {
        super(editor);
        this.simpleSend = simpleSend;
    }

    init() {
        this.button = this.editor.rightButtonsContainer.append(`
            <div sendButton class="${s.button}"></div>`);

        this.editor.quillComponent.onEnterKey(() => this.editor.onSend());

        if (this.simpleSend) {
            this.renderNormalContent();
        } else {
            this.renderLargeContent();
        }
    }

    renderNormalContent() {
        this.button.addClass(s.sendButton);
        this.button.append(`<div class="${s.icon} ${s.send}"></div>`);
        this.button.onClick(() => this.executeClick(), true);
    }

    renderLargeContent() {
        this.button.addClass(s.sendLargeButton);
        //language=HTML
        let sendButton = this.button.append(`
            <div class="${s.sendContent}">
                ${Resources.t('words.sendButtonLabel')}
            </div>
        `);

        let sendButtonAction = async (time?: number) => {
            this.button.addClass(s.locked);
            sendButton.saveForLoading();
            sendButton.render(new LoaderLightComponent());
            await this.executeClick(time);
            sendButton.restore();
        }

        sendButton.onClick(async () => sendButtonAction(), true);

        //language=HTML
        let dropDown = this.button.append(`
            <div class="${s.dropContent}">
                <div class="${s.icon}"></div>
            </div>
        `);

        dropDown.onClick(() => {
            let tooltip = new QuillEditorSendMenuTooltipComponent(dropDown);
            tooltip.screen(false);
            tooltip.create();
            tooltip.menuComponent.onSchedule = async time => {
                await sendButtonAction(time);
                tooltip.dispose();
            };
        });
    }

    async executeClick(scheduleTime?: number) {
        this.button.addClass(s.locked);
        await this.editor.onSend(scheduleTime);
        this.editor.quillComponent.focus();
        this.button.removeClass(s.locked);
    }

}