import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./tab.scss";

export class VisualTabComponent extends Component {

    constructor() {
        super();
        this._onAnimation = false;
        this._overridden = false;

        this._views = {};
        this._speed = 100;
        this._distance = -150;
    }

    private _contentContainer: Component;

    get contentContainer(): Component {
        return this._contentContainer;
    }

    set contentContainer(value: Component) {
        this._contentContainer = value;
    }

    private _selectorContainer: Component;

    get selectorContainer(): Component {
        return this._selectorContainer;
    }

    set selectorContainer(value: Component) {
        this._selectorContainer = value;
    }

    private _currentView: string;

    get currentView(): string {
        return this._currentView;
    }

    set currentView(value: string) {
        this._currentView = value;
    }

    private _views: object;

    /**
     * get and set
     */
    get views(): object {
        return this._views;
    }

    set views(value: object) {
        this._views = value;
    }

    private _onAnimation: boolean;

    get onAnimation(): boolean {
        return this._onAnimation;
    }

    set onAnimation(value: boolean) {
        this._onAnimation = value;
    }

    private _distance: number;

    get distance(): number {
        return this._distance;
    }

    set distance(value: number) {
        this._distance = value;
    }

    private _speed: number;

    get speed(): number {
        return this._speed;
    }

    set speed(value: number) {
        this._speed = value;
    }

    private _overridden: boolean;

    get overridden(): boolean {
        return this._overridden;
    }

    set overridden(value: boolean) {
        this._overridden = value;
    }

    /**
     * commits
     */
    commit() {
        if (this._contentContainer != undefined) {
            this.commitTabs();
        }

        super.commit();
    }

    commitTabs(noSelect?: boolean) {
        for (let buttonClass in this._views) {
            if (this._views.hasOwnProperty(buttonClass)) {
                let button = this.el(buttonClass);
                if (!button.isNull()) {
                    button.onClick(async () => {
                        if (!this._overridden) {
                            await this.selectAnimate(buttonClass);
                        }
                    });
                }
            }
        }

        if (!noSelect && !this._overridden) {
            let btn = Object.keys(this._views)[0];
            this.select(btn, this._views[btn]());
            this.selectorAnimate(btn);
        }
    }

    /**
     * select
     * @param buttonClass
     */
    selectNoAnimate(buttonClass: string) {
        this.select(buttonClass, this._views[buttonClass]());
        this.selectorAnimate(buttonClass);
    }

    async selectAnimate(buttonClass: string) {
        if (this._onAnimation) {
            return;
        }
        if (buttonClass == this._currentView) {
            return;
        }

        let component = this._views[buttonClass]();
        if (component == undefined) {
            return;
        }
        this._onAnimation = true;

        let positive = this._distance;
        let negative = this.negative(positive);

        if (this.index(this._currentView) > this.index(buttonClass)) {
            positive = this.negative(positive);
            negative = this.negative(negative);
        }


        this.selectorAnimate(buttonClass);
        if (this._speed > 0) {
            await this._contentContainer.wait([
                this._contentContainer.fade("out", this._speed),
                this._contentContainer.translate({left: positive, delay: this._speed})
            ]);
        }
        this.select(buttonClass, component);
        if (this._speed > 0) {
            this._contentContainer.setStyle(`left:${negative}px;transition-duration: 0s;`);
            await this._contentContainer.wait([
                this._contentContainer.fade("in", this._speed),
                this._contentContainer.translate({moveLeft: 0, delay: this._speed})
            ]);
        }

        this._onAnimation = false;
    }

    /**
     * logic
     */
    async selectorAnimate(buttonClass: string) {
        let newSelector = this.el(buttonClass).append(`<div visualSelector class="${s.visualSelector}"></div>`);

        let animations = [];
        animations.push(newSelector.fade("in", this._speed));
        if (this._selectorContainer != undefined) {
            animations.push(this._selectorContainer.fade("out", this._speed));
        }

        await this.wait(animations);
        if (this._selectorContainer != undefined && !this._selectorContainer.isNull()) {
            this._selectorContainer.remove();
        }

        this._selectorContainer = newSelector;
    }

    select(buttonClass: string, component: Component) {
        this._currentView = buttonClass;
        this._contentContainer.displayShow();
        this._contentContainer.clearAll();
        this._contentContainer.render(component);
        this.onSelect(buttonClass);
    }

    /**
     * calc
     */
    negative(number: number) {
        if (number < 0) {
            return Math.abs(number);
        } else {
            return -number;
        }
    }

    index(buttonClass: string) {
        return Object.keys(this._views).indexOf(buttonClass);
    }

    /**
     * to override
     */

    onSelect(btnClass: string) {

    }
}