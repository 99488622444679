import {
    VisualClickTooltipWhiteComponent
} from "../../../../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../../../../sedestral-interface-component/interface/component/Component";
import {QuillFormattingImageTabComponent} from "./tab/QuillFormattingImageTabComponent";

export class QuillFormattingImageComponent extends VisualClickTooltipWhiteComponent {

    public tab: QuillFormattingImageTabComponent;

    constructor(component: Component) {
        super(component, 0, 200);
        this.tab = new QuillFormattingImageTabComponent();
        this.template = this.draw(this.tab);
        this.setUnClickable(false);
    }

    commit() {
        this.setStyle(`padding:7px;`);
        super.commit();
    }
}