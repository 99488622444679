import {
    IQuillIntegrationReplace
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/editor/integrations/types/IQuillIntegrationReplace";
import {IEntityModel} from "../../../../../../models/entity/IEntityModel";
import {
    IQuillIntegration
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/editor/integrations/types/IQuillIntegration";
import {Resources} from "../../../../../../resources/Resources";

export class EditorPredefinedIntegrationsInbox {
    public integrationsList: IQuillIntegration[] = [];
    public integrationsListReplaces: IQuillIntegrationReplace[] = [];

    static get(): IQuillIntegration[] {
        return [
            {name: Resources.t('words.name'), value: "name", type: "user"},
            {name: Resources.t('words.email'), value: "email", type: "mail"}
        ];
    }

    static replaces(entity: IEntityModel): IQuillIntegrationReplace[] {
        return [
            {name: "name", replacement: entity.name},
            {name: "email", replacement: entity.email}
        ];
    }
}