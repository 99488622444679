import * as s from "./media-audio.scss";
import {MediaAbstractComponent} from "../MediaAbstractComponent";
import {MediaAudioVolumeComponent} from "./volume/MediaAudioVolumeComponent";
import {Models} from "../../../../models/Models";
import {PreferencesService} from "../../../../services/preferences/PreferencesService";

export class MediaAudioComponent extends MediaAbstractComponent {

    private active: boolean;

    constructor(object: string | File | any) {
        super();
        this.contentObject = object;
        this.active = false;
        this.path = Models.isMediaModel(object) ? object.link : (object instanceof File ? window.URL.createObjectURL(object) : object);

        this.template = `
            <div media mediaAudio class="${s.globalMediaAudio}">
                <audio class="${s.AudioSource}" controls="controls">
                    <source src="${this.path}" type="audio/mpeg"/>
                </audio>
                <div class="${s.audioButtons}">
                    <div class="${s.playButton}">
                        <div class="${s.ActiveIcon} ${s.icon} ${s.play}"></div>
                    </div>
                    <div class="${s.songButton}">
                        <div class="${s.VolumeIcon} ${s.icon} ${s.mute}"></div>
                    </div>
                </div>
                <div class="${s.controls}">
                    <div class="${s.currentTime}">
                        00:00
                    </div>
                    <div class="${s.audioProgression}">
                        <div class="${s.progress}"></div>
                    </div>
                    <div class="AudioTotalTime ${s.totalTime}">
                        0:00
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        let activeButton = this.el(s.playButton);
        let activeIcon = this.el(s.ActiveIcon);

        let audioSource = this.el(s.AudioSource);
        let audioTotalTime = this.el(s.totalTime);
        let audioCurrentTime = this.el(s.currentTime);
        let audioProgression = this.el(s.audioProgression);
        let audioProgress = this.el(s.progress);

        let volumeButton = this.el(s.songButton);
        if (audioSource.element instanceof HTMLAudioElement) {
            let source: HTMLAudioElement = audioSource.element;
            source.load();
            source.volume = PreferencesService.mediaVolume;

            if (source.volume == 0) {
                this.mute();
            } else {
                this.unMute();
            }

            volumeButton.onHover(() => {
                let volumeTooltip = new MediaAudioVolumeComponent(volumeButton, source.volume * 100);
                volumeTooltip.create();
                volumeTooltip.onDispose = () => {
                    volumeTooltip = undefined;
                };
                volumeTooltip.onPick = (height, width) => {
                    height = parseFloat((height / 100).toFixed(2));
                    source.volume = height;
                    PreferencesService.mediaVolume = height;
                }
            });

            activeButton.onClick(() => {
                if (!this.active) {
                    this.play();
                    source.play();
                } else {
                    this.pause();
                    source.pause();
                }
            });
            volumeButton.onClick(() => {
                if (source.volume == 0) {
                    this.unMute();
                    source.volume = 0.5;
                } else {
                    this.mute();
                    source.volume = 0;
                }

                PreferencesService.mediaVolume = source.volume;
            }, false);

            audioSource.addListener("loadedmetadata", () => {
                audioTotalTime.setHtml(this.getTime(source.duration));
            });
            audioSource.addListener("timeupdate", () => {
                audioProgress.setStyle("width:" + ((source.currentTime * 100) / source.duration) + "%");
                audioCurrentTime.setHtml(this.getTime(source.currentTime));

                if (source.currentTime >= source.duration) {
                    this.pause();
                    source.pause();
                }
            });

            audioProgression.onClick((e) => {
                source.currentTime = source.duration / 100 * this.calcProgression(e);
            }, true);
        }
        super.commit();
    }

    mute(): void {
        let volumeIcon = this.el(s.VolumeIcon);
        volumeIcon.removeClass(s.volumeIco);
        volumeIcon.addClass(s.mute);
    }

    unMute(): void {
        let volumeIcon = this.el(s.VolumeIcon);
        volumeIcon.removeClass(s.mute);
        volumeIcon.addClass(s.volumeIco);
    }

    play(): void {
        let activeIcon = this.el(s.ActiveIcon);
        activeIcon.removeClass(s.play);
        activeIcon.addClass(s.pause);
        this.active = true;
    }

    pause(): void {
        let activeIcon = this.el(s.ActiveIcon);
        activeIcon.removeClass(s.pause);
        activeIcon.addClass(s.play);
        this.active = false;
    }

    getTime(t: number): string {
        let m = ~~(t / 60), s = ~~(t % 60);
        return (m < 10 ? "0" + m : m) + ':' + (s < 10 ? "0" + s : s);
    }

    calcProgression(e: any): number {
        let element = this.el(s.audioProgression);
        let offset = element.getOffsets();
        let x = e.pageX - offset['left'];
        return (x * 100) / element.getWidth();
    }
}