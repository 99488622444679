import {EditorButton} from "./EditorButton";
import {EditorComponent} from "../EditorComponent";
import * as s from "../editor.scss";
import {ShortcutsTooltipComponent} from "../../shortcuts/tooltip/ShortcutsTooltipComponent";
import {ShortcutsSwapComponent} from "../../shortcuts/swap/ShortcutsSwapComponent";
import {InboxShortcutService} from "../../../../../../services/inbox/shortcut/InboxShortcutService";
import {ShortcutComponent} from "../../shortcuts/ShortcutComponent";
import {IInboxShortcutModel} from "../../../../../../models/inbox/shortcut/IInboxShortcutModel";
import {
    SedestralMachine
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/machine/SedestralMachine";
import Delta from 'quill-delta';
import {IFileModel} from "../../../../../../models/file/IFileModel";
import {IPreviewModel} from "../../../../../../models/preview/IPreviewModel";
import {AttachmentType} from "../../../../../../models/enums/AttachmentType";
import {Resources} from "../../../../../../resources/Resources";

export class EditorButtonShortcuts extends EditorButton {

    public rendered: boolean = false;
    public tooltipComponent: ShortcutsTooltipComponent | ShortcutsSwapComponent;

    constructor(editor: EditorComponent) {
        super(editor);
    }

    init() {
        //language=HTML
        this.button = this.editor.rightButtonsContainer.append(`
            <div data-tooltip="${Resources.t('words.shortcutsButtonTooltip')}" button class="${s.button}">
                <div class="${s.icon} ${s.shortcuts}"></div>
            </div>
        `);

        this.initPanel(this.button, () => {
            return !this.editor.config.pocket ? new ShortcutsTooltipComponent(this.button, true, true) : new ShortcutsSwapComponent(this.editor, true, true);
        }, async (component) => {
            if (component instanceof ShortcutsTooltipComponent || component instanceof ShortcutsSwapComponent) {
                if (InboxShortcutService.getAll().length == 0) {
                    component.shortcutsComponent.renderGhost(true, 3);
                }

                let shortcuts = await InboxShortcutService.findMind(this.editor);
                component.shortcutsComponent.renderShortcuts(shortcuts);
                component.shortcutsComponent.onValidateSelect = (shortcutComponent) => {
                    component.dispose();
                    if (shortcutComponent instanceof ShortcutComponent) {
                        this.pickShortcut(shortcutComponent.shortcut, this.editor.quillComponent.lastSelection == undefined ? this.editor.quillComponent.quill.getLength() - 1 : this.editor.quillComponent.lastSelection.index, 1, false);
                    }
                };
                if (component instanceof ShortcutsTooltipComponent) {
                    component.calcMaxHeight();
                }
            }
        });

        this.editor.quillComponent.onMatch("/", (status, matchedText, index) => {
            if (status) {
                this.showTooltipShortcuts(matchedText, index);
            } else {
                this.hideTooltipShortcuts();
            }
        });
    }

    async showTooltipShortcuts(match: string, index: number) {
        if (!this.rendered) {
            this.rendered = true;

            this.editor.quillComponent.logicLineBreak.lineBreakForceDisabled = true;
            this.tooltipComponent = !this.editor.config.pocket ? new ShortcutsTooltipComponent(this.editor, false, true) : new ShortcutsSwapComponent(this.editor, false, false);
            this.tooltipComponent.screen(false).animation(false);
            this.tooltipComponent.onDispose = () => this.hideTooltipShortcuts();
            if (this.tooltipComponent instanceof ShortcutsTooltipComponent) {
                this.tooltipComponent.place = () => {
                };
            }
            this.tooltipComponent.create();
        }

        if (InboxShortcutService.getAll().length == 0) {
            this.tooltipComponent.shortcutsComponent.renderGhost(true, 3);
            SedestralMachine.requestFrame()(() => this.placeTooltipShortcuts());
        }


        let shortcuts = await InboxShortcutService.findMind(this.editor);
        this.tooltipComponent.shortcutsComponent.renderShortcuts(shortcuts, false, match);
        SedestralMachine.requestFrame()(() => this.placeTooltipShortcuts());

        if (this.tooltipComponent instanceof ShortcutsTooltipComponent) {
            this.tooltipComponent.calcMaxHeight();
        }

        this.tooltipComponent.shortcutsComponent.onValidateSelect = (component) => {
            if (component instanceof ShortcutComponent) {
                this.pickShortcut(component.shortcut, index, match.length + 1, true);
            }
            this.hideTooltipShortcuts();
        };
    }

    placeTooltipShortcuts() {
        let selection = this.editor.quillComponent.lastSelection;

        if (selection != null) {
            let quillOffsets = this.editor.quillComponent.getOffsets();
            let bounds = this.editor.quillComponent.getBounds(selection.index, selection.length);

            let left = Math.round(quillOffsets.left + bounds.left);
            let top = Math.round((quillOffsets.top - this.tooltipComponent.getHeight()) + bounds.top);

            if (top < 0) {
                top = 10;
            }

            if ((left + this.tooltipComponent.getWidth()) > window.innerWidth) {
                left = Math.round(window.innerWidth - this.tooltipComponent.getWidth() - 10);
            }

            this.tooltipComponent.setStyle(`left:${left}px;top:${top}px`);
        }
    }

    hideTooltipShortcuts() {
        if (this.rendered) {
            this.rendered = false;
            this.editor.quillComponent.settings.lineBreak = true;
            if (!this.tooltipComponent.isNull()) {
                this.tooltipComponent.dispose();
            }
        }

        this.editor.quillComponent.logicLineBreak.lineBreakForceDisabled = false;
    }

    pickShortcut(shortcut: IInboxShortcutModel, index: number, length: number, replace: boolean) {
        if (replace) {
            let deltas = this.editor.quillComponent.logicIntegrations.parseDeltas(JSON.parse(shortcut.deltas));
            this.editor.quillComponent.quill.updateContents(new Delta().retain((index - length) + 1).delete(length).concat(deltas).delete(2))

            if (this.editor.quillComponent.attachmentsComponent != undefined && shortcut.attachments != undefined) {
                let files: IFileModel[] = shortcut.attachments.filter(value => value.type == AttachmentType.FILE).map(value => value.content as IFileModel);
                let previews: IPreviewModel[] = shortcut.attachments.filter(value => value.type == AttachmentType.PREVIEW).map(value => value.content as IPreviewModel);

                files.forEach(file => this.editor.quillComponent.attachmentsComponent.addUploadedFile(file));
                previews.forEach(preview => this.editor.quillComponent.attachmentsComponent.addUploadedPreview(preview));
            }

            InboxShortcutService.use(shortcut, this.editor);
        } else {
            let prefix = "/";
            let text = this.editor.quillComponent.contents().text;
            let lastChar = text.substr(text.length - 2, 1).trim();

            if (lastChar != " " && lastChar.length != 0) {
                prefix = "  /";
            }

            this.editor.quillComponent.insertText(prefix + shortcut.name);
            this.editor.onShortcutPick(shortcut.name, index + length);
        }
    }
}