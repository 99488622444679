import {
    VisualModalComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/modal/VisualModalComponent";
import {
    CheckboxSlidingComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/checkbox/sliding/CheckboxSlidingComponent";
import * as a
    from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/checkbox/sliding/checkbox-sliding.scss";
import * as s from "./shortcuts-creator.scss";
import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {EditorComponent} from "../../editor/EditorComponent";
import {FileService} from "../../../../../../services/file/FileService";
import {EntityService} from "../../../../../../services/entity/EntityService";
import {FileOrigin} from "../../../../../../models/file/FileOrigin";
import {
    MediaUploadComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/media/upload/MediaUploadComponent";
import {
    MediaFactory
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/media/types/MediaFactory";
import {PreviewService} from "../../../../../../services/preview/PreviewService";
import {
    ButtonsSaveComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/buttons/save/ButtonsSaveComponent";
import {InboxShortcutService} from "../../../../../../services/inbox/shortcut/InboxShortcutService";
import {IFileModel} from "../../../../../../models/file/IFileModel";
import {IPreviewModel} from "../../../../../../models/preview/IPreviewModel";
import {IInboxShortcutModel} from "../../../../../../models/inbox/shortcut/IInboxShortcutModel";
import {AttachmentType} from "../../../../../../models/enums/AttachmentType";
import {PermissionsService} from "../../../../../../services/permissions/PermissionsService";
import {Network} from "../../../../../../network/Network";
import {EditorPredefinedIntegrationsInbox} from "../../editor/predefined/EditorPredefinedIntegrationsInbox";
import {Resources} from "../../../../../../resources/Resources";

export class ShortcutCreatorComponent extends VisualModalComponent {

    private shortcut: IInboxShortcutModel;

    private editorComponent: EditorComponent;

    private editorContainer: Component;
    private buttons: ButtonsSaveComponent;

    private nameInput: Component;
    private privateCheckbox: CheckboxSlidingComponent;

    constructor(shortcut?: IInboxShortcutModel) {
        super();
        this.name = Resources.t("words.createShortcut");
        this.buttons = new ButtonsSaveComponent({cancelText: Resources.t("words.delete")});
        this.shortcut = shortcut;
        //language=HTML
        this.modalTemplate = `
            <div class="${s.componentShortcutCreator}">
                <div class="${s.contents}">
                    <div class="${s.inputsContainer}">
                        <div class="${s.inputContainer}">
                            <div class="${s.slash}">/</div>
                            <input class="${s.name}" placeholder="${Resources.t("words.shortcutName")}"/>
                        </div>
                        <div style="${PermissionsService.d(PermissionsService.shortcutsPublic())}"
                             class="${s.slideCheckboxContainer}"></div>
                    </div>
                    <div class="${s.editorContainer}"></div>
                    ${this.draw(this.buttons)}
                </div>
                <div class="${s.illustrationContainer}">
                    <div class="${s.illustration}"></div>
                </div>
            </div>
        `;

    }

    commit() {
        super.commit();
        this.boxContainer.setStyle("height: fit-content;overflow:visible;width: 430px;");
        this.setStyle(`width:100%;height: 100%;position: fixed;left: 0;`);

        this.editorContainer = this.el(s.editorContainer);
        this.nameInput = this.el(s.name);

        this.renderEditor();
        this.renderCheckbox();
        this.renderButtons();

        if (this.shortcut != undefined) {
            this.nameInput.setValue(this.shortcut.name);
            this.timeOut(() => this.privateCheckbox.select(this.shortcut.isPrivate ? "mind" : "all"), 150);
        }
    }

    renderButtons() {
        if (this.shortcut == undefined) {
            this.buttons.cancelButton.displayHide();
        }

        this.buttons.onCancel = async () => {
            this.buttons.setCancelLoading();
            await InboxShortcutService.delete(this.shortcut.id, this);
            this.close();
        };

        this.buttons.onSave = async () => {
            this.buttons.setLoading();

            let files: IFileModel[] = this.editorComponent.quillComponent.attachmentsComponent.allUploaded().files.map(value => value.file);
            let previews: IPreviewModel[] = this.editorComponent.quillComponent.attachmentsComponent.allUploaded().previews;
            if (files.length == 0 && previews.length == 0 && this.editorComponent.quillComponent.length() <= 1) {
                return this.error(Resources.t("words.emptyShortcut"));
            }

            if (this.nameInput.getValueTrim().length < 2) {
                return this.error(Resources.t("words.shortName"));
            }

            let isPrivate = !PermissionsService.shortcutsPublic() ? true : this.privateCheckbox.currentValue == "mind";
            if (this.shortcut == undefined) {
                let request = await InboxShortcutService.create(this.nameInput.getValue(),
                    this.editorComponent.quillComponent.deltaContents(),
                    this.editorComponent.quillComponent.contents().html,
                    isPrivate, files, previews, this);

                if (request == undefined) {
                    return this.error(Resources.t("words.usedName"));
                }

                Network.router.static.components.notifications.notify(Resources.t("words.congratulations"), Resources.t("words.shortcutAdded"), 8000, "success");
            } else {
                let request = await InboxShortcutService.update(this.shortcut.id, this.nameInput.getValue(),
                    this.editorComponent.quillComponent.deltaContents(),
                    this.editorComponent.quillComponent.contents().html,
                    isPrivate, files, previews);

                if (request != undefined) {
                    Network.router.static.components.notifications.notify(Resources.t("words.congratulations"), Resources.t("words.shortcutModified"), 8000, "success");
                }
            }

            this.close();
        };

    }

    renderEditor() {
        this.editorComponent = new EditorComponent({
            settings: true,
            gifs: true,
            emojis: true,
            quill: {
                attachment: true,
                integrations: true,
                formattingLine: true,
                forceEnterLineBreak: true,
                integrationsList: EditorPredefinedIntegrationsInbox.get()
            }
        });

        this.editorContainer.render(this.editorComponent);
        this.editorComponent.quillComponent.attachmentsComponent.onFileAdd = async (component) => {
            let files = await FileService.create(EntityService.activeSite.id, FileOrigin.SHORTCUT_ATTACHMENT, [component.object as File], (p) => {
                if (component.uploadComponent instanceof MediaUploadComponent) {
                    component.uploadComponent.progress(p);
                }
            }, component.mediaComponent);

            if (files == undefined) {
                Network.router.static.components.notifications.notify(Resources.t("words.warning"), Resources.t("words.attachmentSendError"), 8000);
            }

            if (files != undefined) {
                component.uploaded(files[0], MediaFactory.generate(component.object, files[0]));
            } else {
                component.uploaded(undefined, MediaFactory.generate(component.object, undefined));
            }
        };

        this.editorComponent.quillComponent.attachmentsComponent.onUrlAdd = async (component) => {
            component.uploadComponent.simulateProgress();
            let preview = await PreviewService.findByUrl(String(component.object), this);
            if (preview == undefined) {
                Network.router.static.components.notifications.notify(Resources.t("words.warning"), Resources.t("words.attachmentSendError"), 8000);
            }


            component.uploaded(preview, MediaFactory.generate(component.object, preview));
        };


        if (this.shortcut != undefined) {
            this.restoreEditor();
        }
    }


    restoreEditor() {
        this.editorComponent.quillComponent.quill.setContents(JSON.parse(this.shortcut.deltas));
        if (this.shortcut.attachments != undefined) {
            let files: IFileModel[] = this.shortcut.attachments.filter(value => value.type == AttachmentType.FILE).map(value => value.content as IFileModel);
            let previews: IPreviewModel[] = this.shortcut.attachments.filter(value => value.type == AttachmentType.PREVIEW).map(value => value.content as IPreviewModel);

            files.forEach(file => this.editorComponent.quillComponent.attachmentsComponent.addUploadedFile(file));
            previews.forEach(preview => this.editorComponent.quillComponent.attachmentsComponent.addUploadedPreview(preview));
        }
    }

    renderCheckbox() {
        let sliderContainer = this.el(s.slideCheckboxContainer);
        let checkboxEntries = [
            {html: `<div class="${a.icon} ${s.included}"></div>`, value: "all", title: Resources.t("words.forAll")},
            {html: `<div class="${a.icon} ${s.mind}"></div>`, value: "mind", title: Resources.t("words.forMe")}
        ];
        this.privateCheckbox = new CheckboxSlidingComponent(checkboxEntries);
        sliderContainer.render(this.privateCheckbox);
    }

    error(text: string) {
        Network.router.static.components.notifications.notify(Resources.t("words.oops"), text, 8000);
        this.buttons.remLoading();
    }

}