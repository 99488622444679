import {Component} from "../../../../../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./quill-formatting-image-url.scss";
import {Network} from "../../../../../../../../../network/Network";
import {Resources} from "../../../../../../../../../resources/Resources";
import {textIsUrl} from "../../../../../../../../sedestral-interface-component/utilities/TextIsUrl";

export class QuillFormattingImageUrlComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentQuillFormattingImageUrl}">
                <input class="${s.input}" placeholder="${Resources.t("words.imageUrl")}"/>
                <div class="${s.insert}">
                    ${Resources.t("words.insert")}
                </div>
            </div>
        `;
    }

    commit() {
        this.el(s.insert).onClick(() => {
            let value = this.el(s.input).getValue();
            if (textIsUrl(value)) {
                this.onPick(value)
            } else {
                Network.router.static.components.notifications.notify(Resources.t("words.enterValidUrl"), undefined, 2000);
            }
        });
        super.commit();
    }

    onPick(url: string) {

    }

}

