import Quill from "quill";
import {SedestralMachine} from "../../../../../sedestral-interface-component/machine/SedestralMachine";
import {QuillComponent} from "../../../QuillComponent";
import {elementParents} from "../../../../../sedestral-interface-component/utilities/ElementParents";

let Block = Quill.import('blots/block');

export class QuillParagraphBlot extends Block {
    public node: HTMLElement;

    constructor(scroll, domNode) {
        super(scroll, domNode);
        this.node = domNode;
    }

    attach() {
        let q = elementParents(this.node).filter(value => {
            if (value instanceof HTMLElement) {
                return value.hasAttribute("qEditor");
            }
        })[0];
        if (q) {
            SedestralMachine.requestFrame()(() => {
                let quillComponent: QuillComponent = q['quill']['quillComponent'];
                /*
                if (quillComponent.inlineStyle)
                    this.node.setAttribute('style', 'font-size:14px;margin: 0;font-family:Montserrat Medium;color: rgb(0, 0, 0);');

                 */
            });

        }


        super.attach();
    }
}

QuillParagraphBlot.tagName = 'p';