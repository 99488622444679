import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./account-notifications.scss";
import {NotificationService} from "../../../../../../services/notification/NotificationService";
import {NotificationsComponent} from "../../notification/NotificationsComponent";

export class AccountNotificationsComponent extends Component {
    private notificationsComponent: NotificationsComponent;

    constructor() {
        super();
        this.notificationsComponent = new NotificationsComponent();
        this.template = `
            <div class="${s.componentAccountNotifications}">
                ${this.draw(this.notificationsComponent)}
            </div>
        `;
    }

    async commit() {
        super.commit();

        this.notificationsComponent.setLoading();
        let notifications = await NotificationService.findAll(this);
        this.notificationsComponent.renderNotifications(notifications == undefined ? [] : notifications.reverse());
        this.notificationsComponent.renderNotificationsCount();
    }
}