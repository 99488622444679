import * as s from "./quill-formatting-size.scss";
import {
    VisualClickTooltipWhiteComponent
} from "../../../../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../../../../sedestral-interface-component/interface/component/Component";

export class QuillFormattingSizeComponent extends VisualClickTooltipWhiteComponent {
    public currentSize: string;
    public entries: { size: string, tag: string, name: string }[];

    constructor(component: Component, currentSize: string, entries: { size: string, tag: string, name: string }[]) {
        super(component, 100, 170);
        this.currentSize = currentSize;
        this.entries = entries;

        //language=HTML
        this.template = `
            <div class="${s.globalQuillFormattingSize}"></div>
        `;
    }

    commit() {
        this.setStyle(`padding:0;`);

        let container = this.el(s.globalQuillFormattingSize);
        this.entries.forEach(entry => {
            //language=HTML
            let button = container.append(`
                <div data-size="${entry.size}" class="${s.listEntry}">
                    <${entry.tag}>${entry.name}</${entry.tag}>
                    <div class="${s.selectedIcon}"></div>
                </div>
            `);

            button.removeClass(s.selected);
            let attribute = button.getAttribute("data-size");
            button.onClick(() => this.onPick(attribute));
            if (this.currentSize == attribute) {
                button.addClass(s.selected);
            }
        });

        super.commit();
    }

    onPick(size: string) {

    }
}