import {TimerComponent} from "../TimerComponent";
import {Resources} from "../../../../resources/Resources";
import {PreferencesService} from "../../../../services/preferences/PreferencesService";
import {time} from "../../../sedestral-interface-component/utilities/Time";

export class TimerShortComponent extends TimerComponent {
    constructor(date: Date) {
        super(date);
    }

    getTime(): string {
        let options = {};
        options['hour12'] = PreferencesService.hour12;

        if ((time() - this.intTimestamp) < 86400 && new Date().getDay() == this.date.getDay()) {
            options['hour'] = "2-digit";
            options['minute'] = "2-digit";
        } else if ((time() - this.intTimestamp) < 604800) {
            options['weekday'] = "short";
        } else if (new Date().getFullYear() == this.date.getFullYear()) {
            options['day'] = "numeric";
            options['month'] = "short";
            options['year'] = "numeric";
        } else {
            options['day'] = "numeric";
            options['month'] = "long";
            options['year'] = "numeric";
        }

        let dateTimeFormat = new Intl.DateTimeFormat(Resources.language, options);
        return dateTimeFormat.format(this.date);
    }

    canUpdate(): boolean {
        return true;
    }

}