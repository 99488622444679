@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.staticComposer {
  z-index: 9999999;
  display: flex;
  right: 15px;
  position: absolute;
  bottom: 0;
  flex-direction: row;
  align-content: flex-end;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-end;

  .emptyIcon {
    height: 150px;
    width: 150px;

    @include icons.ico("3d-empty");
  }
}
