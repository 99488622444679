import {Component} from "../../sedestral-interface-component/interface/component/Component";
import {SedestralInterface} from "../../sedestral-interface-component/interface/SedestralInterface";
import * as t from "./../tooltip/tooltip.scss";
import * as s from "./swap.scss";
import {onSwipe} from "../../sedestral-interface-component/interface/component/funcs/OnSwipe";

export class VisualSwapComponent extends Component {
    public name: string;
    public _screen: boolean;
    public height: string;
    public outsideClose: boolean;
    public screenContainer: Component;
    public component: Component;
    public closeContainer: Component;
    public swapSelector: Component;
    public contentContainer: Component;
    private _keyId: string;

    constructor(name?: string, component?: Component, height?: string) {
        super();
        this._screen = false;
        this.outsideClose = true;
        this.component = component;
        this.height = height;
        this.name = name;
        this._keyId = "vSwap-" + this.constructor.name;
    }

    commit() {
        this.bind();
        this.animateOpen();

        if (this.height == "100%") {
            this.setStyle(`border-radius:0;`);
        }

        super.commit();
    }

    /**
     * bind
     */

    bind() {
        this.onOutsideClick(async () => {
            if (this.outsideClose) {
                await this.dispose();
            }
        });

        this.closeContainer = this.el(s.close);
        this.contentContainer = this.el(s.content);
        if (this.component != undefined) {
            this.component.onRemove(async () => this.dispose());
        }
        if (this.closeContainer != undefined) {
            this.closeContainer.onClick(() => this.dispose(), true);
        }

        this.bindSwiper();
    }

    bindSwiper() {
        this.swapSelector = this.el(s.swapSelector);
        if (this.swapSelector != undefined) {
            let swiper = onSwipe(this.swapSelector, this);
            swiper.onDown = () => this.dispose();
        }
    }

    /**
     * template
     */

    getTemplate(): string {
        let h = this.height == "100%" ? `calc(${this.height} - env(safe-area-inset-top) - env(safe-area-inset-bottom))` : this.height;
        //language=HTML
        return this.template = `
            <div style="height: ${h};transform: translateY(100%);"
                 class="${s.visualSwap} ${this.height == "100%" ? s.safeArea : ``}">
                <div class="${s.swapSelector}">
                    <div class="${s.swapBar}"></div>
                </div>

                <div class="${s.head}">
                    <div class="${s.name}">
                        ${this.name}
                    </div>
                    <div class="${s.close}">
                        <div class="${s.icon}"></div>
                    </div>
                </div>
                <div swapContent class="${s.content}">
                    ${super.getTemplate()}
                </div>
            </div>
        `;
    }

    /**
     * init
     */
    async create(): Promise<void> {
        if (this.component != undefined) {
            if (this.component[this._keyId]) {
                SedestralInterface.unStore(this);
                return;
            }
            this.component[this._keyId] = true;
        }

        SedestralInterface.main.render(this);
        if (this._screen) {
            this.screenContainer = SedestralInterface.body.append(`<div class="${t.visualScreen}"></div>`);
            SedestralInterface.body.removeChildren(this.screenContainer);
        }
    }

    /**
     * dispose
     */
    async dispose(): Promise<void> {
        if (this._screen && this.screenContainer != undefined) {
            this.screenContainer.remove();
            this.screenContainer = undefined;
        }

        await this.animateDispose();
        if (!this.isNull()) {
            this.remove();
        }

        if (this.component != undefined) {
            this.component[this._keyId] = undefined;
        }
        this.onDispose();
    }

    /**
     * animate
     */

    async animateOpen() {
        return await this.translate({translateY: 0, delay: 500});
    }

    async animateDispose() {
        return await this.translate({translateY: window.innerHeight, delay: 500});
    }

    /**
     * settings
     */

    screen(value: boolean): VisualSwapComponent {
        return this;
    }

    animation(value: boolean): VisualSwapComponent {
        return this;
    }


    /**
     *override
     */

    onDispose() {

    }
}