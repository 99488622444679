import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import {QuillLimitComponent} from "./QuillLimitComponent";
import {IQuillLimit} from "./types/IQuillLimit";
import * as s from "./quill-limits.scss";

export class QuillLimitsComponent extends Component {
    private limits: IQuillLimit[];
    private currentLength: number;

    constructor(limits: IQuillLimit[], currentLength: number) {
        super();
        this.limits = limits;
        this.currentLength = currentLength;
        //language=HTML
        this.template = `
            <div class="${s.globalQuillLimits}"></div>
        `;
    }

    commit() {
        for (let limit of this.limits) {
            this.render(new QuillLimitComponent(limit, this.currentLength));
        }
        super.commit();
    }
}