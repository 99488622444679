import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import {MediaImageComponent} from "../MediaImageComponent";
import Cropper from 'cropperjs';
import * as s from "./media-image-cropper.scss";
import {MediaImageCropperRatioComponent} from "./ratio/MediaImageCropperRatioComponent";
import {ChannelType} from "../../../../../models/channel/ChannelType";
import {ButtonsSaveComponent} from "../../../buttons/save/ButtonsSaveComponent";
import {ICrop} from "./ICrop";

export class MediaImageCropperComponent extends Component {

    public crop: ICrop;

    public imageComponent: MediaImageComponent;
    public ratioComponent: MediaImageCropperRatioComponent;
    public buttonsComponent: ButtonsSaveComponent;

    constructor(object: string | File | any, crop?: ICrop) {
        super();
        this.crop = crop;

        this.buttonsComponent = new ButtonsSaveComponent();
        this.imageComponent = new MediaImageComponent(object);
        this.imageComponent.stickHeight();
        this.ratioComponent = new MediaImageCropperRatioComponent([
            {
                channelType: ChannelType.FACEBOOK_PAGE,
                ratios: [
                    {first: 1.91, second: 1, name: "Publication"},
                    {first: 1, second: 1, name: "Carré"},
                    {first: 1.67, second: 1, name: "Avant"},
                    {first: 4, second: 5, name: "Vertical"}
                ]
            },
            {
                channelType: ChannelType.INSTAGRAM,
                ratios: [
                    {first: 1.91, second: 1, name: "Paysage"},
                    {first: 1, second: 1, name: "Carré"},
                    {first: 4, second: 5, name: "Portrait"}
                ]
            },
            {
                channelType: ChannelType.TWITTER,
                ratios: [
                    {first: 2, second: 1, name: "Publication"},
                    {first: 1, second: 1, name: "Carré"},
                ]
            }
        ]);

        //language=HTML
        this.template = `
            <div class="${s.componentMediaImageCropper}">
                <div class="${s.top}">
                    <div class="${s.ratioContainer}">
                        ${this.draw(this.ratioComponent)}
                    </div>
                    <div class="${s.imageContainer}">
                        ${this.draw(this.imageComponent)}
                    </div>
                </div>
                <div class="${s.buttonsContainer}">
                    ${this.draw(this.buttonsComponent)}
                </div>
            </div>
        `;
    }


    commit() {
        this.imageComponent.onLoad = () => {
            let cropper = new Cropper(this.imageComponent.imageElement.getHTMLElement() as HTMLImageElement, {
                viewMode: 2,
                zoomable: false,
                ready: () => {
                    cropper.setDragMode("move");
                    if (this.crop) {
                        cropper.setData(this.crop)
                    }
                },
            });

            this.ratioComponent.onSelect = (ratio) => cropper.setAspectRatio(ratio);
            this.buttonsComponent.onSave = () => this.onCrop(cropper.getData());
            this.buttonsComponent.onCancel = () => this.onCrop();
        }


        super.commit();
    }

    onCrop(crop?: ICrop) {

    }
}