import {QuillLogic} from "./QuillLogic";
import {QuillComponent} from "../QuillComponent";
import Delta from 'quill-delta';
import {fileIsImage} from "../../../sedestral-interface-component/utilities/FileIsImage";
import {fileIsGif} from "../../../sedestral-interface-component/utilities/FileIsGif";
import {fileGetClipboards} from "../../../sedestral-interface-component/utilities/FileGetClipboards";

export class QuillLogicPasteable extends QuillLogic {
    constructor(quillComponent: QuillComponent) {
        super(quillComponent);
    }

    init() {
        this.quillComponent.quill.clipboard['onPaste'] = (range, p: { text: string, html: string }) => {
            let delta = new Delta().retain(range.index).delete(range.length).insert(p.text);
            this.quillComponent.quill.updateContents(delta, 'user');
            let newPosition = range.index + p.text.length;
            this.quillComponent.quill.setSelection(newPosition, 'user');
        }

        this.quillComponent.addListener("paste", (e) => {
            e.preventDefault();
            e.stopPropagation();

            let files = fileGetClipboards(e.clipboardData);
            let text = (e.originalEvent || e).clipboardData.getData('text/plain');
            let html = (e.originalEvent || e).clipboardData.getData('text/html');

            if (this.quillComponent.settings.inlineFiles) {
                files.forEach(file => {
                    if (fileIsImage(file.type) || fileIsGif(file.type)) {
                        this.quillComponent.insertImage(file, true);
                    }
                });
            } else {
                files.forEach((file) => this.quillComponent.addAttachment(file));
            }
        });
    }

}