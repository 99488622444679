import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import {IQuillIntegration} from "./types/IQuillIntegration";
import * as s from "./quill-integrations.scss";

export class QuillIntegrationComponent extends Component {
    constructor(integration: IQuillIntegration) {
        super();

        //language=HTML
        this.template = `
            <div class="${s.globalQuillIntegration} ${s[integration.type]}">
                <div class="${s.icon}"></div>
                <div class="${s.left}">${integration.name}</div>
                <div class="${s.right}"></div>
            </div>
        `;
    }
}