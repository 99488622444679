import {QuillBlot} from "../QuillBlot";

export class QuillHtmlBlot extends QuillBlot {

    public static REPLY_STYLE = `border-left: 2px solid rgb(239 239 243);min-width:calc(100% - 22px);word-break: break-all;padding: 10px;position: relative;display: inline-block;border-radius: 0;margin-right: 0;margin-top:10px;margin-left:0;margin-bottom:10px;`;

    public keyboard: any;
    public handlers: { entry, handler }[] = [];

    constructor(scroll, domNode) {
        super(scroll, domNode);
        this.offsetSelect = 1;
        //this.borderRadius = 12;
        this.collaborativeCursor = true;
    }

    onReady() {
        super.clickable({selection: true});

        this.keyboard = this.quillComponent.quill.getModule("keyboard");

        this.component.setHtml(this.data.content);
        for (let a of this.component.getHTMLElement().getElementsByTagName("a"))
            a.setAttribute("click", "");

        this.component.setAttribute("contenteditable", "true");

        this.component.onChange(() => {
            if (this.component) {
                this.format("content", this.component.getHtml())
            }
        });
        this.onCursorSelect(() => this.focus(), () => this.blur());
        this.component.onRemove(() => this.blur());

        super.onReady();
    }

    focus() {
        this.quillComponent.logicLineBreak.replyActive = true;
        Object.keys(this.keyboard.bindings).forEach(key => {
            if (key != "Enter" && !key.startsWith("_")) {
                this.keyboard.bindings["_" + key] = this.keyboard.bindings[key];
                delete this.keyboard.bindings[key];
            }
        });

        this.handlers = [];
        this.keyboard.bindings.Enter.forEach(entry => {
            this.handlers.push({entry: entry, handler: entry.handler});
            entry.handler = () => {
                return typeof entry.shiftKey != "undefined";
            }
        });
    }

    blur() {
        Object.keys(this.keyboard.bindings).forEach(key => {
            if (key.startsWith("_")) {
                this.keyboard.bindings[key.replace("_", "")] = this.keyboard.bindings[key];
                delete this.keyboard.bindings[key];
            }
        });
        this.keyboard.bindings.Enter.forEach(entry => {
            let find = this.handlers.find(value => value.entry == entry);
            if (find) {
                entry.handler = find.handler;
            }
        });
    }

}

QuillHtmlBlot.blotName = 'html';
QuillHtmlBlot.tagName = 'div';