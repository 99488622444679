import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {MediaUploadComponent} from "../../media/upload/MediaUploadComponent";
import {MediaAbstractComponent} from "../../media/MediaAbstractComponent";
import {MediaGifComponent} from "../../media/gif/MediaGifComponent";
import {NetworkErrorComponent} from "../../network/NetworkErrorComponent";
import * as s from "./quill-attachment.scss";
import {AvatarComponent} from "../../../../products/panel/components/components/avatar/AvatarComponent";
import {IEntityModel} from "../../../../models/entity/IEntityModel";
import {IAvatarModel} from "../../../../models/avatar/IAvatarModel";
import {MediaImageComponent} from "../../media/image/MediaImageComponent";
import {IDocumentCroppingOutgoing} from "../../../../models/document/IDocumentCroppingOutgoing";
import {QuillAttachmentsComponent} from "./QuillAttachmentsComponent";
import {IDocumentCroppingModel} from "../../../../models/document/IDocumentCroppingModel";
import {ICrop} from "../../media/image/cropper/ICrop";
import {FileService} from "../../../../services/file/FileService";

export class QuillAttachmentComponent extends Component {

    public canCrop: boolean;
    public animation: boolean;
    public attachmentsComponent: QuillAttachmentsComponent;

    public object: any;
    public uploadedObject: any;
    public cropping: { [key: string]: IDocumentCroppingOutgoing };

    public uploadComponent: MediaUploadComponent;
    public mediaComponent: MediaAbstractComponent;

    public removeContainer: Component;
    public informationContainer: Component;
    public informationColorOnly: boolean;

    constructor(attachmentsComponent: QuillAttachmentsComponent, animation?: boolean, canCrop?: boolean) {
        super();
        this.canCrop = canCrop;
        this.animation = animation == undefined ? true : animation;
        this.attachmentsComponent = attachmentsComponent;

        this.uploadComponent = new MediaUploadComponent();

        this.template = `
            <div gqAttachment class="${s.globalQuillAttachment}">
                ${this.draw(this.uploadComponent)}
                <div gqAttachmentRemove class="${s.remove}">
                    <div class="${s.icon}"></div>
                </div>
            </div>
        `;
    }

    commit() {
        this.removeContainer = this.el(s.remove);
        this.removeContainer.onClick((e) => this.onRemoveClick(), true);

        if (this.animation) {
            this.scaleShow();
        }

        super.commit();
    }

    /**
     * get and set
     */

    uploaded(value: any, component: MediaAbstractComponent) {
        if (this.isNull()) {
            return;
        }

        if (this.uploadComponent && !this.uploadComponent.isNull()) {
            this.uploadComponent.remove();
        }

        if (this.mediaComponent && !this.mediaComponent.isNull()) {
            this.mediaComponent.remove();
        }

        this.mediaComponent = component;

        if (value == undefined) {
            this.render(new NetworkErrorComponent());
        } else {
            this.render(this.mediaComponent);
        }

        if (this.animation) {
            this.mediaComponent.scaleShow();
        }

        if (this.mediaComponent instanceof MediaGifComponent) {
            if (!this.mediaComponent.pastilleComponent.isNull()) {
                this.mediaComponent.pastilleComponent.displayShow();
            }
        }

        if (this.canCrop && component instanceof MediaImageComponent) {
            component.renderCrop();
            component.onCrop = (crop) => this.addCrop(this.attachmentsComponent.quillComponent.contentId(), crop);
        }

        this.uploadedObject = value;
        this.onUploaded();
    }

    getObject(): any {
        if (this.uploadedObject) {
            return this.uploadedObject;
        }

        return this.object;
    }

    /**
     * collaborator
     */
    setCollaborator(entity: IEntityModel, color?: string, silent?: boolean) {
        if (!silent) {
            this.renderInformation(entity.name, color, entity.avatar);
        }
    }

    /**
     * crop
     */

    setCrop(cropping: { [key: string]: IDocumentCroppingModel }) {
        if (this.mediaComponent instanceof MediaImageComponent) {
            let contentId = this.attachmentsComponent.quillComponent.contentId();
            if (cropping && cropping[contentId]) {
                this.mediaComponent.setCrop(cropping[contentId]);
            } else {
                this.mediaComponent.load(FileService.link(this.getObject()));
                this.mediaComponent.crop = undefined;
            }

            this.cropping = cropping;
        }
    }

    addCrop(cropId: string, crop: ICrop) {
        if (!this.cropping) {
            this.cropping = {};
        }

        if (!crop) {
            if (this.cropping[cropId]) {
                delete this.cropping[cropId];
            }
        } else {
            this.cropping[cropId] = {
                x: crop.x,
                height: crop.height,
                y: crop.y,
                width: crop.width
            };
        }

        this.onCrop();
    }

    /**
     * information
     */
    renderInformation(text?: string, color?: string, avatar?: IAvatarModel, noDelay?: boolean) {
        if (!text && !avatar) {
            this.informationColorOnly = true;
        }

        if (!this.informationContainer) {
            this.setStyle(`box-shadow: 0 0 0 2px ${color ? color : `var(--outline-blue)`};transition:0.2s;`);
            if (text) {
                //language=HTML
                let information = this.informationContainer = this.append(`
                    <div class="${s.information}">
                        <div class="${s.name}">
                            ${text}
                        </div>
                    </div>
                `);

                if (avatar) {
                    //language=HTML
                    information.prepend(`
                        <div class="${s.avatarContainer}">
                            ${information.draw(new AvatarComponent(avatar))}
                        </div>
                    `);
                }
            }

            if (!noDelay) {
                this.timeOut(() => this.removeInformation(), 5000);
            }
        }
    }

    removeInformation(colorOnly?: boolean) {
        if (!colorOnly || (colorOnly && this.informationColorOnly)) {
            if (this.informationContainer) {
                this.informationContainer.remove();
                this.informationContainer = undefined;
            }

            this.informationColorOnly = false;
            this.setStyle(`box-shadow: unset;`);
        }


    }

    /**
     * override
     */

    onUploaded() {

    }

    onCrop() {

    }

    onRemoveClick() {
    }
}