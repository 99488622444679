import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./site-channel-picker.scss";
import {
    VisualSelectBoxComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/selectbox/VisualSelectBoxComponent";
import {
    LoaderLightComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {SiteChannelService} from "../../../../../../../services/site/channel/SiteChannelService";
import {EntityService} from "../../../../../../../services/entity/EntityService";
import {AvatarComponent} from "../../../avatar/AvatarComponent";
import {PreferencesService} from "../../../../../../../services/preferences/PreferencesService";
import {ISiteChannelModel} from "../../../../../../../models/site/channel/ISiteChannelModel";
import {ISiteChannelPickerConfig} from "./types/ISiteChannelPickerConfig";
import {ProductType} from "../../../../../../../models/product/ProductType";

export class SiteChannelPickerComponent extends Component {

    public selectBox: VisualSelectBoxComponent;
    public config: ISiteChannelPickerConfig;

    public siteChannels: ISiteChannelModel[];

    constructor(config?: ISiteChannelPickerConfig) {
        super();
        this.config = config ?? {};

        //language=HTML
        this.template = `
            <div class="${s.componentSiteChannelPicker}">
                ${this.draw(new LoaderLightComponent())}
            </div>
        `;
    }

    commit() {
        this.init();
        super.commit();
    }

    async init() {
        this.siteChannels = await SiteChannelService.findBySiteIdAndPerms(EntityService.activeSite.id, this.config.productType ? this.config.productType : ProductType.INBOX, this);
        this.clearAll();

        let entries = [];
        this.siteChannels.forEach(sc => {
            if (!this.config.filterChannels || this.config.filterChannels.includes(sc.channel.type)) {
                entries.push({
                    value: sc.id,
                    label: `<div class="${s.componentSiteChannelPickerAvatarContainer}">${this.drawFly(new AvatarComponent(SiteChannelService.avatar(sc)))}</div> ${sc.name}`
                });
            }
        });

        this.selectBox = new VisualSelectBoxComponent({
            entries: entries,
            search: true,
            width: this.config.width,
            selectedValue: this.config.selected ?? PreferencesService.siteChannelPickerSelected
        });
        this.render(this.selectBox);

        this.selectBox.onSelect = entry => {
            PreferencesService.siteChannelPickerSelected = entry.value as string;
            this.onPick(this.siteChannels.find(value => value.id == entry.value));
        }
        this.onReady(this.siteChannels.find(value => value.id == this.selectBox.getSelection().value))
    }

    classLight() {

    }

    selectedMailId(): string {
        return this.siteChannels.find(value => value.id == this.selectBox.getSelection().value)?.mail?.id;
    }

    /**
     * overrode
     */

    onReady(siteChannel: ISiteChannelModel) {

    }

    onPick(siteChannel: ISiteChannelModel) {

    }
}