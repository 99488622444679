import * as s from "../../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../../QuillFormattingComponent";
import {
    QuillFormattingButtonAlignmentComponent
} from "../../position/alignment/QuillFormattingButtonAlignmentComponent";
import {QuillBlot} from "../../../../../blots/QuillBlot";

export const ALIGNMENT_STYLES = {
    right: "display: inline;float: right;",
    left: "display: inline;float: left;",
    unset: "display: inline;float: unset;margin-left: unset;margin-right: unset;",
    center: "display: block;float: unset;margin: auto;"
};

export const ALIGNMENT_STYLES_MARGINS = {
    right: "margin-left: 15px;margin-right:unset;",
    left: "margin-right: 15px;margin-left:unset;",
}

export class QuillFormattingButtonEmbedAlignComponent extends QuillFormattingButtonAlignmentComponent {

    public align: string;

    constructor(align: string, formatter: QuillFormattingComponent) {
        super(formatter);
        this.align = align;
    }

    update() {
        this.removeClass(s.active);
        if (this.align == this.alignment()) {
            this.addClass(s.active);
        }
    }

    bind() {
        this.onClick(() => {
            let blot = this.formattingComponent.quillComponent.getActiveBlot();
            if (blot instanceof QuillBlot) {
                let style = ALIGNMENT_STYLES[this.align];
                if (ALIGNMENT_STYLES_MARGINS[this.align]) {
                    style += ALIGNMENT_STYLES_MARGINS[this.align];
                }

                if (this.hasClass(s.active)) {
                    blot.format("style", ALIGNMENT_STYLES.unset);
                } else {
                    blot.format("style", style);
                }
            }
        });
    }

    alignment(): string {
        let style = this.formattingComponent.quillComponent.currentFormats()['style'];
        if (style && style.replaceAll(" ", "").includes(ALIGNMENT_STYLES[this.align].replaceAll(" ", ""))) {
            return this.align;
        }

        return super.alignment();
    }
}