import {
    StaticComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-router/static/component/StaticComponent";
import * as s from "./notifications.scss";
import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {Resources} from "../../../../../resources/Resources";

export class NotificationsStatic extends StaticComponent {
    constructor() {
        super();
        this.virtual(s.staticNotificationsNotifs);
    }

    notify(title: string, description: string, duration: number, iconClass?: string): void {
        //language=HTML
        let notification = this.append(`
            <div class="${s.notification}">
                <div class="${s.notificationHead}">
                    <div class="${s.icon} ${s[iconClass == undefined ? "error" : iconClass]}"></div>
                    <div class="${s.title}">
                        ${title}
                    </div>
                </div>
                <div class="${s.description}">
                    ${description}
                </div>
                <div class="${s.notificationButtons}">
                    <div class="${s.confirm}">
                        ${Resources.t("words.ok")}
                        <div class="${s.progress}">
                            <div style="animation-duration: ${duration / 1000}s;" class="${s.circle}"></div>
                        </div>
                    </div>
                </div>
            </div>
        `);
        notification.translate({translateY: 10, scale: 1, delay: 250});
        notification.el(s.confirm).onClick(() => {
            this.close(notification);
        });

        notification.timeOut(() => {
            this.close(notification);
        }, duration);

        notification.unClickable();

        if (description == undefined) {
            notification.el(s.description).remove();
        }
    }

    close(notification: Component) {
        let padding = parseInt(notification.getStyle("padding")) * 2;
        notification.setStyle(`height:${(notification.getHeight() - padding)}px;`);
        notification.translate({scale: 0.8, padding: 0, remove: true, margin: 0, opacity: 0, height: 0, delay: 250});

    }
}
