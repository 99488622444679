@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentDateSchedule {
  width: 100%;

  .case {
    padding: 6px;
    border-radius: 8px;
    margin-left: -6px;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    min-height: 17px;

    @include texts.font("semi-bold");

    &:hover {
      background: var(--grey-sur);
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }


  @media screen and (max-width: 480px) {
    .case {
      font-size: 16px !important;
    }
  }
}