/**
 * merge deux objets
 */

export function objectMerge(objectBase, newObject) {
    Object.keys(newObject).forEach(key => {
        if (Array.isArray(newObject[key])) {
            // Si c'est un tableau, remplacez-le simplement ou fusionnez-le si l'original est également un tableau
            if (!Array.isArray(objectBase[key])) {
                objectBase[key] = [];
            }
            objectBase[key] = [...objectBase[key], ...newObject[key]];
        } else if (newObject[key] && typeof newObject[key] === 'object') {
            // Si c'est un objet, appliquez la fusion en profondeur
            if (!objectBase[key] || typeof objectBase[key] !== 'object') {
                objectBase[key] = {};
            }
            objectMerge(objectBase[key], newObject[key]);
        } else {
            // Pour les valeurs primitives, remplacez simplement
            objectBase[key] = newObject[key];
        }
    });

    return objectBase;
}