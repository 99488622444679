import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./network-error.scss";

export class NetworkErrorComponent extends Component {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div networkError class="${s.globalNetworkError}">
                <div class="${s.warning}"></div>
            </div>
        `;
    }
}