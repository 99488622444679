import {EditorButton} from "./EditorButton";
import {EditorComponent} from "../EditorComponent";
import * as s from "../editor.scss";
import {
    RecorderTooltipComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/recorder/tooltip/RecorderTooltipComponent";
import {Resources} from "../../../../../../resources/Resources";

export class EditorButtonRecorder extends EditorButton {
    constructor(editor: EditorComponent) {
        super(editor);
    }

    init() {
        this.button = this.editor.rightButtonsContainer.append(`
           <div data-tooltip="${Resources.t('words.voiceMessageButtonTooltip')}" button class="${s.button}">
                <div class="${s.icon} ${s.microphone}"></div>
            </div>`);

        this.initPanel(this.button, () => {
            let tooltip = new RecorderTooltipComponent(this.button);
            tooltip.onRecorded = ((file, url, statusCode) => {
                this.editor.quillComponent.addAttachment(file);
                tooltip.dispose();
            });

            return tooltip;
        });
    }
}