import {AssignableType} from "../../../../../../../models/assignment/AssignableType";
import {AvatarComponent} from "../../../avatar/AvatarComponent";
import {RoleService} from "../../../../../../../services/roles/RoleService";
import {IRoleModel} from "../../../../../../../models/roles/IRoleModel";
import {IMailModel} from "../../../../../../../models/mail/IMailModel";
import {MailService} from "../../../../../../../services/mail/MailService";

export class AssignmentPickerAvatar {
    static getAvatar(object: any, assignableType: AssignableType): AvatarComponent {
        switch (assignableType) {
            case AssignableType.ACCOUNT:
            case AssignableType.CONTACT:
                return new AvatarComponent(object.avatar).setActive(object)
            case AssignableType.ROLE:
                return new AvatarComponent(RoleService.avatar(object as unknown as IRoleModel))
            case AssignableType.MAIL:
                let mail = object as unknown as IMailModel;
                return mail.assigned ? new AvatarComponent(mail.assigned.avatar).setActive(mail.assigned) : new AvatarComponent(MailService.avatar(mail));
        }
    }
}