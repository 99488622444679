@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentAssignmentPickerEntry {
  position: relative;
  height: 42px;
  border-radius: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &[data-selected] {
    background: var(--grey-sur);
  }

  .avatarContainer {
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    margin-left: 3px;

    @include positioning.flex-globally;
  }

  .text {
    display: flex;
    justify-content: center;
    margin-left: 7px;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .name {
      position: relative;
      font-size: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include texts.font("semi-bold");
    }

    .mail {
      position: relative;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .iconAssigned {
    height: 10px;
    width: 10px;
    align-self: center;
    margin-left: auto;
    margin-right: 10px;

    @include icons.ico("black-checked");
  }

  @media screen and (max-width: 480px) {
    .name {
      font-size: 16px !important;
    }
  }
}