export enum FileOrigin {
    INBOX_ATTACHMENT = 1,
    LIVECHAT_ATTACHMENT = 2,
    LIVECHAT_AVATAR = 6,
    LIVECHAT_VISITOR_AVATAR = 3,
    ENTITY_AVATAR = 4,
    SHORTCUT_ATTACHMENT = 5,
    SITE_AVATAR = 7,
    SITE_CHANNEL_AVATAR = 8,
    INBOX_IMAGE_INSERT = 9,
    AGGREGATOR_AVATAR = 10,
    PUBLISHER_ATTACHMENT = 11,
    EMAIL_AVATAR = 12
}