import {QuillFormattingComponent} from "../QuillFormattingComponent";

export abstract class QuillFormattingBaseLogic {
    public formatter: QuillFormattingComponent;

    protected constructor(formatter: QuillFormattingComponent) {
        this.formatter = formatter;
    }

    abstract init(): void;
}