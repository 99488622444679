import {VisualTabComponent} from "../../../../../../../../sedestral-interface-component-visual/tab/VisualTabComponent";
import * as s from "./quill-formatting-image-tab.scss";
import {QuillFormattingImageUrlComponent} from "../url/QuillFormattingImageUrlComponent";
import {QuillFormattingImageUploadComponent} from "../upload/QuillFormattingImageUploadComponent";

export class QuillFormattingImageTabComponent extends VisualTabComponent {
    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentQuillFormattingImageTab}">
                <div class="${s.buttons}">
                    <div class="${s.button} ${s.url}">
                        Lien
                    </div>
                    <div class="${s.button} ${s.upload}">
                        Upload
                    </div>
                </div>
                <div class="${s.content}"></div>
            </div>
        `;
    }

    commit() {
        this.contentContainer = this.el(s.content);
        this.views[s.url] = () => {
            let c = new QuillFormattingImageUrlComponent();
            c.onPick = url => this.onPick(url);
            return c;
        }
        this.views[s.upload] = () => {
            let c = new QuillFormattingImageUploadComponent();
            c.onPick = file => this.onPick(file);
            return c;
        }

        super.commit();
    }

    onPick(file: File | string) {

    }

}