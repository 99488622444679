.componentShortcutsSwap {
  div[shortcuts] {
    margin-top: 6px;
    margin-bottom: 12px;

    div[emptybutton] {
      display: none;
    }

    div[shortcutsadd] {
      display: none;
    }

    div[shortcutsSearch] {
      margin-left: 13px;
      width: calc(100% - 30px);

      div[shortcutsSearchIcn] {
        height: 16px !important;
        width: 16px !important;
        margin-top: 4px !important;
        margin-right: 2px !important;
      }

      input {
        width: calc(100% - 30px) !important;
        font-size: 15px !important;
      }
    }

    div[shortcut] {
      height: 26px;

      span {
        font-size: 15px !important;
      }
    }
  }
}