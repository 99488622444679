import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {AvatarComponent} from "../avatar/AvatarComponent";
import * as s from "./account.scss";
import {IAccountModel} from "../../../../../models/account/IAccountModel";

export class AccountComponent extends Component {
    constructor(account: IAccountModel) {
        super();

        //language=HTML
        this.template = `
            <div class="${s.componentAccount}">
                <div class="${s.topSide}">
                    <div class="${s.avatarContainer}">
                        ${this.draw(new AvatarComponent(account.avatar).setActive(account))}
                    </div>
                </div>
                <div class="${s.accountName}">
                    ${account.name}
                </div>
                
                    <!-- FUNC
                <div class="${s.accountMail}">
                    ${account.email}
                </div>
                -->
                    <!-- FUNC
                <div class="rolesContainer">
                    
                </div>
                -->
            </div>
        `;
    }
}