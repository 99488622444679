import {EmojisComponent} from "../../../emojis/EmojisComponent";
import {VisualTabComponent} from "../../../../sedestral-interface-component-visual/tab/VisualTabComponent";
import {GifsComponent} from "../../../gifs/GifsComponent";
import * as s from "./quill-goodies.scss";
import {Component} from "../../../../sedestral-interface-component/interface/component/Component";

export class QuillGoodiesComponent extends VisualTabComponent {
    private emojis: boolean;
    private gifs: boolean;
    private pocket: boolean;

    private categoriesContainer: Component;

    constructor(emojis: boolean, gifs: boolean, pocket?: boolean) {
        super();
        this.emojis = emojis;
        this.gifs = gifs;
        this.pocket = pocket;

        //language=HTML
        this.template = `
            <div go class="${s.globalQuillGoodies}">
                <div class="${s.goodiesCategories}"></div>
                <div class="${s.goodiesContainer}"></div>
            </div>
        `;

        this.speed = 0;
    }


    commit() {
        this.contentContainer = this.el(s.goodiesContainer);
        this.categoriesContainer = this.el(s.goodiesCategories);

        if (this.pocket) {
            this.addClass(s.pocket);
        }

        this.renderButtons();
        super.commit();
    }

    renderButtons() {
        let buttons = 0;

        if (this.emojis) {
            buttons++;
            //language=HTML
            this.categoriesContainer.append(`
                <div class="${s.EmojisButton} ${s.goodiesCategory} ${s.categoryLeft}">EMOJIS</div>`);

            this.views[s.EmojisButton] = () => {
                let component = new EmojisComponent();
                if (this.pocket) {
                    component.onCommit(() => component.setPocket());
                }
                component.onPick = (content => {
                    this.onEmojiPick(content);
                });
                return component;
            }
        }

        if (this.gifs) {
            buttons++;
            //language=HTML
            this.categoriesContainer.append(`
                <div class="${s.GifButton} ${s.goodiesCategory} ${s.categoryRight}">GIF</div>`);
            this.views[s.GifButton] = () => {
                let component = new GifsComponent();
                component.onPick = (url) => {
                    this.onGifPick(url);
                }
                return component;
            }
        }

        if (buttons == 1) {
            this.categoriesContainer.displayHide();
            this.contentContainer.setStyle(`height:100%;`);
        }
    }

    /**
     * override
     */

    onEmojiPick(emoji: string) {

    }

    onGifPick(url: string) {

    }
}