@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";

.componentQuillBlotsLogicResize {
  position: absolute;
  display: none;
  border-radius: 3px;
  pointer-events: none;
  z-index: 4;

  .vert, .vertLeft {
    pointer-events: auto;
    height: 60%;
    position: absolute;
    top: 20%;
    right: -14px;
    left: unset;
    width: 12px;

    @include positioning.flex-globally;

    &.vertLeft {
      right: unset;
      left: -14px;
    }

    .selector {
      height: 100%;
      border-radius: 6px;
      cursor: e-resize;
      box-shadow: 0 0 5px #ffffff26;
      max-height: 34px;
      background: var(--black-alpha);
      width: 4px;
    }
  }

  .bottom {
    pointer-events: auto;
    width: 60%;
    position: absolute;
    left: 20%;
    height: 12px;
    bottom: -14px;
    top: unset;
    @include positioning.flex-globally;

    .selector {
      width: 100%;
      border-radius: 6px;
      cursor: n-resize;
      max-width: 34px;
      background: var(--black-alpha);
      height: 4px;
    }
  }

  .diag {
    pointer-events: auto;
    width: 14px;
    position: absolute;
    height: 14px;
    right: -10px;
    bottom: -10px;
    top: unset;
    transform: rotate(90deg);
    cursor: nw-resize;

    &.diagLeft {
      left: -9px;
      transform: rotate(180deg);
      right: unset;
      cursor: nesw-resize;
    }

    .top {
      width: 100%;
      border-radius: 6px 6px 0 6px;
      background: var(--black-alpha);
      height: 4px;
    }

    .right {
      right: 0;
      bottom: 0;
      position: absolute;
      height: calc(100% - 4px);
      border-radius: 0 0 6px 6px;
      background: var(--black-alpha);
      width: 4px;
    }
  }
}