import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./quill-editor-send-menu.scss";
import {DateScheduleTooltipComponent} from "../../../../date/schedule/tooltip/DateScheduleTooltipComponent";
import {Resources} from "../../../../../../resources/Resources";

export class QuillEditorSendMenuComponent extends Component {

    public scheduleButton: Component;

    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentQuillEditorSendMenu}">
                <div class="${s.entry} ${s.schedule}">
                    <div class="${s.icon}"></div>
                    <div class="${s.text}">
                        ${Resources.t("words.schedule")}
                    </div>
                </div>
            </div>
        `;

    }

    commit() {
        this.scheduleButton = this.el(s.schedule);

        let scheduleTooltip;
        this.scheduleButton.onClick(async () => {
            if (scheduleTooltip) {
                scheduleTooltip.dispose();
            } else {
                scheduleTooltip = new DateScheduleTooltipComponent(this.scheduleButton);
                scheduleTooltip.screen(false);
                scheduleTooltip.create();
                scheduleTooltip.onDispose = () => scheduleTooltip = undefined;
                scheduleTooltip.picker.onPick = (button, time) => {
                    this.onSchedule(time);
                    scheduleTooltip.dispose();
                }
            }
        });


        super.commit();
    }

    onSchedule(time: number) {

    }

}