import {Component} from "../../../../../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./quill-formatting-image-upload.scss";
import {
    VisualFilePickerComponent
} from "../../../../../../../../sedestral-interface-component-visual/file/VisualFilePickerComponent";

export class QuillFormattingImageUploadComponent extends Component {

    public picker: VisualFilePickerComponent;

    constructor() {
        super();
        this.picker = new VisualFilePickerComponent({images: true, gifs: true, multiple: false});

        //language=HTML
        this.template = `
            <div class="${s.componentQuillFormattingImageUpload}">
                <div class="${s.icon}"></div>
                <div class="${s.insert}">
                    Séléctionner et insérer
                </div>
                ${this.draw(this.picker)}
            </div>
        `;
    }

    commit() {
        this.picker.onImageAndGifPick = file => this.onPick(file);
        super.commit();
    }

    onPick(file: File) {

    }

}
