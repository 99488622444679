import {
    VisualHoverTooltipWhiteComponent
} from "../../../../sedestral-interface-component-visual/tooltip/VisualHoverTooltipWhiteComponent";
import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import {RangeSliderComponent} from "../../../range/slider/RangeSliderComponent";
import {RangeSliderType} from "../../../range/slider/types/RangeSliderType";
import * as s from "./media-audio-volume.scss";

export class MediaAudioVolumeComponent extends VisualHoverTooltipWhiteComponent {

    private _slider: RangeSliderComponent;

    constructor(button: Component, value: number) {
        super(button, 10, 10);
        this._slider = new RangeSliderComponent({type: RangeSliderType.HORIZONTAL, invert: true, value: value});
        this._slider.onPick = (height, width) => this.onPick(height, width);

        //language=HTML
        this.template = `
            <div class="${s.componentMediaAudioVolume}">
                ${this.draw(this._slider)}
            </div>
        `;
    }

    onPick(height: number, width: number) {
    }

    commit() {
        this.setStyle(`padding: 10px;border-radius: 8px !important;`);
        super.commit();
    }
}