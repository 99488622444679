import {QuillLogic} from "./QuillLogic";
import {QuillComponent} from "../QuillComponent";
import {CollaborationEditorService} from "../../../../services/collaboration/editor/CollaborationEditorService";
import {ICollaborationEditorModel} from "../../../../models/collaboration/editor/ICollaborationEditorModel";
import {ICollaborationEditorViewerModel} from "../../../../models/collaboration/editor/ICollaborationEditorViewerModel";
import {QuillCollaboratorsComponent} from "../collaboration/collaborators/QuillCollaboratorsComponent";
import {Models} from "../../../../models/Models";
import {QuillAttachmentComponent} from "../attachments/QuillAttachmentComponent";
import {
    ICollaborationEditorMetadataModel
} from "../../../../models/collaboration/editor/ICollaborationEditorMetadataModel";
import {SedestralMachine} from "../../../sedestral-interface-component/machine/SedestralMachine";
import {QuillBinding} from "y-quill";
import {HttpStatus} from "../../../../network/status/HttpStatus";
import {IQuillLogic} from "./IQuillLogic";
import {
    ICollaborationEditorAttachmentsModel
} from "../../../../models/collaboration/editor/attachments/ICollaborationEditorAttachmentsModel";
import {ProductType} from "../../../../models/product/ProductType";
import {randomString} from "../../../sedestral-interface-component/utilities/RandomString";

export class QuillCollaborationLogic extends QuillLogic implements IQuillLogic {

    public editorId: string = "hello";
    public productType: ProductType;
    public editorCollaboration: ICollaborationEditorModel;
    public myViewer: ICollaborationEditorViewerModel;

    public collaboratorsComponent: QuillCollaboratorsComponent;
    public readyFuncs: ((editor: ICollaborationEditorModel) => void)[] = [];

    public collaborationBinding: QuillBinding;

    public savingAttachmentBuffer: number;

    constructor(quillComponent: QuillComponent, productType: ProductType) {
        super(quillComponent);
        this.productType = productType;
    }

    /**
     * init
     */
    async init() {
        this.editorId = this.quillComponent.settings.collaboration?.editorId ? this.quillComponent.settings.collaboration?.editorId : randomString(12, false);

        this.quillComponent.renderLoader();
        let join = await CollaborationEditorService.join(this.productType, {
            editorId: this.editorId,
            savingType: this.quillComponent.settings.collaboration?.savingType,
            savingObjectId: this.quillComponent.settings.collaboration?.savingObjectId
        });
        this.quillComponent.onRemove(() => CollaborationEditorService.leave(this.productType, this.editorId, this.myViewer.id));

        if (typeof join == "number") {
            this.quillComponent.removeLoader();
            if (join == HttpStatus.UNAUTHORIZED) {
                this.onUnauthorized();
            } else {
                this.onError();
            }
        } else {
            this.editorCollaboration = join.editor;
            this.myViewer = join.viewer;

            this.initCollaboration();
        }
    }

    initAttachments() {
        if (this.quillComponent.settings.attachment) {
            this.quillComponent.attachmentsComponent.restoreAttachments(
                this.editorCollaboration.attachments.files.map(value => value.file),
                this.editorCollaboration.attachments.previews.map(value => value.preview),
                this.editorCollaboration.attachments.excludedUrls);

            this.quillComponent.attachmentsComponent
                .restoreAttachmentsCropping(this.editorCollaboration.attachments.files.map(value => {
                    return {fileId: value.file.id, cropping: value.cropping}
                }));

            this.quillComponent.onAttachmentsUploader(this.quillComponent, () => this.savingAttachments());
            this.quillComponent.onAttachmentsChange(this.quillComponent, (component, removed) => {
                if (removed) {
                    this.savingAttachments();
                }
            });


            CollaborationEditorService.onAttachmentsChanges(this.editorId, (viewerId, attachments) => {
                let filesComponents = this.quillComponent.attachmentsComponent.components
                    .filter(value => value.uploadedObject && !Models.isPreviewModel(value.uploadedObject));

                let currentFiles = filesComponents
                    .map(value => value.uploadedObject).map(value => value.id) as string[];

                attachments.files.forEach(attach => {
                    if (!currentFiles.includes(attach.file.id)) {
                        let component = this.quillComponent.attachmentsComponent.addUploadedFile(attach.file);
                        this.setAttachmentCollaborator(component);
                        component.scaleShow(300);
                    }
                });

                filesComponents.forEach(async f => {
                    if (!attachments.files.map(v => v.file.id).includes(f.uploadedObject.id)) {
                        await f.scaleHide(300);
                        await this.quillComponent.attachmentsComponent.removeAttachment(f, true);
                    } else {
                        let file = attachments.files.find(value => value.file.id == f.uploadedObject.id);
                        if (file) {
                            f.setCrop(file.cropping);
                        }
                    }
                });

                this.onAttachments(attachments);
            }, this.quillComponent);
        }
    }

    initCollaboration(): void {
        CollaborationEditorService
            .collaborationJoin(this.quillComponent, this.productType, this.editorId, this.myViewer.privateId, (collaboration) => {
                this.collaborationBinding = new QuillBinding(collaboration.yText, this.quillComponent.quill, collaboration.provider.awareness);
                collaboration.provider.awareness.setLocalStateField('user', {
                    name: this.myViewer.account.name,
                    color: this.myViewer.color
                });

                this.quillComponent.onRemove(() => {
                    collaboration.yDoc.destroy();
                    collaboration.provider.destroy();
                    if (this.collaborationBinding) {
                        this.collaborationBinding.destroy();
                        this.collaborationBinding = undefined;
                    }
                });

                SedestralMachine.requestFrame()(() => {
                    SedestralMachine.requestFrame()(() => {
                        this.initAttachments();
                        this.initMetadata();
                    });

                    this.readyFuncs.forEach(value => value(this.editorCollaboration));
                });

                this.quillComponent.removeLoader();
                this.onConnect();
            }, () => {
                if (this.collaborationBinding) {
                    this.collaborationBinding.destroy();
                    this.collaborationBinding = undefined;
                }

                this.quillComponent.removeLoader();
                this.onError();
            });
    }

    async initMetadata() {
        CollaborationEditorService.onMetadata(this.editorId, (m) => {
            let viewer = this.quillComponent.logicCollaboration.editorCollaboration.viewers.filter(value => value.id == m.viewerId)[0];
            this.onMetadata(m, viewer);
        }, this.quillComponent);
    }

    /**
     * attachments
     */
    setAttachmentCollaborator(component: QuillAttachmentComponent, silent?: boolean) {
        let object;
        if (Models.isPreviewModel(component.uploadedObject)) {
            object = this.editorCollaboration.attachments.previews.filter(p => p.preview.baseUrl == component.uploadedObject.baseUrl)[0];
        } else {
            object = this.editorCollaboration.attachments.files.filter(p => p.file.id == component.uploadedObject.id)[0];
        }

        let viewer = this.editorCollaboration.viewers.filter(viewer => viewer.id == object.viewerId)[0];
        component.setCollaborator(object.account, viewer ? viewer.color : undefined, silent);
    }

    async savingAttachments() {
        clearTimeout(this.savingAttachmentBuffer);
        this.savingAttachmentBuffer = this.quillComponent.timeOut(async () => {
            let editorId = this.editorId;
            await CollaborationEditorService.savingAttachments(this.productType, {
                editorId: editorId,
                previewsUrls: this.quillComponent.attachmentsComponent.allUploaded().previews.map(value => value.baseUrl),
                files: this.quillComponent.attachmentsComponent.allUploaded().files.map(value => {
                    return {fileId: value.file.id, metadata: undefined, cropping: value.cropping}
                }),
                excludedUrls: this.quillComponent.attachmentsComponent.excludedUrls
            });
        }, 50);
    }

    /**
     * dispose
     */

    dispose() {

    }

    /**
     * override execute
     */

    setCollaborators(component?: QuillCollaboratorsComponent) {
        if (!component) {
            component = new QuillCollaboratorsComponent();
            this.quillComponent.prerender(component);
        }

        component.renderViewers(this.editorCollaboration.viewers);
        CollaborationEditorService.onViewerJoin(this.editorId, viewer => {
            component.renderViewer(viewer);
        }, this.quillComponent);
        CollaborationEditorService.onViewerLeave(this.editorId, viewer => {
            component.removeViewer(viewer);
        }, this.quillComponent);

        this.collaboratorsComponent = component;
    }

    savingMetadata(metadata: any) {
        CollaborationEditorService.savingMetadata(this.productType, {
            viewerId: this.myViewer.id,
            editorId: this.editorId,
            metadata: metadata
        });
    }

    /**
     * events
     */

    onReady(func: (editor: ICollaborationEditorModel) => void) {
        this.readyFuncs.push(func);
    }

    onMetadata(metadata: ICollaborationEditorMetadataModel, viewer: ICollaborationEditorViewerModel) {

    }

    onAttachments(attachments: ICollaborationEditorAttachmentsModel) {

    }

    onError() {

    }

    onConnect() {

    }

    onUnauthorized() {

    }

}