import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import * as s from "./date-schedule.scss";
import {DatePickerTooltipComponent} from "../picker/tooltip/DatePickerTooltipComponent";
import {Resources} from "../../../../resources/Resources";

export class DateScheduleComponent extends Component {

    public date: Date;

    constructor(date?: Date) {
        super();
        this.date = date;

        //language=HTML
        this.template = `
            <div class="${s.componentDateSchedule}">

                <div data-value="30" class="${s.case}">
                    ${Resources.r("words.minutesSchedule", {count: 30})}
                </div>
                <div data-value="60" class="${s.case}">
                    ${Resources.r("words.hourSchedule", {count: 1})}
                </div>
                <div data-value="120" class="${s.case}">
                    ${Resources.r("words.hoursSchedule", {count: 2})}
                </div>
                <div data-value="180" class="${s.case}">
                    ${Resources.r("words.hoursSchedule", {count: 3})}
                </div>
                <div data-value="360" class="${s.case}">
                    ${Resources.r("words.hoursSchedule", {count: 6})}
                </div>
                <div data-value="1440" class="${s.case}">
                    ${Resources.r("words.daySchedule", {count: 1})}
                </div>
                <div data-value="2880" class="${s.case}">
                    ${Resources.r("words.daysSchedule", {count: 2})}
                </div>
                <div data-value="4320" class="${s.case}">
                    ${Resources.r("words.daysSchedule", {count: 3})}
                </div>
                <div data-value="10080" class="${s.case}">
                    ${Resources.r("words.daysSchedule", {count: 7})}
                </div>
                <div data-value="date" class="${s.case}">
                    ${Resources.t("words.customDate")}
                </div>
            </div>
        `;

    }

    commit() {

        let datePicker;
        this.els(s.case).forEach(c => {
            c.onClick(() => {
                let dataValue = c.getAttribute("data-value");
                if (dataValue == "date") {
                    if (datePicker != undefined && !datePicker.isNull()) {
                        datePicker.dispose();
                    } else {
                        datePicker = new DatePickerTooltipComponent(c, {hours: true, firstDate: this.date});
                        datePicker.screen(false);
                        datePicker.create();
                        datePicker.onValid = (date) => {
                            datePicker.dispose();
                            this.onPick(c, date.getTime());
                        }
                    }
                } else {
                    let time = Date.now() + (((parseInt(dataValue)) * 60) * 1000);
                    this.onPick(c, time);
                }
            });
        });
        super.commit();
    }

    onPick(button: Component, time: number) {

    }

}