import {QuillLogic} from "./QuillLogic";
import {QuillComponent} from "../QuillComponent";

export class QuillLogicSpellChecker extends QuillLogic {

    public value: boolean;

    constructor(quillComponent: QuillComponent, value: boolean) {
        super(quillComponent);
        this.value = value;
    }

    init() {
        this.quillComponent.quill.root.setAttribute("spellcheck", "" + this.value);
    }
}