@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.staticNavigation {
  .navigation {
    width: 60px;
    position: fixed;
    z-index: 40;
    top: -3px;
    height: calc(100% + 3px);
    left: 0;
    border-right: var(--grey-sur) 1px solid;
    background: var(--white);

    .topSide {
      position: absolute;
      width: 100%;
    }

    .bottomSide {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding-bottom: 6px;
      background: var(--white);
      box-shadow: 0px -10px 6px -6px #ffffffba;
    }

    .category {
      height: 56px;
      width: 100%;
      cursor: pointer;
      position: relative;

      @include positioning.flex-globally;

      &.account {
        visibility: visible;
      }

      &.inbox {
        visibility: visible;
      }

      &.plus {
        .button {
          .icon {
            @include icons.ico("black-plus");
          }
        }
      }

      &.settings {
        .button {
          .icon {
            @include icons.ico("black-settings");
          }
        }
      }

      &.selected {
        .capsuleContainer {
          background: var(--grey-sur);
        }
      }

      &:hover {
        .capsuleContainer {
          background: var(--grey-sur);
        }
      }

      .capsuleContainer {
        height: 36px;
        width: 36px;
        padding: 6px;
        border-radius: 100%;

        .button {
          height: 100%;
          width: 100%;
          margin-left: -2px;
          margin-top: -2px;
          border: 2px solid var(--grey-sur);
          border-radius: 100%;

          @include positioning.flex-globally;

          .icon {
            height: 14px;
            width: 14px;
          }
        }

        .notification {
          background: var(--red);
          position: absolute;
          font-size: 9px;
          height: 15px;
          width: 15px;
          border-radius: 100%;
          border: 2px solid var(--white);
          color: var(--white);
          top: 6px;
          right: 10px;

          @include texts.font("semi-bold");
          @include positioning.flex-globally;
        }

        .avatarContainer {
          &.padding {
            position: relative;
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    .submenu {
      .list {
        max-height: 0;
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;

        .category {
          height: 48px;

          .capsuleContainer {
            background: unset !important;
          }

          &.selected {
            .capsuleContainer {
              box-shadow: inset 0 0 0 2px var(--grey-sur-hover);
            }
          }


          &:hover {
            .capsuleContainer {
              box-shadow: inset 0 0 0 2px var(--grey-sur);
            }
          }
        }

        .category:last-child {
          margin-bottom: 5px;
        }
      }
    }

    a {
      color: var(--black);
    }


  }

  .navigationAccount {
    height: 556px;
    display: none;
    width: 348px;
    z-index: 9999;
    transform: scale(0.9);
    bottom: -15px;
    opacity: 0;
    left: 60px;
    overflow: hidden;
    position: absolute;

    @include boxs.sur;
  }
}