import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import * as s from "./range-slider.scss";
import {RangeSliderType} from "./types/RangeSliderType";
import {IRangeSliderSettings} from "./types/IRangeSliderSettings";
import {draggable} from "../../../sedestral-interface-component/interface/component/funcs/Draggable";
import {onDragInside} from "../../../sedestral-interface-component/interface/component/funcs/OnDragInside";

export class RangeSliderComponent extends Component {
    private inverted: boolean;
    private sliderType: RangeSliderType;
    private value: number;
    private range: [number, number];

    private caret: Component;
    private progression: Component;

    constructor(settings?: IRangeSliderSettings) {
        super();
        settings = settings == undefined ? {} : settings;
        this.inverted = settings.invert == undefined ? false : settings.invert;
        this.sliderType = settings.type == undefined ? RangeSliderType.VERTICAL : settings.type;
        this.value = settings.value ? settings.value : 0;
        this.range = settings.range;

        if (this.range != undefined) {
            this.value = this.value > this.range[1] ? this.range[1] : this.value;
            this.value = 100 * this.value / this.range[1];
        }

        //language=HTML
        this.template = `
            <div rangeSlider class="${s.globalRangeSlider}">
                <div class="${s.caret}">
                    <div class="${s.selector}"></div>
                </div>
                <div class="${s.progression}"></div>
            </div>
        `;
    }

    commit() {
        this.caret = this.el(s.caret);
        this.progression = this.el(s.progression);

        this.place();
        this.caret.unClickable();
        draggable(this.caret, true);
        onDragInside(this.caret, (left, top) => {
            this.slide(left, top);
        });


        this.onClick((e) => {
            if (e.target instanceof HTMLElement) {
                let rect = this.getHTMLElement().getBoundingClientRect();
                let left = e.clientX - rect.left;
                let top = e.clientY - rect.top;
                this.slide(left, top);
            }
        });

        super.commit();
    }

    /**
     * place
     */
    place() {
        if (this.sliderType == RangeSliderType.HORIZONTAL) {
            this.addClass(s.horizontal);
        } else {
            this.addClass(s.vertical);
        }

        if (this.inverted) {
            if (this.sliderType == RangeSliderType.HORIZONTAL) {
                this.caret.setStyle(`top:${100 - this.value}%;`);
                this.progression.setStyle(`height:${this.value}%;top:unset;bottom:0;`);
            }
            if (this.sliderType == RangeSliderType.VERTICAL) {
                this.caret.setStyle(`left:${100 - this.value}%;`);
                this.progression.setStyle(`width:${this.value}%;right:0;left:unset;`);
            }
        } else {
            if (this.sliderType == RangeSliderType.HORIZONTAL) {
                this.caret.setStyle(`top:${this.value}%;`);
                this.progression.setStyle(`height:${this.value}%;`);
            }

            if (this.sliderType == RangeSliderType.VERTICAL) {
                this.caret.setStyle(`left:${this.value}%;`);
                this.progression.setStyle(`width:${this.value}%;`);
            }
        }
    }


    /**
     * slide
     */
    slide(left: number, top: number) {
        if (this.sliderType == RangeSliderType.VERTICAL) {
            if (this.inverted) {
                left = this.percent(this.getWidth(), this.getWidth() - left);
                this.progression.setStyle(`width:${left}%;`);
                this.caret.setStyle(`left:${100 - left}%;right:unset;`);
            } else {
                left = this.percent(this.getWidth(), left);
                this.progression.setStyle(`width:${left}%;`);
                this.caret.setStyle(`left:${left}%;right:unset;`);
            }
        }

        if (this.sliderType == RangeSliderType.HORIZONTAL) {
            if (this.inverted) {
                this.progression.setStyle(`height:${this.percent(this.getHeight(), (this.getHeight() - top))}%;`);
                this.caret.setStyle(`top:${this.percent(this.getHeight(), top)}%;`);
            } else {
                top = this.percent(this.getHeight(), top);
                this.progression.setStyle(`height:${top}%;`);
                this.caret.setStyle(`top:${top}%;`);
            }
        }


        if (this.sliderType == RangeSliderType.VERTICAL) {
            if (this.inverted) {
                this.pick(0, Math.round((this.progression.getWidth() - 5) * 100 / (this.getWidth() - 5)));
            } else {
                this.pick(0, Math.round(this.progression.getWidth() * 100 / (this.getWidth() - 5)));
            }
        } else {
            if (this.inverted) {
                this.pick(Math.round((this.progression.getHeight() - 5) * 100 / (this.getHeight() - 5)), 0);
            } else {
                this.pick(Math.round(this.progression.getHeight() * 100 / (this.getHeight() - 5)), 0);
            }
        }
    }

    /**
     * calc
     */

    pick(height: number, width: number) {
        if (this.range != undefined) {
            height = (height * (this.range[1] - this.range[0]) / 100) + this.range[0];
            width = (width * (this.range[1] - this.range[0]) / 100) + this.range[0];
        }

        this.onPick(Math.round(height), Math.round(width));
    }

    percent(max: number, value: number) {
        return (value * 100) / max;
    }

    /**
     * events
     */

    onPick(height: number, width: number) {
    }

}