@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/effects";

.globalMediaAudio {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border-radius: 13px;
  overflow: visible;
  background: var(--white);
  border: var(--grey-sur) 1px solid;

  &:hover {
    transform: none !important;
  }

  .AudioSource {
    display: none;
  }

  .audioButtons {
    height: 100%;
    float: left;

    @include positioning.flex-globally;

    .audioButton {
      cursor: pointer;
      height: 100%;
      width: 40px;
      border-right: var(--grey-sur) 1px solid;
      position: relative;
      float: left;

      @include positioning.flex-globally;
      @include effects.hover-alpha;

      .icon {
        height: 10px;
        width: 10px;

        &.play {
          @include icons.ico("black-play");
        }

        &.mute {
          @include icons.ico("black-mute");
        }

        &.volumeIco {
          @include icons.ico("black-volume");
        }

        &.pause {
          @include icons.ico("black-pause");
        }
      }
    }

    .playButton {
      @extend .audioButton;
      border-radius: 13px 0 0 13px;

      .ActiveIcon {
        visibility: visible;
      }
    }
  }

  .songButton {
    @extend .audioButton;
    @include positioning.flex-globally;

    .VolumeIcon {
      visibility: visible;
    }
  }

  .controls {
    margin-left: 4px;
    width: calc(100% - 100px);
    top: calc(50% - 6px);
    position: relative;
    float: left;

    .currentTime {
      font-size: 9px;
      margin-left: 5px;
      width: 22px;
      position: relative;
      float: left;

      @include texts.font("medium");
      @include texts.font("medium");
    }

    .audioProgression {
      height: 12px;
      margin-left: 12px;
      overflow: hidden;
      cursor: pointer;
      border-radius: 15px;
      width: calc(100% - 70px);
      background: var(--grey-sur);
      position: relative;
      float: left;

      .progress {
        height: 100%;
        width: 0;
        left: 0;
        background: var(--black);
        transition: all 0.2s ease 0s;
      }
    }

    .totalTime {
      font-size: 9px;
      width: 22px;
      position: relative;
      float: right;

      @include texts.font("medium");
    }
  }
}