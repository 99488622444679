import {QuillBlotLogic} from "../QuillBlotLogic";
import {QuillBlot} from "../../QuillBlot";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./quill-blots-logic-resize.scss";
import {onDrag} from "../../../../../sedestral-interface-component/interface/component/funcs/OnDrag";

export class QuillBlotResizeLogic extends QuillBlotLogic {

    public diagonalSelector: Component;
    public diagonalLeftSelector: Component;
    public rightSelector: Component;
    public bottomSelector: Component;
    public leftSelector: Component;

    public startWidth: number;
    public startHeight: number;

    public startLeft: number;
    public startTop: number;

    public cursor: Component;

    constructor(blot: QuillBlot) {
        super(blot);
    }

    /**
     * cursor
     */
    renderCursor() {
        if (!this.cursor) {
            //language=HTML
            this.cursor = this.blot.quillComponent.append(`
                <div class="${s.componentQuillBlotsLogicResize}">
                    <div class="${s.vert}">
                        <div class="${s.selector}"></div>
                    </div>
                    <div class="${s.vertLeft}">
                        <div class="${s.selector}"></div>
                    </div>
                    <div class="${s.bottom}">
                        <div class="${s.selector}"></div>
                    </div>
                    <div class="${s.diag}">
                        <div class="${s.top}"></div>
                        <div class="${s.right}"></div>
                    </div>
                    <div class="${s.diag} ${s.diagLeft}">
                        <div class="${s.top}"></div>
                        <div class="${s.right}"></div>
                    </div>
                </div>`);
            this.cursor.fade("in", 350);

            this.cursor.addListener('mousedown', () => {
                this.startWidth = Math.round(this.blot.component.getWidth());
                this.startHeight = Math.round(this.blot.component.getHeight());
            });
            this.cursor.unClickable();

            this.diagonalSelector = this.cursor.el(s.diag);
            this.diagonalLeftSelector = this.cursor.el(s.diagLeft);
            this.rightSelector = this.cursor.el(s.vert);
            this.bottomSelector = this.cursor.el(s.bottom);
            this.leftSelector = this.cursor.el(s.vertLeft);

            this.startWidth = Math.round(this.blot.component.getWidth());
            this.startHeight = Math.round(this.blot.component.getHeight());

            this.bind();
        }
    }

    removeCursor() {
        if (this.cursor) {
            this.cursor.remove();
            this.cursor = undefined;
        }
    }

    place() {
        this.placeAbove(this.cursor);
    }

    /**
     * drag logic
     */

    bind() {
        onDrag(this.diagonalSelector, (left, top) => this.dragDiagonal(left, top), () => {
            this.startLeft = undefined;

            this.startHeight = Math.round(this.blot.component.getHeight());
            this.startWidth = Math.round(this.blot.component.getWidth());
        });
        onDrag(this.diagonalLeftSelector, (left, top) => this.dragDiagonalLeft(left, top), () => {
            this.startLeft = undefined;
            this.startHeight = Math.round(this.blot.component.getHeight());
            this.startWidth = Math.round(this.blot.component.getWidth());
        });

        onDrag(this.bottomSelector, (left, top) => this.dragHeight(left, top), () => {
            this.startTop = undefined;
            this.startHeight = Math.round(this.blot.component.getHeight());
        });
        onDrag(this.rightSelector, (left, top) => this.dragWidth(left, top), () => {
            this.startLeft = undefined;
            this.startWidth = Math.round(this.blot.component.getWidth());
        });
        onDrag(this.leftSelector, (left, top) => this.dragWidthLeft(left, top), () => {
            this.startLeft = undefined;
            this.startWidth = Math.round(this.blot.component.getWidth());
        });
    }

    dragDiagonal(left, top) {
        if (!this.startLeft) {
            this.startLeft = left;
        }

        let ratio = this.startHeight / this.startWidth;
        let width = Math.round((this.startWidth + (left - this.startLeft)));
        let height = Math.round(width * ratio);

        if (width > 0 && width < (this.blot.quillComponent.getWidth())) {
            this.blot.format("height", height);
            this.blot.format("width", width);
        }
    }

    dragDiagonalLeft(left, top) {
        if (left < 0) {
            left = Math.abs(left);
        } else {
            left = -left;
        }

        if (!this.startLeft) {
            this.startLeft = left;
        }

        let ratio = this.startHeight / this.startWidth;
        let width = Math.round((this.startWidth + (left - this.startLeft)));
        let height = Math.round(width * ratio);

        if (width > 0 && width < (this.blot.quillComponent.getWidth())) {
            this.blot.format("height", height);
            this.blot.format("width", width);
        }
    }

    dragWidth(left, top) {
        if (!this.startLeft) {
            this.startLeft = left;
        }

        let width = Math.round((this.startWidth + (left - this.startLeft)));

        if (width > 0 && width < (this.blot.quillComponent.getWidth())) {
            this.blot.format("height", this.startHeight);
            this.blot.format("width", width);
        }
    }

    dragWidthLeft(left, top) {

        if (left < 0) {
            left = Math.abs(left);
        } else {
            left = -left;
        }

        if (!this.startLeft) {
            this.startLeft = left;
        }

        let width = Math.round((this.startWidth + (left - this.startLeft)));
        if (width > 0 && width < (this.blot.quillComponent.getWidth())) {
            this.blot.format("height", this.startHeight);
            this.blot.format("width", width);
        }
    }

    dragHeight(left, top) {
        if (!this.startTop) {
            this.startTop = top;
        }

        let v = Math.round((this.startHeight + (top - this.startTop)));
        this.blot.format("height", v);
        this.blot.format("width", this.startWidth);
    }

    /**
     * override
     */
    onReady() {
        if (this.blot.data.width > this.blot.quillComponent.getWidth()) {
            this.blot.data.width = this.blot.quillComponent.getWidth();

            let ratio = this.blot.data.height / this.blot.data.width;
            let height = Math.round(this.blot.data.width * ratio);

            this.blot.data.height = height;
        }
        this.blot.component.setAttribute("width", this.blot.data.width);
        this.blot.component.setAttribute("height", this.blot.data.height);
        this.blot.onCursorSelect(() => {
            this.renderCursor();
            this.place();
        }, () => {
            this.removeCursor();
        });

        super.onReady();
    }

    onDetach() {
        if (this.cursor) {
            this.cursor.remove();
            this.cursor = undefined;
        }

        super.onDetach();
    }

    onQuillChange() {
        if (this.cursor) {
            this.place();
        }

        super.onQuillChange();
    }
}