import {
    VisualClickTooltipWhiteComponent
} from "../../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import {QuillGoodiesComponent} from "../QuillGoodiesComponent";

export class QuillGoodiesTooltipComponent extends VisualClickTooltipWhiteComponent {
    constructor(component: Component, emojis: boolean, gifs: boolean) {
        super(component, 357, 345);
        this.mobileStyle(true);
        this._goodies = new QuillGoodiesComponent(emojis, gifs);
    }

    private _goodies: QuillGoodiesComponent;

    get goodies(): QuillGoodiesComponent {
        return this._goodies;
    }

    commit() {
        super.commit();
        this.render(this._goodies);
        this.setStyle(`height:357px;padding:10px 0px 0px 0px;`);
        this.swapable(() => this.dispose());
    }

}