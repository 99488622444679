import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {EditorInboxMailComponent} from "../../../editor/models/inbox/mail/EditorInboxMailComponent";
import * as s from "./conversation-creator-mail.scss";
import {IInboxConversationModel} from "../../../../../../../models/inbox/conversation/IInboxConversationModel";
import {
    IInboxConversationMessageModel
} from "../../../../../../../models/inbox/conversation/message/IInboxConversationMessageModel";
import {IEditor} from "../../../editor/types/IEditor";
import {
    CollaborationEditorSavingType
} from "../../../../../../../models/collaboration/editor/saving/CollaborationEditorSavingType";
import {SiteChannelPickerComponent} from "../../../site/channel/picker/SiteChannelPickerComponent";
import {
    InboxConversationMessageService
} from "../../../../../../../services/inbox/conversation/message/InboxConversationMessageService";
import {Network} from "../../../../../../../network/Network";
import {ProductType} from "../../../../../../../models/product/ProductType";

export class ConversationCreatorMailComponent extends Component {

    public editor: EditorInboxMailComponent;
    public conversation: IInboxConversationModel;
    public message: IInboxConversationMessageModel;

    public channelPicker: SiteChannelPickerComponent;
    public collaboratorsContainer: Component;

    constructor(conversation?: IInboxConversationModel, message?: IInboxConversationMessageModel, channelFromPicker?: SiteChannelPickerComponent, collaboratorsContainer?: Component) {
        super();
        this.conversation = conversation;
        this.message = message;
        this.channelPicker = channelFromPicker;
        this.collaboratorsContainer = collaboratorsContainer;

        let settings: IEditor = {
            collaborationCollaboratorsContainer: collaboratorsContainer,
            mailFromPicker: channelFromPicker,
            mailDisableSendType: true,
            mailShowSubject: true,
            quill: {}
        };
        if (this.message) {
            settings.quill.collaboration = {
                editorId: this.message.id,
                productType: ProductType.PANEL,
                savingType: CollaborationEditorSavingType.CONV_MESSAGE,
                savingObjectId: this.message.id
            }
        }

        this.editor = new EditorInboxMailComponent(settings, conversation);

        //language=HTML
        this.template = `
            <div class="${s.componentConversationCreatorMail}">
                ${this.draw(this.editor)}
            </div>
        `;
    }

    commit() {
        this.editor.onSend = async (scheduleTime) => {
            let result = await InboxConversationMessageService.sendDraft(this.message.id, this.editor.contents().html, scheduleTime, this);
            if (typeof result != "number") {
                Network.router.pages.redirect("/inbox/c/" + (result as IInboxConversationMessageModel).conversationId);
                this.onCreate();
            }
        }

        super.commit();
    }

    onCreate() {

    }

}