import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import * as s from "./loader-wave.scss";

export class LoaderWaveComponent extends Component {
    constructor() {
        super();

        this.template = `
            <div class="${s.globalLoaderWave}"></div>
        `;
    }
}