import {QuillFormattingBaseButtonComponent} from "../QuillFormattingBaseButtonComponent";
import {QuillFormattingLinkComponent} from "./link/QuillFormattingLinkComponent";
import * as s from "../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../QuillFormattingComponent";

export class QuillFormattingButtonLinkComponent extends QuillFormattingBaseButtonComponent {
    constructor(formatter: QuillFormattingComponent) {
        super("link", formatter);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton}">
                <div class="${s.icon} ${s.link}"></div>
            </button>
        `;
    }

    bind() {
        this.onClick(() => {
            let selection = this.formattingComponent.quillComponent.lastSelection;
            let bounds = this.formattingComponent.quillComponent.getBounds(selection.index, selection.length);
            let offsets = this.formattingComponent.quillComponent.getOffsets();
            let contents = this.formattingComponent.quillComponent.contents(selection);
            let formats = this.formattingComponent.quillComponent.currentFormats();

            let tooltip = new QuillFormattingLinkComponent(this.formattingComponent, bounds, offsets, contents.text, formats['link']);
            tooltip.screen(false);
            tooltip.animationDelay = 40;
            tooltip.create();
            tooltip.onPick = ((text, link) => {
                this.formattingComponent.quillComponent.insertLink(text, link, true);
                tooltip.dispose();
            });
        });
    }
}