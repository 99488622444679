import {EditorButton} from "./EditorButton";
import {EditorComponent} from "../EditorComponent";
import * as s from "../editor.scss";
import {Resources} from "../../../../../../resources/Resources";

export class EditorButtonPrivate extends EditorButton {
    constructor(editor: EditorComponent) {
        super(editor);
    }

    init() {
        //language=HTML
        this.button = this.editor.leftButtonsContainer.append(`
            <div button class="${s.button} ${s.privateButton} ${s.notPrivate}">
                <div class="${s.icon}"></div>
            </div>
        `);

        this.button.onClick(() => {
            if (this.editor.config.enablePrivate) {
                this.editor.config.enablePrivate = false;
                this.editor.removeClass(s.private);
                this.button.addClass(s.notPrivate);
                this.button.removeClass(s.private);

                this.editor.quillComponent.executeContentChange(undefined, undefined, "user");
            } else {
                this.editor.config.enablePrivate = true;
                this.editor.addClass(s.private);
                this.button.removeClass(s.notPrivate);
                this.button.addClass(s.private);
                this.editor.quillComponent.executeContentChange(undefined, undefined, "user");
            }
        });

        this.button.setTooltip(Resources.t('words.noteTooltip'));
    }
}