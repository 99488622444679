import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./conversation-creator.scss";
import {SiteChannelPickerComponent} from "../../site/channel/picker/SiteChannelPickerComponent";
import {ISiteChannelModel} from "../../../../../../models/site/channel/ISiteChannelModel";
import {ChannelType} from "../../../../../../models/channel/ChannelType";
import {ConversationCreatorMailComponent} from "./mail/ConversationCreatorMailComponent";
import {IInboxConversationModel} from "../../../../../../models/inbox/conversation/IInboxConversationModel";
import {
    LoaderLightComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {
    InboxConversationMessageService
} from "../../../../../../services/inbox/conversation/message/InboxConversationMessageService";
import {
    IInboxConversationMessageModel
} from "../../../../../../models/inbox/conversation/message/IInboxConversationMessageModel";
import {Resources} from "../../../../../../resources/Resources";
import {ProductType} from "../../../../../../models/product/ProductType";

export class ConversationCreatorComponent extends Component {

    public channelFromPicker: SiteChannelPickerComponent;
    public currentSiteChannel: ISiteChannelModel;

    public collaboratorsContainer: Component;

    public contentContainer: Component;
    public messageId: string;
    public message: IInboxConversationMessageModel;
    public conversation: IInboxConversationModel;

    constructor(messageId: string) {
        super();
        this.messageId = messageId;

        //language=HTML
        this.template = `
            <div class="${s.componentConversationCreator}">
                <div class="${s.content}"></div>
            </div>
        `;
    }

    commit() {
        this.contentContainer = this.el(s.content);

        this.init();
        super.commit();
    }

    async init() {
        this.contentContainer.render(new LoaderLightComponent());

        if (this.messageId) {
            let creator = await InboxConversationMessageService.findCreator(this.messageId);
            this.message = creator.message;
            this.conversation = creator.conversation;
        }

        this.renderFrom();
    }

    /**
     * render
     */

    renderFrom() {
        this.channelFromPicker = new SiteChannelPickerComponent({
            filterChannels: [ChannelType.MAIL],
            productType: ProductType.INBOX,
            selected: this.conversation?.siteChannelId
        });

        //language=HTML
        this.prepend(`
            <div class="${s.from}">
                <div class="${s.case}">
                    ${Resources.t('words.from')}
                </div>
                <div class="${s.pickerContainer}">
                    ${this.draw(this.channelFromPicker)}
                </div>
                <div class="${s.collaboratorsContainer}"></div>
            </div>
        `);

        this.collaboratorsContainer = this.el(s.collaboratorsContainer);

        this.channelFromPicker.onPick = siteChannel => {
            if (this.currentSiteChannel == undefined || this.currentSiteChannel.channel.type != siteChannel.channel.type) {
                this.renderType(siteChannel);
                this.currentSiteChannel = siteChannel;
            }
        }

        this.channelFromPicker.onReady = siteChannel => this.renderType(siteChannel);
    }

    renderType(siteChannel: ISiteChannelModel) {
        this.contentContainer.removeClass(s.mail);

        switch (siteChannel.channel.type) {
            case ChannelType.MAIL:
                this.renderMailType();
                break;
            default:
                this.renderMailType();
                break;
        }
    }

    renderMailType() {
        this.contentContainer.clearAll();
        this.contentContainer.addClass(s.mail);

        let mailComponent = new ConversationCreatorMailComponent(this.conversation, this.message, this.channelFromPicker, this.collaboratorsContainer);
        mailComponent.onCreate = () => this.onCreate();

        this.contentContainer.render(mailComponent);
    }

    onCreate() {

    }

}