import {
    VisualClickTooltipWhiteComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {ShortcutsComponent} from "../ShortcutsComponent";
import {Network} from "../../../../../../network/Network";

export class ShortcutsTooltipComponent extends VisualClickTooltipWhiteComponent {
    public search: boolean;
    public useScrollbar: boolean;
    public shortcutsComponent: ShortcutsComponent;
    public container: Component;

    constructor(component: Component, search: boolean, useScrollbar: boolean) {
        super(component, 0, 350);
        this.search = search;
        this.useScrollbar = useScrollbar;
        this.shortcutsComponent = new ShortcutsComponent(this.search, this.useScrollbar, true);
        this.template = this.draw(this.shortcutsComponent);
        this.mobileStyle(true);
        this.autoMaxHeight(true);
    }

    commit() {
        this.setStyle("padding: 8px 0px;");
        this.shortcutsComponent.onAddClick = () => Network.router.pages.redirect("/general/shortcuts");
        super.commit();
    }
}