import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./quill-formatting-button.scss";
import {QuillFormattingComponent} from "../QuillFormattingComponent";

export abstract class QuillFormattingBaseButtonComponent extends Component {
    public name: string;
    public formattingComponent: QuillFormattingComponent;

    protected constructor(name: string, quillComponent: QuillFormattingComponent) {
        super();
        this.type = "BUTTON";
        this.name = name;
        this.formattingComponent = quillComponent;
    }

    commit() {
        this.bind();
        super.commit();
    }

    bind() {
        this.onClick(() => {
            this.format();
        });
    }

    public update() {
        let formats = this.formattingComponent.quillComponent.currentFormats();
        this.removeClass(s.active);
        if (formats.hasOwnProperty(this.name)) {
            if (formats[this.name] != "" && !this.isBaseValue(formats[this.name])) {
                this.addClass(s.active);
            }
        }
    };

    public format(value?: any) {
        let formats = this.formattingComponent.quillComponent.currentFormats();
        value = (value == undefined) ? !formats.hasOwnProperty(this.name) : value;

        this.formattingComponent.quillComponent.focusScroll();
        if (this.formattingComponent.settings.noApplyFormat == undefined) {
            this.formattingComponent.quillComponent.quill.format(this.name, value, "user");
        }

        this.onFormat(this.name, value);
        this.update();
    }

    public isBaseValue(value: string): boolean {
        return false;
    }

    /**
     * events
     */

    public onFormat(name: string, value: string) {

    }
}