import {EditorButton} from "./EditorButton";
import {EditorComponent} from "../EditorComponent";
import * as s from "../editor.scss";
import {
    QuillIntegrationsTooltipComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/editor/integrations/tooltip/QuillIntegrationsTooltipComponent";
import {Resources} from "../../../../../../resources/Resources";

export class EditorButtonIntegrations extends EditorButton {
    constructor(editor: EditorComponent) {
        super(editor);
    }

    init() {
        //language=HTML
        this.button = this.editor.rightButtonsContainer.append(`
            <div data-tooltip="${Resources.t('words.integrationsButtonTooltip')}" button class="${s.button}">
                <div class="${s.icon} ${s.integration}"></div>
            </div>
        `);

        this.initPanel(this.button, () => {
            return new QuillIntegrationsTooltipComponent(this.button, this.editor.config.quill.integrationsList);
        }, (component) => {
            if (component instanceof QuillIntegrationsTooltipComponent) {
                component.integrationsComponent.onPick = (integration) => {
                    this.editor.quillComponent.logicIntegrations.insert(this.editor.quillComponent.lastSelection.index, integration.value, "", true);
                    component.dispose();
                };
            }
        });
    }
}