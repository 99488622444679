.componentQuillGoodiesSwap {
  div[gifs] {
    height: calc(100% - 62px) !important;
  }

  div[go] {
    margin-left: -15px;
    width: calc(100% + 30px);
  }

  div[swapContent] {
    height: 100%;
  }
}