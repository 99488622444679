import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import * as s from "./loader-paint.scss";

export class LoaderPaintComponent extends Component {

    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.globalLoaderPaint}">
                <div style="display: flex;flex-direction: column;align-items: center;">
                    <div class="${s.ai}"></div>
                    <div class="${s.drawingCircle}">
                        <div class="${s.drawing}"></div>
                    </div>
                </div>
            </div>
        `;
    }
}