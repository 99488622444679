import {
    VisualClickTooltipWhiteComponent
} from "../../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import {ColorsPickerGridComponent} from "../ColorsPickerGridComponent";

export class ColorsPickerGridTooltipComponent extends VisualClickTooltipWhiteComponent {
    private selected: string;

    constructor(component: Component, selected?: string) {
        super(component, 100, 100);
        this.selected = selected;
    }

    commit() {
        this.setStyle(`height: 142px;overflow:hidden;width: 150px;padding: 10px;`);
        let component = new ColorsPickerGridComponent(this.selected);
        this.render(component);
        component.onPick = (color) => {
            this.onPick(color);
        };
        super.commit();
    }

    onPick(color: string): void {

    }
}