import * as s from "./mails-emails-recipient.scss";
import {
    Component
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {AvatarComponent} from "../../../../avatar/AvatarComponent";
import {IEntityModel} from "../../../../../../../../models/entity/IEntityModel";
import {MailsEmailsRecipientsInputComponent} from "../input/MailsEmailsRecipientsInputComponent";
import {IMailModel} from "../../../../../../../../models/mail/IMailModel";
import {Models} from "../../../../../../../../models/Models";
import {MailService} from "../../../../../../../../services/mail/MailService";
import {IMailsEmailsRecipientSettings} from "../types/IMailsEmailsRecipientSettings";
import {
    txtIsValidMail
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtIsValidMail";

export class MailsEmailsRecipientComponent extends Component {

    public mail: string;
    public object: IEntityModel | IMailModel;
    public invalid: boolean;

    public viewerColorTimeout: number;
    public settings: IMailsEmailsRecipientSettings;

    constructor(settings: IMailsEmailsRecipientSettings, mail: string, object: IEntityModel | IMailModel) {
        super();
        this.mail = mail;
        this.object = object;
        this.settings = settings;

        //language=HTML
        this.template = `
            <div class="${s.componentMailsEmailsRecipient}"></div>
        `;
    }

    commit() {
        this.init();
        super.commit();
    }

    init() {
        this.clearAll();
        this.removeClass(s.edition);
        this.removeClass(s.invalid);

        if (!this.object) {
            this.renderString();
        } else if (Models.isEntityModel(this.object)) {
            this.object = this.object as IEntityModel;
            this.renderEntity(this.object);
        } else {
            this.object = this.object as IMailModel;
            if (this.object.assigned) {
                this.renderEntity(this.object.assigned);
            } else {
                this.renderMailObject();
            }
        }

        this.onClick(async () => this.renderEdition());
    }

    /**
     * render
     */

    renderEntity(entity: IEntityModel) {
        //language=HTML
        this.append(`
            <div class="${s.avatarContainer}">
                ${this.draw(new AvatarComponent(entity.avatar).setActive(entity))}
            </div>
        `);

        //language=HTML
        this.append(`
            <div class="${s.name}">
                ${this.reactive(() => entity.name)}
                <div class="${s.mail}">
                    ${this.reactive(() => this.mail.split("@")[1])}
                </div>
            </div>
        `);
        this.renderRemove();
    }

    renderString() {
        if (!txtIsValidMail(this.mail)) {
            this.invalid = true;
            this.addClass(s.invalid);
        }

        //language=HTML
        this.append(`
            <span class="${s.name}">${this.mail}</span>
        `, "span");

        this.renderRemove();
    }

    renderMailObject() {
        //language=HTML
        this.append(`
            <div class="${s.avatarContainer}">
                ${this.draw(new AvatarComponent(MailService.avatar(this.object as IMailModel)))}
            </div>
        `);

        //language=HTML
        this.append(`
            <div class="${s.name}">
                ${this.reactive(() => MailService.senderName(this.object as IMailModel))}
                <div class="${s.mail}">
                    ${this.reactive(() => this.mail.split("@")[1])}
                </div>
            </div>
        `);
        this.renderRemove();
    }

    renderRemove() {
        //language=HTML
        let button = this.append(`
            <div class="${s.remove}">
                <div class="${s.icon}"></div>
            </div>
        `);

        button.onClick(() => this.onRemoveClick());
    }

    async renderEdition() {
        this.clearAll();
        this.addClass(s.edition);
        let input = new MailsEmailsRecipientsInputComponent(this.settings);
        this.render(input);
        input.setValue(this.mail);
        await input.renderPicker();
        input.onPick = (mail, object) => {
            this.onUpdate({mail: this.mail, object: this.object}, {mail: mail, object: object});
            this.mail = mail;
            this.object = object;
            this.init();
        }
        await this.onOutsideClick(() => this.init());
    }

    /**
     * color
     */

    setViewerColor(color: string) {
        this.setStyle(`box-shadow: 0 0 0px 2px ${color};`);
        clearTimeout(this.viewerColorTimeout);
        this.viewerColorTimeout = this.timeOut(() => this.setStyle(`box-shadow:unset`), 1500);
    }

    /**
     * events
     */

    onUpdate(oldValue: { mail: string, object: IEntityModel | IMailModel }, newValue: {
        mail: string,
        object: IEntityModel | IMailModel
    }) {

    }

    onRemoveClick() {

    }
}