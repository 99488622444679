import {QuillComponent} from "../QuillComponent";

export class QuillLogic {
    public quillComponent: QuillComponent;

    constructor(quillComponent: QuillComponent) {
        this.quillComponent = quillComponent;
    }

    init() {

    }

    destroy() {

    }

}