.componentSiteChannelPicker {
  height: 100%;
  width: 100%;

  svg {
    width: 24px !important;
    height: 24px !important;
  }
}

.componentSiteChannelPickerAvatarContainer {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  position: relative;
  display: inline-block;
  flex: 0 0 20px;
}