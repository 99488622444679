import {QuillLogic} from "./QuillLogic";
import {QuillComponent} from "../QuillComponent";

import {isMobile} from "../../../sedestral-interface-component/utilities/IsMobile";

export class QuillLogicLineBreak extends QuillLogic {
    public lineBreakForceDisabled: boolean;
    public lineBreakListeners: string[];

    public keyboard: any;
    public savedEnter: any[] = [];
    public replyActive: boolean;

    constructor(quillComponent: QuillComponent) {
        super(quillComponent);
        this.lineBreakListeners = [];
        this.keyboard = quillComponent.quill.getModule("keyboard");
    }

    init() {
        if (this.quillComponent.settings.forceEnterLineBreak) {
            this.enterMode();
        } else {
            if (this.quillComponent.settings.lineBreak) {
                if (!isMobile() && (this.quillComponent.settings.lineMode == "shift+enter" || this.quillComponent.settings.lineMode == undefined)) {
                    this.shiftEnterMode();
                } else {
                    this.enterMode();
                }
            } else {
                this.noneMode();
            }
        }
    }

    shiftEnterMode() {
        this.keyboard.bindings.Enter.forEach(entry => entry.shiftKey = true);
        this.lineBreakListeners = this.lineBreakListeners.concat(this.quillComponent.onHotKey("Enter", e => {
            e.preventDefault();
            if (!this.lineBreakForceDisabled) {
                this.quillComponent.executeEnterKey();
            }
        }));
    }


    enterMode() {
        this.keyboard.bindings.Enter.forEach(entry => {
            if (!entry['sedestralShiftEnter']) {
                entry.shiftKey = false
            }
        });

        this.lineBreakListeners = this.lineBreakListeners.concat(this.quillComponent.onHotKey("Enter", (e) => {
            let formats = this.quillComponent.currentFormats();
            if (formats["header"]) {
                this.quillComponent.quill.format('header', false); // Appliquer le format paragraphe
            }
        }));

        this.lineBreakListeners = this.lineBreakListeners.concat(this.quillComponent.onHotKey("Shift+Enter", (e) => {
            if (!this.replyActive) {
                e.preventDefault();
            }
        }));

        this.lineBreakListeners = this.lineBreakListeners.concat(this.quillComponent.onHotKey("Control+Enter", () => {
            if (!this.lineBreakForceDisabled) {
                this.quillComponent.executeEnterKey()
            }
        }));
    }

    noneMode() {
        delete this.keyboard.bindings.Enter;
        this.lineBreakListeners = this.lineBreakListeners.concat(this.quillComponent.onHotKey("Enter", (e) => {
            e.preventDefault();
            if (!this.lineBreakForceDisabled) {
                this.quillComponent.executeEnterKey();
            }
        }));
        this.lineBreakListeners = this.lineBreakListeners.concat(this.quillComponent.onHotKey("Shift+Enter", (e) => e.preventDefault()));
    }

    destroy() {
        this.quillComponent.deleteHotKeyListeners(this.lineBreakListeners);
        this.lineBreakListeners = [];
        this.quillComponent.activeKeys = undefined;
        this.quillComponent.activeKeysCaps = false;
    }

}