@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.globalMediaGif {
  height: 100%;
  width: 100%;
  position: relative;

  .pastille {
    bottom: 6px;
    left: 10px;
    border-radius: 16px;
    background: var(--black-alpha);
    padding: 2px 8px;
    position: absolute;


    .icon {
      height: 20px;
      width: 20px;
      filter: var(--icon-white);

      @include icons.ico("black-gif");
    }
  }
}