import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./menu.scss";
import {IMenuEntry} from "./types/IMenuEntry";
import {MenuIcon} from "./types/MenuIcon";
import {Network} from "../../../network/Network";

export class MenuComponent extends Component {

    public entries: IMenuEntry[];

    constructor(entries: IMenuEntry[]) {
        super();
        this.entries = entries;
        //language=HTML
        this.template = `
            <div class="${s.globalMenu}"></div>
        `;
    }

    commit() {
        this.entries.forEach((entry) => this.renderEntry(entry));
        super.commit();
    }

    renderEntry(entry: IMenuEntry) {
        //language=HTML
        let button = this.append(`
            <div class="${s.entry} ${entry.icon ? this.entryIcon(entry.icon) : ``}">
                <div class="${s.icon}"></div>
                <div class="${s.text}">
                    ${entry.text}
                </div>
            </div>
        `);

        if (entry.click) {
            button.onClick(() => entry.click());
        }
        if (entry.link) {
            button.onClick(() => Network.router.pages.redirect(entry.link));
        }
    }

    entryIcon(type: MenuIcon): string {
        switch (type) {
            case MenuIcon.BLOG:
                return s.blog;
            case MenuIcon.HELPCENTER:
                return s.helpCenter;
            case MenuIcon.MEMBERS:
                return s.members;
            case MenuIcon.ROLES:
                return s.roles;
            case MenuIcon.DELETE:
                return s.delete;
            case MenuIcon.EDIT:
                return s.edit;
        }
    }
}