import {QuillLogic} from "./QuillLogic";
import {QuillFormattingLineComponent} from "../editor/formatting/line/QuillFormattingLineComponent";
import {QuillComponent} from "../QuillComponent";

export class QuillLogicFormattingLine extends QuillLogic {

    public formattingLineComponent: QuillFormattingLineComponent;

    constructor(quillComponent: QuillComponent) {
        super(quillComponent);
    }

    init() {
        let contextMenu = false;
        this.formattingLineComponent = new QuillFormattingLineComponent(this.quillComponent);
        this.quillComponent.render(this.formattingLineComponent);
        this.quillComponent.quill.on('selection-change', (range) => {
            if (!this.quillComponent.isReadOnly()) {
                if (range != undefined && !contextMenu) {
                    if (range.length > 0) {
                        let blot = this.quillComponent.getBlotAtIndex(range.index);
                        if (blot && blot.constructor.name == "Text") {
                            let bounds = this.quillComponent.getBounds(range.index, range.length);
                            bounds.top += -40;
                            bounds.top += -this.quillComponent.getScrollTop();

                            this.formattingLineComponent.setStyle(`left:${Math.round(bounds.left)}px;top:${Math.round(bounds.top)}px;`);
                            this.formattingLineComponent.displayShow();
                            this.formattingLineComponent.scale(0.9, 1, 100);
                            this.formattingLineComponent.fade("in", 100);
                        }
                    } else {
                        this.formattingLineComponent.displayHide();
                    }
                }
            }
        });
        this.quillComponent.quill.on('text-change', (delta) => {
            if (!contextMenu) {
                for (let ops of delta.ops) {
                    if (ops.hasOwnProperty("delete")) {
                        this.formattingLineComponent.displayHide();
                        break;
                    }
                }
            }
        });

        this.quillComponent.element.addEventListener('contextmenu', (ev) => {
            contextMenu = true;
            this.quillComponent.timeOut(() => {
                contextMenu = false;
            }, 50);
        }, false);

        this.quillComponent.onOutsideClick(() => {
            this.formattingLineComponent.displayHide();
        });
    }
}