import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import {CheckboxChoicesComponent} from "../../../checkbox/choices/CheckboxChoicesComponent";
import * as s from "./quill-settings.scss";
import {QuillComponent} from "../../QuillComponent";
import {IQuillSettings} from "./types/IQuillSettings";
import {Resources} from "../../../../../resources/Resources";

export class QuillSettingsComponent extends Component {
    private settings: IQuillSettings;
    private quillComponent: QuillComponent

    private lineChoicesComponent: CheckboxChoicesComponent;
    private spellCheckerComponent: CheckboxChoicesComponent;

    private lineContainer: Component;
    private spellCheckerContainer: Component;

    constructor(settings: IQuillSettings, quillComponent: QuillComponent) {
        super();
        this.settings = settings;
        this.quillComponent = quillComponent;


        //language=HTML
        this.template = `
            <div class="${s.componentQuillSettings}">
                <div class="${s.choices} ${s.line}"></div>
                <div class="${s.choices} ${s.oneChoice} ${s.spellChecker}"></div>
            </div>
        `;
    }

    commit() {
        this.lineContainer = this.el(s.line);
        this.spellCheckerContainer = this.el(s.spellChecker);

        this.renderSpellCheckerChoices();
        this.renderLineChoices();
        super.commit();
    }

    /**
     * rendering
     */
    renderSpellCheckerChoices() {
        //language=HTML
        let spellTemplate = `
            <div class="${s.choice}">
                <div class="${s.settings}">
                    <div class="${s.zigzag}"></div>
                    — ${Resources.t("words.spellChecker")}
                </div>
            </div>
        `;

        this.spellCheckerComponent = new CheckboxChoicesComponent([
            {checked: this.settings.spellChecker, nameTemplate: spellTemplate}
        ]);
        this.spellCheckerContainer.render(this.spellCheckerComponent);
        this.spellCheckerComponent.onPick = (checked) => this.onSpellCheckerPick(checked);
    }

    renderLineChoices() {
        let enterTemplate = `
            <div class="${s.choice}">
                <div class="${s.settings}"><span>${Resources.t("words.enter")} —</span> ${Resources.t("words.sendMessage")}</div>
                <div class="${s.settings}"><span>Shift + ${Resources.t("words.enter")}</span> — ${Resources.t("words.newLine")}</div>
            </div>
        `;
        let ctrlTemplate = `
            <div class="${s.choice}">
                <div class="${s.settings}"><span>Ctrl + ${Resources.t("words.enter")}</span> — ${Resources.t("words.sendMessage")}</div>
                <div class="${s.settings}"><span>${Resources.t("words.enter")}</span> — ${Resources.t("words.newLine")}</div>
            </div>
        `;


        this.lineChoicesComponent = new CheckboxChoicesComponent([
            {
                checked: this.settings.lines == "shift+enter" || this.settings.lines == undefined,
                value: "shift+enter",
                nameTemplate: enterTemplate
            },
            {checked: this.settings.lines == "enter", value: "enter", nameTemplate: ctrlTemplate}
        ], true);

        this.lineContainer.render(this.lineChoicesComponent);
        this.lineChoicesComponent.onPick = (checked, choice) => this.onLineModePick(choice.value);
    }

    /**
     * to override
     */

    onSpellCheckerPick(value: boolean) {

    }

    onLineModePick(value: string) {

    }


}