import {QuillBlot} from "../QuillBlot";
import {Component} from "../../../../sedestral-interface-component/interface/component/Component";

export class QuillBlotLogic {
    public blot: QuillBlot;

    constructor(blot: QuillBlot) {
        this.blot = blot;
    }

    isReadOnly(): boolean {
        return this.blot.isReadOnly();
    }

    renderAbove(): Component {
        return this.blot.renderAbove();
    }

    placeAbove(component: Component, offsetLeft?: number, offsetTop?: number, offsetHeight?: number, offsetWidth?: number) {
        this.blot.placeAbove(component, offsetLeft, offsetTop, offsetHeight, offsetWidth);
    }

    onReady() {

    }

    onDetach() {

    }

    onQuillChange() {

    }

    onUpdate() {

    }

}