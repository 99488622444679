@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentMedia {
  .error {
    background: var(--red-light);
    color: var(--red);
    position: absolute;
    top: 0;
    font-size: 11px;
    padding: 4px;
    display: flex;
    z-index: 1;
    align-items: center;
    margin-top: 5px;
    margin-left: 5px;
    width: calc(100% - 18px);
    border-radius: 5px;

    .icon {
      height: 16px;
      flex: 0 0 16px;
      width: 16px;
      background: red;
      margin-right: 6px;
      filter: var(--icon-red);

      @include icons.ico("black-warning");
    }
  }
}