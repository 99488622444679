import {
    VisualClickTooltipWhiteComponent
} from "../../../../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./quill-formatting-alignment.scss";

export class QuillFormattingAlignmentComponent extends VisualClickTooltipWhiteComponent {

    private currentAlignment: string;

    constructor(component: Component, alignment?: string) {
        super(component, 0, 26);
        this.currentAlignment = alignment;

        //language=HTML
        this.template = `
            <div class="${s.globalQuillFormattingAlignment}">
                <div data-type="left" class="${s.listEntry}">
                    <div class="${s.icon} ${s.left}"></div>
                </div>
                <div data-type="center" class="${s.listEntry}">
                    <div class="${s.icon} ${s.center}"></div>
                </div>
                <div data-type="right" class="${s.listEntry}">
                    <div class="${s.icon} ${s.right}"></div>
                </div>
                <div data-type="false" class="${s.listEntry}">
                    <div class="${s.icon} ${s.close}"></div>
                </div>
            </div>
        `;
    }

    commit() {
        this.setStyle(`border-radius:6px;padding:6px;`);
        let buttons: Component[] = this.els(s.listEntry);
        for (let button of buttons) {
            button.removeClass(s.selected);
            let attribute = button.getAttribute("data-type");
            button.onClick(() => {
                this.onPick(attribute);
            });
            if (this.currentAlignment == attribute) {
                button.addClass(s.selected);
            }

        }
        super.commit();
    }

    onPick(alignment: string) {

    }
}