import {IInboxConversationModel} from "../../../../../../../models/inbox/conversation/IInboxConversationModel";
import {EditorPredefinedIntegrationsInbox} from "../../predefined/EditorPredefinedIntegrationsInbox";
import {FileOrigin} from "../../../../../../../models/file/FileOrigin";
import {IFileModel} from "../../../../../../../models/file/IFileModel";
import {IPreviewModel} from "../../../../../../../models/preview/IPreviewModel";
import {
    IQuillContent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/types/IQuillContent";
import {Network} from "../../../../../../../network/Network";
import {InboxConversationService} from "../../../../../../../services/inbox/conversation/InboxConversationService";
import {
    InboxConversationMessageType
} from "../../../../../../../models/inbox/conversation/message/InboxConversationMessageType";
import {
    InboxConversationMessageService
} from "../../../../../../../services/inbox/conversation/message/InboxConversationMessageService";
import {
    IInboxConversationMessageModel
} from "../../../../../../../models/inbox/conversation/message/IInboxConversationMessageModel";
import {EntityService} from "../../../../../../../services/entity/EntityService";
import {IEditor} from "../../types/IEditor";
import {ComSendType} from "../../../../../../../models/enums/ComSendType";
import {Resources} from "../../../../../../../resources/Resources";
import {EditorComponent} from "../../EditorComponent";
import {
    objectMerge
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/ObjectMerge";

export class EditorInboxComponent extends EditorComponent {

    public conversation: IInboxConversationModel;

    constructor(settings: IEditor, conversation: IInboxConversationModel) {
        super(objectMerge(settings, {
            fileOrigin: FileOrigin.INBOX_ATTACHMENT,
            filterInsertedOrigin: FileOrigin.INBOX_IMAGE_INSERT,
            saveContentId: conversation ? "conversation" + conversation.id : undefined
        }));

        this.conversation = conversation;
    }

    commit() {
        super.commit();

        if (this.conversation) {
            this.setIntegrationsReplace(EditorPredefinedIntegrationsInbox.replaces(this.conversation.owner.participant));
        }
    }

    async processMessage(): Promise<{ files: IFileModel[], previews: IPreviewModel[], contents: IQuillContent }> {
        let attachmentsComponent = this.quillComponent.attachmentsComponent;
        if (attachmentsComponent != undefined && !attachmentsComponent.allUploadedReady()) {
            Network.router.static.components.notifications.notify(Resources.t('words.pleaseWait'), Resources.t('words.uploadingFiles'), 8000);

            return;
        }


        let files: IFileModel[] = attachmentsComponent == undefined ? [] : attachmentsComponent.allUploaded().files.map(value => value.file);
        let previews: IPreviewModel[] = attachmentsComponent == undefined ? [] : attachmentsComponent.allUploaded().previews;
        let contents = this.contents();

        if (files.length == 0 && previews.length == 0 && this.quillComponent.lengthWithoutSpaces() < 1) {
            return;
        }

        this.quillComponent.reset();

        if (InboxConversationService.getMyParticipant(this.conversation).unreadCount > 0) {
            InboxConversationService.activeEntityRead(this.conversation);
        }

        return {
            files: files,
            previews: previews,
            contents: contents
        };
    }

    async sendMessage(scheduleTime: number) {
        let process = await this.processMessage();
        if (process != undefined) {
            let type = this.config.enablePrivate ? InboxConversationMessageType.PRIVATE : InboxConversationMessageType.NORMAL;
            let req = await InboxConversationMessageService.create({
                conversationId: this.conversation.id,
                siteChannelId: this.conversation.siteChannelId,
                type: type,
                text: process.contents.html,
                filesIds: process.files.map(value => value.id),
                previewsUrls: process.previews.map(value => value.baseUrl),
                sendType: ComSendType.SEND,
                scheduledTime: scheduleTime
            }, this);
        }
    }

    async updateMessage(message: IInboxConversationMessageModel) {
        let process = await this.processMessage();
        if (process != undefined) {
            await InboxConversationMessageService.update(message.id, process.contents.html, process.files, process.previews, this);
        }
    }

    async contentChange() {
        let attachmentsComponent = this.quillComponent.attachmentsComponent;

        let files = attachmentsComponent == undefined ? [] : attachmentsComponent.allUploaded().files;
        let previews = attachmentsComponent == undefined ? [] : attachmentsComponent.allUploaded().previews;
        let contents = this.contents();

        this.saveContent(files.map(value => value.file.id), previews.map(value => value.id), contents.html);

        if (!this.config.enablePrivate && (files.length > 0 || previews.length > 0 || contents.text.length > 1)) {
            return EntityService.typing(this.conversation.id, true, contents.html, files.map(value => value.file), previews);
        }

        if (this.conversation != undefined && EntityService.activeEntity.typingConversationId != undefined) {
            return EntityService.typing(this.conversation.id, false);
        }
    }
}