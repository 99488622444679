@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/outline";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentComposerTab {
  @include boxs.sur;

  overflow: hidden;
  margin-left: 10px;
  border-radius: 12px 12px 0 0;

  &.collapsed {
    &:hover {
      @include outline.outline(3);
    }
  }

  &.unCollapsed {
    .reduce {
      display: flex !important;
    }

    .content {
      padding: 0 15px 15px 15px;
    }
  }

  .head {
    font-size: 15px;
    padding: 8px 14px;
    align-items: center;
    display: flex;

    @include texts.font("semi-bold");

    .icon {
      margin-top: 3px;
      margin-right: 8px;
      height: 14px;
      width: 14px;

      @include icons.ico("black-inbox");
    }

    .buttons {
      display: flex;
      margin-left: auto;

      .button {
        height: 24px;
        width: 24px;
        margin-left: 6px;

        @include positioning.flex-globally;
        @include buttons.round;

        &.close {
          .btnIcon {
            @include icons.ico("black-close-bold");
          }
        }

        &.reduce {
          display: none;

          .btnIcon {
            @include icons.ico("black-reduce");
          }
        }

        .btnIcon {
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  .content {
    display: block;
  }
}