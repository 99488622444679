import {
    StaticComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-router/static/component/StaticComponent";
import {
    LoaderSlidingComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/sliding/LoaderSlidingComponent";
import * as s from "./loaders-sliding.scss";

export class SlidingLoaderStatic extends StaticComponent {

    constructor() {
        super();
        this.virtual(s.staticLoaderSliding);
    }

    init(): void {
        this.show();
        this.setStyle("z-index: 999999;position: fixed;");
        this.append(this.draw(new LoaderSlidingComponent()));
        super.init();
    }

    remove() {
        this.clear();
        this.removeAttribute(`style`);
        this.hide();
    }

}