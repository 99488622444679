import {EditorButton} from "./EditorButton";
import {EditorComponent} from "../EditorComponent";
import {
    LoaderProgressComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/progress/LoaderProgressComponent";
import * as s from "../editor.scss";
import {
    QuillLimitsTooltipComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/editor/limits/tooltip/QuillLimitsTooltipComponent";

export class EditorButtonLimit extends EditorButton {

    public processComponent: LoaderProgressComponent;

    constructor(editor: EditorComponent) {
        super(editor);
    }

    init() {
        //language=HTML
        this.processComponent = new LoaderProgressComponent();
        this.button = this.editor.rightButtonsContainer.append(`
            <div button class="${s.button}">
                ${this.editor.rightButtonsContainer.draw(this.processComponent)}
            </div>
        `);
        this.button.onHover(() => {
            let limitsTooltip = new QuillLimitsTooltipComponent(this.button, this.editor.config.quill.limits, this.editor.quillComponent.length());
            limitsTooltip.create();
        });

        this.editor.quillComponent.quill.on('text-change', () => this.update());
        this.update();
    }

    update() {
        this.processComponent.updateLimit(this.editor.quillComponent.limit(), (this.editor.quillComponent.length() - 1));
    }
}