import Quill from "quill";

let Block = Quill.import('blots/block');

export class QuillQuoteBlot extends Block {
    static create(data) {
        let container: Node = super.create(undefined);
        if (container instanceof HTMLElement) {
            container.setAttribute("style", "margin: 0px 0px 0px 0.8ex;border-left: 3px solid rgb(226 226 226);padding-left: 1ex;");
        }

        return container;
    }

}

QuillQuoteBlot.blotName = 'blockquote';
QuillQuoteBlot.tagName = 'blockquote';