import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./colors-picker-grid.scss";

export class ColorsPickerGridComponent extends Component {

    private colors: string[];
    private selected: string;
    private selectedComponent: Component;

    constructor(selected?: string) {
        super();
        this.selected = selected;
        this.colors = [
            "#000000", "#404040", "#6E6E6E", "#A5A5A5", "#CFCFCF", "#FFFFFF",
            "#F2D7D5", "#D98880", "#CD6155", "#A93226", "#922B21", "#641E16",
            "#D2B4DE", "#A569BD", "#8E44AD", "#7D3C98", "#6C3483", "#5B2C6F",
            "#AED6F1", "#85C1E9", "#5DADE2", "#2E86C1", "#2874A6", "#1B4F72",
            "#A9DFBF", "#7DCEA0", "#27AE60", "#229954", "#1E8449", "#145A32",
            "#F9E79F", "#F8C471", "#F5B041", "#F39C12", "#AF601A", "#784212",
            "#AAB7B8", "#AAB7B8", "#95A5A6", "#839192", "#717D7E", "#4D5656",
            "#AEB6BF", "#5D6D7E", "#34495E", "#2E4053", "#283747", "#1B2631"
        ];


        //language=HTML
        this.template = `
            <div class="${s.globalColorsPickerGrid}"></div>
        `;
    }

    commit() {
        for (let color of this.colors) {
            let component = this.append(`
                <div style="background: ${color};" class="${s.color}"></div>
            `);

            component.onClick(() => {
                this.onPick(color);
                if (this.selectedComponent != undefined) {
                    this.selectedComponent.clear();
                }

                component.append(`<div class="${s.checked}"></div>`);
                this.selectedComponent = component;
            });

            if (color.toLowerCase() == this.selected || color == this.selected) {
                component.append(`<div class="${s.checked}"></div>`);
                this.selectedComponent = component;
            }

        }

        this.scrollable();
        super.commit();
    }


    onPick(color: string): void {

    }
}