import {onDrag} from "./OnDrag";
import {Component} from "../Component";

export function onDragInside(component: Component, func: (left: number, top: number) => void) {
    onDrag(component, (left: number, top: number) => {
        let parent = component.getHTMLElement().parentElement;

        let maxLeft = (parent.clientWidth - component.getWidth());
        let maxTop = (parent.clientHeight - component.getHeight());

        let finalLeft = 0;
        let finalTop = 0;

        if (left < 0) {
            finalLeft = 0;
        } else if (left > maxLeft) {
            finalLeft = maxLeft;
        } else if (left >= 0 && (left <= maxLeft)) {
            finalLeft = left;
        }

        if (top < 0) {
            finalTop = 0;
        } else if (top > maxTop) {
            finalTop = maxTop;
        } else if (top >= 0 && (top <= maxTop)) {
            finalTop = top;
        }

        if (finalTop < 0) {
            finalTop = 0;
        }
        if (finalLeft < 0) {
            finalLeft = 0;
        }

        func(finalLeft, finalTop);
    });
}