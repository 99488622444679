import {IQuillIntegrationReplace} from "./IQuillIntegrationReplace";

export class QuillIntegrationController {
    //xxx {name} xxx
    static fetch(html: string, entries?: any): string {
        let regex = /{{{(.*?)}}}/g;
        let founds = html.match(regex);

        if (founds != undefined) {
            for (let found of founds) {
                let values = found.replace("{{{", "").replace("}}}", "").split("}}{{");
                if (entries == undefined) {
                    html = html.replace(found, `{${values[0]} ${values[1] != "" ? ` ou ${values[1]}` : ""}}`);
                }
            }
        }

        return html;
    }

    //xxx Anis xxxx
    static print(html: string, replaces: IQuillIntegrationReplace[]) {
        let regex = /{{{(.*?)}}}/g;
        let founds = html.match(regex);

        if (founds != undefined) {
            for (let found of founds) {
                let values = found.replace("{{{", "").replace("}}}", "").split("}}{{");
                let replace = replaces.find(value => value.name == values[0]);
                html = html.replace(found, replace != undefined ? (replace.replacement != undefined ? replace.replacement : values[1]) : "");
            }
        }

        return html;
    }
}