import {QuillFormattingBaseButtonComponent} from "../QuillFormattingBaseButtonComponent";
import * as s from "../quill-formatting-button.scss";
import {QuillFormattingImageComponent} from "./image/QuillFormattingImageComponent";
import {QuillFormattingComponent} from "../../QuillFormattingComponent";

export class QuillFormattingButtonImageComponent extends QuillFormattingBaseButtonComponent {
    constructor(formatter: QuillFormattingComponent) {
        super("image", formatter);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton}">
                <div class="${s.icon} ${s.image}"></div>
            </button>
        `;
    }

    bind() {
        this.onClick(() => {
            let tooltip = new QuillFormattingImageComponent(this);
            tooltip.screen(false)
            tooltip.create();
            tooltip.tab.onPick = file => {
                tooltip.dispose();
                this.formattingComponent.quillComponent.insertImage(file, true);
            }
        });
    }
}