.componentMediaImageCropper {
  height: 100%;
  width: 100%;

  .top {
    display: flex;
    height: calc(100% - 85px);
    position: relative;

    .ratioContainer {
      width: 260px;
      height: 100%;
      position: relative;
    }

    .imageContainer {
      height: 100%;
      width: calc(100% - 270px);
      justify-content: center;
      align-items: center;
      display: flex;
      position: absolute;
      right: 0;
    }
  }

  .buttonsContainer {
    margin-top: 15px;
  }


  :global {
    .cropper-container {
      direction: ltr;
      font-size: 0;
      line-height: 0;
      position: relative;
      -ms-touch-action: none;
      touch-action: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .cropper-container img {
      display: block;
      height: 100%;
      image-orientation: 0deg;
      max-height: none !important;
      max-width: none !important;
      min-height: 0 !important;
      min-width: 0 !important;
      width: 100%;
    }

    .cropper-wrap-box,
    .cropper-canvas,
    .cropper-drag-box,
    .cropper-crop-box,
    .cropper-modal {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .cropper-wrap-box,
    .cropper-canvas {
      overflow: hidden;
    }

    .cropper-drag-box {
      background-color: #fff;
      opacity: 0;
    }

    .cropper-modal {
      background-color: #000;
      opacity: 0.5;
    }

    .cropper-view-box {
      display: block;
      height: 100%;
      outline: 2px solid var(--white);
      overflow: hidden;
      width: 100%;
    }

    .cropper-dashed {
      border: 0 solid #eee;
      display: block;
      position: absolute;
    }

    .cropper-dashed.dashed-h {
      border-bottom-width: 1px;
      border-top-width: 1px;
      height: calc(100% / 3);
      left: 0;
      top: calc(100% / 3);
      width: 100%;
    }

    .cropper-dashed.dashed-v {
      border-left-width: 1px;
      border-right-width: 1px;
      height: 100%;
      left: calc(100% / 3);
      top: 0;
      width: calc(100% / 3);
    }

    .cropper-center {
      display: block;
      height: 0;
      left: 50%;
      opacity: 0.75;
      position: absolute;
      top: 50%;
      width: 0;
    }

    .cropper-center::before,
    .cropper-center::after {
      background-color: #eee;
      content: ' ';
      display: block;
      position: absolute;
    }

    .cropper-center::before {
      height: 1px;
      left: -3px;
      top: 0;
      width: 7px;
    }

    .cropper-center::after {
      height: 7px;
      left: 0;
      top: -3px;
      width: 1px;
    }

    .cropper-face,
    .cropper-line,
    .cropper-point {
      display: block;
      height: 100%;
      opacity: 0.1;
      position: absolute;
      width: 100%;
    }

    .cropper-face {
      background-color: #fff;
      left: 0;
      top: 0;
    }

    .cropper-line {
      background-color: transparent;
    }

    .cropper-line.line-e {
      cursor: ew-resize;
      right: -3px;
      top: 0;
      width: 5px;
    }

    .cropper-line.line-n {
      cursor: ns-resize;
      height: 5px;
      left: 0;
      top: -3px;
    }

    .cropper-line.line-w {
      cursor: ew-resize;
      left: -3px;
      top: 0;
      width: 5px;
    }

    .cropper-line.line-s {
      bottom: -3px;
      cursor: ns-resize;
      height: 5px;
      left: 0;
    }

    .cropper-point {
      background: white;
      opacity: 1;
      height: 10px;
      width: 10px;
      border-radius: 2px;
      box-shadow: 0 0 3px black;
    }

    .cropper-point.point-e {
      cursor: ew-resize;
      margin-top: -3px;
      right: -3px;
      display: none;
      top: 50%;
    }

    .cropper-point.point-n {
      cursor: ns-resize;
      left: 50%;
      display: none;
      margin-left: -3px;
      top: -3px;
    }

    .cropper-point.point-w {
      cursor: ew-resize;
      left: -3px;
      display: none;
      margin-top: -3px;
      top: 50%;
    }

    .cropper-point.point-s {
      bottom: -3px;
      cursor: s-resize;
      display: none;
      left: 50%;
      margin-left: -3px;
    }

    .cropper-point.point-ne {
      cursor: nesw-resize;
      right: -5px;
      top: -5px;
    }

    .cropper-point.point-nw {
      cursor: nwse-resize;
      left: -5px;
      top: -5px;
    }

    .cropper-point.point-sw {
      bottom: -5px;
      cursor: nesw-resize;
      left: -5px;
    }

    .cropper-point.point-se {
      bottom: -5px;
      cursor: nwse-resize;
      height: 10px;
      opacity: 1;
      right: -5px;
      width: 10px;
    }

    .cropper-point.point-se::before {
      background-color: #39f;
      bottom: -50%;
      content: ' ';
      display: block;
      height: 200%;
      opacity: 0;
      position: absolute;
      right: -50%;
      width: 200%;
    }

    .cropper-invisible {
      opacity: 0;
    }

    .cropper-bg {
      background: var(--black-alpha);
    }

    .cropper-hide {
      display: block;
      height: 0;
      position: absolute;
      width: 0;
    }

    .cropper-hidden {
      display: none !important;
    }

    .cropper-move {
      cursor: move;
    }

    .cropper-crop {
      cursor: crosshair;
    }

    .cropper-disabled .cropper-drag-box,
    .cropper-disabled .cropper-face,
    .cropper-disabled .cropper-line,
    .cropper-disabled .cropper-point {
      cursor: not-allowed;
    }
  }
}