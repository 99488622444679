/**
 * récuper tous les files du clipboard
 */
import {fileUrlExtension} from "./FileUrlExtension";
import {fileIsSecure} from "./FileIsSecure";

export function fileGetClipboards(clipboardData: any): File[] {
    let files = [];
    let items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
        let file = items[i].getAsFile();
        if (file instanceof File) {
            let type = fileUrlExtension(file.name);
            if (fileIsSecure(type)) {
                let file = items[i].getAsFile();
                files.push(file);
            }
        }
    }

    return files;
}