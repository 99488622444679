import {QuillIntegrationComponent} from "./QuillIntegrationComponent";
import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import {IQuillIntegration} from "./types/IQuillIntegration";
import * as s from "./quill-integrations.scss";

export class QuillIntegrationsComponent extends Component {
    private integrations: IQuillIntegration[];

    constructor(integrations: IQuillIntegration[]) {
        super();
        this.integrations = integrations;
        //language=HTML
        this.template = `
            <div class="${s.globalQuillIntegrations}"></div>
        `;
    }

    commit() {
        for (let integration of this.integrations) {
            let integrationComponent = new QuillIntegrationComponent(integration);
            this.render(integrationComponent);

            integrationComponent.onClick(() => {
                this.onPick(integration);
            }, true);
        }

        super.commit();
    }

    onPick(integration: IQuillIntegration): void {

    }
}