import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {QuillAttachmentComponent} from "./QuillAttachmentComponent";
import * as s from "./quill-attachments.scss";
import {IQuillUploaded} from "./types/IQuillUploaded";
import {QuillComponent} from "../QuillComponent";
import {IPreviewModel} from "../../../../models/preview/IPreviewModel";
import {IFileModel} from "../../../../models/file/IFileModel";
import {MediaFactory} from "../../media/types/MediaFactory";
import {PreviewService} from "../../../../services/preview/PreviewService";
import {config} from "../../../../config";
import {EmptyBasicComponent} from "../../empty/EmptyBasicComponent";
import {IDocumentCroppingModel} from "../../../../models/document/IDocumentCroppingModel";
import {Models} from "../../../../models/Models";

export class QuillAttachmentsComponent extends Component {
    public files: File[];
    public excludedUrls: string[];
    public urls: string[];
    public components: QuillAttachmentComponent[];
    public quillComponent: QuillComponent;
    public emptyComponent: Component;

    constructor(quillComponent?: QuillComponent) {
        super();
        this.quillComponent = quillComponent;
        //language=HTML
        this.template = `
            <div quillAttachments class="${s.globalQuillAttachments}"></div>`;

        this.files = [];
        this.urls = [];
        this.components = [];
        this.excludedUrls = [];
    }

    commit() {
        this.renderEmpty();
        super.commit();
    }

    restoreAttachments(files: IFileModel[], previews: IPreviewModel[], excludedUrls?: string[]) {
        files.forEach(file => this.addUploadedFile(file));
        previews.forEach(preview => this.addUploadedPreview(preview));
        if (excludedUrls) {
            this.excludedUrls = excludedUrls;
        }
    }

    restoreAttachmentsCropping(crops: { fileId: string, cropping: { [key: string]: IDocumentCroppingModel } }[]) {
        this.components.forEach(attachment => {
            if (attachment.getObject()) {
                let crop = crops.find(crop => crop.fileId == attachment.getObject().id);
                if (crop) {
                    attachment.setCrop(crop.cropping);
                }
            }
        });
    }

    addAttachment(file: File | string): QuillAttachmentComponent {
        if (file != undefined) {
            if (file instanceof File) {
                return this.addFile(file);
            } else {
                return this.addUrl(file);
            }
        }
    }

    addUploadedPreview(preview: IPreviewModel): QuillAttachmentComponent {
        this.urls.push(preview.baseUrl);
        let attachmentComponent = new QuillAttachmentComponent(this, false, this.quillComponent?.settings.canCrop);
        attachmentComponent.onRemoveClick = () => this.removeAttachment(attachmentComponent);
        attachmentComponent.onCrop = () => this.cropAttachment(attachmentComponent);

        this.components.push(attachmentComponent);
        this.render(attachmentComponent);

        let mediaComponent = MediaFactory.generate(PreviewService.proxyUrl(preview), preview);

        attachmentComponent.uploaded(preview, mediaComponent);
        this.removeEmpty();

        return attachmentComponent;
    }

    addUploadedFile(file: IFileModel): QuillAttachmentComponent {
        let attachmentComponent = new QuillAttachmentComponent(this, false, this.quillComponent?.settings.canCrop);
        attachmentComponent.onRemoveClick = () => this.removeAttachment(attachmentComponent);
        attachmentComponent.onCrop = () => this.cropAttachment(attachmentComponent);

        this.components.push(attachmentComponent);
        this.render(attachmentComponent);

        let mediaComponent = MediaFactory.generate(file, file);
        attachmentComponent.uploaded(file, mediaComponent);

        this.removeEmpty();

        return attachmentComponent;
    }

    /**
     * adding
     */
    addFile(file: File): QuillAttachmentComponent {
        this.files.push(file);
        return this.renderAttachment(file);
    }

    addUrl(url: string): QuillAttachmentComponent {
        if (!this.urls.includes(url) && !this.excludedUrls.includes(url)) {
            this.urls.push(url);
            return this.renderAttachment(url);
        }
    }

    /**
     * get
     */

    getAttachment(objectId: string): QuillAttachmentComponent {
        return this.components.find(value => value.uploadedObject?.id == objectId);
    }

    /**
     * empty
     */

    renderEmpty() {
        if (this.quillComponent && this.quillComponent.settings.emptyText) {
            if (!this.emptyComponent) {
                this.emptyComponent = this.render(new EmptyBasicComponent(`<div class="${s.emptyImage}"></div>`, this.quillComponent.settings.emptyText));
            }
        } else {
            this.displayHide();
        }
    }

    removeEmpty() {
        if (this.emptyComponent) {
            this.emptyComponent.remove();
            this.emptyComponent = undefined;
        }

        this.displayShow();
    }

    /**
     * rendering
     */

    renderAttachment(object: string | File): QuillAttachmentComponent {
        let attachmentComponent = new QuillAttachmentComponent(this, undefined, this.quillComponent?.settings.canCrop);
        attachmentComponent.object = object;
        attachmentComponent.onRemoveClick = () => this.removeAttachment(attachmentComponent);
        attachmentComponent.onUploaded = () => this.onAttachmentUploaded(attachmentComponent);
        attachmentComponent.onCrop = () => this.cropAttachment(attachmentComponent);

        this.components.push(attachmentComponent);
        this.render(attachmentComponent);

        if (object instanceof File) {
            this.onFileAdd(attachmentComponent);
        } else {
            this.onUrlAdd(attachmentComponent);
        }

        if (this.quillComponent) {
            this.quillComponent.executeContentChange(undefined, undefined, "user", true);
            this.quillComponent.executeAttachmentsChange(attachmentComponent);
        }

        this.removeEmpty();
        return attachmentComponent;
    }

    async removeAttachment(component: QuillAttachmentComponent, silent?: boolean) {
        if (component.object) {
            if (component.object instanceof File) {
                this.files.splice(this.files.indexOf(component.object), 1);
            } else {
                this.urls.splice(this.urls.indexOf(component.object), 1);
                if (!component.object.startsWith(config.tenorMediaHost)) {
                    this.excludedUrls.push(component.object);
                }
            }
        }

        this.components.splice(this.components.indexOf(component), 1);

        if (this.components.length < 1) {
            this.renderEmpty();
        }

        component.remove();

        if (!silent && this.quillComponent != undefined) {
            this.quillComponent.executeContentChange(undefined, undefined, "user", true);
            this.quillComponent.executeAttachmentsChange(component, true);
        }

        this.onAttachmentRemove();
    }

    cropAttachment(component: QuillAttachmentComponent) {
        if (this.quillComponent != undefined) {
            this.quillComponent.executeContentChange(undefined, undefined, "user", true);
            this.quillComponent.executeAttachmentsChange(component);
            this.onAttachmentUploaded(component);
        }
    }

    reset() {
        this.files = [];
        this.urls = [];
        this.components = [];
        this.excludedUrls = [];

        this.clearAll();
        this.renderEmpty();
    }

    /**
     * events
     */

    async onUrlAdd(component: QuillAttachmentComponent) {

    }

    async onFileAdd(component: QuillAttachmentComponent) {

    }

    onAttachmentUploaded(component: QuillAttachmentComponent) {

    }

    onAttachmentRemove() {

    }

    /**
     * all
     */

    allUploadedReady(): boolean {
        return this.components.filter(value => typeof value.object != "string").filter(value => value.uploadedObject == undefined).length <= 0;
    }

    allUploaded(): IQuillUploaded {
        let files = [];
        let previews = [];
        this.components.forEach(value => {
            if (value.uploadedObject != undefined) {
                if (value.mediaComponent.contentObject instanceof File || Models.isMediaModel(value.mediaComponent.contentObject)) {
                    files.push({file: value.uploadedObject, cropping: value.cropping});
                } else {
                    previews.push(value.uploadedObject);
                }
            }
        });

        return {files: files, previews: previews};
    }

    /**
     * get and set
     */

    length(): number {
        return this.files.length + this.urls.length;
    }
}