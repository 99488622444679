import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./shortcut.scss";
import {IInboxShortcutModel} from "../../../../../models/inbox/shortcut/IInboxShortcutModel";
import {ShortcutCreatorComponent} from "./creator/ShortcutCreatorComponent";
import {
    SedestralInterface
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/SedestralInterface";
import {
    QuillIntegrationController
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/editor/integrations/types/QuillIntegrationController";
import {Resources} from "../../../../../resources/Resources";
import {
    randomInteger
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/RandomInteger";
import {txtStrip} from "../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtStrip";

export class ShortcutComponent extends Component {
    public shortcut: IInboxShortcutModel;

    constructor(shortcut: IInboxShortcutModel) {
        super();
        this.shortcut = shortcut;
        this.template = `
            <div shortcut class="${s.componentShortcut}">
                <div class="${s.texts}">
                    <span class="${s.name}">${this.renderName()}</span>
                    <span class="${s.description}">${this.renderDescription()}</span>
                </div>
            </div>
        `;
    }

    renderButtons() {
        //language=HTML
        let buttons = this.append(`
            <div class="${s.buttons}"></div>`);

        if (this.shortcut.isPrivate) {
            //language=HTML
            buttons.append(`
                <div data-tooltip="${Resources.t("words.onlyMe")}" data-tooltip-position="bottom" class="${s.button}">
                    <div class="${s.icon} ${s.mind}"></div>
                </div>
            `);

        }


        /*
        //language=HTML
        let editButton = buttons.append(`
            <div class="${s.button}">
                <div class="${s.icon} ${s.edit}"></div>
            </div>
        `);
        this.onClick(() => editButton.render(new ShortcutCreatorComponent(this.shortcut)));

         */

        this.onClick(() => {
            let creator = new ShortcutCreatorComponent(this.shortcut);
            SedestralInterface.main.render(creator);
            this.onRemove(() => {
                if (!creator.isNull()) {
                    creator.remove();
                }
            })
        });

        super.commitTooltips();
    }

    renderName(): string {
        if (this.shortcut == undefined) {
            return ``;
        }

        return `/${this.shortcut.name}`;
    }

    renderDescription(): string {
        if (this.shortcut == undefined) {
            return `<div style="width: ${randomInteger(30, 70)}%;" class="${s.ghost}"></div><div style="width: 46px;position: absolute;right: 0;" class="${s.ghost}"></div>`;
        }

        return QuillIntegrationController.fetch(txtStrip(this.shortcut.html));
    }
}