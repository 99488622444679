import {QuillBlot} from "../QuillBlot";

export class QuillImageBlot extends QuillBlot {

    constructor(scroll, domNode) {
        super(scroll, domNode);
        this.collaborativeCursor = true;
        this.borderRadius = 10;
    }

    static value(node) {
        if (!node['data']) {
            switch (node.tagName) {
                case "IMG":
                    return {url: node.getAttribute('src')};
            }
        }

        return node['data'];
    }

    format(name, value) {
        if (name == "src") {
            this.removeLoading();
        }

        if (name == "error" && this.data.upload) {
            this.removeLoading();
            this.setLoading();
            this.logicLoader.renderError();
        }

        super.format(name, value);

        if (this.data.loading) {
            this.setLoading();
            this.logicLoader.renderLoaderPaint();
        }
    }

    onReady() {
        super.formattable({
            delete: true,
            alignWithMargin: true,
            embedAlignCenter: true,
            embedAlignLeft: true,
            embedWidthHalf: true,
            embedAlignRight: true,
            embedWidthMax: true,
            shadow: true
        });
        super.resizable();
        super.clickable({pointer: true});

        if (this.data.sedestralFirstData && this.data.sedestralFirstData.startsWith("data:")) {
            this.remove();
        } else {
            this.component.setAttribute("contenteditable", "false");

            if (this.data.error) {
                this.setLoading();
                this.logicLoader.renderError();
            } else if (this.data.upload) {
                this.setLoading();
                this.logicLoader.renderLoader();
            } else {
                this.component.setAttribute("src", this.data.src);
            }
        }

        super.onReady();
    }
}

QuillImageBlot.blotName = 'image';
QuillImageBlot.tagName = 'img';