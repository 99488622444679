import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {LoaderLightComponent} from "../../loader/light/LoaderLightComponent";
import * as s from "./media-upload.scss";

export class MediaUploadComponent extends Component {
    private _progressContainer: Component;

    constructor() {
        super();
        this.template = `
            <div mediaUpload class="${s.globalMediaUpload}">
                ${this.draw(new LoaderLightComponent())}
                <div class="${s.uploadProgression}">
                    <div progress class="${s.progress}"></div>
                </div>
            </div>
        `;
    }

    commit() {
        super.commit();
        this._progressContainer = this.el(s.progress);
    }

    simulateProgress() {
        let progress = 0;
        this.interval(() => {
            if (progress <= 80) {
                progress += 5;
                this.progress(progress);
            }
        }, 50);
    }

    progress(progression: number) {
        this._progressContainer.setStyle(`width:${progression}%`);
    }

}