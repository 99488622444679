@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.globalQuillAttachments {
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;

  @include positioning.clear;

  .emptyImage {
    height: 60px;
    width: 60px;

    @include icons.ico("3d-image");
  }
}