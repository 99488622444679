import {EditorButton} from "./EditorButton";
import {EditorComponent} from "../EditorComponent";
import * as s from "../editor.scss";
import {GPTService} from "../../../../../../services/gpt/GPTService";
import {GptType} from "../../../../../../models/enums/GptType";

export class EditorButtonGPT extends EditorButton {

    public conversationId: string;

    constructor(editor: EditorComponent, conversationId: string) {
        super(editor);
        this.conversationId = conversationId;
    }

    init() {
        //language=HTML
        this.button = this.editor.leftButtonsContainer.append(`
            <div button class="${s.button} ${s.gptButton}">
                <div class="${s.icon}"></div>
            </div>
        `);

        this.button.onClick(async () => {
            await GPTService.request({
                contentId: this.conversationId,
                type: GptType.CHAT_GENERATE_RESPONSE_INBOX,
                content: this.editor.contents().text
            });
        });
    }
}