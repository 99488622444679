.globalRangeSlider {
  border-radius: 10px;
  background: var(--grey-sur);
  position: relative;
  cursor: pointer;

  &.vertical {
    width: 100%;
    height: 5px;
  }

  &.horizontal {
    height: 100%;
    width: 5px;
  }

  .caret {
    height: 5px;
    width: 5px;
    top: 0;
    left: 0;
    background: var(--red);
    position: absolute;
    border-radius: 100%;
    z-index: 1;

    .selector {
      height: 16px;
      width: 16px;
      border-radius: 100%;
      background: var(--white);
      margin-left: -5px;
      cursor: grab;
      position: absolute;
      margin-top: -5px;
      box-shadow: 0 0 4px #00000042;
    }
  }

  .progression {
    height: 5px;
    width: 5px;
    position: absolute;
    background: var(--blue);
    border-radius: 20px;
  }
}