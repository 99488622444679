import {QuillBlotLogic} from "../QuillBlotLogic";
import {QuillBlot} from "../../QuillBlot";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import {SedestralMachine} from "../../../../../sedestral-interface-component/machine/SedestralMachine";
import {IQuillBlotClickable} from "./IQuillBlotClickable";

export class QuillBlotClickableLogic extends QuillBlotLogic {

    public cursor: Component;
    public cursorHover: Component;
    public active: boolean;

    public settings: IQuillBlotClickable;

    constructor(blot: QuillBlot, settings: IQuillBlotClickable) {
        super(blot);
        this.settings = settings ? settings : {};
    }

    /**
     * cursor hover
     */
    renderCursorHover() {
        if (!this.cursorHover) {
            this.cursorHover = this.renderAbove();
            this.cursorHover.setStyle(`box-shadow: 0 0 0 2px var(--outline-blue);`);
        }
    }

    removeCursorHover() {
        if (this.cursorHover) {
            this.cursorHover.remove();
            this.cursorHover = undefined;
        }
    }

    placeHover() {
        this.placeAbove(this.cursorHover);
    }

    /**
     * cursor base
     */

    renderCursor() {
        if (!this.cursor) {
            this.cursor = this.renderAbove();
            this.cursor.setStyle(`box-shadow: 0 0 0 2px rgb(0 132 255 / 82%);`);
        }
    }

    removeCursor() {
        if (this.cursor) {
            this.cursor.remove();
            this.cursor = undefined;
        }
    }

    place() {
        this.placeAbove(this.cursor, -2, -2, 4, 4);
    }

    /**
     * override
     */

    onReady() {
        if (this.settings.pointer) {
            this.blot.component.setStyle(`cursor:pointer;`);
        }

        this.blot.component.onHover(() => {
            if (!this.cursor && !this.blot.isReadOnly()) {
                this.renderCursorHover();
                this.placeHover();
            }
        });
        this.blot.component.onLeave(() => this.removeCursorHover());

        this.blot.onCursorSelect(() => {
            this.renderCursor();
            this.place();
            this.active = true;
            if (!this.settings.selection) {
                SedestralMachine.requestFrame()(() => {
                    this.blot.quillComponent.addClass("disableSelection");
                });
            }
            this.removeCursorHover();
        }, () => {
            this.removeCursor();
            this.active = false;
            if (!this.settings.selection) {
                this.blot.quillComponent.removeClass("disableSelection");
            }
        });

        super.onReady();
    }

    onDetach() {
        if (this.active && !this.settings.selection) {
            this.blot.quillComponent.removeClass("disableSelection");
        }

        if (this.cursor) {
            this.cursor.remove();
            this.cursor = undefined;
            this.active = false;
        }

        if (this.cursorHover) {
            this.cursorHover.remove();
            this.cursorHover = undefined;
        }

        super.onDetach();
    }

    onQuillChange() {
        if (this.cursor && this.active) {
            this.place();
        }

        if (this.cursorHover) {
            this.placeHover();
        }

        super.onQuillChange();
    }
}