@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/keyframes";

.globalLoaderWave {
  display: inline-block;
  width: 70px;
  height: 70px;
  position: absolute;

  &:before, &:after {
    @include keyframes.wave;
    content: '';
    border: 4px solid white;
    opacity: 1;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    animation: wave 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    position: absolute;

  }

  &:after {
    animation-delay: -0.4s;
  }

}