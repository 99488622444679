import {
    StaticComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-router/static/component/StaticComponent";
import * as s from "./loaders-page.scss";
import {ProductType} from "../../../../../../models/product/ProductType";
import {CapsuleComponent} from "../../../components/capsule/CapsuleComponent";
import {Resources} from "../../../../../../resources/Resources";
import {ProductName} from "../../../../../../models/product/ProductName";
import {
    LoaderLightComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {isMobile} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/IsMobile";

export class PageLoaderStatic extends StaticComponent {
    private product: ProductType;

    constructor() {
        super();
        this.virtual(s.staticPageLoaders);
        this.product = ProductType.INBOX;
        //language=HTML
        this.template = `

        `;

    }

    init(): void {
        this.show();
        this.setStyle(`transform:scale(1);opacity:1;transition-duration:0s;opacity:1;`);

        if (this.product == undefined) {
            this.append(this.draw(new LoaderLightComponent()));
        } else {
            //language=HTML
            this.append(`
                <div class="${s.content}">
                    <div class="${s.relative}">
                        <div class="${s.name}">
                            ${Resources.t(`words.products.${ProductName.toString(this.product)}`)}
                        </div>
                        <div class="${s.capsule}">
                            ${this.draw(new CapsuleComponent(this.product))}
                        </div>
                    </div>
                </div>
            `);

            let capsule = this.el(s.capsule);
            let name = this.el(s.name);
            let relative = this.el(s.relative);

            capsule.translate({moveLeft: isMobile() ? 0 : -90, rotate: "-360deg", delay: 1100});
            name.scaleShow(1600);
            relative.scaleShow(1600);
        }

        super.init();
    }

    remove() {
        this.clear();
        this.hide();
    }

    async animateRemove() {
        this.setStyle(`transform:scale(1);opacity:1;`);
        await this.translate({
            delay: 80,
            scale: 0.9,
            opacity: 0
        });

        this.remove();
    }
}