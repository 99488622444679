import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    LoaderLightComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import * as s from "./assignment-picker.scss";
import {AssignmentService} from "../../../../../../services/assignment/AssignmentService";
import {AssignableType} from "../../../../../../models/assignment/AssignableType";
import {IAssignmentSettings} from "../types/IAssignmentSettings";
import {
    VisualKeyListComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/keyboard/VisualKeyListComponent";
import {CancelTokenSource} from "axios";
import {
    EmptyBasicComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/empty/EmptyBasicComponent";
import {EntityService} from "../../../../../../services/entity/EntityService";
import {IResultAssignment} from "../../../../../../services/assignment/types/IResultAssignment";
import {AssignableState} from "../../../../../../models/assignment/AssignableState";
import {AssignmentPickerEntryComponent} from "./entry/AssignmentPickerEntryComponent";
import {Resources} from "../../../../../../resources/Resources";
import {
    objectUpdate
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/ObjectUpdate";

export class AssignmentPickerComponent extends VisualKeyListComponent {
    public objectsScrollable: Component;
    public objectsContainer: Component;
    public inputContainer: Component;
    public savedInputValue: string;
    public searchContainer: Component;

    public settings: IAssignmentSettings;
    public request: { cancelable: CancelTokenSource, promise: Promise<{ data: IResultAssignment }> };

    public ready: boolean;

    constructor(settings: IAssignmentSettings) {
        super();
        this.settings = settings;
        this.alwaysFocus = true;

        //language=HTML
        this.template = `
            <div class="${s.componentAssignmentPicker}">
                <div class="${s.searchContainer}">
                    <div class="${s.icon}"></div>
                    <input placeholder="${Resources.t("words.assignment.search")}"
                           value="${this.settings.nameFilter ?? ""}" class="${s.input}"/>
                </div>
                <div class="${s.objectsScrollable}">
                    <div class="${s.objects}"></div>
                </div>
            </div>
        `;
    }

    commit() {
        this.objectsScrollable = this.el(s.objectsScrollable);
        this.objectsContainer = this.el(s.objects);
        this.searchContainer = this.el(s.searchContainer);
        this.inputContainer = this.el(s.input);
        this.listContainer = this.objectsScrollable;
        this.bind();

        //language=HTML
        this.objectsContainer.append(`
            <div class="${s.loader}">
                ${this.draw(new LoaderLightComponent())}
            </div>
        `);

        this.renderObjects();
        this.inputContainer.focusAtEnd();
        super.commit();
    }

    bind() {
        this.objectsScrollable.scrollable();

        if (!this.settings.search) {
            this.addClass(s.noSearch);
        }

        this.inputContainer.onKey(() => {
            let value = this.inputContainer.getValue();
            if (value != this.savedInputValue) {
                this.savedInputValue = value;
                this.settings.nameFilter = value;
                this.renderObjects();
            }
        }, 15);
    }

    renderObjects(settings?: IAssignmentSettings) {
        if (settings) {
            objectUpdate(this.settings, settings);
        }
        if (this.request) {
            this.request.cancelable.cancel();
        }

        //@ts-ignore
        this.request = AssignmentService.find({
            siteId: EntityService.activeSite.id,
            contentId: this.settings.assignable.id, ...this.settings
        });

        this.request.promise.then((value) => {
            if (value != undefined) {
                let entities = AssignmentService.store(this.settings, value.data);
                this.renderObjectsEntries(entities);

                if (!this.ready) {
                    this.ready = true;
                    this.onReady();
                }
            }
        });
    }

    renderObjectsEntries(entities: IResultAssignment) {
        this.objectsContainer.clearAll();

        let entries: { type: AssignableType, object: any }[] = [];
        if (this.settings.accounts) {
            entities.accounts.forEach(o => entries.push({type: AssignableType.ACCOUNT, object: o}));
        }
        if (this.settings.contacts) {
            entities.contacts.forEach(o => entries.push({type: AssignableType.CONTACT, object: o}));
        }
        if (this.settings.roles) {
            entities.roles.forEach(o => entries.push({type: AssignableType.ROLE, object: o}));
        }
        if (this.settings.mails) {
            entities.mails.forEach(o => entries.push({type: AssignableType.MAIL, object: o}));
        }
        entries = entries.sort((a, b) => a.object.name < b.object.name ? 1 : -1);

        if (this.settings.assignable && this.settings.assignable.assignments) {
            entries = entries.sort((a, b) => {
                let aParticipant = this.settings.assignable.assignments.filter(value => value.objectId == a.object.id && (value.state == AssignableState.JOINED || value.state == AssignableState.INVITED))[0];
                let bParticipant = this.settings.assignable.assignments.filter(value => value.objectId == b.object.id && (value.state == AssignableState.JOINED || value.state == AssignableState.INVITED))[0];
                return (aParticipant == undefined ? 0 : 1) < (bParticipant == undefined ? 0 : 1) ? 1 : -1;
            });
        }

        if (entries.length > 0) {
            this.selectable = entries.map(object => this.renderObjectEntry(object));
        } else {
            this.selectable = [];
            //language=HTML
            this.objectsContainer.append(`
                <div class="${s.emptyContainer}">
                    ${this.objectsContainer.draw(new EmptyBasicComponent(`<div class="${s.icon}"></div>`, this.settings.mails && this.settings.emptyText ? this.settings.emptyText : Resources.t("words.notResult")))}
                </div>
            `);
        }

        super.commitSelectable();
        super.commitTooltips();
    }

    renderObjectEntry(entry: { type: AssignableType, object: any }): Component {
        let component = new AssignmentPickerEntryComponent(
            this.settings,
            entry.type,
            entry.object,
            this.settings.assignable.assignments.find(value => value.objectId == entry.object.id && value.state != AssignableState.DELETED) != undefined
        );
        this.objectsContainer.render(component);
        return component;
    }


    entitiesCount(): number {
        return this.selectable.length;
    }


    /**
     * events
     */
    onValidateSelect(component: Component) {
        if (component instanceof AssignmentPickerEntryComponent) {
            this.onPick(component.object, component.assignableType);
        }
    }

    onPick(object: any, assignableType: AssignableType) {

    }

    onReady() {

    }
}