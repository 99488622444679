import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import {QuillComponent} from "../../QuillComponent";
import {IQuillFormatting} from "./types/IQuillFormatting";
import * as s from "./quill-formatting.scss";
import {QuillFormattingButtonBoldComponent} from "./buttons/font/QuillFormattingButtonBoldComponent";
import {QuillFormattingBaseButtonComponent} from "./buttons/QuillFormattingBaseButtonComponent";
import {QuillFormattingButtonItalicComponent} from "./buttons/font/QuillFormattingButtonItalicComponent";
import {QuillFormattingButtonStrikethroughComponent} from "./buttons/font/QuillFormattingButtonStrikethroughComponent";
import {QuillFormattingButtonUnderlineComponent} from "./buttons/font/QuillFormattingButtonUnderlineComponent";
import {QuillFormattingButtonFontSizeComponent} from "./buttons/font/font/QuillFormattingButtonFontSizeComponent";
import {QuillFormattingButtonFontColorComponent} from "./buttons/font/font/QuillFormattingButtonFontColorComponent";
import {
    QuillFormattingButtonFontBackgroundComponent
} from "./buttons/font/font/QuillFormattingButtonFontBackgroundComponent";
import {QuillFormattingButtonListComponent} from "./buttons/position/QuillFormattingButtonListComponent";
import {
    QuillFormattingButtonAlignmentComponent
} from "./buttons/position/alignment/QuillFormattingButtonAlignmentComponent";
import {QuillFormattingButtonLinkComponent} from "./buttons/element/QuillFormattingButtonLinkComponent";
import {QuillFormattingLinkLogic} from "./logic/link/QuillFormattingLinkLogic";
import {QuillFormattingButtonFontFamilyComponent} from "./buttons/font/font/QuillFormattingButtonFontFamilyComponent";
import {QuillFormattingButtonQuoteComponent} from "./buttons/font/QuillFormattingButtonQuoteComponent";
import {QuillFormattingButtonImageComponent} from "./buttons/element/QuillFormattingButtonImageComponent";
import {QuillFormattingButtonEmbedDeleteComponent} from "./buttons/embed/QuillFormattingButtonEmbedDeleteComponent";
import {
    QuillFormattingButtonEmbedAlignCenterComponent
} from "./buttons/embed/align/QuillFormattingButtonEmbedAlignCenterComponent";
import {
    QuillFormattingButtonEmbedWidthHalfComponent
} from "./buttons/embed/width/QuillFormattingButtonEmbedWidthHalfComponent";
import {
    QuillFormattingButtonEmbedWidthMaxComponent
} from "./buttons/embed/width/QuillFormattingButtonEmbedWidthMaxComponent";
import {
    QuillFormattingButtonEmbedAlignLeftComponent
} from "./buttons/embed/align/QuillFormattingButtonEmbedAlignLeftComponent";
import {
    QuillFormattingButtonEmbedAlignRightComponent
} from "./buttons/embed/align/QuillFormattingButtonEmbedAlignRightComponent";
import {QuillFormattingButtonEmbedShadowComponent} from "./buttons/embed/QuillFormattingButtonEmbedShadowComponent";

export class QuillFormattingComponent extends Component {
    public quillComponent: QuillComponent;
    public settings: IQuillFormatting;
    public buttons: QuillFormattingBaseButtonComponent[];

    constructor(quillComponent: QuillComponent, settings: IQuillFormatting) {
        super();
        this.quillComponent = quillComponent;
        this.settings = settings;
        this.buttons = [];

        //language=HTML
        this.template = `
            <div class="${s.globalQuillFormatting}"></div>
        `;
    }

    commit() {
        this.renderButtons();
        this.initLogic();

        this.quillComponent.onOutsideClick(() => this.removeClass(s.disabled));

        this.quillComponent.quill.on("selection-change", (delta, oldContents, source) => {
            if (source == "user") {
                this.updateButtons();
                this.updateDisabled();
            }
        });

        this.quillComponent.quill.on("text-change", (range, oldRange, source) => {
            if (source == "user") {
                this.updateButtons();
                this.updateDisabled();
            }
        });

        this.updateButtons();
        super.commit();
    }

    /**
     * buttons rendering
     */

    renderButtons() {
        this.renderFontButtons();
        this.renderAlignmentButtons();
        this.renderAttachmentButtons();
        this.renderAdditionalButtons();
        this.renderEmbedWidthButtons();
        this.renderEmbedAlignButtons();
        this.renderOtherButtons();
    }

    renderFontButtons() {
        let container = this.append(`<div qCa class="${s.categoryButtons}">`);
        if (this.settings.bold) {
            this.register(container, new QuillFormattingButtonBoldComponent(this));
        }

        if (this.settings.italic) {
            this.register(container, new QuillFormattingButtonItalicComponent(this));
        }

        if (this.settings.strikethrough) {
            this.register(container, new QuillFormattingButtonStrikethroughComponent(this));
        }

        if (this.settings.underline) {
            this.register(container, new QuillFormattingButtonUnderlineComponent(this));
        }

        if (this.settings.fontFamily) {
            this.register(container, new QuillFormattingButtonFontFamilyComponent(this));
        }

        if (this.settings.fontSize) {
            this.register(container, new QuillFormattingButtonFontSizeComponent(this, this.settings.fontSizeType ? this.settings.fontSizeType : "size"));
        }

        if (this.settings.fontColor) {
            this.register(container, new QuillFormattingButtonFontColorComponent(this));
        }

        if (this.settings.fontBackground) {
            this.register(container, new QuillFormattingButtonFontBackgroundComponent(this));
        }

        if (container.getHtml().trim() == "") {
            container.remove();
        }
    }

    renderAlignmentButtons() {
        let container = this.append(`<div qCa class="${s.categoryButtons}"></div>`);

        if (this.settings.list) {
            this.register(container, new QuillFormattingButtonListComponent(this));
        }

        if (this.settings.alignment) {
            this.register(container, new QuillFormattingButtonAlignmentComponent(this));
        }

        if (container.getHtml().trim() == "") {
            container.remove();
        }

    }

    renderAttachmentButtons() {
        let container = this.append(`<div qCa class="${s.categoryButtons}"></div>`);
        if (this.settings.link) {
            this.register(container, new QuillFormattingButtonLinkComponent(this));
        }

        if (this.settings.image) {
            this.register(container, new QuillFormattingButtonImageComponent(this));
        }

        if (container.getHtml().trim() == "") {
            container.remove();
        }
    }

    renderAdditionalButtons() {
        let container = this.append(`<div qCa class="${s.categoryButtons}"></div>`);
        if (this.settings.quote) {
            this.register(container, new QuillFormattingButtonQuoteComponent(this));
        }

        if (container.getHtml().trim() == "") {
            container.remove();
        }
    }

    renderEmbedWidthButtons() {
        let container = this.append(`<div qCa class="${s.categoryButtons}"></div>`);
        if (this.settings.embedWidthHalf) {
            this.register(container, new QuillFormattingButtonEmbedWidthHalfComponent(this));
        }
        if (this.settings.embedWidthMax) {
            this.register(container, new QuillFormattingButtonEmbedWidthMaxComponent(this));
        }

        if (container.getHtml().trim() == "") {
            container.remove();
        }
    }

    renderEmbedAlignButtons() {
        let container = this.append(`<div qCa class="${s.categoryButtons}"></div>`);
        if (this.settings.embedAlignLeft) {
            this.register(container, new QuillFormattingButtonEmbedAlignLeftComponent(this));
        }
        if (this.settings.embedAlignCenter) {
            this.register(container, new QuillFormattingButtonEmbedAlignCenterComponent(this));
        }
        if (this.settings.embedAlignRight) {
            this.register(container, new QuillFormattingButtonEmbedAlignRightComponent(this));
        }
        if (container.getHtml().trim() == "") {
            container.remove();
        }
    }

    renderOtherButtons() {
        let container = this.append(`<div qCa class="${s.categoryButtons}"></div>`);
        if (this.settings.shadow) {
            this.register(container, new QuillFormattingButtonEmbedShadowComponent(this));
        }

        if (this.settings.delete) {
            this.register(container, new QuillFormattingButtonEmbedDeleteComponent(this));
        }

        if (container.getHtml().trim() == "") {
            container.remove();
        }
    }

    /**
     * update buttons active
     */
    updateButtons() {
        this.timeOut(() => {
            for (let button of this.buttons)
                button.update();
        }, 0);
    }

    updateDisabled() {
        if (this.quillComponent.isHtmlBlot()) {
            this.addClass(s.disabled);
        } else {
            this.removeClass(s.disabled);
        }
    }

    /**
     * logic
     */

    initLogic() {
        if (this.settings.lineLink) {
            let linkLogic = new QuillFormattingLinkLogic(this);
            linkLogic.init();
        }
    }

    /**
     * format
     */

    register(container: Component, button: QuillFormattingBaseButtonComponent) {
        container.render(button);
        this.buttons.push(button);
        button.onFormat = (name, value) => this.onFormat(button, name, value);
    }

    /**
     * events
     */

    public onFormat(button: QuillFormattingBaseButtonComponent, name: string, value: string) {

    }


}