import {VisualModalComponent} from "../../../../../sedestral-interface-component-visual/modal/VisualModalComponent";
import {MediaImageCropperComponent} from "../MediaImageCropperComponent";
import * as s from "./media-image-cropper-modal.scss";
import {ICrop} from "../ICrop";

export class MediaImageCropperModalComponent extends VisualModalComponent {

    public cropper: MediaImageCropperComponent;

    constructor(object: string | File | any, crop?: ICrop) {
        super();
        this.cropper = new MediaImageCropperComponent(object, crop);
        this.name = "Recadrer une image";
        //language=HTML
        this.modalTemplate = `
            <div class="${s.componentMediaImageCropperModal}">
                <div class="${s.content}">
                    ${this.draw(this.cropper)}
                </div>
            </div>
        `;
        this.modalStyle = "width:920px;height:650px;";
    }
}