import {LoaderWaveComponent} from "../../loader/wave/LoaderWaveComponent";
import {MediaErrorComponent} from "../error/MediaErrorComponent";
import * as s from "./media-image.scss";
import {MediaAbstractComponent} from "../MediaAbstractComponent";
import {Models} from "../../../../models/Models";
import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {MediaImageCropperModalComponent} from "./cropper/modal/MediaImageCropperModalComponent";
import {Resources} from "../../../../resources/Resources";
import {IDocumentCroppingModel} from "../../../../models/document/IDocumentCroppingModel";
import {MachineListener} from "../../../sedestral-interface-component/machine/listener/MachineListener";
import {ICrop} from "./cropper/ICrop";
import {fileToBase64} from "../../../sedestral-interface-component/utilities/FileToBase64";

export class MediaImageComponent extends MediaAbstractComponent {

    public showError: boolean;
    public loadListeners: MachineListener[];

    public image: any;

    public imageContainer: Component;
    public imageElement: Component;

    public crop: IDocumentCroppingModel;

    constructor(object: string | File | any, showError?: boolean) {
        super();
        this.path = Models.isMediaModel(object) ? object.link : object;
        this.contentObject = object;

        this.showError = showError;
        this.loadListeners = [];

        //language=HTML
        this.template = `
            <div media mediaImage class="${s.globalMediaImage}">
                <div class="${s.container}"></div>
            </div>
        `;
    }

    commit() {
        this.imageContainer = this.el(s.container);

        this.init();
        super.commit();
    }

    init() {
        if (this.contentObject instanceof File) {
            fileToBase64(this.contentObject, (data) => {
                this.load(data);
                this.path = data;
            });
        } else {
            this.load(this.path);
        }
    }

    load(src: string) {
        this.image = new Image();
        this.loadListeners.push(this.putListener(this.image, "error", () => this.error()));
        this.loadListeners.push(this.putListener(this.image, "load", () => this.loaded(src)));
        this.image.src = src;
    }

    loaded(src: any) {
        this.imageContainer.clearAll();
        if (!this.isNull()) {
            this.imageElement = this.imageContainer.append(`<img src="${src}" alt="image"/>`);

            this.onLoad();
            this.timeOut(() => this.resize(), 50)

            this.imageContainer.onResize(() => this.resize());
        }
    }

    error() {
        this.clearAll();

        if (this.showError == undefined || this.showError) {
            this.render(new MediaErrorComponent());
        }
    }

    resize() {
        if (this.image) {
            super.resize(this.image.width, this.image.height, this.imageElement);
        }
    }

    /**
     * crop
     */

    renderCrop() {
        //language=HTML
        let editButton = this.append(`
            <div class="${s.edit}">
                ${Resources.t("words.crop")}
                <div class="${s.icon}"></div>
            </div>`);

        editButton.onClick(() => {
            let cropper = new MediaImageCropperModalComponent(this.path, this.crop);
            cropper.screenRender();
            cropper.cropper.onCrop = (crop) => {
                this.imageContainer.clearAll();
                this.imageContainer.render(new LoaderWaveComponent());

                cropper.close();
                this.onCrop(crop);
            };
        });
    }

    setCrop(crop: IDocumentCroppingModel) {
        if (crop) {
            this.deleteListeners(this.loadListeners);
            this.loadListeners = [];

            this.load(crop.content.link);
        }

        this.crop = crop;
    }

    /**
     * override
     */

    onLoad() {

    }

    onCrop(crop: ICrop) {

    }
}