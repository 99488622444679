import {Component} from "../../sedestral-interface-component/interface/component/Component";
import {WebAudioRecorder} from "../../sedestral-interface-utilities/WebAudioRecorder";

import {LoaderLightComponent} from "../loader/light/LoaderLightComponent";
import * as s from "./recorder.scss";
import {EmptyBasicComponent} from "../empty/EmptyBasicComponent";
import {randomString} from "../../sedestral-interface-component/utilities/RandomString";

export class RecorderComponent extends Component {

    private recordButton: Component;
    private timeContainer: Component;
    private canvasContainer: Component;
    private loadingContainer: Component;
    private iconContainer: Component;

    private canvas: HTMLCanvasElement;
    private ctx: CanvasRenderingContext2D;
    private recorder: WebAudioRecorder;

    private color: string;

    constructor(color?: string) {
        super();
        this.color = color == undefined ? "black" : color;

        //language=HTML
        this.template = `
            <div class="${s.globalRecorder}">
                <div style="background: ${this.color}" class="${s.recordButton}">
                    <div class="${s.icon} ${s.record}"></div>
                </div>
                <div style="background: ${this.color}" class="${s.time}">0s</div>
                <canvas class="${s.canvas}"></canvas>
                <div class="${s.loadingContainer}"></div>
            </div>`;
    }

    commit() {
        this.recordButton = this.el(s.recordButton);
        this.timeContainer = this.el(s.time);
        this.canvasContainer = this.el(s.canvas);
        this.iconContainer = this.el(s.icon);
        this.loadingContainer = this.el(s.loadingContainer);

        if (this.canvasContainer.element instanceof HTMLCanvasElement) {
            this.canvas = this.canvasContainer.element;
            this.ctx = this.canvas.getContext("2d");
        }

        this.resetFrequency();
        this.recordButton.onClick(() => {
            if (this.hasClass(s.recording)) {
                this.stopRecord();
                this.removeClass(s.recording);
                this.iconContainer.removeClass(s.stop);
                this.iconContainer.addClass(s.record);
            } else {
                this.startRecord();
                this.addClass(s.recording);
                this.iconContainer.removeClass(s.record);
                this.iconContainer.addClass(s.stop);
                this.resetTime();
            }
        });
    }

    renderFrequency(frequency) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height); // Clear the canvas
        this.ctx.fillStyle = this.color;
        let bar_x, bar_width, bar_height;
        let bars = 40;
        for (let i = 0; i < bars; i++) {
            bar_x = i * 8;
            bar_width = 3;
            bar_height = -(frequency[i] / 8);
            this.ctx.fillRect(bar_x, this.canvas.height / 2, bar_width, bar_height - 2);
            this.ctx.fillRect(bar_x, this.canvas.height / 2, bar_width, -bar_height + 2);
        }
    }

    resetFrequency() {
        this.renderFrequency([0, 0, 100, 200, 100, 40, 0, 20, 60, 0, 90, 40, 200, 100, 50, 0, 0, 0, 0, 0, 20, 50, 100, 20, 100, 70, 30, 10, 0, 80, 140, 190, 100, 50, 20, 0, 0, 0, 0, 0]);
    }

    renderTime(time: number) {
        this.timeContainer.setHtml(time + "s");
    }

    resetTime() {
        this.timeContainer.setHtml("0s");
    }

    renderLoading() {
        //language=HTML
        this.loadingContainer.show();
        this.loadingContainer.render(new LoaderLightComponent());
    }

    removeLoading() {
        this.loadingContainer.hide();
        this.loadingContainer.clearAll();
    }

    async startRecord() {
        this.renderLoading();
        this.recorder = new WebAudioRecorder();
        let permission = await this.recorder.init();
        if (permission) {
            this.recorder.onComplete = (recorder, blob) => {
                if (this.recorder.finalTime < 2) {
                    this.onRecorded(undefined, undefined, 1);
                    this.removeLoading();
                } else {
                    this.removeLoading();
                    let file = new File(
                        [blob],
                        randomString(20, false) + ".mp3",
                        {
                            type: "audio/mp3"
                        }
                    );
                    let url = window.URL.createObjectURL(blob);
                    this.onRecorded(file, url, 0);
                }
                this.resetFrequency();
                this.resetTime();
                this.recorder = undefined;
            };
            this.recorder.onEncoderLoaded = () => {
                this.removeLoading();
            };
            this.recorder.onEncodingProgress = (recorder, progress) => {

            };
            this.recorder.onTimeChange = (time) => {
                this.renderTime(time);
            };
            this.recorder.onFrequency = (frequency) => {
                this.renderFrequency(frequency);
            };

            this.recorder.startRecording();
        } else {
            this.removeLoading();
            this.clearAll();
            this.render(new EmptyBasicComponent("🚨", "Impossible d'accéder au micro"))
        }
    }

    stopRecord() {
        this.renderLoading();
        this.recorder.finishRecording();
    }

    onRecorded(file: File, url: string, statusCode: number) {
    }

    destroy() {
        if (this.recorder != undefined) {
            this.recorder.finishRecording();
            this.recorder.cancelEncoding();
            this.recorder = undefined;
        }

        super.destroy();
    }
}