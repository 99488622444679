@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentQuillBlotsLogicCursor {
  position: absolute;
  padding: 2px;
  display: none;
  pointer-events: none;
  z-index: 5;

  .name {
    position: absolute;
    padding: 4px 5px;
    color: var(--white);
    border-radius: 2px;
    font-size: 11px;
    top: -12px;

    @include texts.font("semi-bold");
  }
}