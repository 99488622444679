import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    QuillComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/QuillComponent";
import {
    IQuillContent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/types/IQuillContent";
import * as s from "./editor.scss";
import {PreferencesService} from "../../../../../services/preferences/PreferencesService";
import {IEditor} from "./types/IEditor";
import {EditorButton} from "./buttons/EditorButton";
import {EditorButtonLimit} from "./buttons/EditorButtonLimit";
import {EditorButtonSettings} from "./buttons/EditorButtonSettings";
import {EditorButtonAttachment} from "./buttons/EditorButtonAttachment";
import {EditorButtonGoodies} from "./buttons/EditorButtonGoodies";
import {EditorButtonRecorder} from "./buttons/EditorButtonRecorder";
import {EditorButtonSend} from "./buttons/EditorButtonSend";
import {EditorButtonPrivate} from "./buttons/EditorButtonPrivate";
import {EditorButtonIntegrations} from "./buttons/EditorButtonIntegrations";
import {EditorButtonShortcuts} from "./buttons/EditorButtonShortcuts";
import {EditorButtonFormatting} from "./buttons/EditorButtonFormatting";
import {
    IQuillIntegration
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/editor/integrations/types/IQuillIntegration";
import {
    IQuillIntegrationReplace
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/editor/integrations/types/IQuillIntegrationReplace";
import {
    EmptyBasicComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/empty/EmptyBasicComponent";
import {Resources} from "../../../../../resources/Resources";
import {
    LoaderLightComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {
    SedestralMachine
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/machine/SedestralMachine";
import {
    SedestralMemory
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/memory/SedestralMemory";
import {FileService} from "../../../../../services/file/FileService";
import {PreviewService} from "../../../../../services/preview/PreviewService";
import {
    QuillAttachmentComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/attachments/QuillAttachmentComponent";
import {Network} from "../../../../../network/Network";
import {
    MediaFactory
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/media/types/MediaFactory";
import {EntityService} from "../../../../../services/entity/EntityService";
import {
    MediaUploadComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/media/upload/MediaUploadComponent";
import {FileOrigin} from "../../../../../models/file/FileOrigin";
import {
    QuillImageBlot
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/blots/image/QuillImageBlot";
import {EditorButtonGPT} from "./buttons/EditorButtonGPT";
import {config} from "../../../../../config";
import {
    IQuillCollaboration
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/types/settings/IQuillCollaboration";


export class EditorComponent extends Component {
    public quillContainer: Component;
    public quillComponent: QuillComponent;

    public emptyContainer: Component;
    public rightButtonsContainer: Component;
    public leftButtonsContainer: Component;
    public unCollapseButton: Component;
    public toolbarContainer: Component;
    public toolbarPositionContainer: Component;

    public buttons: EditorButton[];

    public editionContainer: Component;
    public formattingContainer: Component;

    public config: IEditor;

    public buttonShortcuts: EditorButtonShortcuts;
    public buttonSend: EditorButtonSend;


    constructor(config: IEditor) {
        super();
        this.config = config;
        this.buttons = [];

        //language=HTML
        this.template = `
            <div editor class="${s.componentEditor}">
                <div editableContainer class="${s.editionContainer}">
                    <div editable class="${s.editable}"></div>
                    <div editableTb class="${s.toolbarPosition}">
                        <div class="${s.toolbar}">
                            <div class="${s.toolbarContent}">
                                <div class="${s.formattingContainer}"></div>
                                <div edButtons class="${s.buttonsContainer}">
                                    <div leftButtons class="${s.leftButtons}">
                                        <div unCollapseBtn button class="${s.button} ${s.unCollapse}">
                                            <div class="${s.icon}"></div>
                                        </div>
                                    </div>
                                    <div rightButtons class="${s.rightButtons}"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        this.quillContainer = this.el(s.editable);
        this.rightButtonsContainer = this.el(s.rightButtons);
        this.leftButtonsContainer = this.el(s.leftButtons);
        this.editionContainer = this.el(s.editionContainer);
        this.unCollapseButton = this.el(s.unCollapse);
        this.formattingContainer = this.el(s.formattingContainer);
        this.toolbarContainer = this.el(s.toolbar);
        this.toolbarPositionContainer = this.el(s.toolbarPosition);

        this.quillComponent = new QuillComponent(this.config.quill);

        this.registerSettings();
        this.quillContainer.render(this.quillComponent);

        if (this.config.value) {
            this.quillComponent.setContent(this.config.value);
        }

        if (this.config.focus) {
            this.quillComponent.focusAtEnd();
            this.timeOut(() => this.quillComponent.focusAtEnd(), 1);
        }

        if (this.config.disable) {
            this.quillComponent.quill.disable();
        }

        if (this.config.valueText) {
            this.quillComponent.quill.insertText(0, this.config.valueText, "silent");
        }

        if (this.config.valueDeltas) {
            this.quillComponent.setDeltas(this.config.valueDeltas);
        }

        this.registerAll();

        if (this.config.quill.collaboration?.editorId) {
            this.initCollaboration();
        }

        this.quillComponent.onInsertedImageFile = (blot, file, data, height, width) => this.onInsertedImageFile(blot, file, data, height, width);
        if (this.config.quill.attachment) {
            this.quillComponent.attachmentsComponent.onFileAdd = (component => this.onFileAdd(component));
            this.quillComponent.attachmentsComponent.onUrlAdd = (component) => this.onUrlAdd(component);
            this.quillComponent.beforeAddAttachment = (file) => this.beforeAddAttachment(file);
        }

        super.commitTooltips();
        super.commit();
    }

    /**
     * registers
     */

    registerSettings() {
        this.quillComponent.settings.lineMode = this.config.formatting ? "Enter" : PreferencesService.editorLineMode;
        this.quillComponent.settings.spellChecker = this.config.quill.spellChecker != undefined ? this.config.quill.spellChecker : PreferencesService.editorSpellChecker;
    }

    registerButton(button: EditorButton) {
        button.init();
        this.buttons.push(button);
    }

    registerAll(): void {
        if (this.config.send || this.config.simpleSend) {
            this.buttonSend = new EditorButtonSend(this, this.config.simpleSend);
            this.registerButton(this.buttonSend);
        }
        if (this.config.settings) {
            this.registerButton(new EditorButtonSettings(this));
        }
        if (this.config.recorder) {
            this.registerButton(new EditorButtonRecorder(this));
        }
        if (this.config.gifs || this.config.emojis) {
            this.registerButton(new EditorButtonGoodies(this));
        }
        if (this.config.shortcuts) {
            this.buttonShortcuts = new EditorButtonShortcuts(this);
            this.registerButton(this.buttonShortcuts);
        }
        if (this.config.quill.attachment) {
            this.registerButton(new EditorButtonAttachment(this));
        }
        if (this.config.quill.integrations) {
            this.registerButton(new EditorButtonIntegrations(this));
        }
        if (this.config.quill.limits) {
            this.registerButton(new EditorButtonLimit(this));
        }
        if (this.config.private) {
            this.registerButton(new EditorButtonPrivate(this));
        }

        if (!config.isProd) {
            if (this.config.gpt && this.config.conversationId) {
                this.registerButton(new EditorButtonGPT(this, this.config.conversationId));
            }
        }

        if (this.config.formatting) {
            this.registerButton(new EditorButtonFormatting(this));
        }

        if (this.config.forcePrivate) {
            this.config.enablePrivate = true;
            this.addClass(s.private);
        }
    }

    /**
     * stick
     */
    stick(offsetBottom: () => number, offsetScrollBottom: () => number) {
        let focused = false;
        this.onClick(() => {
            this.stickCalc(offsetBottom(), offsetScrollBottom());
            focused = true;
        });
        this.onOutsideClick(() => {
            this.stickOff();
            focused = false;
        });

        this.quillComponent.onContentChange(this, () => {
            if (this.quillComponent.quill.hasFocus() || focused) {
                this.stickCalc(offsetBottom(), offsetScrollBottom());
            }
        });

        this.onParentsScroll(() => {
            if (this.quillComponent.quill.hasFocus() || focused) {
                this.stickCalc(offsetBottom(), offsetScrollBottom())
            }
        });

        this.onWindowResize(() => {
            if (this.quillComponent.quill.hasFocus() || focused) {
                this.stickCalc(offsetBottom(), offsetScrollBottom())
            }
        });

    }

    stickCalc(offsetBottom: number, offsetScrollBottom: number) {
        if (this.getOffsets().top < window.innerHeight &&
            (this.toolbarPositionContainer.getOffsets().top >= window.innerHeight - this.toolbarContainer.getHeight() - offsetScrollBottom)) {
            this.stickOn(offsetBottom);
        } else {
            this.stickOff();
        }
    }

    stickOn(offsetBottom: number) {
        this.toolbarPositionContainer.setStyle(`height:${this.toolbarPositionContainer.getHeight()}px`);
        this.toolbarContainer.addClass(s.fixed);
        let offsets = this.getOffsets();
        this.toolbarContainer.setStyle(`bottom: ${offsetBottom}px;left:${offsets.left}px;width:${this.getWidth()}px`);
    }

    stickOff() {
        this.toolbarContainer.removeClass(s.fixed);
        this.toolbarContainer.removeAttribute("style");
        this.toolbarPositionContainer.removeAttribute("style");
    }

    /**
     * get and set
     */

    rightButton(constructor: string): EditorButton {
        return this.buttons.find(value => value.constructor.name == constructor);
    }

    rightButtons(): Component[] {
        return this.rightButtonsContainer.els(s.button);
    }

    leftButtons(): Component[] {
        return this.leftButtonsContainer.els(s.button);
    }

    contents(): IQuillContent {
        return this.quillComponent.contents();
    }

    setIntegrations(integrations: IQuillIntegration[]) {
        this.config.quill.integrationsList = integrations;
        this.registerSettings();
    }

    setIntegrationsReplace(integrationsListReplaces: IQuillIntegrationReplace[]) {
        this.config.quill.integrationsListReplaces = integrationsListReplaces;
        this.registerSettings();
    }

    /**
     * collaboration
     */

    initCollaboration(collaboration?: IQuillCollaboration) {
        if (collaboration) {
            this.config.quill.collaboration = collaboration;
            this.quillComponent.registerLogicCollaboration();
        }

        this.quillComponent.logicCollaboration.onUnauthorized = () => this.renderEmpty(true);
        this.quillComponent.logicCollaboration.onError = async () => {
            this.renderEmpty();
            await SedestralMachine.sleep(5000);

            this.quillComponent.reset();
            this.quillComponent.logicCollaboration.dispose();
            this.quillComponent.registerLogicCollaboration();
            this.initCollaboration();
        }

        this.quillComponent.logicCollaboration.onConnect = () => this.removeEmpty();
    }

    /**
     * empty
     */

    renderEmpty(unauthorized?: boolean) {
        if (!this.emptyContainer) {
            //language=HTML
            this.emptyContainer = this.append(`
                <div class="${s.emptyContainer}">
                    <div class="${s.empty}">
                        ${this.draw(new EmptyBasicComponent(
                                `<div class="${s.icon}"></div>`,
                                Resources.t("words.cannotReadOrModify") + (!unauthorized ? ". " + Resources.t("words.attemptReconnect") : "")
                        ))}
                    </div>
                    ${!unauthorized ? `<div class="${s.loader}">${this.draw(new LoaderLightComponent())}</div>` : ``}
                </div>
            `);
        }
    }

    removeEmpty() {
        if (this.emptyContainer) {
            this.emptyContainer.remove();
            this.emptyContainer = undefined;
        }
    }

    /**
     * save and restore
     */

    public saveContent(filesIds: string[], previewsIds: string[], contents: string): void {
        SedestralMemory.setItem(this.config.saveContentId, JSON.stringify({
            filesIds: filesIds,
            previewsIds: previewsIds,
            contents: contents
        }));
    }

    public restoreMemoryContent() {
        let content = SedestralMemory.getItem(this.config.saveContentId);
        if (content != undefined) {
            this.restoreContent(JSON.parse(content));
        }
    }

    public restoreContent(settings: { filesIds: string[], previewsIds: string[], contents: string }): void {
        this.quillComponent.quill.root.innerHTML = settings.contents
        this.timeOut(() => {
            this.quillComponent.quill.setSelection(this.quillComponent.quill.getLength(), 0);
        }, 1);

        let files = FileService.files.filter(value => settings.filesIds.includes(value.id));
        let previews = PreviewService.previews.filter(value => settings.previewsIds.includes(value.id));
        this.quillComponent.attachmentsComponent.restoreAttachments(files, previews);
    }

    /**
     * override
     */

    async onSend(scheduleTime?: number) {

    }

    onShortcutPick(name: string, index: number) {

    }

    /**
     * files links and uploading
     */

    public async beforeAddAttachment(file: File): Promise<boolean> {
        return true;
    }

    public async onUrlAdd(component: QuillAttachmentComponent) {
        component.uploadComponent.simulateProgress();
        let preview = await PreviewService.findByUrl(String(component.object), this);
        if (!component.isNull()) {
            component.uploaded(preview, MediaFactory.generate(component.object, preview));
        }
    }

    async uploadFile(component: QuillAttachmentComponent, progress?: (progress: number) => void) {
        return await FileService.create(EntityService.activeSite.id, this.config.fileOrigin, [component.object as File], progress, component.mediaComponent);
    }

    public async onFileAdd(component: QuillAttachmentComponent) {
        if (this.config.fileOrigin) {
            let progress = (p) => {
                if (component.uploadComponent instanceof MediaUploadComponent) {
                    component.uploadComponent.progress(p);
                }
            }

            let files = await this.uploadFile(component, progress);

            if (files != undefined) {
                component.uploaded(files[0], MediaFactory.generate(component.object, files[0]));
            } else {
                component.uploaded(undefined, MediaFactory.generate(component.object, undefined));
            }
        }
    }

    public async uploadInsertFile(file: File, uploadComponent?: Component) {
        return await FileService.create(EntityService.activeSite.id, FileOrigin.INBOX_IMAGE_INSERT, [file], (p) => {
            if (uploadComponent instanceof MediaUploadComponent) {
                uploadComponent.progress(p);
            }
        }, this);
    }

    public async onInsertedImageFile(blot: any, file: File, data: string, height: number, width: number): Promise<void> {
        let uploadComponent = blot.domNode['uploadComponent'];
        let files = await this.uploadInsertFile(file, uploadComponent);

        if (files && blot instanceof QuillImageBlot) {
            blot.logicLoader.removeLoader();
            if (files == undefined || files.length == 0) {
                Network.router.static.components.notifications.notify(Resources.t('words.warning'), Resources.t('words.attachmentError'), 8000);
                blot.logicLoader.renderError();
                blot.format("error", true);
            } else {
                blot.format("src", files[0].link);
            }

            blot.format("upload", false);
        }
    }
}