import "./../../sedestral-interface-modules/sedestral-interface-sass/sedestral.scss";
import "./../../sedestral-interface-modules/sedestral-interface-component-global/loader/background/loader-background.scss";
import "./Panel.scss";

import {Resources} from "../../resources/Resources";
import {PanelRouter} from "./components/PanelRouter";
import {Network} from "../../network/Network";
import {ProductsLanguages} from "../ProductsLanguages";

export class Panel {
    private static router: PanelRouter;

    public static async init(): Promise<void> {
        this.router = new PanelRouter(ProductsLanguages.panel);

        await Resources.init([this.router.language()], this.router.languages);
        await Promise.all([
            await Resources.loadI18n("panel"),
            await Resources.loadCountries(),
            await Resources.loadWorldLanguages()
        ]);

        await Network.start(this.router);
        await this.router.init({defaultLanguage: Resources.language, languages: Resources.languages});
    }
}