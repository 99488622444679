import {EditorButton} from "./EditorButton";
import {EditorComponent} from "../EditorComponent";
import * as s from "../editor.scss";
import {
    QuillFormattingComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/editor/formatting/QuillFormattingComponent";

import {
    objectUpdate
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/ObjectUpdate";

export class EditorButtonFormatting extends EditorButton {
    constructor(editor: EditorComponent) {
        super(editor);
    }

    init() {
        let formatting = new QuillFormattingComponent(this.editor.quillComponent, objectUpdate({
            fontFamily: true,
            bold: true,
            italic: true,
            underline: true,
            quote: true,
            strikethrough: true,
            fontSize: true,
            fontColor: true,
            fontBackground: true,
            lineLink: true,
            link: true,
            image: true,
            list: true,
            alignment: true
        }, this.editor.config.formattingSettings ? this.editor.config.formattingSettings : {}));

        this.editor.formattingContainer.displayShow();
        //language=HTML
        this.button = this.editor.formattingContainer.append(`
            <div formatting class="${s.formatting}">
                ${this.editor.formattingContainer.draw(formatting)}
            </div>
        `);
    }
}