@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/outline";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.globalQuillAttachment {
  height: 90px;
  width: 190px;
  border-radius: 10px;
  margin-right: 5px;
  position: relative;
  float: left;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  div[media] {
    border-radius: 13px;
    display: flex !important;
  }

  div[mediaImage] {
    min-width: 50px;
  }

  div[networkError] {
    min-width: 100px;
    border-radius: 13px;
    box-shadow: inset 0 0 0 1px var(--grey-sur);
  }

  div[mediaLink] {
    min-width: 150px;
    display: block !important;
    box-shadow: inset 0 0 0 1px var(--grey-sur);

    div[mediaImage] {
      max-height: 15px;
      width: 100%;
    }

    .title {
      @include texts.ellipsis(1);
    }

    .website {
      margin-top: 1px;
      margin-left: 7px;
      float: left;
    }
  }

  div[mediaAudio] {
    width: 188px;
    overflow: visible !important;
  }

  div[mediaFile] {
    border: var(--grey-sur) 1px solid;
    height: calc(100% - 2px);
    width: calc(100% - 2px);

    .icon {
      height: 22px;
      width: 22px;
    }

    .fileType {
      font-size: 12px;
    }

    .fileName {
      font-size: 10px;

      @include texts.ellipsis(1);
    }
  }

  .remove {
    background: var(--black-alpha);
    top: 5px;
    cursor: pointer;
    right: 5px;
    z-index: 2;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    position: absolute;

    @include positioning.flex-globally;

    &:hover {
      @include outline.blue-hover(3);
    }

    .icon {
      height: 8px;
      width: 8px;
      filter: var(--icon-white);

      @include icons.ico("black-close");
    }
  }

  .information {
    position: absolute;
    left: 4px;
    z-index: 9;
    padding: 2px;
    top: 4px;
    border-radius: 8px;
    max-width: calc(100% - 12px);
    display: flex;
    background: var(--white);
    align-items: center;
    box-shadow: 0 0 4px #0000001a;

    .avatarContainer {
      height: 20px;
      width: 20px;

    }

    .name {
      margin-left: 4px;
      margin-right: 6px;
      font-size: 13px;

      @include texts.font("semi-bold");
    }
  }
}