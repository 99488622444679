@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentShortcut {
  height: 22px;
  font-size: 13px;
  padding-top: 5px;
  padding-left: 10px;
  width: calc(100% - 10px);
  cursor: pointer;
  position: relative;

  &[data-selected] {
    background: var(--grey-sur);
  }

  .texts {
    width: calc(100% - 62px);
    float: left;

    @include texts.ellipsis(1);

    .name {
      font-size: 13px;
      margin-right: 16px;

      @include texts.font("bold");
    }

    .ghost {
      height: 10px;
      margin-top: 6px;
      border-radius: 20px;
      margin-right: 10px;
      position: relative;
      float: left;
      background: var(--grey-sur);
    }

    .description {
      font-size: 12px;
      color: var(--black-alpha-half);
    }
  }

  .buttons {
    float: right;

    .button {
      height: 24px;
      width: 24px;
      margin-top: -4px;
      margin-right: 5px;
      float: left;

      @include positioning.flex-globally;
      @include buttons.round;

      .icon {
        height: 14px;
        width: 14px;

        &.mind {
          @include icons.ico("black-mind");
        }


        &.edit {
          @include icons.ico("black-edit");
        }
      }
    }
  }
}