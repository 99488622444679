import {onDrag} from "./OnDrag";
import {Component} from "../Component";
import {onDragInside} from "./OnDragInside";

export function draggable(component: Component, inside?: boolean): void {
    if (inside != undefined && inside) {
        onDragInside(component, (left, top) => {
            component.getHTMLElement().style.left = left + 'px';
            component.getHTMLElement().style.top = top + 'px';
        });
    } else {
        onDrag(component, (left, top) => {
            component.getHTMLElement().style.left = left + 'px';
            component.getHTMLElement().style.top = top + 'px';
        });
    }
}