import {QuillFormattingBaseButtonComponent} from "../QuillFormattingBaseButtonComponent";
import * as s from "../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../QuillFormattingComponent";

export class QuillFormattingButtonItalicComponent extends QuillFormattingBaseButtonComponent {
    constructor(formatting: QuillFormattingComponent) {
        super("italic", formatting);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton}">
                <div class="${s.icon} ${s.italic}"></div>
            </button>
        `;
    }
}