import {EditorComponent} from "../EditorComponent";
import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    VisualClickTooltipWhiteComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {
    VisualSwapComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/swap/VisualSwapComponent";
import {Network} from "../../../../../../network/Network";
import {
    MachineListener
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/machine/listener/MachineListener";

export class EditorButton {
    public editor: EditorComponent;
    public button: Component;
    public locked: boolean;

    public textListener: MachineListener;

    constructor(editor: EditorComponent) {
        this.editor = editor;
    }


    initPanel(button: Component, component: () => VisualClickTooltipWhiteComponent | VisualSwapComponent, onCommit?: (component: Component) => void) {
        button.onClick(() => {
            let panelComponent = component();

            if (panelComponent instanceof VisualClickTooltipWhiteComponent) {
                let panel: VisualClickTooltipWhiteComponent = (panelComponent as VisualClickTooltipWhiteComponent);
                panel.calcMiddle = () => {
                    return (this.editor.getOffsets().left + this.editor.getWidth()) - panel.getWidth();
                };
            }

            panelComponent.screen(false);
            panelComponent.create();

            if (!panelComponent.isNull()) {
                panelComponent.unClickable();
                if (onCommit != undefined) {
                    onCommit(panelComponent);
                }
            }
        });
    }

    init() {

    }

    lock(text?: string) {
        this.locked = true;

        if (text) {
            this.textListener = this.button.onClick(() => {
                Network.router.static.components.notifications.notify(text, undefined, 8000);
            });
        }
    }

    unLock() {
        if (this.textListener) {
            this.button.deleteListener(this.textListener);
        }
        this.locked = false;
    }
}