import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import * as s from "./composer-tab.scss";
import {IComposer} from "../../../../products/panel/components/static/composer/types/IComposer";

export class ComposerTabComponent extends Component {

    public contentContainer: Component;
    public reduceButton: Component;
    public closeButton: Component;
    public composer: IComposer;

    constructor(composer: IComposer) {
        super();
        this.composer = composer;

        //language=HTML
        this.template = `
            <div class="${s.componentComposerTab}">
                <div class="${s.head}">
                    <div class="${s.icon}"></div>
                    Conversation

                    <div class="${s.buttons}">
                        <div class="${s.button} ${s.reduce}">
                            <div class="${s.btnIcon}"></div>
                        </div>
                        <div class="${s.button} ${s.close}">
                            <div class="${s.btnIcon}"></div>
                        </div>
                    </div>
                </div>
                <div class="${s.content}"></div>
            </div>
        `;
    }

    commit() {
        this.contentContainer = this.el(s.content);
        this.reduceButton = this.el(s.reduce);
        this.closeButton = this.el(s.close);

        super.commit();
    }

    classCollapsed() {
        this.removeClass(s.unCollapsed);
        this.addClass(s.collapsed);
    }

    classUnCollapsed() {
        this.addClass(s.unCollapsed);
        this.removeClass(s.collapsed);
    }

    isCollapsed() {
        return this.hasClass(s.collapsed);
    }

}