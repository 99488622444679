import {QuillFormattingBaseButtonComponent} from "../QuillFormattingBaseButtonComponent";
import * as s from "../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../QuillFormattingComponent";
import {QuillBlot} from "../../../../blots/QuillBlot";
import {Resources} from "../../../../../../../resources/Resources";

export class QuillFormattingButtonEmbedShadowComponent extends QuillFormattingBaseButtonComponent {
    constructor(formatter: QuillFormattingComponent) {
        super("shadow", formatter);
        //language=HTML
        this.template = `
            <button data-tooltip="${Resources.t("words.shadow")}" class="${s.globalQuillFormattingButton}">
                <div class="${s.icon} ${s.shadow}"></div>
            </button>
        `;
    }

    commit() {
        super.commitTooltips();
        super.commit();
    }

    update() {
        this.removeClass(s.active);
        if (this.shadow()) {
            this.addClass(s.active);
        }
    }

    bind() {
        this.onClick(() => {
            let blot = this.formattingComponent.quillComponent.getActiveBlot();
            if (blot instanceof QuillBlot) {
                if (this.hasClass(s.active)) {
                    blot.format("style", "box-shadow: unset;");
                } else {
                    blot.format("style", "box-shadow:rgb(0 0 0 / 4%) 0px 0px 0px 1px, rgb(0 0 0 / 10%) 0px 0px 20px;");
                }
            }
        });
    }

    shadow(): boolean {
        let style = this.formattingComponent.quillComponent.currentFormats()['style'];
        return style && style.includes("box-shadow:") && !style.includes("box-shadow: unset");
    }
}