import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import {QuillComponent} from "../../../QuillComponent";
import * as s from "./quill-formatting-line.scss";
import {QuillFormattingComponent} from "../QuillFormattingComponent";

export class QuillFormattingLineComponent extends Component {

    constructor(component: QuillComponent) {
        super();
        //language=HTML
        this.template = `
            <div class="${s.globalQuillFormattingLine}">
                ${this.draw(new QuillFormattingComponent(component, {
                bold: true,
                italic: true,
                underline: true,
                strikethrough: true,
                link: true,
                lineLink: true,
                list: true
            }
        ))}
            </div>
        `;
    }
}