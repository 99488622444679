import {QuillFormattingBaseButtonComponent} from "../../QuillFormattingBaseButtonComponent";
import {QuillFormattingAlignmentComponent} from "./QuillFormattingAlignmentComponent";
import {Component} from "../../../../../../../sedestral-interface-component/interface/component/Component";
import * as s from "../../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../../QuillFormattingComponent";
import {txtCapitalize} from "../../../../../../../sedestral-interface-component/utilities/TxtCapitalize";

export class QuillFormattingButtonAlignmentComponent extends QuillFormattingBaseButtonComponent {
    private icon: Component;

    constructor(formatter: QuillFormattingComponent) {
        super("align", formatter);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton} ${s.large}">
                <div class="${s.AlignmentIcon} ${s.icon} ${s.alignmentLeft}"></div>
                <div class="${s.icon} ${s.bottom}"></div>
            </button>
        `;
    }

    commit() {
        this.icon = this.el(s.AlignmentIcon);
        super.commit();
    }

    update() {
        this.icon.removeClass(s.alignmentLeft);
        this.icon.removeClass(s.alignmentRight);
        this.icon.removeClass(s.alignmentCenter);

        let alignment = this.alignment();
        if (alignment != undefined) {
            this.icon.addClass(s[`alignment${txtCapitalize(alignment)}`]);
        } else {
            this.icon.addClass(s.alignmentLeft);
        }

        super.update();
    }

    bind() {
        this.onClick(() => {
            let tooltipComponent = new QuillFormattingAlignmentComponent(this);
            tooltipComponent.screen(false);
            tooltipComponent.create();

            tooltipComponent.onPick = (alignment => {
                tooltipComponent.dispose();
                if (alignment == "left" && !this.formattingComponent.settings.alignmentLeft) {
                    this.format(false);
                } else {
                    this.format(alignment);
                }
            });
        });
    }

    alignment(): string {
        return this.formattingComponent.quillComponent.currentFormats()['align'];
    }
}