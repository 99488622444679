import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import * as s from "./loader-progress.scss";

export class LoaderProgressComponent extends Component {
    constructor(size?: number) {
        super();
        size = size ? size : 13;
        //language=HTML
        this.template = `
            <div class="${s.globalLoaderProgress}">
                <div style="width: ${size}px;height: ${size}px;border:var(--grey-sur) 3px solid;"
                     class="${s.limit}"></div>
                <div class="${s.spinner}">
                    <svg style="width: ${size + 5}px;height: ${size + 5}px;" viewBox="0 0 114 114">
                        <circle class="${s.progress}" transform="rotate(-90 57 57)" r="50" cx="57" cy="57"/>
                    </svg>
                </div>
            </div>
        `;
    }

    commit() {
        super.commit();

        this.updateLimit(100, 50);
    }

    updateLimit(maxValue: number, value: number) {
        let progressCircle = this.el(s.progress);
        let calc = -((value / maxValue) * 314.16) - 314.16;
        if (progressCircle.element instanceof SVGCircleElement) {
            progressCircle.element.style.strokeDashoffset = "" + calc;
        }
    }
}