/**
 * visisbility
 */
import {Component} from "../Component";

export function isScrollVisible(component: Component, container: Component) {
    let top = component.getHTMLElement().offsetTop;
    let scrollTop = container.getHTMLElement().scrollTop;
    let height = container.getHeight();

    return ((top - scrollTop) >= 0 && (top - scrollTop) < height);
}