import Quill from "quill";
import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {SedestralInterface} from "../../../sedestral-interface-component/interface/SedestralInterface";
import {QuillComponent} from "../QuillComponent";
import {QuillBlotClickableLogic} from "./logic/clickable/QuillBlotClickableLogic";
import {QuillBlotLogic} from "./logic/QuillBlotLogic";
import {QuillBlotResizeLogic} from "./logic/resize/QuillBlotResizeLogic";
import {QuillBlotFormatterLogic} from "./logic/formatter/QuillBlotFormatterLogic";
import {QuillBlotLoaderLogic} from "./logic/loader/QuillBlotLoaderLogic";
import {IQuillFormatting} from "../editor/formatting/types/IQuillFormatting";
import {QuillBlotCursorLogic} from "./logic/cursor/QuillBlotCursorLogic";
import {IQuillBlotClickable} from "./logic/clickable/IQuillBlotClickable";
import IQuillRange from "quill-cursors/dist/quill-cursors/i-range";
import {QuillBlot} from "./QuillBlot";
import {elementParents} from "../../../sedestral-interface-component/utilities/ElementParents";
import {objectUpdate} from "../../../sedestral-interface-component/utilities/ObjectUpdate";
import {randomString} from "../../../sedestral-interface-component/utilities/RandomString";
import {objectEquals} from "../../../sedestral-interface-component/utilities/ObjectEquals";


const ATTRIBUTES = ['alt', 'error', 'src', 'height', 'width', 'style', 'data-id'];
let BlockEmbed = Quill.import('blots/block/embed');
let Block = Quill.import('blots/block');

export abstract class QuillBlotBlock extends Block {
    static blotName: string;
    static tagName: string;
    static datas: any = {};

    public quillComponent: QuillComponent;
    public node: HTMLElement;
    public component: Component;
    public spanComponent: Component;
    public data: any;
    public logics: QuillBlotLogic[] = [];
    public logicLoader: QuillBlotLoaderLogic;
    public logicCursor: QuillBlotCursorLogic;
    public attached: boolean;
    public selectionHandlers: ((delta) => void)[] = [];
    public textHandlers: ((delta) => void)[] = [];

    //settings
    public offsetSelect: number = 0;
    public borderRadius: number = 3;
    public collaborativeCursor = false;
    public withSpan: boolean;

    /**
     * object
     */

    constructor(scroll, domNode) {
        super(scroll, domNode);
        this.node = domNode;
        this.data = QuillBlot.datas[domNode.getAttribute("sdt-id")];
    }

    /**
     * static blot
     */
    static create(data): Node {
        data = typeof data != "object" ? {sedestralFirstData: data} : data;
        let node: HTMLElement = super.create(undefined);
        let id = (data && data.sedestralId) ? data.sedestralId : (node.hasAttribute("sdt-id") ? node.getAttribute("sdt-id") : randomString(12, false));

        if (!QuillBlot.datas[id]) {
            QuillBlot.datas[id] = data;
        } else {
            objectUpdate(QuillBlot.datas[id], data);
        }


        node.setAttribute("sdt-id", id);
        data['sedestralId'] = id;
        node['data'] = QuillBlot.datas[id];
        return node;
    }

    static value(node) {
        return node['data'];
    }

    static register() {
        if (/Firefox/i.test(navigator.userAgent)) {
            setTimeout(() => {
                // @ts-ignore
                document.execCommand('enableObjectResizing', false, false);
            }, 1);
        }
    }

    static formats(domNode) {
        let formats = {};
        ATTRIBUTES.forEach(attribute => {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute);
            }
        });
        Object.keys(formats).forEach(k => {
            if (k.startsWith("sedestral")) {
                delete formats[k];
            }
        })

        return formats;
    }

    attach() {
        this.component = SedestralInterface.el(this.node);
        if (this.withSpan) {
            this.spanComponent = SedestralInterface.el(this.component.getHTMLElement().getElementsByTagName("span")[0]);
        } else {
            this.component.clearAll();
        }

        this.node['data'] = this.data;
        this.quillComponent = this.getQuillComponent(this.node);

        this.data.quillId = this.quillComponent.quillId;
        this.onContentChange(() => this.executeQuillChange());
        this.component.onWindowResize(() => this.executeQuillChange());

        this.onReady();

        if (!this.attached) {
            this.logics.forEach(value => value.onReady());
        }
        this.attached = true;

        super.attach();
    }

    detach() {
        if (this.attached) {
            this.logics.forEach(value => value.onDetach());
            this.component.destroy();
            this.component = undefined;

            if (this.spanComponent) {
                this.spanComponent.destroy();
                this.spanComponent = undefined;
            }

            this.selectionHandlers.forEach(handler => this.quillComponent.quill.off("selection-change", handler))
            this.textHandlers.forEach(handler => this.quillComponent.quill.off("text-change", handler))
        }

        super.detach()
    }

    update(mutations: MutationRecord[], context: {
        [key: string]: any;
    }): void {
        this.logics.forEach(value => value.onUpdate());
        super.update(mutations, context)
    }

    format(name, value) {
        this.data[name] = value;
        if (ATTRIBUTES.indexOf(name) > -1) {
            if (name == "style") {
                this.component.setStyle(value)
            } else {
                if (value) {
                    this.node.setAttribute(name, value);
                } else {
                    this.node.removeAttribute(name);
                }
            }

        } else {
            this.node.setAttribute("sdt-id", this.node.getAttribute("sdt-id"));
            super.format(name, value);
        }
    }

    /**
     * logic
     */

    registerLogic(logic: QuillBlotLogic) {
        this.logics.push(logic);
    }

    clickable(settings?: IQuillBlotClickable): void {
        this.registerLogic(new QuillBlotClickableLogic(this, settings));
    }

    formattable(settings: IQuillFormatting): void {
        this.registerLogic(new QuillBlotFormatterLogic(this, settings));
    }

    resizable(): void {
        this.registerLogic(new QuillBlotResizeLogic(this));
    }

    select(): void {
        this.quillComponent.quill.setSelection(this.getIndex(), 1, "user");
    }

    /**
     * set
     */

    setLoading() {
        this.logicLoader = new QuillBlotLoaderLogic(this);
        this.logics.push(this.logicLoader);
    }

    removeLoading() {
        if (this.logicLoader) {
            this.logicLoader.removeLoader();
            this.logicLoader.removeError();
            this.logics.splice(this.logics.indexOf(this.logicLoader), 1);
            this.logicLoader = undefined;
        }
    }

    setCursor(name: string, color: string) {
        this.logicCursor = new QuillBlotCursorLogic(this, name, color);
        this.logics.push(this.logicCursor);
    }

    removeCursor() {
        if (this.logicCursor) {
            this.logicCursor.removeCursor();
            this.logics.splice(this.logics.indexOf(this.logicCursor), 1);
            this.logicCursor = undefined;
        }
    }

    /**
     * get
     */

    getDocumentBounds(): { top: number, left: number } {
        return {
            left: this.component.getOffsets().left,
            top: (this.node.getBoundingClientRect().top + document.documentElement.scrollTop)
        };
    }

    getInsideBounds() {
        return this.component.getParentOffsets(this.quillComponent);
    }

    getIndex() {
        return this.offset(this.quillComponent.quill.scroll);
    }

    getQuillComponent(node) {
        let q = elementParents(node).filter(value => {
            if (value instanceof HTMLElement) {
                return value.hasAttribute("qEditor");
            }
        })[0];

        let component = q ? q['quill']['quillComponent'] : undefined;
        if (component == undefined) {
            return QuillComponent._quills.filter(value => value.id == this.data.quillId)[0].quill;
        }

        return component;
    }

    /**
     * above
     */

    renderAbove(): Component {
        return this.quillComponent.append(`
                <div style="position: absolute;display:none;pointer-events:none;border-radius:${this.borderRadius}px;z-index: 4;"></div>`);
    }

    placeAbove(component: Component, offsetLeft?: number, offsetTop?: number, offsetHeight?: number, offsetWidth?: number) {
        offsetLeft = offsetLeft ? offsetLeft : 0;
        offsetTop = offsetTop ? offsetTop : 0;
        offsetHeight = offsetHeight ? offsetHeight : 0;
        offsetWidth = offsetWidth ? offsetWidth : 0;

        let bounds = this.getInsideBounds();
        if (bounds) {
            component.setStyle(`display:block;transition:0s;left:${bounds.left + offsetLeft}px;top:${bounds.top + offsetTop}px;height: ${this.component.getHeight() + offsetHeight - this.component.getPaddingHeight()}px;width: ${this.component.getWidth() + offsetWidth}px;`);
        }
    }

    /**
     * executes
     */

    executeQuillChange() {
        this.logics.forEach(value => value.onQuillChange());
    }

    /**
     * handler
     */

    onReady() {

    }

    onCursorSelect(onFocus: () => void, onBlur?: () => void) {
        let focused = false;
        let handler = (delta: IQuillRange) => {
            if (delta && (delta.index >= this.getIndex() && delta.index < (this.getIndex() + this.length() + this.offsetSelect)) && delta.length <= this.length()) {
                if (!focused) {
                    onFocus();
                    focused = true;
                }
            } else if (delta != undefined) {
                if (focused) {
                    if (onBlur) {
                        onBlur();
                    }
                    focused = false;
                }
            }
        };

        this.component.onClick(() => {
            let blotRange = {index: this.getIndex(), length: this.length()};
            if (objectEquals(blotRange, this.quillComponent.lastSelection)) {
                handler(blotRange);
            }

            this.quillComponent.quill.setSelection(this.getIndex(), this.length(), "user");
        });
        this.component.onOutsideClick((e) => {
            if (e.target != SedestralInterface.body.element) {
                if (focused) {
                    if (onBlur) {
                        onBlur();
                    }
                    focused = false;
                }
            }
        });

        this.quillComponent.quill.on("selection-change", handler);
        this.selectionHandlers.push(handler);
    }

    onContentChange(callback: (delta: any) => void) {
        let handler = (delta) => {
            callback(delta);
        };

        this.quillComponent.quill.on("selection-change", handler);
        this.selectionHandlers.push(handler);

        this.quillComponent.quill.on("text-change", handler);
        this.textHandlers.push(handler);
    }

}