import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./assignment-picker-entry.scss";
import {AssignableType} from "../../../../../../../models/assignment/AssignableType";
import {IAssignmentSettings} from "../../types/IAssignmentSettings";
import {AssignmentPickerAvatar} from "../types/AssignmentPickerAvatar";
import {MailService} from "../../../../../../../services/mail/MailService";
import {AvatarComponent} from "../../../avatar/AvatarComponent";

import {
    filterXSS
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/FilterXSS";

export class AssignmentPickerEntryComponent extends Component {

    public assignableType: AssignableType;
    public object: any;
    public settings: IAssignmentSettings;
    public assigned: boolean;

    public textContainer: Component;
    public avatarContainer: Component;
    public avatarComponent: AvatarComponent;

    constructor(settings: IAssignmentSettings, assignableType: AssignableType, object: any, assigned: boolean) {
        super();
        this.assignableType = assignableType;
        this.object = object;
        this.settings = settings;
        this.assigned = assigned;

        //language=HTML
        this.template = `
            <div class="${s.componentAssignmentPickerEntry}">
                <div class="${s.avatarContainer}"></div>
                <div class="${s.text}"></div>
            </div>
        `;
    }

    commit() {
        this.avatarContainer = this.el(s.avatarContainer);
        this.textContainer = this.el(s.text);

        this.renderAvatar();
        this.renderName();
        if (this.settings.showMails) {
            this.renderMail();
        }

        if (this.assigned) {
            this.renderAssigned();
        }


        if (this.settings.showType) {
            this.avatarComponent.setType(this.assignableType);
        }


        super.commit();
    }

    renderAvatar() {
        this.avatarComponent = AssignmentPickerAvatar.getAvatar(this.object, this.assignableType);
        this.avatarContainer.render(this.avatarComponent);
    }

    renderName() {
        let name = this.object.name;
        if (this.assignableType == AssignableType.MAIL) {
            name = MailService.senderName(this.object);
        }

        //language=HTML
        this.textContainer.append(`
            <div class="${s.name}">
                ${filterXSS(name)}
            </div>
        `);
    }

    renderMail() {
        let mail = this.object.email;
        if (this.assignableType == AssignableType.MAIL) {
            mail = this.object.fullName;
        }

        //language=HTML
        this.textContainer.append(`
            <div class="${s.mail}">
                ${filterXSS(mail)}
            </div>
        `);
    }

    renderAssigned() {
        this.append(`<div class="${s.iconAssigned}"></div>`);
    }


}