import {QuillFormattingBaseButtonComponent} from "../../QuillFormattingBaseButtonComponent";
import * as s from "../../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../../QuillFormattingComponent";
import {QuillBlot} from "../../../../../blots/QuillBlot";

export class QuillFormattingButtonEmbedWidthComponent extends QuillFormattingBaseButtonComponent {

    public value: string;

    constructor(value: string, formatter: QuillFormattingComponent) {
        super("width", formatter);
        this.value = value;
    }

    update() {
        this.removeClass(s.active);
        if (this.width() == this.value) {
            this.addClass(s.active);
        }
    }

    bind() {
        this.onClick(() => {
            let blot = this.formattingComponent.quillComponent.getActiveBlot();
            if (blot instanceof QuillBlot) {
                if (this.value == "max") {
                    blot.format("width", "100%");
                } else if (this.value == "half") {
                    blot.format("width", "260");
                }

                blot.format("height", "auto");
            }
        });
    }

    width(): string {
        let width = this.formattingComponent.quillComponent.currentFormats()['width'];
        if (width == "260") {
            return "half";
        } else if (width == "100%") {
            return "max";
        }

        return undefined;
    }
}