@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/effects";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/animations";

.componentNotification {
  margin-bottom: 5px;
  border-radius: 13px;
  padding: 8px 5px;
  left: -3px;
  width: calc(100% - 5px);
  transition: 0.1s;
  cursor: pointer;
  position: relative;

  @include positioning.clear;
  @include effects.hover-alpha;

  &.new {
    background: var(--blue-new);
    border-color: var(--blue-new-text) !important;
    color: var(--blue-new-text) !important;

    .date {
      color: var(--blue-new-text) !important;
    }

    .round {
      .icon {
        filter: var(--icon-blue-light);
      }
    }
  }

  .capsuleContainer {
    height: 44px;
    width: 44px;
    position: relative;
    float: left;

    .littleCapsule {
      height: 18px;
      width: 18px;
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 100%;
      box-shadow: 0 0 10px 0 #00000024;
    }
  }

  .content {
    width: calc(100% - 54px);
    font-size: 13px;
    margin-left: 10px;
    position: relative;
    float: left;

    @include texts.font("semi-bold");

    .text {
      margin-bottom: 3px;

      @include texts.font("medium");

      strong, b {
        @include texts.font("bold");
      }
    }

    .others {
      font-size: 10px;
      position: relative;
      color: var(--grey-text);

      @include texts.font("medium");

      .date {
        margin-top: 4px;
        float: left;
      }
    }
  }

  @media screen and (max-width: 480px) {
    @include animations.ripple;

    .capsuleContainer {
      height: 60px;
      width: 60px;
    }

    .content {
      font-size: 14px !important;
      width: calc(100% - 74px);
      margin-left: 14px;
      margin-top: 4px;
    }
  }
}