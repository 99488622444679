import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import {
    VisualClickTooltipWhiteComponent
} from "../../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import * as s from "./quill-integration-creator.scss";
import {Resources} from "../../../../../../resources/Resources";
import {elementOffsets} from "../../../../../sedestral-interface-component/utilities/ElementOffsets";

export class QuillIntegrationCreatorComponent extends VisualClickTooltipWhiteComponent {

    private value: string;

    constructor(component: Component, value: string) {
        super(component, 140, 290);
        this.value = value;
    }

    commit() {
        super.commit();
        //language=HTML
        this.append(`
            <div class="${s.globalQuillIntegrationCreator}">
                <input class="${s.input}" placeholder="${Resources.t("words.replacementText")}"/>
                <div class="${s.integrationButtons}">
                    <div class="${s.integrationButton} ${s.validate}">${Resources.t("words.validate")}</div>
                    <div class="${s.integrationButton} ${s.cancel}">${Resources.t("words.cancel")}
                    </div>
                </div>
            </div>
        `);


        this.setStyle(`padding:0;`);
        let input = this.el(s.input);
        let validate = this.el(s.validate);
        let cancel = this.el(s.cancel);

        input.focus();
        validate.onClick(() => {
            this.onValidate(input.getValue());
        }, true);
        cancel.onClick(() => {
            this.onCancel();
        }, true);
        input.setValue(this.value);
    }

    place() {
        super.place();
        this.placeTop();
    }

    calcOutsideBottom(): number {
        let offsets = elementOffsets(this.component.element);
        return offsets.bottom + 5;
    }

    calcMiddle(): number {
        let offsets = elementOffsets(this.component.element);
        return offsets.left;
    }

    calcTop(): number {
        let offsets = elementOffsets(this.component.element);
        return offsets.top + 20;
    }


    onValidate(value: string): void {
        this.remove();
    }

    onCancel(): void {
        this.remove();
    }
}