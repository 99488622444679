import * as s from "../../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../../QuillFormattingComponent";
import {QuillFormattingButtonEmbedWidthComponent} from "./QuillFormattingButtonEmbedWidthComponent";

export class QuillFormattingButtonEmbedWidthHalfComponent extends QuillFormattingButtonEmbedWidthComponent {
    constructor(formatter: QuillFormattingComponent) {
        super("half", formatter);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton}">
                <div class="${s.icon} ${s.embedHalfWidth}"></div>
            </button>
        `;
    }
}