import {QuillFormattingBaseButtonComponent} from "../QuillFormattingBaseButtonComponent";
import * as s from "../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../QuillFormattingComponent";

export class QuillFormattingButtonEmbedDeleteComponent extends QuillFormattingBaseButtonComponent {
    constructor(formatter: QuillFormattingComponent) {
        super("delete", formatter);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton}">
                <div class="${s.icon} ${s.delete}"></div>
            </button>
        `;
    }

    bind() {
        this.onClick(() => {
            let selection = this.formattingComponent.quillComponent.lastSelection;
            this.formattingComponent.quillComponent.quill.deleteText(selection.index, selection.length == 0 ? 1 : selection.length, "user");
        });
    }
}