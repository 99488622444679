import {QuillBlotLogic} from "../QuillBlotLogic";
import {QuillBlot} from "../../QuillBlot";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./quill-blots-logic-cursor.scss";

export class QuillBlotCursorLogic extends QuillBlotLogic {

    public cursor: Component;
    public name: string;
    public color: string;

    constructor(blot: QuillBlot, name: string, color: string) {
        super(blot);
        this.name = name;
        this.color = color;
        this.renderCursor();
    }

    /**
     * cursor
     */
    renderCursor() {
        if (!this.cursor) {
            //language=HTML
            this.cursor = this.blot.quillComponent.append(`
                <div style="box-shadow: 0 0 0 2px ${this.color};  border-radius: ${this.blot.borderRadius}px;"
                     class="${s.componentQuillBlotsLogicCursor}">
                    <div style="background:${this.color};" class="${s.name}">
                        ${this.name}
                    </div>
                </div>`);

            this.cursor.unClickable();
            let name = this.cursor.el(s.name);
            let timeout = this.cursor.timeOut(() => name.fade("out", 500), 1500);
            let listener = this.blot.component.onHover(() => {
                clearTimeout(timeout);
                name.fade("in", 300);
                timeout = this.cursor.timeOut(() => name.fade("out", 500), 1500);
            });
            this.cursor.onRemove(() => this.blot.component.deleteListener(listener));
            this.place();
        }
    }

    removeCursor() {
        if (this.cursor) {
            this.cursor.remove();
            this.cursor = undefined;
        }
    }

    place() {
        this.placeAbove(this.cursor, -2, -2, 0, 0);
    }

    /**
     * override
     */

    onDetach() {
        if (this.cursor) {
            this.cursor.remove();
            this.cursor = undefined;
        }

        super.onDetach();
    }

    onQuillChange() {
        if (this.cursor) {
            this.place();
        }

        super.onQuillChange();
    }
}