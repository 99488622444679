import * as s from "./quill-formatting-family.scss";
import {
    VisualClickTooltipWhiteComponent
} from "../../../../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../../../../sedestral-interface-component/interface/component/Component";
import {QuillComponent} from "../../../../../QuillComponent";

export class QuillFormattingFamilyComponent extends VisualClickTooltipWhiteComponent {
    private currentFont: string;

    constructor(component: Component, currentSize?: string) {
        super(component, 100, 140);
        this.currentFont = currentSize;

        //language=HTML
        this.template = `
            <div class="${s.globalQuillFormattingFamily}"></div>
        `;
    }

    commit() {
        this.setStyle(`padding:0;`);
        let container = this.el(s.globalQuillFormattingFamily);
        QuillComponent._fonts.forEach(font => {
            //language=HTML
            let button = container.append(`
                <div class="${s.listEntry}">
                    <span style="font-family: ${font.font}">${font.name}</span>
                    <div class="${s.selectedIcon}"></div>
                </div>
            `);

            button.removeClass(s.selected);
            button.onClick(() => this.onPick(font.font));
            if (this.currentFont == font.font) {
                button.addClass(s.selected);
            }
        })

        super.commit();
    }

    onPick(font: string) {

    }
}