import {Component} from "../../sedestral-interface-component/interface/component/Component";

export abstract class TimerComponent extends Component {

    protected constructor(date: Date) {
        super();
        this._date = date;

        this.template = `
            <time>${this.getTime()}</time>
        `;
    }

    private _date: Date;

    /**
     * get and set
     */
    get date(): Date {
        return this._date;
    }

    set date(value: Date) {
        this._date = value;
    }

    get intTimestamp(): number {
        return Math.floor(this.date.getTime() / 1000);
    }

    commit() {
        if (this.canUpdate()) {
            this.interval(() => {
                this.update();
            }, 7000);
        }

        super.commit();
    }

    /**
     * update
     */
    update(): void {
        this.setHtml(this.getTime());
    }

    canUpdate(): boolean {
        return false;
    }

    /**
     * to override
     */
    abstract getTime();
}