import {Component} from "../../sedestral-interface-component/interface/component/Component";
import {LoaderLightComponent} from "../loader/light/LoaderLightComponent";
import * as s from "./gifs.scss";
import {Network} from "../../../network/Network";
import {Resources} from "../../../resources/Resources";

export class GifsComponent extends Component {

    private gifsContainer: Component;
    private searchInput: Component;

    constructor() {
        super();
        this.template = `
            <div gifs class="${s.globalGifs}">
                <div class="${s.searchContainer}">
                    <div class="${s.icon}"></div>
                    <input class="${s.input}" placeholder="${Resources.t("words.searchPlaceholderGif")}"/>
                </div>

                <div class="${s.gridContainer}">
                    <div class="${s.gridStretch}">
                        ${this.draw(new LoaderLightComponent())}
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        this.gifsContainer = this.el(s.gridStretch);
        this.searchInput = this.el(s.input);

        this.init();
        super.commit();
    }

    async init(): Promise<void> {
        let request = await Network.request(undefined, (token) => Network.tenor.get("trending?locale=fr_FR&media_filter=basic", {cancelToken: token}), this);
        this.renderGifs(request.data.results);

        this.searchInput.onKey(async () => {
            let request = await Network.request(undefined, (token) => Network.tenor.get(`search?q=${encodeURI(this.searchInput.getValue())}&media_filter=basic&limit=30`, {cancelToken: token}), this);
            this.renderGifs(request.data.results);
        }, 500);
    }

    renderGifs(gifObjects: any): void {
        this.gifsContainer.clearAll();
        for (let gifObject of gifObjects) {
            this.renderGif(gifObject);
        }
    }

    renderGif(gifObject: any): void {
        let gif = gifObject.media[0].tinygif;
        let width = gif.dims[0];
        let height = gif.dims[1];

        let styleHeight = Math.round(((141 / width) * height));
        //language=HTM
        let gifComponent = this.gifsContainer.append(`
            <div style="height: ${styleHeight}px;" class="${s.item}">
                <img class="${s.image}" src="${gif.preview}" alt="gif"/>
            </div>
        `);
        let imageComponent = gifComponent.el(s.image);
        gifComponent.onHover(() => {
            imageComponent.setAttribute("src", gif.url);
        });

        gifComponent.onClick(async () => {
            this.onPick(gif.url);
        });
    }

    /**
     * to override
     */

    onPick(url: string) {

    }


}