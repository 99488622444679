import {
    VisualHoverTooltipWhiteComponent
} from "../../../../../sedestral-interface-component-visual/tooltip/VisualHoverTooltipWhiteComponent";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import {QuillLimitsComponent} from "../QuillLimitsComponent";
import {IQuillLimit} from "../types/IQuillLimit";

export class QuillLimitsTooltipComponent extends VisualHoverTooltipWhiteComponent {
    private limits: IQuillLimit[];
    private currentLength: number;

    constructor(component: Component, limits: IQuillLimit[], currentLength: number) {
        super(component, 60, 90);
        this.leaveDelay = 0;
        this.limits = limits;
        this.currentLength = currentLength;
    }

    commit() {
        super.commit();
        this.setStyle("min-height:30px;max-height:160px;overflow:hidden;padding:10px;");
        this.render(new QuillLimitsComponent(this.limits, this.currentLength));
    }

    place() {
        super.place();
        this.placeTop();
    }
}