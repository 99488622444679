import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {ICheckboxChoice} from "./types/ICheckboxChoice";
import * as s from "./checkbox-choices.scss";
import {ButtonCheckBoxComponent} from "../../buttons/check/box/ButtonCheckBoxComponent";
import {ICheckboxChoiceEntry} from "./types/ICheckboxChoiceEntry";
import {TooltipComponent} from "../../tooltip/TooltipComponent";

export class CheckboxChoicesComponent extends Component {
    private choices: ICheckboxChoice[];
    private entries: ICheckboxChoiceEntry[];
    private unique: boolean;

    constructor(choices: ICheckboxChoice[], unique?: boolean) {
        super();
        this.choices = choices;
        this.entries = [];
        this.unique = unique;

        //language=HTML
        this.template = `
            <div class="${s.globalCheckboxChoices}"></div>
        `;
    }


    commit() {
        this.initChoice();
        this.initDisplay();

        super.commit();
    }

    initChoice() {
        for (let choice of this.choices) {
            let buttonComponent = new ButtonCheckBoxComponent(choice.checked);
            //language=HTML
            let choiceComponent = this.append(`
                <div class="${s.choiceContainer}">
                    <div class="${s.choiceHead}">
                        <div class="${s.checkbox}">
                            ${this.draw(buttonComponent)}
                        </div>
                        <div class="${s.choiceName}">${choice.nameTemplate}
                            ${choice.nameTooltip != undefined ? this.draw(new TooltipComponent(choice.nameTooltip)) : ``}
                        </div>
                    </div>
                    ${choice.contentTemplate == undefined ? `` : `<div class="${s.choiceContent}">${typeof choice.contentTemplate == "object" ? `` : choice.contentTemplate}</div>`}
                </div>
            `);

            if (typeof choice.contentTemplate == "object") {
                choiceComponent.el(s.choiceContent).render(choice.contentTemplate);
            }

            buttonComponent.onStateChange = (checked) => {
                this.entries.forEach(value => value.buttonComponent.stateChange(false, true))

                buttonComponent.stateChange(true,true);
                this.onPick(checked, choice);
            }


            this.entries.push({
                buttonComponent: buttonComponent,
                choiceComponent: choiceComponent,
                value: choice.value
            });
        }
    }

    initDisplay() {
        for (let entry of this.entries) {
            entry.buttonComponent.onClick(() => {
                this.updateDisplay();
            });
        }

        this.updateDisplay();
    }

    updateDisplay() {
        for (let entry of this.entries) {
            if (entry.buttonComponent.checked) {
                entry.choiceComponent.el(s.choiceContent).displayShow();
            } else {
                entry.choiceComponent.el(s.choiceContent).displayHide();
            }
        }

        this.onContentShow();
    }


    onPick(checked: boolean, choice: ICheckboxChoice) {
    }

    onContentShow() {

    }

}