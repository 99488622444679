import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./button-check-switch.scss";
import {LoaderLightComponent} from "../../../loader/light/LoaderLightComponent";
import {ButtonCheckComponent} from "../ButtonCheckComponent";

export class ButtonSwitchComponent extends ButtonCheckComponent {
    private loadingContainer: Component;

    constructor(checked?: boolean) {
        super(checked);
        //language=HTML
        this.template = `
            <div btnSwitch ${this.state()} class="${s.globalButtonCheckSwitch}">
                <div btnSwitchCaret class="${s.caret}"></div>
            </div>
        `;
    }

    setLoading() {
        //language=HTML
        this.addClass(s.loading);
        //language=HTML
        this.loadingContainer = this.append(`
            <div class="${s.loading}">
                ${this.draw(new LoaderLightComponent())}
            </div>
        `);
    }

    removeLoading() {
        this.removeClass(s.loading);
        this.loadingContainer.remove();
        this.loadingContainer = undefined;
    }
}