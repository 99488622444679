import {
    VisualClickTooltipWhiteComponent
} from "../../../../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../../../../sedestral-interface-component/interface/component/Component";
import {BoundsStatic} from "quill";
import {IOffsets} from "../../../../../../../sedestral-interface-component/utilities/types/IOffsets";
import * as s from "./quill-formatting-link.scss";
import {QuillFormattingComponent} from "../../../QuillFormattingComponent";
import {textIsUrl} from "../../../../../../../sedestral-interface-component/utilities/TextIsUrl";
import {txtClipboard} from "../../../../../../../sedestral-interface-component/utilities/TxtClipboard";

export class QuillFormattingLinkComponent extends VisualClickTooltipWhiteComponent {
    private link: string;
    private content: string;
    private bounds: BoundsStatic;
    private offsets: IOffsets;
    private linkInput: Component;
    private textInput: Component;
    private formattingComponent: QuillFormattingComponent;

    constructor(component: QuillFormattingComponent, bounds: BoundsStatic, offsets: IOffsets, content?: string, link?: string) {
        super(component, 20, 240);
        this.mobileStyle(true);
        this.link = link;
        this.content = content;
        this.bounds = bounds;
        this.offsets = offsets;
        this.formattingComponent = component;

        //language=HTML
        this.template = `
            <div class="${s.globalQuillFormattingLink}">
                <div style="${link != undefined ? "display:none;" : ""}" class="${s.inputContainer}">
                    <div class="${s.icon} ${s.edit}"></div>
                    <input class="${s.Text}" value="${content == undefined ? "" : content}" placeholder="Texte"/>
                </div>
                <div class="${s.inputContainer}">
                    <div class="${s.icon} ${s.link}"></div>
                    <input value="${link == undefined ? "" : link}" class="${s.LinkInput}" placeholder="Lien"/>
                </div>
                <div class="${s.buttons}">
                    <div class="${s.validateButton}">
                        Appliquer
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        this.setStyle(`padding:12px;`);
        this.textInput = this.el(s.Text);
        this.linkInput = this.el(s.LinkInput);

        if (this.link == undefined && textIsUrl(this.content)) {
            this.linkInput.setValue(this.parseLink(this.content));
        }

        let validateButton = this.el(s.validateButton);
        validateButton.onClick((e) => {
            this.onPick(this.textInput.getValue(), this.parseLink(this.linkInput.getValue()));
        }, true);

        if (this.link != undefined) {
            this.renderButtons();
        }

        this.swapable(() => this.dispose());
        super.commit();
    }


    /**
     * buttons
     */

    renderButtons() {
        let buttonsContainer = this.el(s.buttons);
        //language=HTML
        let copyButton = buttonsContainer.append(`
            <div class="${s.roundButton}">
                <div class="${s.icon} ${s.copy}"></div>
            </div>`);
        copyButton.onClick(() => {
            txtClipboard(this.parseLink(this.linkInput.getValue()));
            this.dispose();
        }, true);

        //language=HTML
        let deleteButton = buttonsContainer.append(`
            <div class="${s.roundButton}">
                <div class="${s.icon} ${s.remove}"></div>
            </div>`);
        deleteButton.onClick(() => {
            this.onDelete();
        }, true);
    }

    /**
     * position
     */
    calcMiddle(): number {
        let quillOffsets = this.formattingComponent.quillComponent.getOffsets();
        return quillOffsets.left + this.bounds.left;
    }

    outsideLeft(): boolean {
        return false;
    }

    calcTop(): number {
        let quillOffsets = this.formattingComponent.quillComponent.getOffsets();
        return (quillOffsets.top - this.getHeight()) + (this.bounds.top > 400 ? 400 : this.bounds.top)  + 10;
    }

    calcOutsideBottom(): number {
       /* console.log(this.bounds);
        console.log(this.offsets)
        let top = ((this.bounds.top + this.offsets.top) - this.component.getHTMLElement().scrollTop) - this.getHeight();*/
        return this.component.getOffsets().bottom + this.component.getHeight() + 4;
    }

    calcOutsideRight(): number {
        //return (window.innerWidth - (this.bounds.left + this.offsets.left));
        return 10;
    }


    /**
     * parse
     */
    parseLink(link: string) {
        if (!link.startsWith("http")) {
            link = "https://" + link;
        }

        return link;
    }

    /**
     * to override
     */

    onPick(text: string, link: string) {

    }

    onDelete() {

    }
}