@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentConversationCreator {

  .from {
    display: flex;
    align-items: center;

    .case {
      height: 26px;
      background: var(--blue-new);
      color: var(--blue-new-text);
      border-radius: 6px;
      flex: 0 0 20px;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      padding: 0 6px;
      font-size: 13px;
      display: flex;

      @include texts.font("semi-bold");
    }

    .pickerContainer {
      height: 40px;
      width: 100%;
      position: relative;
    }

    .collaboratorsContainer {
      visibility: visible;
      margin-left: 5px;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 103px);
    position: relative;

    &.mail {
      margin-left: -12px;
      width: calc(100% + 24px);
      margin-top: 14px;
    }
  }

}