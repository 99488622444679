import {QuillLogic} from "./QuillLogic";
import {QuillComponent} from "../QuillComponent";

export class QuillLogicMagicUrl extends QuillLogic {

    constructor(quillComponent: QuillComponent) {
        super(quillComponent);
    }

    init() {
        let linksList = [];
        this.quillComponent.quill.on('text-change', () => {
            let links = this.quillComponent.links();
            links.forEach(value => {
                if (!linksList.includes(value)) {
                    linksList.push(value);
                }
            });

            if (this.quillComponent.attachmentsComponent != undefined) {
                let linksComponents = this.quillComponent.attachmentsComponent.components.filter(value => typeof value.object == "string");
                let linksComponentsToRemove = linksComponents.filter(value => !links.includes(value.object) && linksList.includes(value.object));

                links.forEach(link => {
                    if (this.quillComponent.attachmentsComponent.components.filter(value => value.object == link)[0] == undefined) {
                        this.quillComponent.addAttachment(link);
                    }
                });

                linksComponentsToRemove.forEach(value => {
                    this.quillComponent.attachmentsComponent.removeAttachment(value);
                });
            }
        });
    }
}