@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/effects";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.globalEmojis {
  height: 100%;
  width: 100%;
  user-select: none;

  input {
    &::placeholder {
      color: var(--grey-text);
    }

    &:focus {
      outline: none;
    }
  }

  .searchContainer {
    border-bottom: var(--grey-sur) 1px solid;
    width: calc(100% - 15px);
    padding-left: 15px;
    height: 30px;

    .icon {
      height: 14px;
      width: 14px;
      margin-top: 7px;
      margin-right: 10px;
      float: left;
      filter: var(--icon-grey);

      @include icons.ico("black-search");
    }

    .input {
      font-size: 13px;
      padding-top: 3px;
      width: calc(100% - 40px);
      float: left;
      padding-left: 0;
      color: var(--black);
      height: 24px;
      border: unset;

      @include texts.font("semi-bold");
    }
  }

  .emojisContainer {
    width: 100%;
    height: calc(100% - 31px);
    overflow: hidden;

    .categories {
      width: 32px;
      height: 100%;
      margin-left: 5px;
      margin-top: 5px;
      float: left;
      z-index: 30;
      position: relative;

      .category {
        font-size: 14px;
        height: 30px;
        width: 30px;
        border-radius: 10px;
        cursor: pointer;
        position: relative;

        @include effects.hover-alpha;
        @include positioning.flex-globally;

        &:last-child {
          border: 0 !important;
        }

        .selector {
          display: none;
          background: var(--blue);
          position: absolute;
          right: -4px;
          width: 3px;
          border-radius: 6px;
          height: 80%;
          top: 10%;
        }
      }
    }

    .right {
      height: 100%;
      width: calc(100% - 37px);
      float: left;

      .emojisBoard {
        height: calc(100% - 40px);
        position: relative;

        .emojisCategory {
          padding: 5px 10px;

          @include positioning.clear;

          .emojisCategoryName {
            margin-bottom: 5px;
            font-size: 20px;

            @include texts.font("bold");
          }

          .list {
            margin-left: -5px;
            width: calc(100% + 10px);
            position: relative;

            .emoji {
              cursor: pointer;
              font-size: 20px;
              width: 31px;
              height: 31px;
              border-radius: 8px;
              float: left;

              @include positioning.flex-globally;
              @include effects.hover-alpha;
            }
          }
        }

        .emojis {
          height: 100%;
          width: 100%;

          div[scrolltrack] {
            right: 6px;
            top: 10px !important;
            height: calc(100% - 20px) !important;
          }
        }

        .searchBoard {
          height: 100%;
          width: 100%;
          display: none;
          left: 0;
          top: 0;
          z-index: 14;
          position: absolute;
          background: var(--white);

          .search {
            height: 100%;
            width: 100%;

            div[scrolltrack] {
              right: 6px;
              top: 0 !important;
            }

            .empty {
              max-width: 182px;
              margin: 60px auto auto auto;
              text-align: center;

              @include texts.font("bold");
            }
          }
        }
      }

      .selectedEmoji {
        width: 100%;
        height: 40px;
        z-index: 3;
        position: relative;

        .emoji {
          font-size: 20px;
          margin-right: 10px;
          margin-top: 5px;
          float: right;
        }

        .name {
          margin-top: 10px;
          margin-right: 12px;
          float: right;

          @include texts.font("bold");
        }
      }
    }
  }

  &.pocket {
    .emojisContainer {
      .categories {
        display: none;
      }

      .right {
        padding-left: 5px;
        width: calc(100% - 5px);

        .emojisBoard {
          height: 100%;

          .emojisCategory {
            .emojisCategoryName {
              font-size: 18px !important;
            }

            .list {
              .emoji {
                font-size: 26px !important;
                width: 38px !important;
                height: 38px !important;
              }
            }
          }
        }

        .selectedEmoji {
          display: none;
        }
      }
    }
  }
}