import {
    Component
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import * as s from "./mails-emails-recipients-input.scss";
import {AssignmentPickerTooltipComponent} from "../../../../assignment/picker/tooltip/AssignmentPickerTooltipComponent";
import {AssignmentType} from "../../../../../../../../models/assignment/AssignmentType";
import {IEntityModel} from "../../../../../../../../models/entity/IEntityModel";
import {IMailModel} from "../../../../../../../../models/mail/IMailModel";
import {AssignableType} from "../../../../../../../../models/assignment/AssignableType";
import {IMailsEmailsRecipientSettings} from "../types/IMailsEmailsRecipientSettings";
import {Resources} from "../../../../../../../../resources/Resources";
import {
    txtIsValidMail
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/TxtIsValidMail";

export class MailsEmailsRecipientsInputComponent extends Component {
    public inputSavedValue: string;
    public assignmentPicker: AssignmentPickerTooltipComponent;
    public settings: IMailsEmailsRecipientSettings;

    constructor(settings: IMailsEmailsRecipientSettings) {
        super();
        this.inputSavedValue = "";
        this.settings = settings;
        this.type = "INPUT";
        //language=HTML
        this.template = `
            <input class="${s.componentMailsEmailsRecipientsInput}"/>
        `;
    }

    commit() {
        this.onClick(async () => {
            if (this.assignmentPicker == undefined) {
                await this.renderPicker();
            }
        });

        this.onKeyUp(async () => {
            if (this.getValueTrim() != this.inputSavedValue) {
                this.inputSavedValue = this.getValueTrim();
                await this.renderPicker();
            }
        });

        this.onHotKeys(["Enter", "Space"], async () => {
            if (this.settings.mails && this.assignmentPicker) {
                if (this.assignmentPicker.picker.entitiesCount() == 0) {
                    await this.dispose();
                    this.onPick(this.getValueTrim().toLowerCase());
                    this.setValue("");
                }
            }
        });

        this.onHotKey("Backspace", async () => {
            if (this.getValueTrim().length === 0) {
                this.removeLast();
                await this.dispose();
            }
        });

        super.commit();
    }

    async renderPicker() {
        if (this.getValue().length == 0) {
            await this.dispose();
        }

        if (this.assignmentPicker == undefined) {
            this.assignmentPicker = new AssignmentPickerTooltipComponent(this, {
                showMails: true,
                showType: true,
                accounts: false,
                contacts: true,
                mails: this.settings.mails,
                limitAccounts: 0,
                limitMails: 4,
                limitContacts: 4,
                nameFilter: this.getValueTrim(),
                mailFilter: this.getValueTrim(),
                assignable: {
                    assignments: []
                }, assignmentType: AssignmentType.MAIL
            });

            this.assignmentPicker.picker.onPick = async (object, assignableType) => {
                this.setValue("");
                if (assignableType == AssignableType.MAIL) {
                    this.onPick((object as IMailModel).fullName, object);
                } else {
                    this.onPick((object as IEntityModel).email, object);
                }
                await this.assignmentPicker.dispose();
            };

            this.assignmentPicker.onDispose = () => this.assignmentPicker = undefined;
            this.assignmentPicker.screen(false);
            this.assignmentPicker.excludeOutsideClickComponent(this);
            await this.assignmentPicker.create(true);

            this.assignmentPicker.picker.onReady = async () => {
                this.assignmentPicker.show();
                await this.assignmentPicker.scaleShow();
            };

        } else {
            this.assignmentPicker.picker.renderObjects({
                ...this.assignmentPicker.picker.settings,
                emptyText: txtIsValidMail(this.getValueTrim()) ? Resources.t("words.useThisAddress") + ": " + this.getValueTrim() : Resources.t("words.noResults"),
                nameFilter: this.getValueTrim(),
                mailFilter: this.getValueTrim(),
            });
        }
    }

    /**
     * events
     */

    async dispose() {
        if (this.assignmentPicker !== undefined) {
            await this.assignmentPicker.dispose();
        }
    }

    onPick(mail: string, object?: IEntityModel | IMailModel) {

    }

    removeLast() {

    }
}