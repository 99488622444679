import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./media.scss";

export abstract class MediaAbstractComponent extends Component {
    public contentObject: string | File | any;
    public path: string;

    public errorContainer: Component;
    public stickToHeight: boolean;

    commit() {
        this.addClass(s.componentMedia);
        super.commit();
    }

    setError(text: string) {
        if (this.errorContainer) {
            this.errorContainer.remove();
            this.errorContainer = undefined;
        }

        if (text) {
            //language=HTML
            this.errorContainer = this.append(`
                <div class="${s.error}">
                    <div class="${s.icon}"></div>
                    ${text}
                </div>
            `);
        }
    }

    stickHeight() {
        this.stickToHeight = true;
    }

    resize(mediaWidth: number, mediaHeight: number, element: Component) {
        if (mediaWidth < mediaHeight) {
            element.setStyle(`width:100%;height:unset;`);
        } else {
            element.setStyle(`width:unset;height:100%;`);
        }

        if (this.getWidth() > element.getWidth()) {
            element.setStyle(`width:100%;height:unset;`);
        }

        if (this.getHeight() > element.getHeight()) {
            element.setStyle(`width:unset;height:100%;`);
        }


        if (this.stickToHeight) {
            if (element.getHeight() > this.getHeight()) {
                element.setStyle(`width:unset;height:100%;`);
            }

            if (element.getWidth() > this.getWidth()) {
                element.setStyle(`width:100%;height:unset;`);
            }
        }

    }

}