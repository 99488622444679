@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/alt";

.globalNetworkError {
  height: 100%;
  width: 100%;

  @include positioning.flex-globally;

  .warning {
    height: 24px;
    width: 24px;
    filter: var(--icon-red);

    @include icons.ico("black-warning");
  }
}