import * as s from "./media-link.scss";
import {MediaAbstractComponent} from "../MediaAbstractComponent";
import {IMediaLink} from "./types/IMediaLink";
import {MediaImageComponent} from "../image/MediaImageComponent";
import {NetworkErrorComponent} from "../../network/NetworkErrorComponent";
import {Resources} from "../../../../resources/Resources";

export class MediaLinkComponent extends MediaAbstractComponent {
    constructor(object: IMediaLink) {
        super();
        this.contentObject = object;

        if (this.contentObject.ping) {
            //language=HTML
            this.template = `
                <div media mediaLink class="${s.globalMediaLink}">
                    ${object.fileUrl ? this.draw(new MediaImageComponent(object.fileUrl, false)) : ``}
                    <div class="${s.text}">
                        <div class="${s.title}">
                            ${object.title ?? Resources.t("words.noTitle")}
                        </div>
                        <div class="${s.website}">
                            <div class="${s.host}">${object.host}</div>
                            <!-- FUNC <div class="icon"></div>-->
                        </div>
                    </div>
                </div>
            `;
        } else {
            //language=HTML
            this.template = `
                <div media mediaLink class="${s.globalMediaLink}">
                    ${this.draw(new NetworkErrorComponent())}
                </div>
            `;
        }
    }
}