import {EditorButton} from "./EditorButton";
import {EditorComponent} from "../EditorComponent";
import * as s from "../editor.scss";
import {PreferencesService} from "../../../../../../services/preferences/PreferencesService";
import {
    QuillSettingsTooltipComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/editor/settings/tooltip/QuillSettingsTooltipComponent";
import {Resources} from "../../../../../../resources/Resources";

export class EditorButtonSettings extends EditorButton {
    constructor(editor: EditorComponent) {
        super(editor);
    }

    init() {
        //language=HTML
        this.button = this.editor.rightButtonsContainer.append(`
            <div data-tooltip="${Resources.t('words.preferencesButtonTooltip')}" button class="${s.button}">
                <div class="${s.icon} ${s.settings}"></div>
            </div>
        `);

        this.initPanel(this.button, () => {
            let tooltip = new QuillSettingsTooltipComponent(this.button, {
                lines: PreferencesService.editorLineMode,
                spellChecker: PreferencesService.editorSpellChecker
            }, this.editor.quillComponent);

            tooltip.settingsComponent.onSpellCheckerPick = value => {
                this.editor.quillComponent.setSpellChecker(value);
                PreferencesService.editorSpellChecker = value;
            }

            tooltip.settingsComponent.onLineModePick = value => {
                PreferencesService.editorLineMode = value;
                this.editor.quillComponent.setLineBreak(value);
            }

            return tooltip;
        });
    }
}