import {
    Component
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    LoaderLightComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import * as s from "./mails-emails-picker.scss";
import {
    VisualSelectBoxComponent
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/selectbox/VisualSelectBoxComponent";
import {MailService} from "../../../../../../../services/mail/MailService";
import {
    ISelectBoxEntry
} from "../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/selectbox/types/ISelectBoxEntry";
import {IMailsEmailsPicker} from "./types/IMailsEmailsPicker";
import {Resources} from "../../../../../../../resources/Resources";

export class MailsEmailsPickerComponent extends Component {

    public selectBox: VisualSelectBoxComponent;
    public settings: IMailsEmailsPicker;
    public ready: boolean;
    public readyFunc: () => void;

    constructor(settings: IMailsEmailsPicker) {
        super();
        this.settings = settings;
        //language=HTML
        this.template = `
            <div class="${s.componentEmailsPicker}">
                ${this.draw(new LoaderLightComponent())}
            </div>
        `;
    }

    commit() {
        this.init();
        super.commit();
    }

    async init() {
        let mails = this.settings.siteId ?
            await MailService.findBySite(this.settings.siteId) :
            await MailService.findByServer(this.settings.serverId);
        this.clearAll();

        let entries: ISelectBoxEntry[] = [];
        mails.forEach(mail => {
            entries.push({
                label: mail.fullName,
                value: mail.id
            });
        });

        if (this.settings.forceLabel && entries.filter(value => value.value == this.settings.preSelectedId).length == 0) {
            entries.push({
                label: this.settings.forceLabel,
                value: this.settings.preSelectedId
            });
        }
        this.selectBox = new VisualSelectBoxComponent({
            entries: entries,
            selectedValue: this.settings.preSelectedId,
            selectedValueNullable: true,
            noneText: Resources.t('words.selectAddress'),
            search: true
        });
        this.render(this.selectBox);

        this.selectBox.onSelect = entry => this.onPick(entry.value as string);

        this.ready = true;
        if (this.readyFunc) {
            this.readyFunc();
        }
    }

    runAsReady(func: () => void) {
        if (this.ready) {
            func();
        } else {
            this.readyFunc = func;
        }
    }

    selectedMailId(): string {
        return this.selectBox.getSelection().value as string;
    }

    classLight() {
        this.addClass(s.light);
    }

    onPick(mailId: string) {

    }
}