import {
    VisualClickTooltipWhiteComponent
} from "../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import {DateScheduleComponent} from "../DateScheduleComponent";

export class DateScheduleTooltipComponent extends VisualClickTooltipWhiteComponent {

    public picker: DateScheduleComponent;

    constructor(component: Component, date?: Date) {
        super(component, 50, 150);
        this.picker = new DateScheduleComponent(date);
        this.template = this.draw(this.picker);
    }
}