import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./keyboard.scss";
import {isScrollVisible} from "../../sedestral-interface-component/interface/component/funcs/IsScrollVisible";

export class VisualKeyListComponent extends Component {

    public listContainer: Component;
    public selectable: Component[];
    public selected: Component;
    public index: number;
    public whenFocus: boolean;
    public alwaysFocus: boolean;
    public onScrollAnimation: boolean;
    public onScrollTimeout: any;

    constructor() {
        super();
        this.selectable = [];
        this.whenFocus = true;
        this.alwaysFocus = false;
        this.selected = undefined;

        this.template = `
            <div class="${s.visualKeyList}"></div>
        `;
    }

    commit(): void {
        this.commitSelectable();
        super.commit();
    }

    commitSelectable() {
        this.clearListeners();

        this.selected = undefined;
        this.index = 0;

        this.onClick(() => {
            this.whenFocus = true;
        });
        this.onOutsideClick(() => {
            this.whenFocus = false;
        });
        if (this.selectable.length > 0) {
            let index = 0;
            for (let component of this.selectable) {
                component.onClick(() => this.select(this.selectable.indexOf(component), true));
                component.setAttribute("index", "" + index);
                component.onHover(() => {
                    if (!this.onScrollAnimation) {
                        this.select(parseInt(component.getAttribute("index")))
                    }
                });

                index++;
            }

            this.select(0);

            this.putListener(window, "keydown", (e) => {
                if (this.whenFocus || this.alwaysFocus) {
                    if (e.code == "ArrowDown") {
                        this.select(this.index + 1);
                        e.preventDefault();
                    } else if (e.code == "ArrowUp") {
                        this.select(this.index - 1);
                        e.preventDefault();
                    } else if (e.code == "Enter" || e.code == "NumpadEnter") {
                        this.select(this.index, true);
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
            })
        }
    }

    select(index: number, validate?: boolean) {
        if (index >= this.selectable.length) {
            index = 0;
        } else if (index < 0) {
            index = this.selectable.length - 1;
        }


        this.index = index;
        if (this.selected != undefined) {
            this.selected.removeAttribute("data-selected");
        }


        let component = this.selectable[index];
        this.selected = component;
        this.selected.setAttribute("data-selected", "true");


        if (this.listContainer) {
            if (!isScrollVisible(component, this.listContainer)) {
                component.scrollIntoView(this.listContainer, undefined, "auto");
                this.onScrollAnimation = true;
                clearTimeout(this.onScrollTimeout);
                this.onScrollTimeout = this.timeOut(() => this.onScrollAnimation = false, 50);
            }
        }

        this.onSelect(component);
        if (validate) {
            this.onValidateSelect(component);
        }

    }

    onSelect(component: Component) {

    }

    onValidateSelect(component: Component) {

    }
}