@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentAccount {
  width: 100%;

  .topSide {
    position: relative;
    @include positioning.flex-globally;

    .avatarContainer {
      height: 60px;
      width: 60px;
      position: relative;
    }
  }

  .accountName {
    font-size: 22px;
    margin-top: 8px;
    text-align: center;
    position: relative;

    @include texts.font("bold");
  }

  .accountMail {
    font-size: 13px;
    text-align: center;
    position: relative;
    color: var(--grey-text);

    @include texts.font("medium");
  }

  .rolesContainer {
    border-top: var(--grey-sur) 1px solid;
    width: 100%;
    margin-top: 20px;
    padding: 15px 15px 0 15px;
    margin-left: -15px;
    position: relative;
  }

  .avatarContainer {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    margin-top: 4px;
    position: relative;
    float: left;

    @include positioning.flex-globally;
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 20px;
  }
}