import {QuillLogic} from "./QuillLogic";
import {QuillComponent} from "../QuillComponent";

export class QuillLogicLimit extends QuillLogic {
    constructor(quillComponent: QuillComponent) {
        super(quillComponent);
    }

    init() {
        this.quillComponent.quill.on('text-change', () => {
            if (this.quillComponent.settings.limits && this.quillComponent.settings.limits.length > 0) {
                if (this.quillComponent.quill.getLength() > this.quillComponent.limit()) {
                    this.quillComponent.quill.deleteText(this.quillComponent.limit(), this.quillComponent.quill.getLength());
                }
            }
        });
    }
}