import {QuillFormattingBaseLogic} from "../QuillFormattingBaseLogic";
import {QuillFormattingLinkComponent} from "../../buttons/element/link/QuillFormattingLinkComponent";
import {QuillFormattingComponent} from "../../QuillFormattingComponent";

export class QuillFormattingLinkLogic extends QuillFormattingBaseLogic {

    constructor(formatter: QuillFormattingComponent) {
        super(formatter);
    }

    init(): void {
        this.formatter.quillComponent.el("ql-editor").onClick((e) => {
            if (this.formatter.quillComponent.isHtmlBlot()) {
                return;
            }

            let element = e.target;
            if (e.target.tagName == "A" || e.target.parentElement.tagName == "A") {

                let selection = this.formatter.quillComponent.quill.getSelection();
                let bounds = this.formatter.quillComponent.quill.getBounds(selection.index, selection.length);
                let offsets = this.formatter.getOffsets();
                let link = element.getAttribute("href");

                // Adjust the position based on the scroll position of the editor
                let editor = this.formatter.quillComponent.quill.root;
                let scrollTop = editor.scrollTop;
                let scrollLeft = editor.scrollLeft;

                offsets.top += (element.offsetHeight - 20) + 3 - scrollTop;
                offsets.left += bounds.left - scrollLeft;

                let tooltip = new QuillFormattingLinkComponent(this.formatter, bounds, offsets, undefined, link);
                tooltip.screen(false).animation(false);
                tooltip.create();

                tooltip.onPick = ((text, link) => {
                    element.setAttribute("href", link);
                    tooltip.dispose();
                });

                tooltip.onDelete = () => {
                    this.formatter.quillComponent.quill.setSelection({
                        index: this.formatter.quillComponent.getBlotIndex(element),
                        length: element.innerText.length
                    });

                    this.formatter.quillComponent.quill.format("link", false, "user");
                    tooltip.dispose();
                }
            }
        });
    }
}