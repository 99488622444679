@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/animations";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/outline";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.staticNotificationsNotifs {
  z-index: 999999999;
  width: 580px;
  left: calc(50% - 290px);
  position: absolute;
  top: 0;

  .notification {
    @include boxs.sur;

    overflow: hidden;
    width: calc(100% - 24px);
    padding: 8px;
    border-radius: 10px;
    transform: translateY(-20px) scale(0.9);
    margin-bottom: 10px;

    .notificationHead {
      position: relative;
      margin-bottom: 5px;
      display: flex;

      @include positioning.clear;

      .icon {
        height: 18px;
        width: 18px;
        flex: 0 0 18px;
        margin-top: 1px;
        margin-right: 6px;
        float: left;

        &.error {
          filter: var(--icon-red);

          @include icons.ico("black-circle-close");
        }


        &.success {
          filter: var(--icon-green);

          @include icons.ico("black-circle-check");
        }
      }

      .title {
        font-size: 16px;
        margin-top: 1px;
        z-index: 2;
        float: left;
        position: relative;

        @include texts.font("bold");
      }
    }

    .description {
      margin-bottom: 7px;
      top: -4px;
      font-size: 13px;
      z-index: 2;
      color: var(--grey-thumb);
      position: relative;
    }

    .notificationButtons {
      position: relative;

      @include positioning.clear;

      .confirm {
        @include buttons.grey;
        @include positioning.flex-globally;
        @include outline.blue-hover(3);
        @include texts.font("bold");

        float: right;
        position: relative;
        z-index: 3;
        width: 105px;
        border-radius: 20px;
        height: 26px;
        font-size: 12px;

        .progress {
          @include animations.spinner-stopwatch;

          width: 8px;
          top: 6px;
          margin-left: 9px;
          z-index: 2;
          height: 8px;
          border-radius: 100%;
          border: 3px solid #d8d8d8;
          float: left;
          position: relative;

          .circle {
            @include animations.spinner-stopwatch-circle;

            left: -3px;
            top: -3px;
            border: 3px solid #000;
          }
        }
      }
    }

  }

  @media screen and (max-width: 480px) {
    left: 0 !important;
    width: 100% !important;

    .notification {
      width: calc(100% - 40px) !important;
      margin-left: 12px !important;
    }
  }
}
