@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentEmailsPicker {
  height: 100%;
  width: 100%;
  border-radius: 7px;

  svg {
    width: 26px !important;
    height: 26px !important;
  }

  &.light {
    div[data-selectbox] {
      border-color: transparent;
      margin-left: -3px;
      height: 21px;
    }
  }
}

:global {
  .componentEmailsPickerUnverified {
    float: right;
    background: var(--orange-very-light);
    padding: 3px;
    font-size: 10px;
    border-radius: 12px;
    margin-left: 10px;
    color: var(--orange);

    @include texts.font("medium");
  }
}