import {IAccountTwoFactorMethod} from "../../../../../models/account/twofactor/IAccountTwoFactorMethod";
import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    PromptCodeComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/prompt/code/PromptCodeComponent";
import {
    ButtonsSaveComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/buttons/save/ButtonsSaveComponent";
import {Resources} from "../../../../../resources/Resources";
import {NetworkHeaders} from "../../../../../network/types/NetworkHeaders";
import {Network} from "../../../../../network/Network";
import {HttpStatus} from "../../../../../network/status/HttpStatus";
import * as s from "./two-factor.scss";

export class TwoFactorComponent extends Component {

    public response: any;
    public promptComponent: PromptCodeComponent;
    public buttonsComponent: ButtonsSaveComponent;
    public method: IAccountTwoFactorMethod;

    constructor(email: string, response: any) {
        super();

        this.response = response;
        this.promptComponent = new PromptCodeComponent(6);
        this.buttonsComponent = new ButtonsSaveComponent({
            saveText: Resources.t("words.validate"),
            hideCancelButton: true
        });

        this.method = +parseInt(this.response.response.headers[NetworkHeaders.TF_TRANSACTION_METHOD_HEADER]) as IAccountTwoFactorMethod;

        //language=HTML
        this.template = `
            <div class="${s.componentTwoFactorMail}">
                <div class="${s.drawing}"></div>
                <div class="${s.text}">
                    ${this.method === IAccountTwoFactorMethod.EMAIL ? `${Resources.t("words.enterVerificationCode")} <span>${this.reactive(() => email)}</span>` : Resources.t("words.enterVerificationCodeTotp")}
                </div>
                ${this.method === IAccountTwoFactorMethod.EMAIL ? `<div class="${s.smallText}">${Resources.t("words.checkSpamEmail")}</div>` : ''}
                <div class="${s.codeContainer}">
                    ${this.draw(this.promptComponent)}
                </div>
                <div class="${s.buttonsContainer}">
                    ${this.method === IAccountTwoFactorMethod.EMAIL ? `<div class="${s.retry}">${Resources.t("words.didNotReceiveEmail")}</div>` : ''}
                    ${this.draw(this.buttonsComponent)}
                </div>
            </div>
        `;
    }

    commit() {

        let launch = async () => {
            this.buttonsComponent.setLoadingAndInactive();

            let headers = {};
            headers[NetworkHeaders.TF_TRANSACTION_ID_HEADER] = this.response.response.headers[NetworkHeaders.TF_TRANSACTION_ID_HEADER];
            headers[NetworkHeaders.TF_TRANSACTION_METHOD_HEADER] = this.response.response.headers[NetworkHeaders.TF_TRANSACTION_METHOD_HEADER];
            headers[NetworkHeaders.TF_TRANSACTION_CODE_HEADER] = this.promptComponent.getValue();

            let request = await Network.retry(this.response, this, {headers: headers});
            if (request.status == HttpStatus.OK) {
                this.onVerify();
            }

            this.buttonsComponent.remLoadingAndInactive();
        }

        this.promptComponent.onFinish = launch;
        this.buttonsComponent.onSave = launch;

        if (this.method == IAccountTwoFactorMethod.EMAIL) {
            this.el(s.retry).onClick(() => this.onRetry());
        }

        super.commit();
    }

    onVerify() {

    }

    onRetry() {

    }

}