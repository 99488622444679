import * as s from "./media-file.scss";
import {MediaAbstractComponent} from "../MediaAbstractComponent";
import {IMedia} from "../types/IMedia";
import {Models} from "../../../../models/Models";
import {fileUrlExtension} from "../../../sedestral-interface-component/utilities/FileUrlExtension";

export class MediaFileComponent extends MediaAbstractComponent {
    constructor(media: File | IMedia | any) {
        super();
        this.contentObject = media;
        let type = Models.isMediaModel(media) ? media.extension : fileUrlExtension(media.name);
        this.path = Models.isMediaModel(media) ? media.link : media;

        this.template = `
            <div media mediaFile class="${s.globalMediaFile}">
                <div class="${s.image}">
                    <div class="${s.icon}"></div>
                </div>
                <div class="${s.names}">
                    <div class="${s.fileType}">${type}</div>
                    <div class="${s.fileName}">${media.name}</div>
                </div>
            </div>
        `;
    }
}