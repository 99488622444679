@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.globalQuillGoodies {
  height: 100%;
  width: 100%;

  .goodiesCategories {
    height: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    width: calc(100% - 20px);
    position: relative;

    .goodiesCategory {
      width: calc(50% - 8px);
      font-size: 10px;
      border-radius: 20px;
      height: 24px;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      @include positioning.flex-globally;
      @include texts.font("black");

      &.EmojisButton {
        visibility: visible;
      }

      &.GifButton {
        visibility: visible;
      }

      div[visualSelector] {
        height: 100%;
        width: 100%;
        left: 0;
        z-index: -1;
      }

      &:hover {
        border-color: transparent !important;
        background: var(--grey-sur);
      }

      &.categoryLeft {
        float: left;
      }

      &.categoryRight {
        float: right;
      }
    }
  }

  .goodiesContainer {
    height: calc(100% - 30px);
    overflow: hidden;
    width: 100%;
    position: relative;
  }

  &.pocket {
    .goodiesCategories {
      width: calc(100% - 64px) !important;

      .goodiesCategory {
        border: 0 !important;
        height: 26px !important;
      }
    }
  }
}