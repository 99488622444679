import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {AccountComponent} from "../AccountComponent";
import {
    VisualHoverTooltipWhiteComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/tooltip/VisualHoverTooltipWhiteComponent";
import {
    LoaderLightComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/light/LoaderLightComponent";
import {AccountService} from "../../../../../../services/account/AccountService";
import {
    EmptyBasicComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/empty/EmptyBasicComponent";
import {Resources} from "../../../../../../resources/Resources";

export class AccountTooltipComponent extends VisualHoverTooltipWhiteComponent {
    private accountId: string;

    constructor(component: Component, accountId: string) {
        super(component, 120, 140);
        this.mobileStyle(true);

        this.accountId = accountId;
    }

    commit() {
        super.commit();
        this.render(new LoaderLightComponent());
        this.init();
        this.swapable(() => this.dispose());
    }

    async init() {
        this.clear();
        let account = await AccountService.findById(this.accountId);
        if (account == undefined) {
            this.render(new EmptyBasicComponent("👽", Resources.t("words.account.errorAccess")));
        } else {
            this.render(new AccountComponent(account));
        }
    }
}