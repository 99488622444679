import {Network} from "../../network/Network";
import {SedestralMachine} from "../sedestral-interface-component/machine/SedestralMachine";

export class WebAudioRecorder {

    private options: any;
    private timeInterval: any;
    private startTime: number;

    private context: BaseAudioContext;
    private input: GainNode;
    private analyser: AnalyserNode;
    private processor: ScriptProcessorNode;
    private worker: Worker;
    private stream: MediaStream;

    constructor() {
        this.options = {
            timeLimit: 120,
            encodeAfterRecord: true,
            progressInterval: 1000,
            bufferSize: undefined,
            mp3: {
                mimeType: "audio/mpeg",
                bitRate: 160
            }
        };
        this.timeInterval = undefined;
    }

    private _finalTime: number;

    /**
     * getter and setter
     */


    get finalTime(): number {
        return this._finalTime;
    }

    set finalTime(value: number) {
        this._finalTime = value;
    }

    /**
     * initialisations
     */
    init(): Promise<boolean> {
        return SedestralMachine.promise((resolve) => {
            navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((e) => {
                let audioContext = new window.AudioContext();
                let input = audioContext.createMediaStreamSource(e);

                this.stream = e;
                this.context = input.context;
                this.input = this.context.createGain();
                this.analyser = audioContext.createAnalyser();

                input.connect(this.input);
                input.connect(this.analyser);
                this.initWorker();
                resolve(true);
            }).catch((e) => {
                resolve(false);
            });
        });
    }

    initWorker(): void {
        if (this.worker != undefined) {
            this.worker.terminate();
        }

        this.onEncoderLoading(this);
        this.worker = new Worker(Network.vendor + "/mp3/WebAudioRecorderMp3.min.js");

        this.worker.onmessage = (event) => {
            let data = event.data;
            switch (data.command) {
                case "loaded":
                    this.onEncoderLoaded(this);
                    break;
                case "timeout":
                    this.onTimeout(this);
                    break;
                case "progress":
                    this.onEncodingProgress(this, data.progress);
                    break;
                case "complete":
                    this.onComplete(this, data.blob);
                    break;
                case "error":
                    this.onError(data.message);
            }
        };
        this.worker.postMessage({
            command: "init",
            config: {
                sampleRate: this.context.sampleRate,
                numChannels: 2
            },
            options: this.options
        });
    }

    /**
     * recording manage
     */
    startRecording(): void {
        this.timeInterval = setInterval(() => {
            this.onTimeChange(this.recordingTime());
        }, 1000);

        this._finalTime = 0;
        let channels = 2;
        let buffer = [];
        this.processor = this.context.createScriptProcessor(undefined, channels, channels);
        this.input.connect(this.processor);
        this.processor.connect(this.context.destination);
        this.processor.onaudioprocess = (event) => {
            let fbc_array = new Uint8Array(this.analyser.frequencyBinCount);
            this.analyser.getByteFrequencyData(fbc_array);
            this.onFrequency(fbc_array);

            for (let ch = 0; ch < channels; ++ch)
                buffer[ch] = event.inputBuffer.getChannelData(ch);

            this.worker.postMessage({command: "record", buffer: buffer});
        };
        this.worker.postMessage({
            command: "start",
            bufferSize: this.processor.bufferSize
        });
        this.startTime = Date.now();
    }

    finishRecording(): void {
        if (this.isRecording()) {
            clearInterval(this.timeInterval);
            this._finalTime = this.recordingTime();
            this.input.disconnect();
            this.processor.disconnect();
            delete this.processor;
            this.worker.postMessage({command: "finish"});

            if (this.stream != undefined) {
                this.stream.getAudioTracks()[0].stop();
                this.stream = undefined;
            }
        } else {
            this.onError("finishRecording: no recording is running");
        }
    }

    /**
     * recording infos
     */
    isRecording(): boolean {
        return this.processor != undefined;
    }

    recordingTime(): number {
        let time = Math.round(this.isRecording() ? (Date.now() - this.startTime) * 0.001 : undefined);
        let text = time;
        if (time > 120) {
            text = Math.round(time / 60);
        }
        return text;
    }

    /**
     * encoding
     */
    cancelEncoding(): void {
        if (!this.isRecording()) {
            this.onEncodingCanceled(this);
            if (this.worker != undefined) {
                this.worker.terminate();
            }
        }
    }

    /**
     * functions to override
     */

    onTimeChange(time): void {

    }

    onFrequency(frq): void {

    }

    onError(message): void {
        console.log(message);
    }

    onEncoderLoading(recorder): void {

    }

    onEncoderLoaded(recorder): void {

    }

    onEncodingProgress(recorder, progress): void {
    }

    onEncodingCanceled(recorder): void {

    }

    onTimeout(recorder): void {
        recorder.finishRecording();
    }

    onComplete(recorder, blob): void {

    }
}




