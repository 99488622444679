import {VisualSwapComponent} from "../../../../../sedestral-interface-component-visual/swap/VisualSwapComponent";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import {QuillGoodiesComponent} from "../QuillGoodiesComponent";
import * as s from "./quill-goodies-swap.scss";

export class QuillGoodiesSwapComponent extends VisualSwapComponent {
    public goodies: QuillGoodiesComponent;

    constructor(emojis: boolean, gifs: boolean, name?: string, component?: Component, height?: string) {
        super(name, component, "70%");
        this.goodies = new QuillGoodiesComponent(emojis, gifs);
        this.template = this.draw(this.goodies);
    }

    commit() {
        this.addClass(s.componentQuillGoodiesSwap);
        super.commit();
    }
}