import {
    StaticComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-router/static/component/StaticComponent";
import * as s from "./composer.scss";
import {ConversationCreatorComponent} from "../../components/conversation/creator/ConversationCreatorComponent";
import {IComposer} from "./types/IComposer";
import {ComposerType} from "./types/ComposerType";
import {
    ComposerTabComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/composer/tab/ComposerTabComponent";
import {EntityService} from "../../../../../services/entity/EntityService";
import {IAccountModel} from "../../../../../models/account/IAccountModel";
import {Network} from "../../../../../network/Network";
import {
    EmptyBasicComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/empty/EmptyBasicComponent";
import {Resources} from "../../../../../resources/Resources";
import {AccountComposerService} from "../../../../../services/account/composers/AccountComposerService";

export class ComposerStatic extends StaticComponent {

    public tabs: ComposerTabComponent[];

    constructor() {
        super();
        this.virtual(s.staticComposer);
        this.tabs = [];

        this.renderTabs();
    }

    /**
     * render
     */
    renderTabs() {
        if (EntityService.activeEntity && (EntityService.activeEntity as IAccountModel).composers) {
            let composerTabs = (EntityService.activeEntity as IAccountModel).composers;
            composerTabs.forEach(value => this.renderTab(value));
        }
    }


    renderTab(composer: IComposer, show?: boolean) {
        let tab = new ComposerTabComponent(composer);
        this.render(tab);

        tab.classCollapsed();
        tab.onClick(() => {
            if (tab.isCollapsed()) {
                this.showTab(tab);
            }
        });

        if (show) {
            this.showTab(tab);
        }

        tab.reduceButton.onClick(() => this.reduceTab(tab), true);
        tab.closeButton.onClick(() => {
            this.removeTab(tab);
        });

        this.tabs.push(tab);
    }

    /**
     * show
     */

    showTab(tab: ComposerTabComponent) {
        tab.classUnCollapsed();

        tab.setStyle(`height: 600px;width: 615px;`);
        switch (tab.composer.type) {
            case ComposerType.CONVERSATION_MESSAGE:
                let conversationCreatorComponent = new ConversationCreatorComponent(tab.composer.contentId);
                conversationCreatorComponent.onCreate = () => this.removeTab(tab);

                tab.contentContainer.render(conversationCreatorComponent);
                break;

            case ComposerType.EMPTY:
                tab.contentContainer.render(new EmptyBasicComponent(`<div class="${s.emptyIcon}"></div>`, tab.composer.contentId));
                break;
        }
    }

    /**
     * hide
     */

    reduceTab(tab: ComposerTabComponent) {
        tab.contentContainer.clearAll();
        tab.classCollapsed();
        tab.setStyle(`height: unset;width: unset;`);
    }

    removeTab(tab: ComposerTabComponent) {
        if (tab.composer.id) {
            AccountComposerService.delete(tab.composer.id, this);
        }

        tab.remove();
        this.tabs.splice(this.tabs.indexOf(tab), 1);
    }

    /**
     * can
     */

    canTab() {
        let canTab = this.tabs.length < 4;
        if (!canTab) {
            Network.router.static.components.notifications.notify(Resources.t("words.maxTabsError"), undefined, 3000)
        }
        return canTab;
    }

}