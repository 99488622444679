import * as s from "../../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../../QuillFormattingComponent";
import {QuillFormattingButtonEmbedAlignComponent} from "./QuillFormattingButtonEmbedAlignComponent";

export class QuillFormattingButtonEmbedAlignCenterComponent extends QuillFormattingButtonEmbedAlignComponent {
    constructor(formatter: QuillFormattingComponent) {
        super("center", formatter);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton}">
                <div class="${s.icon} ${s.embedAlignCenter}"></div>
            </button>
        `;
    }
}