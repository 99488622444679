import {
    VisualClickTooltipWhiteComponent
} from "../../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import {QuillSettingsComponent} from "../QuillSettingsComponent";
import {QuillComponent} from "../../../QuillComponent";
import {IQuillSettings} from "../types/IQuillSettings";

export class QuillSettingsTooltipComponent extends VisualClickTooltipWhiteComponent {
    public settingsComponent: QuillSettingsComponent;

    constructor(component: Component, settings: IQuillSettings, quillComponent: QuillComponent) {
        super(component, 140, 280);
        this.settingsComponent = new QuillSettingsComponent(settings, quillComponent);
        //language=HTML
        this.template = `
            <div>
                ${this.draw(this.settingsComponent)}
            </div>
        `;
    }

    commit() {
        this.setStyle(`padding:0;`);
        super.commit();
    }
}