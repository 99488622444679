import {
    VisualClickTooltipWhiteComponent
} from "../../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import {IQuillIntegration} from "../types/IQuillIntegration";
import {QuillIntegrationsComponent} from "../QuillIntegrationsComponent";

export class QuillIntegrationsTooltipComponent extends VisualClickTooltipWhiteComponent {
    public integrations: IQuillIntegration[];
    public integrationsComponent: QuillIntegrationsComponent;

    constructor(component: Component, integrations: IQuillIntegration[]) {
        super(component, 0, 200);
        this.integrations = integrations;
    }

    commit() {
        super.commit();
        this.integrationsComponent = new QuillIntegrationsComponent(this.integrations);
        this.render(this.integrationsComponent);
        this.setStyle(`padding:10px 0px;`);
    }
}