@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.globalColorsPickerGrid {
  height: 100%;
  width: calc(100% + 15px);

  div[scrolltrack] {
    height: calc(100% - 20px) !important;
    top: 9px !important;
    right: 8px;
    display: block;
  }

  .color {
    height: 20px;
    width: 20px;
    border-radius: 6px;
    margin-right: 4px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
    float: left;

    @include positioning.flex-globally;

    &:hover {
      opacity: 0.7;
    }

    .checked {
      height: 12px;
      width: 12px;
      filter: var(--icon-white);

      @include icons.ico("black-checked");
    }
  }
}