import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {CapsuleComponent} from "../capsule/CapsuleComponent";
import {ProductType} from "../../../../../models/product/ProductType";
import * as s from "./notification.scss";
import {INotificationModel} from "../../../../../models/notification/INotificationModel";
import {
    TimerShortComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/timers/types/TimerShortComponent";
import {NotificationType} from "../../../../../models/notification/NotificationType";
import {AvatarComponent} from "../avatar/AvatarComponent";
import {InboxConversationService} from "../../../../../services/inbox/conversation/InboxConversationService";
import {Network} from "../../../../../network/Network";
import {Resources} from "../../../../../resources/Resources";
import {filterXSS} from "../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/FilterXSS";

export class NotificationComponent extends Component {
    private notification: INotificationModel;

    private dateContainer: Component;
    private capsuleContainer: Component;
    private textContainer: Component;

    constructor(notification: INotificationModel) {
        super();
        this.notification = notification;

        //language=HTML
        this.template = `
            <div class="${s.componentNotification}">
                <div class="${s.capsuleContainer}"></div>
                <div class="${s.content}">
                    <div class="${s.text}"></div>
                    <div class="${s.others}">
                        <div class="${s.date}"></div>
                    </div>
                </div>
            </div>
        `;
    }

    commit() {
        this.dateContainer = this.el(s.date);
        this.capsuleContainer = this.el(s.capsuleContainer);
        this.textContainer = this.el(s.text);

        this.dateContainer.render(new TimerShortComponent(new Date(this.notification.time)));
        let redirection;
        switch (this.notification.type) {
            case NotificationType.CONVERSATION_PARTICIPANT:
                this.capsuleContainer.render(new AvatarComponent(this.notification.conversation.owner.participant.avatar).setActive(this.notification.conversation.owner.participant));
                //language=HTML
                this.capsuleContainer.append(`
                    <div class="${s.littleCapsule}">
                        ${this.capsuleContainer.draw(new CapsuleComponent(ProductType.INBOX))}
                    </div>
                `);
                this.setText(`${Resources.t("words.notJoinedConversation")}  <strong>${this.notification.conversation.owner.participant.name}</strong>.`);
                redirection = `/inbox/c/${this.notification.conversation.id}`;
                break;
            case NotificationType.CONVERSATION:
                this.capsuleContainer.render(new AvatarComponent(this.notification.conversation.owner.participant.avatar).setActive(this.notification.conversation.owner.participant));
                //language=HTML
                this.capsuleContainer.append(`
                    <div class="${s.littleCapsule}">
                        ${this.capsuleContainer.draw(new CapsuleComponent(ProductType.INBOX))}
                    </div>
                `);
                this.setText(`${Resources.t("words.conversationPending")} <strong>${this.notification.conversation.owner.participant.name}</strong>.`);
                redirection = `/inbox/c/${this.notification.conversation.id}`;
                break;
            case NotificationType.MESSAGE:
                this.capsuleContainer.render(new AvatarComponent(this.notification.conversation.owner.participant.avatar).setActive(this.notification.conversation.owner.participant));
                //language=HTML
                this.capsuleContainer.append(`
                    <div class="${s.littleCapsule}">
                        ${this.capsuleContainer.draw(new CapsuleComponent(ProductType.INBOX))}
                    </div>
                `);
                let participant = InboxConversationService.getMyParticipant(this.notification.conversation);
                this.setText(`${Resources.t("words.unreadMessages")} <strong>${participant.unreadCount} </strong> ${Resources.t("words.inConversation")} <strong>${this.notification.conversation.owner.participant.name}</strong>.`);
                redirection = `/inbox/c/${this.notification.conversation.id}`;
                break;
        }

        if (redirection != undefined) {
            this.onClick(() => Network.router.pages.redirect(redirection), false, true);
        }

        super.commit();
    }


    setText(text: string) {
        this.textContainer.setHtml(filterXSS(text));
    }

    setNew() {
        this.addClass(s.new);
    }

    removeNew() {
        this.removeClass(s.new);
    }
}