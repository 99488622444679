import {QuillBlotLogic} from "../QuillBlotLogic";
import {QuillBlot} from "../../QuillBlot";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import {LoaderLightComponent} from "../../../../loader/light/LoaderLightComponent";
import {NetworkErrorComponent} from "../../../../network/NetworkErrorComponent";
import {LoaderPaintComponent} from "../../../../loader/paint/LoaderPaintComponent";

export class QuillBlotLoaderLogic extends QuillBlotLogic {

    public loader: Component;
    public error: Component;

    constructor(blot: QuillBlot) {
        super(blot);
    }

    /**
     * cursor base
     */

    renderLoaderPaint() {
        if (!this.loader) {
            //language=HTML
            this.loader = this.blot.quillComponent.append(`
                <div style="border-radius:10px;position: absolute;display:none;background:var(--grey);border:unset;pointer-events:none;padding:2px;z-index: 4;"></div>`);

            this.loader.render(new LoaderPaintComponent());
            this.place();
        }
    }

    renderLoader() {
        if (!this.loader) {
            //language=HTML
            this.loader = this.blot.quillComponent.append(`
                <div style="position: absolute;display:none;background:var(--grey);border:unset;pointer-events:none;padding:2px;z-index: 4;"></div>`);

            this.loader.render(new LoaderLightComponent());
            this.place();
        }
    }

    removeLoader() {
        if (this.loader) {
            this.loader.remove();
            this.loader = undefined;
        }
    }

    place() {
        this.placeAbove(this.loader, 0, 0, -4, -4);
    }

    /**
     * error
     */

    renderError() {
        if (!this.error) {
            //language=HTML
            this.error = this.blot.quillComponent.append(`
                <div style="position: absolute;display:none;pointer-events:none;border-radius:3px;padding:2px;z-index: 4;box-shadow: 0 0 0 2px var(--outline-blue);"></div>`);

            this.error.render(new NetworkErrorComponent());
            this.placeError();
        }
    }

    removeError() {
        if (this.error) {
            this.error.remove();
            this.error = undefined;
        }
    }

    placeError() {
        this.placeAbove(this.loader, 0, 0, -4, -4);
    }

    /**
     * override
     */


    onDetach() {
        if (this.loader) {
            this.loader.remove();
            this.loader = undefined;
        }

        if (this.error) {
            this.error.remove();
            this.error = undefined;
        }

        super.onDetach();
    }


    onQuillChange() {
        if (this.loader) {
            this.place();
        }

        if (this.error) {
            this.placeError();
        }

        super.onQuillChange();
    }
}