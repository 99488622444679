import {QuillHtmlBlot} from "../QuillHtmlBlot";
import * as s from "./quill-reply-blot.scss";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import {SedestralMachine} from "../../../../../sedestral-interface-component/machine/SedestralMachine";

export class QuillHtmlReplyBlot extends QuillHtmlBlot {

    public collapseContainer: Component;
    public collapseButton: Component;

    constructor(scroll, domNode) {
        super(scroll, domNode);
    }

    onReady() {
        this.component.addClass(s.componentQuillHtmlReplyBlot);
        this.component.addClass("sedestral-Reply");
        this.component.timeOut(() => SedestralMachine.requestFrame()(() => {
            if (this.collapseContainer) {
                this.placeAbove(this.collapseContainer);
            }
        }), 100);

        if (!this.collapseContainer) {
            this.renderCollapse();
        }

        if (!this.data.unCollapsed) {
            this.collapse();
        } else {
            this.unCollapse();
        }

        this.bind();

        super.onReady();
    }

    bind() {
        this.collapseButton.onClick(() => {
            this.quillComponent.focusScroll();
            if (this.component.hasClass(s.collapsed)) {
                this.quillComponent.quill.setSelection(this.getIndex(), 0, "user");
            } else {
                this.quillComponent.quill.setSelection(this.getIndex() - 1, 0, "user");
                this.collapse();
            }

        }, true);


        this.onContentChange(() => {
            if (this.collapseContainer) {
                this.placeAbove(this.collapseContainer);
            }
        });
        this.onCursorSelect(() => this.unCollapse());
    }

    renderCollapse() {
        this.collapseContainer = this.renderAbove();
        //language=HTML
        this.collapseButton = this.collapseContainer.append(`
            <div class="${s.componentQuillHtmlReplyBlotCollapse}">
                <div class="${s.more}"></div>
            </div>
        `);
    }

    removeCollapse() {
        if (this.collapseContainer) {
            this.collapseContainer.remove();
            this.collapseContainer = undefined;
            this.collapseButton = undefined;
        }
    }

    collapse() {
        this.format("unCollapsed", false);
        this.component.removeClass(s.unCollapsed);
        this.component.addClass(s.collapsed);
    }

    unCollapse() {
        this.format("unCollapsed", true);
        this.component.removeClass(s.collapsed);
        this.component.addClass(s.unCollapsed);
        this.executeQuillChange();
    }

    detach() {
        this.removeCollapse();
        super.detach();
    }
}

QuillHtmlReplyBlot.blotName = 'reply';
QuillHtmlReplyBlot.tagName = 'blockquote';