/**
 * Vérifie si le type est une audio
 */
export function fileIsAudio(type: string): boolean {
    if (type != undefined) {
        type = type.toLowerCase();
    }
    switch (type) {
        case "mp3":
        case "audio/mpeg":
        case "audio/mp3":
            return true;
    }
    return false;
}