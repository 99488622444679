@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/alt";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/animations";

.componentShortcuts {
  width: 100%;

  &.shine {
    position: relative;
    @include animations.shine;
  }

  div[scrolltrack] {
    top: 12px;
    height: calc(100% - 18px);
    display: block;
    right: 6px;
  }

  .shortcutsHead {
    height: 30px;
    margin-bottom: 5px;
    position: relative;

    .searchBloc {
      margin-left: 10px;
      width: calc(100% - 136px);
      float: left;

      .icon {
        height: 14px;
        width: 14px;
        margin-top: 5.5px;
        margin-right: 0;
        float: left;
        filter: var(--icon-grey);

        @include icons.ico("black-search");
      }

      .input {
        font-size: 13px;
        height: 20px;
        padding-top: 5px;
        width: calc(100% - 22px);
        position: relative;
        border: unset;
        float: left;

        @include texts.font("semi-bold");
      }
    }

    .addButton {
      width: 100px;
      height: 22px;
      border-radius: 20px;
      margin-right: 10px;
      cursor: pointer;
      float: right;
      position: relative;

      @include positioning.flex-globally;

      &:hover {
        background: var(--grey-sur);
      }

      .icon {
        height: 10px;
        width: 10px;
        margin-top: 3px;
        margin-right: 7px;
        float: left;

        @include icons.ico("black-plus");
      }

      .text {
        font-size: 14px;
        float: left;

        @include texts.font("bold");
      }
    }
  }

  .listContainer {
    width: 100%;
    height: calc(100% - 35px);
  }

  .emptyContainer {
    position: absolute;
    top: 40px;
    background: linear-gradient(to top, var(--white), #d06bf900);
    height: calc(100% - 40px);
    width: 100%;
    border-radius: 16px;

    &.pocket {
      .icon {
        height: 50px;
        width: 50px;
      }


      div[emptyemoji] {
        margin-top: -35px;
      }

      div[emptytext] {
        width: 80%;
        left: 10%;
        font-size: 15px;

        @include texts.font("semi-bold");
      }


      div[emptyButton] {
        font-size: 12px;
        padding: 7px 18px;
        margin-top: 15px;
      }
    }

    div[emptyemoji] {
      margin-top: -100px;
    }

    strong, b {
      @include texts.font("extra-bold");
    }

    .icon {
      height: 80px;
      width: 80px;

      @include icons.ico("3d-shortcuts");
    }
  }

}