@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/inputs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentShortcutCreator {
  .contents {
    @include positioning.clear;

    .inputsContainer {
      margin-top: 12px;
      margin-bottom: 15px;
      width: 100%;

      @include positioning.clear;

      .inputContainer {
        position: relative;
        float: left;

        .slash {
          top: 14px;
          left: 15px;
          font-size: 12px;
          position: absolute;

          @include texts.font("bold");
        }

        .name {
          visibility: visible;
        }

        input {
          @include inputs.border;

          height: 42px;
          font-size: 14px;
          padding-left: 21px;
        }
      }

      .slideCheckboxContainer {
        border: var(--grey-sur) 2px solid;
        width: 68px;
        padding: 4px;
        height: 30px;
        border-radius: 10px;
        float: right;

        .included {
          @include icons.ico("black-all-included");
        }

        .mind {
          @include icons.ico("black-mind");
        }
      }
    }

    .editorContainer {
      border: var(--grey-sur) 2px solid;
      border-radius: 10px;
      position: relative;
      margin-bottom: 10px;
      padding-bottom: 8px;

      div[qleditor] {
        min-height: 140px;
      }

      div[editable] {
        height: calc(100% - 45px);
        font-size: 14px;

        div[qeditor] {
          height: fit-content;
        }

        div[scrolltrack] {
          height: calc(100% - 45px);
        }
      }
    }

    div[btns], div[btnc] {
      font-size: 14px;
      padding: 10px 25px;
      width: fit-content;
      border-radius: 8px;
      margin-top: 40px;
      float: right;
    }
  }

  .illustrationContainer {
    height: 90px;
    bottom: -20px;
    overflow: hidden;
    width: 170px;
    position: absolute;

    .illustration {
      height: 120px;
      width: 120px;
      @include icons.ico("lineal-shortcuts");
    }
  }

  div[chsliding] {
    width: calc(100% + 4px) !important;
  }

}