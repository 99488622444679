import {
    VisualSwapComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/swap/VisualSwapComponent";
import {ShortcutsComponent} from "../ShortcutsComponent";
import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {Network} from "../../../../../../network/Network";
import * as s from "./shortcuts-swap.scss";
import {Resources} from "../../../../../../resources/Resources";

export class ShortcutsSwapComponent extends VisualSwapComponent {

    public search: boolean;
    public useScrollbar: boolean;
    public shortcutsComponent: ShortcutsComponent;

    constructor(component: Component, search: boolean, useScrollbar: boolean) {
        super(Resources.t("words.shortcuts"), component);
        this.search = search;
        this.useScrollbar = useScrollbar;
    }

    commit() {
        super.commit();
        this.addClass(s.componentShortcutsSwap);

        this.shortcutsComponent = new ShortcutsComponent(this.search, this.useScrollbar, true);
        this.render(this.shortcutsComponent);
        this.shortcutsComponent.onAddClick = () => Network.router.pages.redirect("/general/shortcuts");
    }
}