import * as s from "./notifications.scss";
import {
    Component
} from "../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    LoaderObjectListComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/loader/objects/list/LoaderObjectListComponent";
import {INotificationModel} from "../../../../../models/notification/INotificationModel";
import {NotificationComponent} from "./NotificationComponent";
import {
    EmptyBasicComponent
} from "../../../../../sedestral-interface-modules/sedestral-interface-component-global/empty/EmptyBasicComponent";
import {EntityService} from "../../../../../services/entity/EntityService";
import {Resources} from "../../../../../resources/Resources";

export class NotificationsComponent extends Component {
    private contentContainer: Component;

    constructor() {
        super();
        //language=HTML
        this.template = `
            <div class="${s.componentNotifications}">
                <div class="${s.top}">
                    <div class="${s.title}">
                        <div class="${s.text}">🔔 ${Resources.t("words.notificationsTitle")}</div>
                    </div>
                        <!--
                    <div class="${s.viewButton}">
                        <div class="${s.icon}"></div>
                    </div>
                    -->
                </div>
                <div class="${s.content}"></div>
            </div>
        `;

    }

    commit() {
        this.contentContainer = this.el(s.content);
        this.scrollable();
        super.commit();
    }

    renderNotificationsCount() {
        if (EntityService.activeNotifications.total > 0) {
            this.el(s.title).append(`<div class="${s.notifs}">${EntityService.activeNotifications.total}</div>`);
        }
    }

    renderNotifications(notifications: INotificationModel[]) {
        this.contentContainer.clearAll();
        if (notifications.length > 0) {
            notifications.forEach(value => this.contentContainer.render(new NotificationComponent(value)));
        } else
            //language=HTML
        {
            this.contentContainer.append(`
                <div class="${s.loading} ${s.empty}">
                    ${this.draw(new EmptyBasicComponent(`<div class="${s.icon}"></div>`, Resources.t("words.noNotificationsToShow")))}
                </div>
            `);
        }
    }

    setLoading() {
        //language=HTML
        this.contentContainer.append(`
            <div class="${s.loading}">
                ${this.draw(new LoaderObjectListComponent(true, 10))}
            </div>
        `);
    }
}