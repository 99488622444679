import {QuillBlotLogic} from "../QuillBlotLogic";
import {QuillBlot} from "../../QuillBlot";
import {Component} from "../../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./quill-blots-logic-formatter.scss";
import {QuillFormattingComponent} from "../../../editor/formatting/QuillFormattingComponent";
import {IQuillFormatting} from "../../../editor/formatting/types/IQuillFormatting";


export class QuillBlotFormatterLogic extends QuillBlotLogic {

    public cursor: Component;
    public settings: IQuillFormatting;

    constructor(blot: QuillBlot, settings: IQuillFormatting) {
        super(blot);
        this.settings = settings;
    }

    /**
     * cursor
     */
    renderCursor() {
        if (!this.cursor) {
            //language=HTML
            this.cursor = this.blot.quillComponent.append(`
                <div class="${s.componentQuillBlotsLogicFormatter}"></div>`);
            this.cursor.fade("in", 350);
            this.cursor.unClickable();

            let formatter = new QuillFormattingComponent(this.blot.quillComponent, this.settings);
            //language=HTML
            this.cursor.append(`
                <div class="${s.formatterContainer}">
                    ${this.cursor.draw(formatter)}
                </div>
            `);

            this.bind();
        }
    }

    removeCursor() {
        if (this.cursor) {
            this.cursor.remove();
            this.cursor = undefined;
        }
    }

    place() {
        let bounds = this.blot.getInsideBounds();
        let left = bounds.left + ((this.blot.component.getWidth() / 2) - (this.cursor.getWidth() / 2));
        let top = bounds.top;
        if (left < 0) {
            left = 5;
        }
        if (top < 0) {
            top = 0;
        }

        this.cursor.setStyle(`display:block;transition:0s;${(left + this.cursor.getWidth()) > window.innerWidth ? `right: 5px;left: unset;` : `left:${left}px;right:unset;`}top:${top - 45}px;`);
    }


    bind() {

    }

    /**
     * override
     */
    onReady() {
        this.blot.component.setStyle(`cursor: pointer;`);
        if (this.blot.data.style) {
            this.blot.component.setStyle(this.blot.data.style);
        }
        this.blot.onCursorSelect(() => {
            this.renderCursor();
            this.place();
        }, () => {
            this.removeCursor();
        });

        super.onReady();
    }

    onDetach() {
        if (this.cursor) {
            this.cursor.remove();
            this.cursor = undefined;
        }

        super.onDetach();
    }

    onQuillChange() {
        if (this.cursor) {
            this.place();
        }

        super.onQuillChange();
    }
}