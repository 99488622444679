import {QuillFormattingBaseButtonComponent} from "../../QuillFormattingBaseButtonComponent";
import {Component} from "../../../../../../../sedestral-interface-component/interface/component/Component";
import {
    ColorsPickerGridTooltipComponent
} from "../../../../../../colors/picker/grid/tooltip/ColorsPickerGridTooltipComponent";
import * as s from "../../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../../QuillFormattingComponent";

export class QuillFormattingButtonFontBackgroundComponent extends QuillFormattingBaseButtonComponent {

    private colorComponent: Component;
    private BASE_BACKGROUND: string = "#ffffff";

    constructor(formatting: QuillFormattingComponent) {
        super("background", formatting);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton} ${s.formatBackground}">
                <div class="${s.icon} ${s.background}"></div>
                <div class="${s.fontBackground} ${s.background}"></div>
            </button>
        `;
    }

    commit() {
        this.colorComponent = this.el(s.fontBackground);
        super.commit();
    }

    update() {
        let color = this.formattingComponent.quillComponent.currentFormats()['background'];
        this.colorComponent.setStyle(`background: ${this.BASE_BACKGROUND}`);
        if (color != undefined) {
            this.colorComponent.setStyle(`background: ${color}`);
        }
        super.update();
    }

    bind() {
        this.onClick(() => {
            let formats = this.formattingComponent.quillComponent.currentFormats();
            let tooltipComponent = new ColorsPickerGridTooltipComponent(this, formats['background'] == undefined ? this.BASE_BACKGROUND : formats['background']);
            tooltipComponent.screen(false);
            tooltipComponent.create();
            tooltipComponent.onPick = (color => {
                tooltipComponent.dispose();
                this.format(color);
            });
        });
    }

    isBaseValue(value: string): boolean {
        return value == this.BASE_BACKGROUND;
    }
}