import {
    VisualClickTooltipWhiteComponent
} from "../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {RecorderComponent} from "../RecorderComponent";

export class RecorderTooltipComponent extends VisualClickTooltipWhiteComponent {
    constructor(component: Component) {
        super(component, 24, 280);
    }

    commit() {
        super.commit();
        let recorder = new RecorderComponent();
        this.render(recorder);
        recorder.onRecorded = (file, url, statusCode) => {
            this.onRecorded(file, url, statusCode);
        }
    }

    onRecorded(file: File, url: string, statusCode: number) {
    }

    place() {
        super.place();
        this.placeTop();
    }
}