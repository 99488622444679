import {Component} from "../../sedestral-interface-component/interface/component/Component";
import * as s from "./emojis.scss";
import {Resources} from "../../../resources/Resources";
import {Network} from "../../../network/Network";

export class EmojisComponent extends Component {

    private selectedContainer: Component;
    private emojisContainer: Component;
    private categoriesContainer: Component;
    private searchBoardContainer: Component;
    private searchContainer: Component;
    private emojis: object;
    private searchInput: Component;

    constructor() {
        super();

        this.template = `
            <div class="${s.globalEmojis}">
                <div class="${s.searchContainer}">
                    <div class="${s.icon}"></div>
                    <input placeholder=" ${Resources.t("words.search")}" class="${s.input}"/>
                </div>
                <div class="${s.emojisContainer}">
                    <div class="${s.categories}"></div>
                    <div class="${s.right}">
                        <div class="${s.emojisBoard}">
                            <div class="${s.searchBoard}">
                                <div class="${s.search}"></div>
                            </div>
                            <div class="${s.emojis}"></div>
                        </div>
                        <div class="${s.selectedEmoji}"></div>
                    </div>
                </div>
            </div>
        `;
    }

    async commit(): Promise<void> {
        this.emojisContainer = this.el(s.emojis);
        this.emojisContainer.scrollable();
        this.categoriesContainer = this.el(s.categories);
        this.searchContainer = this.el(s.search);
        this.searchBoardContainer = this.el(s.searchBoard);
        this.selectedContainer = this.el(s.selectedEmoji);

        this.searchInput = this.el(s.input);
        this.searchInput.onKey(() => {
            this.renderSearch(this.searchInput.getValue());
        }, 20);

        this.init();
        super.commit();
    }

    async init() {
        this.emojis = await Resources.loadEmojis(Network.vendor);
        for (let category in this.emojis) {
            if (this.emojis.hasOwnProperty(category)) {
                this.renderCategory(Resources.t("words.emojis." + category), this.emojis[category], false);
            }
            await this.sleep(1);
        }

        this.selectEmoji(this.emojis[Object.keys(this.emojis)[0]][0]);
    }

    renderSearch(search: string) {
        search = search.toLowerCase();
        this.searchContainer.clearAll();

        if (search.length == 0) {
            this.searchBoardContainer.displayHide();
            return;
        }
        let emojis = [];
        for (let category in this.emojis) {
            if (this.emojis.hasOwnProperty(category)) {
                for (let emoji in this.emojis[category]) {
                    if (this.emojis[category].hasOwnProperty(emoji)) {
                        let object = this.emojis[category][emoji];

                        let match = false;
                        if (object.description.includes(search)) {
                            match = true;
                        } else {
                            for (let tag in object.tags) {
                                if (object.tags.hasOwnProperty(tag)) {
                                    if (tag.includes(search)) {
                                        match = true;
                                    }
                                }
                            }
                        }

                        if (match && emojis.length < 100) {
                            emojis.push(object);
                        }
                    }
                }
            }
        }

        this.searchBoardContainer.displayShow();
        this.searchContainer.scrollable();

        if (emojis.length > 0) {
            this.renderCategory(Resources.t("words.searchCategory"), emojis, true);
        } else {
            this.searchContainer.append(`
                <div class="${s.empty}">Aucun résultat pour votre recherche 💤</div>
            `);
        }
    }

    renderCategory(name: string, emojis: object, search: boolean): void {

        let emojisContainer = search ? this.searchContainer : this.emojisContainer;
        let container = this.renderContainer(emojisContainer, name);
        let listContainer = container.el(s.list);

        if (!search) {
            let buttonContainer = this.renderButton(container, emojis[0].emoji);
            this.emojisContainer.onScroll(() => {
                let selector = buttonContainer.el(s.selector);

                let scrollTop = Math.round(this.emojisContainer.getScrollTop());
                let offsetTop = Math.round((container.getParentOffsets()['top'] + scrollTop));
                let height = container.getHeight();

                if (scrollTop >= offsetTop && scrollTop < (offsetTop + height)) {
                    selector.displayShow();
                } else {
                    selector.displayHide();
                }
            });
        }

        for (let emoji in emojis) {
            if (emojis.hasOwnProperty(emoji)) {
                this.renderEmoji(listContainer, emojis[emoji]);
            }
        }
    }

    renderContainer(container: Component, name: string): Component {
        return container.append(`
            <div class="${s.emojisCategory}">
                <div class="${s.emojisCategoryName}">
                    ${name}
                </div>
                <div class="${s.list}"></div>
            </div>
        `);
    }

    renderButton(container: Component, emoji: string): Component {
        let categoryButton = this.categoriesContainer.append(`
            <div class="${s.category}">
                ${emoji}
                <div class="${s.selector}"></div>
            </div>
        `);

        categoryButton.onClick(() => {
            this.searchBoardContainer.displayHide();
            this.searchContainer.clearAll();
            container.scrollIntoView(this.emojisContainer, 2);
        });

        return categoryButton;
    }

    renderEmoji(container: Component, emoji: object): void {
        let emojiContainer = container.append(`<div em class="${s.emoji}">${emoji['emoji']}</div>`);
        emojiContainer.onHover(() => this.selectEmoji(emoji));
        emojiContainer.onClick(() => this.onPick(emoji['emoji']));
    }

    selectEmoji(emoji: any) {
        //language=HTML
        this.selectedContainer.clearAll();
        this.selectedContainer.append(`
            <div class="${s.emoji}">
                ${emoji['emoji']}
            </div>
            <div class="${s.name}">
                ${emoji['description']}
            </div>
        `);
    }

    setPocket() {
        this.addClass(s.pocket);
    }

    /**
     * override
     */

    onPick(content: string) {

    }
}