import {
    VisualClickTooltipWhiteComponent
} from "../../../../../../sedestral-interface-component-visual/tooltip/VisualClickTooltipWhiteComponent";
import {Component} from "../../../../../../sedestral-interface-component/interface/component/Component";
import {QuillEditorSendMenuComponent} from "../QuillEditorSendMenuComponent";

export class QuillEditorSendMenuTooltipComponent extends VisualClickTooltipWhiteComponent {

    public menuComponent: QuillEditorSendMenuComponent;

    constructor(component: Component) {
        super(component, 10, 150);
        this.menuComponent = new QuillEditorSendMenuComponent();
        this.template = this.draw(this.menuComponent);
    }

    commit() {
        this.setStyle(`padding:7px;`);
        super.commit();
    }

}