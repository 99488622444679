import {ShortcutComponent} from "../ShortcutComponent";
import {
    VisualKeyListComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/keyboard/VisualKeyListComponent";
import {IInboxShortcutModel} from "../../../../../../models/inbox/shortcut/IInboxShortcutModel";

export class ShortcutsListComponent extends VisualKeyListComponent {

    private shortcuts: IInboxShortcutModel[];
    private components: ShortcutComponent[];

    constructor(shortcuts?: IInboxShortcutModel[]) {
        super();
        this.shortcuts = [...shortcuts];
        this.components = [];
    }

    commit(): void {
        if (this.shortcuts != undefined) {
            this.shortcuts.sort((a, b) => (a.uses > b.uses) ? 1 : -1).reverse();
            for (let shortcut of this.shortcuts) {
                if (shortcut != undefined) {
                    let component = new ShortcutComponent(shortcut);
                    this.render(component);
                    this.components.push(component);
                }
            }

            this.selectable = this.components;
        }

        super.commit();
    }

    renderButtons() {
        for (let component of this.components)
            component.renderButtons();
    }


    renderGhost(length?: number) {
        for (let i = 0; i < (length == undefined ? 10 : length); i++)
            this.render(new ShortcutComponent(undefined));
    }
}