import {IQuillIntegrationBlot} from "../blots/integration/types/IQuillIntegrationBlot";
import {IQuillContent} from "../types/IQuillContent";
import {QuillComponent} from "../QuillComponent";
import {QuillLogic} from "./QuillLogic";
import {SedestralMachine} from "../../../sedestral-interface-component/machine/SedestralMachine";

export class QuillIntegrationsLogic extends QuillLogic {

    constructor(quillComponent: QuillComponent) {
        super(quillComponent);
    }

    public insert(index: number, value: string, empty: string, created?: boolean) {
        let integration = this.quillComponent.settings.integrationsList.find(i => i.value == value);
        let data: IQuillIntegrationBlot = {
            empty: empty == undefined ? '' : empty,
            value: integration.value,
            sedestralIsNew: created ? true : undefined
        };

        this.quillComponent.quill.insertEmbed(index, "integration", data, created ? "user" : "silent");
        if (created) {
            this.quillComponent.quill.insertText(index + 1, ' \u200B ', created ? "user" : "silent");
        }
    }

    public restore(html: string) {
        if (html != undefined) {
            let newString = `${html}`;
            let regex = /{{{(.*?)}}}/g;
            let founds = newString.match(regex);
            let entries: any[] = [];

            if (founds != undefined) {
                for (let found of founds) {
                    newString = newString.replace(found, "");
                    let values = found.replace("{{{", "").replace("}}}", "").split("}}{{");

                    entries.push({
                        value: values[0],
                        empty: values[1],
                        index: values[2]
                    });
                }
            }

            this.quillComponent.quill.root.innerHTML = newString;
            SedestralMachine.requestFrame()(() => {
                for (let entry of entries) {
                    this.insert(entry.index, entry.value, entry.empty, false);
                }
            });
        }
    }

    public parseHtml(content: IQuillContent): IQuillContent {
        let opses = this.quillComponent.getOps("integration");
        let addedSize = 0;

        for (let ops of opses) {
            let index = ops.sedestralIndex;
            let start = content.text.substring(0, index + addedSize);
            let end = content.text.substr(index + addedSize, content.text.length);
            let blot = this.quillComponent.getBlotAtIndex(index);

            let replacement = ``;
            replacement += "{";
            replacement += "{{" + ops.value + "}}";
            replacement += "{{" + ops.empty + "}}";
            replacement += "{{" + index + "}}";
            replacement += "}";
            addedSize += (replacement.length - 1);

            content.text = start + replacement + end;
            content.html = content.html.replace(this.quillComponent.cleanHtml(blot.domNode).outerHTML.trim(), replacement);
        }

        return content;
    }

    public parseDeltas(deltas: any): any {
        deltas.ops.forEach(ops => {
            if (ops.insert) {
                if (typeof ops.insert == "object") {
                    if (Object.keys(ops.insert)[0] == "integration") {
                        let replace = this.quillComponent.settings.integrationsListReplaces.find(value => value.name == ops.insert[Object.keys(ops.insert)[0]].value);
                        ops.insert = replace ? replace.replacement : "none";
                    }
                }
            }
        });


        return deltas;
    }
}