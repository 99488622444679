@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentQuillSettings {
  .choices {
    padding: 2px 14px;
    border-bottom: 1px solid var(--grey-sur);

    &.line {
      visibility: visible;
    }

    &.spellChecker {
      visibility: visible;
    }

    div[btncheckbox] {
      margin-top: 8px;
    }

    &.oneChoice {
      border-bottom: 0;

      div[btncheckbox] {
        margin-top: 0;
      }
    }

    .choice {
      .settings {
        font-size: 12px;
        color: var(--grey-extra-dark);

        @include texts.font("medium");

        span {
          font-size: 13px;
          color: var(--black);
          @include texts.font("semi-bold");
        }

        .zigzag {
          float: left;
          height: 24px;
          width: 24px;
          margin-top: -4px;
          margin-right: 10px;
          filter: var(--icon-red);

          @include icons.ico("black-zigzag");
        }
      }
    }
  }
}
