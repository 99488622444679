import {EditorButton} from "./EditorButton";
import {EditorComponent} from "../EditorComponent";
import * as s from "../editor.scss";
import {
    VisualFilePickerComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/file/VisualFilePickerComponent";
import {Resources} from "../../../../../../resources/Resources";
import {
    objectUpdate
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/ObjectUpdate";

export class EditorButtonAttachment extends EditorButton {

    public picker: VisualFilePickerComponent;

    constructor(editor: EditorComponent) {
        super(editor);
    }

    init() {
        this.button = this.editor.rightButtonsContainer.append(`
            <div data-tooltip="${Resources.t('words.files')}" button class="${s.button}">
                <div class="${s.icon} ${s.attachment}"></div>
            </div>
        `);

        this.renderPicker();
    }

    renderPicker() {
        this.picker = new VisualFilePickerComponent(objectUpdate({
            gifs: true,
            multiple: true,
            secure: true,
            videos: true,
            files: true,
            images: true,
        }, this.editor.config.attachmentSettings));


        this.button.render(this.picker);
        this.picker.onPick = (async (file) => {
            if (await this.editor.quillComponent.beforeAddAttachment(file)) {
                this.editor.quillComponent.addAttachment(file);
            }
        });
    }

    lock(text?: string) {
        this.picker.remove();
        super.lock(text);
    }

    unLock() {
        this.renderPicker();
        super.unLock();
    }
}