import * as s from "../../quill.scss";
import {QuillBlot} from "../QuillBlot";
import {IQuillIntegration} from "../../editor/integrations/types/IQuillIntegration";
import {IQuillIntegrationBlot} from "./types/IQuillIntegrationBlot";
import {QuillIntegrationCreatorComponent} from "../../editor/integrations/creator/QuillIntegrationCreatorComponent";

export class QuillIntegrationBlot extends QuillBlot {

    constructor(scroll, domNode) {
        super(scroll, domNode);
        this.withSpan = true;
    }

    onReady() {
        if (!this.component.hasClass(s.qlSpanBlotContainer)) {
            this.component.addClass(s.qlSpanBlotContainer);
            let integration: IQuillIntegration = this.quillComponent.settings.integrationsList.find(value => value.value == this.data.value);

            //language=HTML
            let child = this.spanComponent.append(`
                <span class="${s.qlSpanBlotChild}">
                <span class="${s.qlSpanBlotDelimiter}"><span
                        class="${s.icon} ${s.blue} ${s.integration}"></span></span>
                <span class="${s.qlSpanBlotContent}">${integration.name}</span>
            </span>
            `, "span");


            this.component.onClick(() => {
                this.openIntegrationCreator(this, this.data);
            });

            if (this.data.sedestralIsNew) {
                this.quillComponent.timeOut(() => this.openIntegrationCreator(this, this.data), 5);
            }

            this.format("sedestralIndex", this.getIndex());
            this.onContentChange(() => this.format("sedestralIndex", this.getIndex()));
        }

        super.onReady();
    }

    public openIntegrationCreator(blot: QuillIntegrationBlot, data: IQuillIntegrationBlot) {
        let creator = new QuillIntegrationCreatorComponent(blot.component, data.empty);
        creator.screen(false).animation(false);
        creator.create();

        creator.onCancel = () => {
            creator.dispose();
        }
        creator.onValidate = (value) => {
            blot.setEmpty(value);
            creator.dispose();
        };
    }

    setEmpty(value: string) {
        this.format("empty", value);
    }
}

QuillIntegrationBlot.blotName = 'integration';
QuillIntegrationBlot.tagName = 'integration';