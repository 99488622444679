import {QuillLogic} from "./QuillLogic";
import {QuillComponent} from "../QuillComponent";
import Quill from "quill";

const Parchment = Quill.import('parchment');

export class QuillLogicShiftEnter extends QuillLogic {

    public keyboard: any;

    constructor(quillComponent: QuillComponent) {
        super(quillComponent);
        this.keyboard = quillComponent.quill.getModule("keyboard");
    }

    init() {
        this.keyboard.bindings.Enter.unshift({
            sedestralShiftEnter: true,
            key: 'Enter',
            shiftKey: true,
            handler: (range, context) => {
                this.quillComponent.quill.insertText(range.index, '\n', 'user')
                this.quillComponent.quill.setSelection(range.index + 1, 'user');
                this.applyFormats(context);
                this.quillComponent.quill.format('line', "q", 'user');
            }
        });


        this.keyboard.bindings.Enter.unshift({
            sedestralShiftEnter: true,
            key: 'Enter',
            handler: (range, context) => {
                this.quillComponent.quill.insertText(range.index, '\n', 'user')
                this.quillComponent.quill.setSelection(range.index + 1, 'user');

                let f = this.quillComponent.quill.getFormat(range.index + 1);
                if (f.hasOwnProperty('line')) {
                    delete (f.line)
                    this.quillComponent.quill.removeFormat(range.index + 1, 0)
                }
            }
        });
    }

    applyFormats(context) {
        let lineFormats = Object.keys(context.format).reduce(
            (formats, format) => {
                if (  // @ts-ignore
                    this.quillComponent.quill.scroll.query(format, Parchment.Scope.BLOCK) &&
                    !Array.isArray(context.format[format])
                ) {
                    formats[format] = context.format[format];
                }
                return formats;
            },
            {},
        );

        Object.keys(context.format).forEach(name => {
            if (lineFormats[name] != null) {
                return;
            }
            if (Array.isArray(context.format[name])) {
                return;
            }
            if (name === 'code' || name === 'link') {
                return;
            }
            this.quillComponent.quill.format(name, context.format[name], Quill.sources.USER);
        });
    }

}