@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/inputs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/effects";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";

.componentAssignmentPicker {
  position: relative;
  width: 100%;
  overflow: hidden;

  &.noSearch {
    .objectsScrollable {
      max-height: 100%;

      div[scrolltrack] {
        top: 2px;
        height: calc(100% - 4px);
      }
    }

    .searchContainer {
      display: none;
    }
  }

  .searchContainer {
    position: relative;
    height: 26px;
    margin-bottom: 7px;
    cursor: text;
    border-radius: 30px;
    left: 10px;
    width: calc(100% - 20px);

    .icon {
      height: 14px;
      width: 14px;
      top: 6px;
      position: relative;
      filter: var(--icon-grey);

      @include icons.ico("black-search");
    }

    .input {
      @include inputs.basic;
      @include texts.font("semi-bold");

      font-size: 13px;
      overflow: hidden;
      height: 20px;
      position: absolute;
      background: unset;
      width: calc(100% - 35px) !important;
      top: 2px;
      left: 14px;
    }
  }


  .objectsScrollable {
    margin-left: 7px;
    width: calc(100% - 14px);
    max-height: calc(100% - 34px);

    &[scrollvirtual] {
      .objects {
        width: calc(100% - 6px);
      }
    }

    div[scrolltrack] {
      top: 32px;
      right: 4px;
      z-index: 0;
      height: calc(100% - 38px);
    }

    .objects {
      .loader {
        height: 200px;

        circle {
          stroke-width: 4px !important;
        }
      }

      .emptyContainer {
        position: relative;
        height: 120px;

        div[emptytext] {
          font-size: 14px;

          @include texts.font("semi-bold");
        }

        .icon {
          height: 60px;
          width: 60px;

          @include icons.ico("3d-users");
        }
      }

      .empty {
        height: 46px;
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 480px) {

    .searchContainer {
      margin-bottom: 10px !important;

      input {
        font-size: 15px !important;
      }

      textarea {
        font-size: 15px !important;
      }
    }
  }
}