import * as s from "./editor-inbox-mail.scss";
import {IInboxConversationModel} from "../../../../../../../../models/inbox/conversation/IInboxConversationModel";
import {PermissionsService} from "../../../../../../../../services/permissions/PermissionsService";
import {EditorInboxComponent} from "../EditorInboxComponent";
import {IEditor} from "../../../types/IEditor";
import {
    QuillCollaboratorsComponent
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/collaboration/collaborators/QuillCollaboratorsComponent";
import {
    Component
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {MailsEmailsRecipientsComponent} from "../../../../mail/emails/recipients/MailsEmailsRecipientsComponent";
import {
    VisualSelectBoxTooltipComponent
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/selectbox/tooltip/VisualSelectBoxTooltipComponent";
import {
    ISelectBoxEntry
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component-global/selectbox/types/ISelectBoxEntry";
import {MailsEmailsPickerComponent} from "../../../../mail/emails/picker/MailsEmailsPickerComponent";
import {MailRecipientsType} from "../../../../../../../../models/enums/MailRecipientsType";
import {EntityService} from "../../../../../../../../services/entity/EntityService";
import {
    ICollaborationEditorSavingMessageModel
} from "../../../../../../../../models/collaboration/editor/saving/message/incoming/ICollaborationEditorSavingMessageModel";
import {
    ICollaborationEditorSavingMessageRecipientModel
} from "../../../../../../../../models/collaboration/editor/saving/message/incoming/ICollaborationEditorSavingMessageRecipientModel";
import {
    ICollaborationEditorViewerModel
} from "../../../../../../../../models/collaboration/editor/ICollaborationEditorViewerModel";
import {ICollaborationEditorModel} from "../../../../../../../../models/collaboration/editor/ICollaborationEditorModel";
import {ComSendType} from "../../../../../../../../models/enums/ComSendType";
import {
    ICollaborationEditorSavingMessageOutgoingModel
} from "../../../../../../../../models/collaboration/editor/saving/message/outgoing/ICollaborationEditorSavingMessageOutgoingModel";
import {SiteChannelPickerComponent} from "../../../../site/channel/picker/SiteChannelPickerComponent";
import {Resources} from "../../../../../../../../resources/Resources";
import {
    objectUpdate
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/ObjectUpdate";
import {
    objectEquals
} from "../../../../../../../../sedestral-interface-modules/sedestral-interface-component/utilities/ObjectEquals";

export class EditorInboxMailComponent extends EditorInboxComponent {

    public collaboratorsContainer: Component;

    public mailsPicker: MailsEmailsPickerComponent | SiteChannelPickerComponent;
    public mailsPickerTimeout: number;

    public linesContainer: Component;
    public recipientsTypes: ISelectBoxEntry[] = [
        {value: MailRecipientsType.TO, label: "À"},
        {value: MailRecipientsType.CC, label: "Cc"},
        {value: MailRecipientsType.BCC, label: "Bcc"},
        {value: MailRecipientsType.SUBJECT, label: "Sujet"}
    ];

    public sendTypeComponent: Component;
    public sendTypeTimeout: number;
    public selectedSendType: ComSendType;

    public subjectInput: Component;
    public subjectInputTimeout: number;
    public collaborationEditor: ICollaborationEditorModel;

    public recipientsComponents: {
        type: MailRecipientsType,
        component: MailsEmailsRecipientsComponent,
        line: Component
    }[];

    public fromLine: Component;

    constructor(settings: IEditor, conversation: IInboxConversationModel) {
        super(objectUpdate({
            quill: {
                inlineStyle: true,
                formattingLine: true,
                antiSpam: 200,
                magicUrl: false,
                lineBreak: true,
                inlineFiles: true,
                shiftEnterLineBreak: true
            },
            shortcuts: true, emojis: true, send: true, formatting: true,
        } as IEditor, settings), conversation);
        this.recipientsComponents = [];

        if (PermissionsService.inboxFiles()) {
            this.config.recorder = true;
            this.config.gifs = true;
            this.config.quill.attachment = true;
        }
    }

    commit() {
        super.commit();
        this.addClass(s.componentEditorInboxMail);
        this.linesContainer = this.prepend(`<div editorLines class="${s.lines}"></div>`);


        this.renderFrom();
        this.renderLine(MailRecipientsType.TO);

        if (this.config.mailShowSubject) {
            this.renderLine(MailRecipientsType.SUBJECT);
        }
    }

    async initCollaboration() {
        super.initCollaboration();

        this.quillComponent.logicCollaboration.onReady(editor => {
            this.collaborationEditor = editor;

            this.renderFromCollaboration();
            this.restoreAll(editor);
        });

        this.quillComponent.logicCollaboration.onMetadata = (data, viewer) => {
            let metadata = data.metadata as ICollaborationEditorSavingMessageModel;

            this.updateAll(metadata, viewer);
        }
    }

    /**
     * render
     */

    renderFrom() {
        this.mailsPicker = this.config.mailFromPicker ?? new MailsEmailsPickerComponent({siteId: EntityService.activeSite.id});

        if (!this.config.mailFromPicker) {
            //language=HTML
            this.fromLine = this.linesContainer.append(`
                <div class="${s.line}">
                    <div class="${s.case}">
                        De
                    </div>
                    <div class="${s.recipientsContainer}">
                        ${this.linesContainer.draw(this.mailsPicker)}
                    </div>
                </div>
            `);
        }

        this.mailsPicker.classLight();
        this.mailsPicker.onPick = () => this.executeMetaDataChanges();
    }

    renderFromCollaboration() {
        let container = this.config.collaborationCollaboratorsContainer ?? this.fromLine;

        if (this.collaboratorsContainer) {
            this.collaboratorsContainer.remove();
            this.collaboratorsContainer = undefined;
        }

        let collaboratorsComponent = new QuillCollaboratorsComponent();

        //language=HTML
        this.collaboratorsContainer = container.append(`
            <div class="${s.collaborationContainer}">
                ${container.draw(collaboratorsComponent)}
            </div>
        `);

        this.quillComponent.logicCollaboration.setCollaborators(collaboratorsComponent);
    }

    renderLine(type: MailRecipientsType) {
        let entry = this.recipientsTypes.filter(value => value.value == type)[0];
        if (entry != undefined) {
            //language=HTML
            let line = this.linesContainer.append(`
                <div class="${s.line}">
                    <div class="${s.case} ${s.recipients}">
                        ${entry.label}
                    </div>
                </div>
            `);
            if (type != MailRecipientsType.SUBJECT) {
                this.renderLineRecipients(type, line);
            } else {
                this.renderLineSubject(line);
            }

            if (type == MailRecipientsType.TO) {
                if (!this.config.mailDisableSendType) {
                    this.renderLineSendType(line);
                }
                this.renderLineMore(line);
            }

            this.recipientsTypes.splice(this.recipientsTypes.indexOf(entry), 1);
        }
    }

    renderLineSubject(line: Component) {
        line.el(s.case).remove();
        //language=HTML
        this.subjectInput = line.append(`
            <input class="${s.input}" placeholder="${Resources.t('words.subjectPlaceholder')}"/>
        `);

        this.subjectInput.onKey(() => this.executeMetaDataChanges(), 500);
    }

    renderLineRecipients(type: MailRecipientsType, line: Component) {
        let recipentsComponent = new MailsEmailsRecipientsComponent({mails: true});
        recipentsComponent.onContentChange = () => this.executeMetaDataChanges();
        //language=HTML
        line.append(`
            <div class="${s.recipientsContainer}">
                ${line.draw(recipentsComponent)}
            </div>
        `);

        this.recipientsComponents.push({type: type, component: recipentsComponent, line: line});
    }

    renderLineSendType(line: Component) {
        //language=HTML
        this.sendTypeComponent = line.append(`
            <div class="${s.sendTypeButton}">
                <div class="${s.icon} ${s.reply}"></div>
                <div class="${s.arrow}"></div>
            </div>
        `);

        this.sendTypeComponent.onClick(() => {
            let select = new VisualSelectBoxTooltipComponent(this.sendTypeComponent, {
                entries: [{
                    value: ComSendType.SEND,
                    label: Resources.t('words.reply')
                }, {
                    value: ComSendType.FORWARD,
                    label: Resources.t('words.forward')
                }],
                width: 120
            }, this.selectedSendType);

            select.screen(false);
            select.create();
            select.onSelect = (entry) => {
                this.updateLineSendTypeState(entry.value as number);
                this.executeMetaDataChanges();
            }
        });
    }

    renderLineMore(line: Component) {
        //language=HTML
        let button = line.append(`
            <div class="${s.moreButton}">
                <div class="${s.icon}"></div>
            </div>
        `);

        button.onClick(() => {
            let select = new VisualSelectBoxTooltipComponent(button, {
                entries: this.recipientsTypes,
                width: 80
            });
            select.screen(false);
            select.create();
            select.onSelect = (entry) => {
                select.dispose();
                this.renderLine(entry.value as MailRecipientsType);
                if (this.recipientsTypes.length == 0) {
                    button.remove();
                }
            }
        });
    }

    /**
     * restore
     */

    restoreAll(editor: ICollaborationEditorModel) {
        let metadata: ICollaborationEditorSavingMessageModel = editor.metadata;

        if (metadata && metadata.mail) {
            let toLineComponent = this.getLineRecipients(MailRecipientsType.TO);
            this.updateLineSendTypeState(metadata.mail.sendType);

            if (metadata.mail.to && metadata.mail.to.length > 0) {
                this.cleanMessageRecipients(metadata.mail.to);
                toLineComponent.renderRecipients(metadata.mail.to.map(messageRecipient => {
                    return {
                        mail: messageRecipient.mail,
                        object: messageRecipient.entity ? messageRecipient.entity : messageRecipient.mailObject
                    }
                }));
            }

            if (metadata.mail.cc && metadata.mail.cc.length > 0) {
                this.renderLine(MailRecipientsType.CC);
                let lineComponent = this.getLineRecipients(MailRecipientsType.CC);
                this.cleanMessageRecipients(metadata.mail.cc);
                lineComponent.renderRecipients(metadata.mail.cc.map(messageRecipient => {
                    return {
                        mail: messageRecipient.mail,
                        object: messageRecipient.entity ? messageRecipient.entity : messageRecipient.mailObject
                    }
                }));
            }

            if (metadata.mail.bcc && metadata.mail.bcc.length > 0) {
                this.renderLine(MailRecipientsType.BCC);
                let lineComponent = this.getLineRecipients(MailRecipientsType.BCC);
                this.cleanMessageRecipients(metadata.mail.bcc);
                lineComponent.renderRecipients(metadata.mail.bcc.map(messageRecipient => {
                    return {
                        mail: messageRecipient.mail,
                        object: messageRecipient.entity ? messageRecipient.entity : messageRecipient.mailObject
                    }
                }));
            }

            if (metadata.mail.subject && metadata.mail.subject.length > 0) {
                this.renderLine(MailRecipientsType.SUBJECT);
                this.subjectInput.setValue(metadata.mail.subject);
            }

            if (this.mailsPicker instanceof MailsEmailsPickerComponent) {
                this.mailsPicker.runAsReady(() => {
                    this.mailsPicker.selectBox.select(metadata.mail.fromMailId);
                });
            }
        }
    }

    /**
     * update
     */

    updateAll(metadata: ICollaborationEditorSavingMessageModel, viewer: ICollaborationEditorViewerModel) {
        this.updateMailPicker(metadata, viewer);

        if (metadata.mail.to) {
            this.updateLineRecipients(MailRecipientsType.TO, metadata.mail.to, viewer);
        }
        if (metadata.mail.cc) {
            this.updateLineRecipients(MailRecipientsType.CC, metadata.mail.cc, viewer);
        }
        if (metadata.mail.bcc) {
            this.updateLineRecipients(MailRecipientsType.BCC, metadata.mail.bcc, viewer);
        }

        if (metadata.mail.subject != undefined) {
            this.updateSubject(metadata, viewer);
        }

        if (metadata.mail.sendType) {
            this.updateLineSendTypeState(metadata.mail.sendType, viewer);
        }

    }

    updateMailPicker(metadata: ICollaborationEditorSavingMessageModel, viewer: ICollaborationEditorViewerModel) {
        if (this.mailsPicker.selectBox.getSelection().value != metadata.mail.fromMailId) {
            clearTimeout(this.mailsPickerTimeout);
            this.mailsPicker.selectBox.select(metadata.mail.fromMailId);
            if (viewer.id != this.quillComponent.logicCollaboration.myViewer.id) {
                this.mailsPicker.setStyle(`box-shadow: 0 0 0 2px ${viewer.color};`);
                this.mailsPicker.showTooltip(viewer.account.name, 1500);
                this.mailsPickerTimeout = this.timeOut(() => this.mailsPicker.setStyle(`box-shadow:unset;`), 1500);
            }
        }
    }

    updateSubject(metadata: ICollaborationEditorSavingMessageModel, viewer: ICollaborationEditorViewerModel) {
        this.renderLine(MailRecipientsType.SUBJECT);
        if (this.subjectInput.getValue() != metadata.mail.subject) {
            this.subjectInput.setValue(metadata.mail.subject);
            if (viewer.id != this.quillComponent.logicCollaboration.myViewer.id) {
                this.subjectInput.setStyle(`box-shadow: 0 0 0 2px ${viewer.color};`);
                this.subjectInput.showTooltip(viewer.account.name, 1500, "left");
                clearTimeout(this.subjectInputTimeout);
                this.subjectInputTimeout = this.timeOut(() => this.subjectInput.setStyle(`box-shadow: unset;`), 1500);
            }
        }
    }

    updateLineRecipients(type: MailRecipientsType, messageRecipients: ICollaborationEditorSavingMessageRecipientModel[], viewer: ICollaborationEditorViewerModel) {
        let lineRecipients = this.getLineRecipients(type);
        if (lineRecipients || messageRecipients.length > 0) {
            this.cleanMessageRecipients(messageRecipients);
            this.renderLine(type);
            lineRecipients = this.getLineRecipients(type);

            let toRemove = lineRecipients.recipients.filter(lineRecipient => {
                return messageRecipients.filter(messageRecipient => {
                    let entry = lineRecipients.toRecipient(messageRecipient.mail, messageRecipient.entity);
                    return objectEquals(lineRecipient, entry);
                }).length == 0;
            });

            let toAdd = messageRecipients.filter(messageRecipient => {
                return !lineRecipients.hasRecipient(messageRecipient.mail, messageRecipient.entity);
            });


            toRemove.forEach(lineRecipient => {
                lineRecipients.removeRecipient(lineRecipient, {
                    color: viewer.color,
                    viewerName: viewer.account.name
                });
            });

            toAdd.forEach(messageRecipient => {
                lineRecipients.renderRecipient(messageRecipient.mail, messageRecipient.entity, {
                    color: viewer.color,
                    viewerName: viewer.account.name
                });
            });
        }

    }

    updateLineSendTypeState(sendType: ComSendType, viewer?: ICollaborationEditorViewerModel) {
        if (!this.config.mailDisableSendType) {
            let icon = this.sendTypeComponent.el(s.icon);
            icon.removeClass(s.forward);
            icon.removeClass(s.reply);

            if (sendType == ComSendType.SEND) {
                icon.addClass(s.reply);
            } else {
                icon.addClass(s.forward);
            }

            if (viewer && this.selectedSendType != sendType) {
                clearTimeout(this.sendTypeTimeout);
                this.sendTypeComponent.setStyle(`box-shadow: 0 0 0px 2px ${viewer.color};`);
                this.sendTypeComponent.showTooltip(viewer.account.name, 1500);
                this.sendTypeTimeout = this.sendTypeComponent.timeOut(() => this.sendTypeComponent.setStyle(`box-shadow: unset;`), 1500);
            }

            this.selectedSendType = sendType;
        }
    }

    /**
     * get
     */
    getLineRecipients(type: MailRecipientsType): MailsEmailsRecipientsComponent {
        let x = this.recipientsComponents.filter(value => value.type == type)[0];
        return x ? x.component : undefined;
    }

    /**
     * logic
     */

    executeMetaDataChanges() {
        if (this.config.quill.collaboration?.editorId) {
            this.quillComponent.logicCollaboration.savingMetadata(this.toMetaData());
        }
    }

    toMetaData(): ICollaborationEditorSavingMessageOutgoingModel {
        let metadata: ICollaborationEditorSavingMessageOutgoingModel = {
            mail: {
                subject: this.subjectInput ? this.subjectInput.getValue() : undefined,
                sendType: this.selectedSendType,
                fromMailId: this.mailsPicker.selectedMailId() ? this.mailsPicker.selectedMailId() : null
            }
        };
        this.recipientsComponents.forEach(value => {
            // @ts-ignore
            metadata.mail[value.type] = value.component.recipients;
        });

        return metadata;
    }

    cleanMessageRecipients(r: ICollaborationEditorSavingMessageRecipientModel[]) {
        r.forEach(messageRecipient => {
            if (messageRecipient.entity) {
                messageRecipient.entity.type = EntityService.getType(messageRecipient.entity);
            }
        });
    }
}