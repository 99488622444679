@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.componentQuillFormattingImageUrl {
  .input {
    height: 36px;
    margin-bottom: 10px;
    border-width: 1px;
  }

  .insert {
    width: fit-content;
    float: right;
    font-size: 13px;
    padding: 6px 14px;
    border-radius: 15px;

    @include texts.font("semi-bold");
    @include buttons.green;
  }

}