import {QuillFormattingBaseButtonComponent} from "../../QuillFormattingBaseButtonComponent";
import {Component} from "../../../../../../../sedestral-interface-component/interface/component/Component";
import {
    ColorsPickerGridTooltipComponent
} from "../../../../../../colors/picker/grid/tooltip/ColorsPickerGridTooltipComponent";
import * as s from "../../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../../QuillFormattingComponent";

export class QuillFormattingButtonFontColorComponent extends QuillFormattingBaseButtonComponent {

    private colorComponent: Component;
    private BASE_COLOR: string = "#000000";

    constructor(formatting: QuillFormattingComponent) {
        super("color", formatting);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton} ${s.large}">
                <div style="background: ${this.BASE_COLOR}" class="${s.fontColor}"></div>
                <div class="${s.icon} ${s.bottom}"></div>
            </button>
        `;
    }

    commit() {
        this.colorComponent = this.el(s.fontColor);
        super.commit();
    }

    update() {
        let color = this.formattingComponent.quillComponent.currentFormats()['color'];
        this.colorComponent.setStyle(`background: ${this.BASE_COLOR}`);
        if (color != undefined) {
            this.colorComponent.setStyle(`background: ${color}`);
        }
        super.update();
    }

    bind() {
        this.onClick(() => {
            let formats = this.formattingComponent.quillComponent.currentFormats();
            let tooltipComponent = new ColorsPickerGridTooltipComponent(this, formats['color'] == undefined ? this.BASE_COLOR : formats['color']);
            tooltipComponent.screen(false);
            tooltipComponent.create();
            tooltipComponent.onPick = (color => {
                tooltipComponent.dispose();
                this.format(color);
            });
        });
    }

    isBaseValue(value: string): boolean {
        return value == this.BASE_COLOR;
    }
}