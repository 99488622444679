@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";

.componentMailsEmailsRecipient {
  border: 1px solid var(--grey-sur);
  font-size: 13px;
  border-radius: 8px;
  padding: 2px;
  height: 28px;
  display: flex;
  cursor: pointer;
  z-index: 1;

  input {
    background: transparent !important;
  }

  &:hover {
    background: var(--grey-sur-hover);
    border-color: transparent;
  }

  &.invalid {
    background: var(--red);
    color: var(--white);

    .remove {
      &:hover {
        background: var(--grey-alpha);
      }

      .icon {
        filter: var(--icon-white);
      }
    }
  }

  &.edition {
    background: transparent;
    padding: 0;
    padding-bottom: 4px;

    input {
      padding-left: 10px;
    }

    &:hover {
      border-color: var(--grey-sur);
    }
  }

  @include texts.font("semi-bold");

  .avatarContainer {
    height: 22px;
    width: 22px;
    margin-left: 3px;
    margin-top: 3.5px;
    border-radius: 100%;
    position: relative;
  }

  .name {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .mail {
      color: var(--grey-text);
      font-size: 10px;

      @include texts.font("medium");
    }
  }

  .remove {
    height: 20px;
    width: 20px;
    margin-top: 4px;

    @include positioning.flex-globally;
    @include buttons.round;

    &:hover {
      background: var(--grey-sur-hover) !important;
    }


    .icon {
      height: 10px;
      width: 10px;

      @include icons.ico("black-close");
    }
  }

  input {
    height: 32px;
    border: unset;
  }
}