import {MediaImageComponent} from "../image/MediaImageComponent";
import {MediaVideoComponent} from "../video/MediaVideoComponent";
import * as s from "./media-gif.scss";
import {MediaAbstractComponent} from "../MediaAbstractComponent";
import {IMedia} from "../types/IMedia";
import {Component} from "../../../sedestral-interface-component/interface/component/Component";
import {Models} from "../../../../models/Models";
import {fileUrlExtension} from "../../../sedestral-interface-component/utilities/FileUrlExtension";
import {fileIsVideo} from "../../../sedestral-interface-component/utilities/FileIsVideo";

export class MediaGifComponent extends MediaAbstractComponent {
    constructor(object: string | File | IMedia | any) {
        super();
        this.contentObject = object;
        this.path = Models.isMediaModel(object) ? object.link : object;
        let type = Models.isMediaModel(object) ? object.extension : (object instanceof File ? object.type : fileUrlExtension(object));

        let template;
        if (fileIsVideo(type)) {
            template = this.draw(new MediaVideoComponent(object, true));
        } else {
            template = this.draw(new MediaImageComponent(object));
        }

        this.template = `
            <div media mediaGif class="${s.globalMediaGif}">
                ${template}
                <div class="${s.pastille}">
                    <div class="${s.icon}"></div>
                </div>
            </div>
        `;
    }

    private _pastilleComponent: Component;

    get pastilleComponent(): Component {
        return this._pastilleComponent;
    }

    set pastilleComponent(value: Component) {
        this._pastilleComponent = value;
    }

    commit() {
        this._pastilleComponent = this.el(s.pastille);
        super.commit();
    }
}