@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/outline";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentEditorInboxMail {
  height: fit-content !important;
  width: 100%;
  position: relative;

  div[qeditor] {
    padding-top: 25px;

    div[contenteditable][data-gramm] {
      min-height: 80px;
    }
  }

  div[edButtons] {
    padding: 10px 0px 10px 0px;
  }

  .lines {
    width: calc(100% - 24px);
    margin-left: 12px;

    .line {
      display: flex;
      width: 100%;
      position: relative;
      margin-top: 6px;
      border-bottom: 1px solid var(--grey-sur);
      padding-bottom: 6px;

      .input {
        width: 100%;
        padding: 0;
        height: 26px;
        border: unset !important;
      }

      .case {
        height: 26px;
        flex: 0 0 20px;
        font-size: 13px;
        padding: 0px 6px;
        margin-right: 8px;
        border-radius: 6px;
        background: var(--blue-new);
        color: var(--blue-new-text);

        @include texts.font("semi-bold");
        @include positioning.flex-globally;

        &.recipients {
          height: 34px;
        }
      }

      .recipientsContainer {
        display: flex;
        width: 100%;

        div[data-selectbox] {
          border-radius: 6px;
          width: 100%;
        }
      }

      .collaborationContainer {
        margin-left: 10px;
      }

      .moreButton {
        height: 34px;
        border-radius: 20px;
        width: 28px;
        cursor: pointer;

        @include positioning.flex-globally;

        &:hover {
          border-color: transparent;
          background: var(--grey-sur);
        }

        .icon {
          height: 12px;
          width: 12px;

          @include icons.ico("black-more");
        }
      }

      .sendTypeButton {
        margin-right: 6px;
        display: flex;
        align-items: center;
        padding: 5px 6px;
        height: 22px;
        border-radius: 8px;
        border: 1px solid var(--grey-sur);
        cursor: pointer;
        transition: 0.1s;

        &:hover {
          border-color: transparent;
          background: var(--grey-sur);
        }

        .icon {
          height: 14px;
          width: 14px;

          &.reply {
            @include icons.ico("black-reply");
          }

          &.forward {
            @include icons.ico("black-forward");
          }
        }

        .arrow {
          height: 10px;
          width: 10px;
          margin-left: 12px;

          @include icons.ico("black-bottom");
        }
      }
    }
  }
}