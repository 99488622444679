@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/editor";

.globalQuillFormattingSize {
  font-size: 14px;

  .listEntry {
    text-align: left;
    cursor: pointer;
    position: relative;

    @include editor.tags;

    .selectedIcon {
      height: 10px;
      width: 10px;
      float: right;
      position: absolute;
      right: 8px;
      top: calc(50% - 5px);
      display: none;

      @include icons.ico("black-checked");
    }

    &.selected {
      .selectedIcon {
        display: block;
      }
    }

    h1, h2, h3, p, h4, h5, h6 {
      margin: 6px;
      padding: 2px 8px;
    }

    &:hover {
      background: var(--grey-sur);
    }
  }
}