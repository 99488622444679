import {QuillBlotBlock} from "../QuillBlotBlock";

export class QuillSectionBlot extends QuillBlotBlock {

    public keyboard: any;

    constructor(scroll, domNode) {
        super(scroll, domNode);
        this.offsetSelect = 1;
        this.collaborativeCursor = true;
    }

    onReady() {
        super.clickable({selection: false, pointer: false});
        this.component.setAttribute("contenteditable", "false");

        this.onCursorSelect(() => this.focus(), () => this.blur());
        this.component.onRemove(() => this.blur());

        super.onReady();
    }


    focus() {

    }

    blur() {

    }

}

QuillSectionBlot.blotName = 'section';
QuillSectionBlot.tagName = 'section';