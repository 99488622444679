@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/effects";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/buttons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/extends/boxs";
@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";

.componentNotifications {
  height: 100%;
  width: 100%;

  div[scrolltrack] {
    right: -2px;
  }

  &[scrollvirtual] {
    padding-left: 6px;
    margin-left: -6px;

    .content {
      width: calc(100% - 4px);
    }
  }

  .top {
    height: 22px;
    margin-top: 7px;
    margin-bottom: 15px;
    position: relative;

    .title {
      float: left;

      @include texts.font("bold");

      .text {
        font-size: 18px;
        float: left;
      }

      .notifs {
        border-radius: 100%;
        color: var(--white);
        height: 16px;
        width: 16px;
        top: 1px;
        position: relative;
        background: var(--red);
        float: left;
        margin-left: 8px;
        margin-top: 3px;
        padding: 1px;
        font-size: 11px;

        @include positioning.flex-globally;
        @include texts.font("semi-bold");
      }
    }

    .viewButton {
      position: relative;
      float: right;
      padding: 5px;
      top: -2px;
      left: 0;

      @include buttons.round;
      @include positioning.flex-globally;

      .icon {
        height: 18px;
        width: 18px;

        @include icons.ico("black-view");
      }
    }

  }

  .content {
    visibility: visible;

    .loading {
      visibility: visible;
      height: calc(100% - 60px);
      overflow: hidden;
      position: absolute;
      width: calc(100% - 8px);
      border-radius: 8px;

      &.empty {
        margin-top: -40px;

        .icon {
          height: 120px;
          width: 120px;

          @include icons.ico("3d-shipment");
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .loading {
      height: calc(100% - 15px) !important;
      width: calc(100% - 0px) !important;
    }
    .top {
      display: none;
    }
  }

  @media screen and (max-width: 1100px) {
    .top {
      .title {
        .text {
          font-size: 15px !important;
          margin-top: 2px !important;
        }
      }
    }
  }
}