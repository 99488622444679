.componentMailsEmailsRecipients {
  height: 100%;
  width: 100%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row wrap;
  -webkit-box-align: end;
  display: inline-flex;
  vertical-align: baseline;
  row-gap: 6px;
  column-gap: 6px;
  position: relative;

  .selector {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}