import * as s from "./media-video.scss";
import {MediaAbstractComponent} from "../MediaAbstractComponent";
import {Models} from "../../../../models/Models";
import {LoaderWaveComponent} from "../../loader/wave/LoaderWaveComponent";
import {Component} from "../../../sedestral-interface-component/interface/component/Component";

export class MediaVideoComponent extends MediaAbstractComponent {
    public loop: boolean;

    public video: Component;
    public play: Component;
    public loader: LoaderWaveComponent;

    public videoElement: HTMLVideoElement;

    constructor(object: string | File | any, loop?: boolean) {
        super();
        this.loop = loop;
        this.contentObject = object;
        this.path = Models.isMediaModel(object) ? object.link : (object instanceof File ? window.URL.createObjectURL(object) : object);
        this.loader = new LoaderWaveComponent();

        //language=HTML
        this.template = `
            <div media mediaVideo class="${s.globalMediaVideo}">
                ${this.draw(this.loader)}
                <video class="${s.video}" ${loop ? "muted" : ""}>
                    <source src="${this.path}" type="video/mp4"/>
                </video>
                <div class="${s.play}">
                    <div class="${s.icon}"></div>
                </div>
            </div>
        `;
    }

    commit() {
        this.video = this.el(s.video);
        this.play = this.el(s.play);

        let videoElement = this.video.getHTMLElement() as HTMLVideoElement;

        this.video.addListener("canplay", () => {
            this.videoElement = videoElement;
            this.video.displayShow();

            this.resize();
            this.onResize(() => this.resize());
            this.loader.remove();
        });


        if (this.loop) {
            this.video.setAttribute("loop", "");
            this.video.setAttribute("autoplay", "");
            this.video.setAttribute("muted", "");

            this.loader.remove();
            this.play.remove();
        } else {
            this.play.onClick(() => {
                this.play.remove();
                videoElement.play();
                this.video.setAttribute("controls", "");
                this.video.setStyle(`width: 100%;`);
            });
        }

        super.commit();
    }

    resize() {
        if (this.videoElement) {
            super.resize(this.videoElement.videoWidth, this.videoElement.videoHeight, this.video);
        }
    }
}