@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.globalCheckboxChoices {

  .choiceContainer {
    min-height: 30px;
    position: relative;
    margin-bottom: 10px;

    .choiceHead {
      @include positioning.clear;

      .checkbox {
        float: left;
        margin-top: 14px;
      }

      .choiceName {
        float: left;
        margin-top: 14px;
        font-size: 14px;
        margin-left: 10px;
        max-width: calc(100% - 30px);


        @include texts.font("semi-bold");
      }
    }

    .choiceContent {
      position: relative;
      margin-top: 20px;
      display: none;
      margin-bottom: 20px;
      width: 100%;
    }
  }

  &.left {
    .choiceContainer {
      float: left;
      margin-right: 10px;

      .checkbox, .choiceName {
        margin-top: 0;
      }
    }
  }

}