import {EditorButton} from "./EditorButton";
import {EditorComponent} from "../EditorComponent";
import * as s from "../editor.scss";
import {
    QuillGoodiesTooltipComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/editor/goodies/tooltip/QuillGoodiesTooltipComponent";
import {
    QuillGoodiesSwapComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/quill/editor/goodies/swap/QuillGoodiesSwapComponent";
import {Resources} from "../../../../../../resources/Resources";

export class EditorButtonGoodies extends EditorButton {
    constructor(editor: EditorComponent) {
        super(editor);
    }

    init() {
        //language=HTML
        this.button = this.editor.rightButtonsContainer.append(`
            <div data-tooltip="${Resources.t('words.gifAndEmojisButtonTooltip')}" button class="${s.button}">
                <div class="${s.icon} ${s.emojis}"></div>
            </div>
        `);


        this.initPanel(this.button, () => {
            let tooltip = !this.editor.config.pocket ? new QuillGoodiesTooltipComponent(this.button, this.editor.config.emojis, this.editor.config.gifs)
                : new QuillGoodiesSwapComponent(this.editor.config.emojis, this.editor.config.gifs, Resources.t('words.gifs'), this.editor);
            tooltip.goodies.onEmojiPick = (emoji => {
                this.editor.quillComponent.insertText(emoji);
                tooltip.dispose();
            });
            tooltip.goodies.onGifPick = (url => {

                if (this.editor.config.formatting) {
                    this.editor.quillComponent.insertImage(url, true);
                } else {
                    this.editor.quillComponent.addAttachment(url);
                }

                tooltip.dispose();
            });
            return tooltip;
        });
    }
}