import {Component} from "../../../../sedestral-interface-component/interface/component/Component";
import * as s from "./quill-collaborators.scss";
import {
    ICollaborationEditorViewerModel
} from "../../../../../models/collaboration/editor/ICollaborationEditorViewerModel";
import {AvatarComponent} from "../../../../../products/panel/components/components/avatar/AvatarComponent";
import {
    AccountTooltipComponent
} from "../../../../../products/panel/components/components/account/tooltip/AccountTooltipComponent";

export class QuillCollaboratorsComponent extends Component {

    public activeAccountsContainer: Component;

    public avatars: { accountId: string, component: Component }[];

    constructor() {
        super();
        this.avatars = [];
        //language=HTML
        this.template = `
            <div class="${s.componentQuillCollaborators}">
                <div class="${s.activeAccountsContainer}"></div>
            </div>
        `;
    }

    commit() {
        this.activeAccountsContainer = this.el(s.activeAccountsContainer);

        super.commit();
    }

    renderViewers(viewers: ICollaborationEditorViewerModel[]) {
        viewers.forEach(value => this.renderViewer(value));
    }

    renderViewer(viewer: ICollaborationEditorViewerModel) {
        if (!this.avatars.find(value => value.accountId == viewer.account.id)) {
            //language=HTML
            let avatar = this.activeAccountsContainer.append(`
                <div collabFloat class="${s.avatarFloat}">
                    <div collabAvatar class="${s.avatarBorder}">
                        <div style="border: 2px solid ${viewer.color};"
                             class="${s.avatarContainer}">
                            ${this.activeAccountsContainer.draw(new AvatarComponent(viewer.account.avatar, {
                contentId: viewer.account.id,
                componentClass: AccountTooltipComponent
            }))}
                        </div>
                    </div>
                </div>
            `);

            this.avatars.push({accountId: viewer.account.id, component: avatar});
        }
    }

    removeViewer(viewer: ICollaborationEditorViewerModel) {
        let a = this.avatars.filter(value => value.accountId == viewer.account.id)[0];
        if (a != undefined) {
            a.component.remove();
            this.avatars.splice(this.avatars.indexOf(a), 1);
        }
    }
}