import {QuillFormattingBaseButtonComponent} from "../../QuillFormattingBaseButtonComponent";
import {QuillFormattingSizeComponent} from "../size/QuillFormattingSizeComponent";
import * as s from "../../quill-formatting-button.scss";
import {QuillFormattingComponent} from "../../../QuillFormattingComponent";

export class QuillFormattingButtonFontSizeComponent extends QuillFormattingBaseButtonComponent {
    private BASE_FONTSIZE: string = "14px";

    constructor(formatting: QuillFormattingComponent, name: string) {
        super(name, formatting);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton} ${s.large}">
                <div class="${s.icon} ${s.fontSize}"></div>
                <div class="${s.icon} ${s.bottom}"></div>
            </button>
        `;
    }

    bind() {
        this.onClick(() => {
            let formats = this.formattingComponent.quillComponent.currentFormats();
            let entries = this.name == "header" ? [
                {size: "2", tag: "h2", name: `En-tête 2`},
                {size: "3", tag: "h3", name: `En-tête 3`},
                {size: "4", tag: "h4", name: `En-tête 4`},
                {size: "5", tag: "h5", name: `En-tête 5`},
                {size: "6", tag: "h6", name: `En-tête 6`},
                {size: "false", tag: "p", name: "Normal"}
            ] : [
                {size: "2em", tag: "h1", name: "Large"},
                {size: "1.5em", tag: "h2", name: "Grand"},
                {size: "1.17em", tag: "h3", name: "Moyen"},
                {size: "false", tag: "p", name: "Normal"}
            ];

            let tooltipComponent = new QuillFormattingSizeComponent(this, formats[this.name], entries);
            tooltipComponent.screen(false);
            tooltipComponent.create();
            tooltipComponent.onPick = (size) => {
                tooltipComponent.dispose();

                this.format(size == "false" ? false : size);
            }
        });
    }

    isBaseValue(value: string): boolean {
        return value == this.BASE_FONTSIZE;
    }
}