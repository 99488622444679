/**
 * Redimensionner une image en gardant le ratio
 */

export function fileResizeImage(originalWidth: number, originalHeight: number, maxWidth: number, maxHeight: number) {
    let newWidth: number, newHeight: number;
    let originalRatio = originalWidth / originalHeight;

    if (originalWidth > originalHeight) {
        newWidth = Math.min(originalWidth, maxWidth);
        newHeight = newWidth / originalRatio;
    } else {
        newHeight = Math.min(originalHeight, maxHeight);
        newWidth = newHeight * originalRatio;
    }

    if (newWidth > maxWidth) {
        newWidth = maxWidth;
        newHeight = newWidth / originalRatio;
    }
    if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = newHeight * originalRatio;
    }

    return {width: newWidth, height: newHeight};
}

export async function getImageSize(url: string): Promise<{width: number, height: number}> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            resolve({ width: img.width, height: img.height });
        };
        img.onerror = () => {
            resolve(undefined);
        };
        img.src = url;
    });
}