import {QuillFormattingBaseButtonComponent} from "../../QuillFormattingBaseButtonComponent";
import {QuillComponent} from "../../../../../QuillComponent";
import * as s from "../../quill-formatting-button.scss";
import {QuillFormattingFamilyComponent} from "../family/QuillFormattingFamilyComponent";
import {Component} from "../../../../../../../sedestral-interface-component/interface/component/Component";
import {QuillFormattingComponent} from "../../../QuillFormattingComponent";

export let BASE_FONT: string = "Montserrat Medium";

export class QuillFormattingButtonFontFamilyComponent extends QuillFormattingBaseButtonComponent {

    public nameContainer: Component;

    constructor(formatting: QuillFormattingComponent) {
        super("font", formatting);
        //language=HTML
        this.template = `
            <button class="${s.globalQuillFormattingButton} ${s.fontFamily}">
                <div class="${s.icon} ${s.fonts}"></div>
                <span class="${s.name}">Montserrat</span>
                <div class="${s.icon} ${s.bottom}"></div>
            </button>
        `;
    }

    commit() {
        this.nameContainer = this.el(s.name);
        super.commit();
    }

    bind() {
        this.onClick(() => {
            let formats = this.formattingComponent.quillComponent.currentFormats();
            let tooltipComponent = new QuillFormattingFamilyComponent(this, formats['font']);
            tooltipComponent.screen(false);
            tooltipComponent.create();
            tooltipComponent.onPick = (size) => {
                tooltipComponent.dispose();
                this.format(size);
            }
        });
    }

    update() {
        let formats = this.formattingComponent.quillComponent.currentFormats();
        let value = formats['font'] == undefined || formats['font'] == "" ? BASE_FONT : formats['font'];
        let font = QuillComponent._fonts.filter(f => f.font == value)[0];
        if (font) {
            this.nameContainer.setStyle(`font-family:${font.font};`);
            this.nameContainer.setHtml(font.name);
            super.update();
        }
    }

    addClass(cls: string) {
        if (cls == s.active) {
            return;
        }

        super.addClass(cls);
    }

    isBaseValue(value: string): boolean {
        return value == BASE_FONT;
    }
}