@use "src/sedestral-interface-modules/sedestral-interface-sass/icons/icons";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.globalPromptCode {
  display: flex;
  flex-direction: row;
  gap: 12px;

  .input {
    height: 50px;
    width: 50px;
    text-align: center;
    font-size: 18px;
    caret-color: transparent;

    @include texts.font("semi-bold");

    &:focus {
      border: 2px solid var(--blue-hover) !important;
    }
  }
}